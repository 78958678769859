/* istanbul ignore file */
(() => {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    const newParams = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, newParams.bubbles, newParams.cancelable, newParams.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
  return true;
})();
