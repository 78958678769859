import styles from './bulk_upload_view.css';
import commonStyles from '../../css/common.css';
import { remove } from '../../assets/icons';

const cancelBulkUploadId = 'cancelBulkUpload';
export const cancelBulkUploadSelector = '#cancelBulkUpload';

const bulkUploadFormId = 'bulkUploadForm';
export const bulkUploadFormSelector = '#bulkUploadForm';

const bulkUploadFileInputId = 'bulkUploadFileInput';
export const bulkUploadFileInputSelector = '#bulkUploadFileInput';

const bulkUploadErrorMessageId = 'bulkUploadErrorMessage';
export const bulkUploadErrorMessageSelector = '#bulkUploadErrorMessage';

const bulkUploadFileLabelId = 'bulkUploadFileLabel';
export const bulkUploadFileLabelSelector = '#bulkUploadFileLabel';

const auxTextNode1Id = 'auxTextNode1';
export const auxTextNode1Selector = `#${auxTextNode1Id}`;

const auxTextNode2Id = 'auxTextNode2';
export const auxTextNode2Selector = `#${auxTextNode2Id}`;

const bulkUploadRemoveFileId = 'bulkUploadRemoveFile';
export const bulkUploadRemoveFileSlector = `#${bulkUploadRemoveFileId}`;

const sendEcardId = 'sendEcard';
export const sendEcardSelector = '#sendEcard';

const bulkUploadTemplate = (translateKey, showHelpGuide, isCcMigratedCustomer, groupHelpGuideLink) => `<article aria-live="off">
  <p class="${styles.bulkUploadHeading}">${translateKey('give-widget-group-ecard', 'Group eCard')}</p>
  <div id="${cancelBulkUploadId}"></div>
  <p class="${styles.advice}" aria-live ="polite">
    ${translateKey('give-widget-bulk-upload-info-text', `To add several people at the same time,
    upload a properly formatted Excel document.
    Not sure if its's correctly formatted correctly?
    Download one of the following templates: `)}
  </p>
  <a
    href="https://vision.appreciatehub.com/ui/givewidget/employee_template.xls"
    id="bulk-ecard-employee-template"
    target="_blank"
    class="${styles.link}"  
  >
    ${translateKey('give-widget-download-employee-id-template-label', 'Download Employee Id Template')} ${translateKey('excel', 'excel')}
  </a>
  <a href="https://vision.appreciatehub.com/ui/givewidget/email_template.xls" id="bulk-ecard-email-template" target="_blank" class="${styles.link}">
    ${translateKey('give-widget-download-employee-email-template-label', 'Download Email Template')} ${translateKey('excel', 'excel')}
  </a>
  ${!isCcMigratedCustomer ?
    `<a href="${groupHelpGuideLink}" id="bulk-ecard-help-guide" target="_blank" class="${styles.link} ${showHelpGuide ? '' : styles.hidden}">
    ${translateKey('group-help-guide', 'Help Guide')} ${translateKey('pdf', 'PDF')}
  </a>` : ''}
  <p class="${styles.bulkUploadErrorMessage} ${styles.hidden}" id="${bulkUploadErrorMessageId}" aria-live="polite"></p>
  <form class="${styles.bulkUploadForm}" id="${bulkUploadFormId}" aria-live="off">
    <fieldset>
      <legend class="${styles.auxText}" id="${auxTextNode1Id}">
      ${translateKey('give-widget-drag-drop-template', 'Drag and drop .xls, .xlsx, or .csv file to upload.')}
      </legend>
      <input type="file" id="${bulkUploadFileInputId}" class="${styles['sr-only']}" accept=".xls, .xlsx, .csv" multiple="false" autocomplete="off" 
       />
      <label for="${bulkUploadFileInputId}" class="${styles.fileUploadLabel}" id="${bulkUploadFileLabelId}" aria-hidden="true" >
        ${translateKey('upload-file', 'upload-file')}
      </label>
      <button
        class="${styles.linkButton} ${styles.removeButton} ${styles.hidden}" id="${bulkUploadRemoveFileId}" type="button">
          ${remove}
          <div class="${styles['sr-only']}">remove</div>
      </button>
      <p class="${styles.auxText} ${styles.hidden}" id="${auxTextNode2Id}"></p>
    </fieldset>
  </form>
  <div id="${styles.callToActionDiv}" class="${styles.nextButtonWrapper} ${commonStyles.callToActionDiv}">
    <div id=${styles.nextButton}></div>
    <div class="${styles.buttonContainer}">
        <button id="${sendEcardId}" type="button">Send eCard</button>
    </div>
  </div>
  </article>`;

export default bulkUploadTemplate;
