import styles from './ecard_tile.css';
import { ribbon } from '../../assets/icons';
import { getEcardUrl } from '../../utils/utils';

export const imageElement = (card) => {
  // These are listed in order of precedence.
  const imageUrl = getEcardUrl(card);
  if (imageUrl !== '') {
    return `<div class="${styles.eCardImgWrapper}">
      <img src="${imageUrl}" class="${styles.eCardImg}" title="${card.text}" alt="${card.text}">
    </div>`;
  }

  return `<div class="${styles.defaultPreview}"></div>`;
};

const eCardTileTemplate = (props, translateKeyFn, index) => (
  `<li class="${styles.eCardTile} ${props.class}" role="option" data-index="${index}" aria-labelledby="ecard_tile_select_${index}">
    ${imageElement(props.card)}
    <div class="${styles.overlay}">
      <button
        id="ecard_tile_select_${index}"
        class="${styles.selectBtn} select"
        aria-label="${translateKeyFn('give-widget-select-ecard-label', `Select ${props.card.text}`, '{cardName}', props.card.text)}"
        type="button"
        tabindex="0"
      >
        ${translateKeyFn('select', 'Select')}
      </button>
      <button class="${styles.previewBtn} preview" type="button" tabindex="0"
        aria-label="${translateKeyFn('preview', 'Preview')} ${props.card.text}">
        ${translateKeyFn('preview', 'Preview')}
      </button>
    <button class="${styles.favBtn} favorite" aria-label="Favorite ${props.card.text}" type="button" tabindex="0" >${ribbon}</button>
  </li>`
);

export default eCardTileTemplate;
