import emptyElement, { getFirstElementChild, positionElement } from '../../utils/utils';
import eCardListContainerTemplate, { eCardItemsTemplate, previewTemplate } from './ecard_list_container_template.js';
import ECardTile from '../ecard_tile/ecard_tile.js';
import SelectBox from '../select-box/select-box';
import styles from './ecard_list_container.css';
import ecardSelectionViewStyles from '../../views/ecard_selection_view/ecard_selection_view.css';
import eCardTileStyles from '../ecard_tile/ecard_tile.css';

export default class ECardListContainer {
  defaultProps = {
    eCardList: [],
    filterCategories: [],
    onSelectECard: () => {},
    onFavoriteECard: () => {},
    uploadTabsShowing: false
  };

  eCardTiles = [];

  static prepCategories(arr = [], translateKeyFn) {
    return [
      {
        text: translateKeyFn('all', 'All'),
        value: ''
      },
      ...arr.map((item) => ({
        text: item,
        value: item
      }))
    ];
  }

  set filteredECardList(x) {
    if (Array.isArray(x)) {
      this.props.filteredECardList = x;
      emptyElement(this.eCardListUl);
      this.eCardListUl.insertAdjacentHTML('afterbegin', eCardItemsTemplate(this.props.filteredECardList));
      this.props.filteredECardList.forEach((card, i) => {
        this.eCardTiles[i] = new ECardTile(`#ecard-tile-${i}`, i);
        this.eCardTiles[i].render({
          card,
          index: i,
          favorited: card.isFavorited,
          onClickPreview: this.openPreview,
          onSelectTile: this.props.onSelectECard,
          onClickFavorite: (isFavorited, eproductName) => {
            this.props.onFavoriteECard(isFavorited, eproductName);
          },
          id: `${card.eproductName}`
        }, this.translateKeyFn);
      });
      this.eCardIfViewMoreShows(this);
    }
  }

  set eCardList(x) {
    if (Array.isArray(x)) {
      this.props.eCardList = x;
      this.filteredECardList = x;
    }
  }

  set uploadTabsShowing(x) {
    this.props.uploadTabsShowing = x;
    if (x) {
      this.node.classList.add(styles.withTabs);
    } else {
      this.node.classList.remove(styles.withTabs);
    }
  }

  changeCategory = (wrappedCategory) => {
    if (wrappedCategory.value === '') {
      this.filteredECardList = this.props.eCardList;
    } else {
      this.filteredECardList = this.props.eCardList.filter((card) => (
        card.categories.indexOf(wrappedCategory.value) > -1
      ));
    }

    positionElement(`#${ecardSelectionViewStyles.callToActionDiv}`);
  };

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for ECardList component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    this.translateKeyFn = translateKeyFn;
    const eCardViewFragment = document.createRange().createContextualFragment(eCardListContainerTemplate(translateKeyFn));
    const eCardViewTemp = this.node;
    this.node = getFirstElementChild(eCardViewFragment);
    eCardViewTemp.parentNode.replaceChild(this.node, eCardViewTemp);
    this.eCardListUl = this.node.querySelector(`.${styles.eCardList}`);
    this.eCardsPerPage = 21;
    this.filterSelectBox = new SelectBox('#filter-ecard');
    this.loadMoreButton = document.querySelector('#give-widget_ecard-load-more');
    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener('click', this.eCardIfViewMoreClicked);
    }
    this.filterSelectBox.render({
      data: ECardListContainer.prepCategories(this.props.filterCategories, translateKeyFn),
      onChange: this.changeCategory,
      className: styles.filterSelectBox,
      ariaLabel: 'Filter eCards by category'
    });

    this.filteredECardList = this.props.eCardList;
    this.uploadTabsShowing = this.props.uploadTabsShowing;
  }

  eCardIfViewMoreClicked = () => {
    const cardsHidden = document.querySelectorAll(`.${eCardTileStyles.eCardTile}.${styles.hidden}`);
    for (let i = 0; i < cardsHidden.length && i < this.eCardsPerPage; i++) {
      cardsHidden[i].classList.remove(styles.hidden);
    }

    cardsHidden[0].querySelector(`.${eCardTileStyles.selectBtn}`).focus();

    positionElement(`#${ecardSelectionViewStyles.callToActionDiv}`);
    if (cardsHidden.length <= this.eCardsPerPage) {
      this.loadMoreButton.classList.add(styles.hidden);
    }
  }

  eCardIfViewMoreShows = () => {
    const cardsNotHidden = document.querySelectorAll(`.${eCardTileStyles.eCardTile}:not(.${styles.hidden})`);
    for (let i = this.eCardsPerPage; i < cardsNotHidden.length; i++) {
      cardsNotHidden[i].classList.add(styles.hidden);
    }
    if (cardsNotHidden.length > this.eCardsPerPage) {
      this.loadMoreButton.classList.remove(styles.hidden);
    } else if (this.loadMoreButton) {
      this.loadMoreButton.classList.add(styles.hidden);
    }
  }

  openPreview = (evt, index) => {
    const width = this.node.offsetWidth;
    let cardsPerRow = 3;
    if (width < 457) {
      cardsPerRow = 1;
    } else if (width < 620) {
      cardsPerRow = 2;
    }

    const position = index % cardsPerRow;
    let insertAfterIndex = index + (cardsPerRow - position - 1);
    if (insertAfterIndex >= this.props.filteredECardList.length) {
      insertAfterIndex = this.props.filteredECardList.length - 1;
    }

    if (this.previewElement) {
      this.removePreview(index, () => {
        this.insertPreview(index, insertAfterIndex);
      });
    } else {
      this.insertPreview(index, insertAfterIndex);
    }
  };

  insertPreview = (index, insertAfterIndex) => {
    // TODO: translation for button text
    this.eCardTiles[insertAfterIndex].node.insertAdjacentHTML(
      'afterend',
      previewTemplate(this.eCardTiles[index].card, this.translateKeyFn('give-widget-select-ecard', 'Select eCard'), this.translateKeyFn)
    );
    this.previewElement = this.node.querySelector(`.${styles.preview}`);
    const closeButton = this.previewElement.querySelector(`.${styles.close}`);
    const previewContent = this.previewElement.querySelector(`.${styles.previewContent}`);
    closeButton.addEventListener('click', this.removePreview.bind(this, index));
    closeButton.focus();
    // We need to make sure that the inserted div has time to render before we remove the class
    window.requestAnimationFrame(() => {
      this.previewElement.classList.remove(styles.collapsed);
      this.previewElement.querySelector(`.${styles.select}`).addEventListener('click', (evt) => {
        this.props.onSelectECard(evt, this.props.filteredECardList[index]);
      });
    });
    this.previewElement.addEventListener('transitionend', () => {
      previewContent.scrollIntoView();
    });
  };

  removePreview = (index, afterRemove) => {
    this.previewElement.classList.add(styles.collapsed);
    const previewBtn = this.eCardTiles[index].node.querySelector(`.${eCardTileStyles.previewBtn}`);
    previewBtn.classList.add(eCardTileStyles.show);
    previewBtn.focus();
    this.eCardListUl.appendChild(this.previewElement);
    // Wait for the css transition to collapse the previous preview before removing it
    window.setTimeout(() => {
      if (this.previewElement) {
        this.eCardListUl.removeChild(this.previewElement);
        delete this.previewElement;
      }
      if (typeof afterRemove === 'function') {
        afterRemove();
      }
    }, 500);
  };
}
