import styles from './modal.css';
import commonStyles from '../../css/common.css';
import modalTemplate, { modalBodySelector } from './modal_template';
import emptyElement, { getFirstElementChild, positionElement } from '../../utils/utils';

/**
 * Constants required by Modal Class
 * Constants are selectors and event names
 */
const dialogSelector = '.js-modalDialog';
const closeBtnSelector = '.js-close';
const submitBtnSelector = '.js-submit';
const activeClass = styles['js-show'];
const inactiveClass = styles['js-hide'];
const modalOpenRequestedEventName = 'uiModalOpenRequested';
const modalCloseRequestedEventName = 'uiModalCloseRequested';
const modalOpenedEventName = 'uiModalOpened';
const modalClosedEventName = 'uiModalClosed';

let initialScrollPosition;
/**
 * Setting the inital Modal Id to 0, every component gets its own modal Id
 */
let initialModalId = 0;

/**
 * Class representing a Modal component
 *
 */
export default class Modal {
  static generateId() {
    initialModalId += 1;
    return `modal${initialModalId - 1}`;
  }

  static isNodeDisabled(node) {
    return node.disabled === undefined || node.disabled === null ? false : node.disabled;
  }

  static getTabIndex(node) {
    const tabIndex = node.getAttribute('tabindex');
    if (tabIndex === '' || tabIndex === null) return undefined;
    return parseInt(tabIndex, 10);
  }

  static isNodeVisible(node) {
    return !!(node.offsetWidth || node.offsetHeight || node.getClientRects().length);
  }

  static isRadioGroup(node) {
    return node.getAttribute('role') === 'radiogroup';
  }

  static isElementInteractable(node) {
    return (
      node.nodeType === 1 &&
      (Modal.getTabIndex(node) === 0 ||
        (node.nodeName.toLowerCase() === 'a' &&
          node.getAttribute('href') !== '' &&
          node.getAttribute('href') !== null &&
          Modal.isNodeDisabled(node) === false &&
          Modal.isNodeVisible(node)) ||
        (node.nodeName.toLowerCase() === 'button' &&
          Modal.isNodeDisabled(node) === false &&
          Modal.getTabIndex(node) !== -1 &&
          Modal.isNodeVisible(node)) ||
        (node.nodeName.toLowerCase() === 'input' &&
          Modal.isNodeDisabled(node) === false &&
          Modal.getTabIndex(node) !== -1 &&
          Modal.isNodeVisible(node)) ||
        (node.nodeName.toLowerCase() === 'select' &&
          Modal.isNodeDisabled(node) === false &&
          Modal.getTabIndex(node) !== -1 &&
          Modal.isNodeVisible(node)) ||
        (node.nodeName.toLowerCase() === 'textarea' &&
          Modal.isNodeDisabled(node) === false &&
          Modal.getTabIndex(node) !== -1 &&
          Modal.isNodeVisible(node)))
    );
  }

  static getLastTabbableChild(node) {
    /**
     * DFS algorithm, I am doing this becuase its faster then querySelectorAll
     * We are am not considering tabIndex order
     * Please read the below stackoverflow question
     * https://stackoverflow.com/questions/31659567/performance-of-mutationobserver-to-detect-nodes-in-entire-dom
     * Its not advisable to check for offsetWidth and offsetHeight as it leads to layout trashing, but its unavoidable
     */
    let { childNodes } = node;
    /**
     * We are considering a radio group as a single node for focus.
     */
    if (Modal.isRadioGroup(node) && Modal.isNodeVisible(node)) { return node; }
    childNodes = [].slice.call(childNodes);
    if (childNodes.length === 0) return Modal.isElementInteractable(node) ? node : null;
    return childNodes
      .reverse()
      .filter((childNode) => childNode.nodeType === 1)
      .filter((childNode) => childNode.getAttribute('role') !== 'dialog')
      .filter((childNode) => (childNode.computedStyleMap && childNode.computedStyleMap().get('display')?.value) !== 'none')
      .reduce((acc, childNode) => {
        if (acc === null && childNode.childNodes.length > 0) {
          const lastTabbableChild = Modal.getLastTabbableChild(childNode);
          if (lastTabbableChild !== null) {
            return lastTabbableChild;
          } else if (lastTabbableChild === null && Modal.isElementInteractable(childNode)) { return childNode; }
          return acc;
        } else if (acc === null && Modal.isElementInteractable(childNode)) return childNode;
        return acc;
      }, null);
  }

  cachedFirstElement = null;
  cachedLastElement = null;

  defaultProps = {
    id: Modal.generateId(),
    isOpen: false,
    closable: true,
    closeOnSubmit: false,
    isFullScreen: true,
    innerHTML: '',
    beforeShow: () => {},
    beforeClose: () => {},
    afterClose: () => {},
    onSubmit: () => {},
    triggeringElement: {} // the element that was clicked to open the modal
  };

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to modal component`);
    }
  }

  set id(x) {
    this.node.setAttribute('id', x);
    this.props.id = x;
  }

  get id() {
    return this.props.id;
  }

  set isOpen(x) {
    if (x === true) {
      this.open({
        type: modalOpenRequestedEventName,
        detail: {
          id: this.id
        }
      });
    } else {
      this.close({
        type: modalCloseRequestedEventName,
        detail: {
          id: this.id
        }
      });
    }
    this.props.isOpen = x;
  }

  get isOpen() {
    return this.props.isOpen;
  }

  set closable(x) {
    if (x) {
      this.enableAllCloseButtons();
    } else {
      this.disableAllCloseButtons();
    }
    this.props.closable = x;
  }

  get closable() {
    return this.props.closable;
  }

  set closeOnSubmit(x) {
    this.props.closeOnSubmit = x;
  }

  get closeOnSubmit() {
    return this.props.closeOnSubmit;
  }

  set beforeClose(x) {
    this.props.beforeClose = x;
  }

  get beforeClose() {
    return this.props.beforeClose;
  }

  set afterClose(x) {
    this.props.afterClose = x;
  }

  get afterClose() {
    return this.props.afterClose;
  }

  set beforeShow(x) {
    this.props.beforeShow = x;
  }

  get beforeShow() {
    return this.props.beforeShow;
  }

  set onSubmit(x) {
    this.props.onSubmit = x;
  }

  get onSubmit() {
    return this.props.onSubmit;
  }

  set isFullScreen(x) {
    this.props.isFullScreen = x;
    if (x) {
      this.dialog.classList.remove(styles.notFullSize);
      this.dialog.classList.add(styles.fullSize);
    } else {
      this.dialog.classList.remove(styles.fullSize);
      this.dialog.classList.add(styles.notFullSize);
    }
  }

  set innerHTML(x) {
    this.props.innerHTML = x;
    const frag = document.createRange().createContextualFragment(x);
    const modalBody = this.node.querySelector(modalBodySelector);
    emptyElement(modalBody);
    let { firstChild } = frag;
    while (firstChild) {
      modalBody.appendChild(firstChild);
      ({ firstChild } = frag);
    }
  }

  get innerHTML() {
    return this.props.innerHTML;
  }

  open({ type = '', detail = {} } = {}) {
    initialScrollPosition = window.scrollY;
    if (type === modalOpenRequestedEventName && detail.id === this.props.id) {
      this.props.beforeShow();
      this.node.classList.remove(inactiveClass);
      this.node.classList.add(activeClass);
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.position = 'fixed';
      document.querySelector('body').style.top = `${initialScrollPosition * -1}px`;
      document.querySelector('body').style.left = 0;
      document.querySelector('body').style.right = 0;
      this.handleAriaOnOpen();
      this.handleKeysOnOpen();
      this.node.dispatchEvent(new CustomEvent(modalOpenedEventName, {
        detail,
        bubbles: true
      }));
    }
  }

  close({ type = '', detail = {} } = {}) {
    if (type === modalCloseRequestedEventName && detail.id === this.props.id) {
      window.parent.postMessage({
        closeIframe: true
      }, '*');

      this.props.beforeClose();
      this.node.classList.remove(activeClass);
      document.querySelector('body').style.right = '';
      document.querySelector('body').style.left = '';
      document.querySelector('body').style.top = '';
      document.querySelector('body').style.overflow = '';
      document.querySelector('body').style.position = '';
      window.scrollBy(0, initialScrollPosition);
      this.node.classList.add(inactiveClass);
      this.handleAriaOnClose();
      this.handleKeysOnClose();
      this.node.dispatchEvent(new CustomEvent(modalClosedEventName, {
        detail,
        bubbles: true
      }));
      this.dialogMutationObserver.disconnect();
      this.props.afterClose();
    }
  }

  handleAriaOnOpen() {
    if (this.dialog) {
      this.dialog.setAttribute('aria-hidden', 'false');
    }
  }

  handleAriaOnClose() {
    if (this.dialog) {
      this.dialog.setAttribute('aria-hidden', 'true');
    }
    if (this.props.triggeringElement instanceof window.HTMLElement) {
      this.props.triggeringElement.focus();
    }
  }

  focusCloseBtn() {
    this.node.querySelector(closeBtnSelector).focus();
  }

  handleKeysOnClose() {
    if (this.props.closable) {
      [].slice.call(this.node.querySelectorAll(closeBtnSelector)).forEach((closeBtn) => {
        closeBtn.removeEventListener('click', this.handleCloseButtonClick);
      });
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }
    [].slice.call(this.node.querySelectorAll(submitBtnSelector)).forEach((submitBtn) => {
      submitBtn.removeEventListener('click', this.handleSubmitButtonClick);
    });
  }

  handleKeysOnOpen = () => {
    if (this.props.closable) {
      [].slice.call(this.node.querySelectorAll(closeBtnSelector)).forEach((closeBtn) => {
        closeBtn.removeEventListener('click', this.handleCloseButtonClick);
        closeBtn.addEventListener('click', this.handleCloseButtonClick);
      });
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
      document.addEventListener('keydown', this.handleDocumentKeyDown);
    }
    [].slice.call(this.node.querySelectorAll(submitBtnSelector)).forEach((submitBtn) => {
      submitBtn.removeEventListener('click', this.handleSubmitButtonClick);
      submitBtn.addEventListener('click', this.handleSubmitButtonClick);
    });
  };

  handleCloseButtonClick = () => {
    this.close({
      type: modalCloseRequestedEventName,
      detail: {
        id: this.props.id
      }
    });
  };

  handleSubmitButtonClick = () => {
    this.props.onSubmit();
    if (this.props.closeOnSubmit) {
      this.close({
        type: modalCloseRequestedEventName,
        detail: {
          id: this.props.id
        }
      });
    }
  };

  // Commented ESC key functionality to close the modal
  // handleDocumentKeyDown = ({ keyCode, which }) => {
  //   if ((keyCode || which) === 27) {
  //     this.close({
  //       type: modalCloseRequestedEventName,
  //       detail: {
  //         id: this.props.id
  //       }
  //     });
  //   }
  // };

  disableAllCloseButtons() {
    [].slice.call(this.node.querySelectorAll(closeBtnSelector)).forEach((closeBtn) => {
      closeBtn.setAttribute('disabled', 'disabled');
      closeBtn.setAttribute('tabindex', '-1');
    });
  }

  enableAllCloseButtons() {
    [].slice.call(this.node.querySelectorAll(closeBtnSelector)).forEach((closeBtn) => {
      closeBtn.removeAttribute('disabled');
      closeBtn.setAttribute('tabindex', '0');
    });
  }

  scrollHandler() {
    let lastScrollPos = 0;
    return () => {
      const windowScrollPos = this.node.scrollTop;
      const closeBtn = this.node.querySelector(closeBtnSelector);
      const windowRect = this.node.getBoundingClientRect();
      const dialogRect = this.dialog.getBoundingClientRect();

      const btnMargin = 16;
      const margins = windowRect.right - dialogRect.right;
      const xPos = margins + btnMargin;

      const marginTop = 40;
      const modalMarginTop = 92;
      const stickyPaddingTop = 13;
      const togglePosition = modalMarginTop + marginTop - stickyPaddingTop;

      const headerMarginBottom = 16;

      if (windowScrollPos >= modalMarginTop) {
        closeBtn.classList.add(styles.stickyButton);
        closeBtn.style.right = `${xPos}px`;
      } else {
        closeBtn.classList.remove(styles.stickyButton);
        closeBtn.removeAttribute('style');
      }

      const headerHiddenDiv = this.node.querySelector('#give-widget-hiddendiv');
      const header = this.node.querySelector('.give-widget-current-view > header');
      const form = this.node.querySelector('.give-widget-current-view > header + form');
      const callToActionDivSelector = `.give-widget-current-view .${commonStyles.callToActionDiv}`;
      const callToActionDiv = this.node.querySelector(callToActionDivSelector);

      if (header) {
        const headerRect = header.getBoundingClientRect();
        const headerHeight = form ? (form.getBoundingClientRect().top - headerRect.top) : (headerRect.height + headerMarginBottom);

        if (lastScrollPos < windowScrollPos && windowScrollPos >= togglePosition && !headerHiddenDiv) {
          header.classList.add(styles.stickyHeader);
          const hiddenDiv = document.createElement('div');
          hiddenDiv.style.height = headerHeight;
          header.insertAdjacentElement('beforebegin', hiddenDiv);
        } else if (lastScrollPos > windowScrollPos && windowScrollPos < togglePosition && headerHiddenDiv) {
          header.classList.remove(styles.stickyHeader);
          headerHiddenDiv.parentNode.removeChild(headerHiddenDiv);
        }

        header.style.right = `${margins}px`;
      }

      if (callToActionDiv) {
        positionElement(callToActionDivSelector);
      }

      lastScrollPos = windowScrollPos;
    };
  }

  setFocusOnLastTabbableElement = (evt) => {
    const keyCode = evt.keyCode || evt.which;
    if (evt.shiftKey === true && keyCode === 9) {
      evt.preventDefault();
      this.cachedLastElement.focus();
    }
  };

  setFocusOnFirstTabbableElement = (evt) => {
    const keyCode = evt.keyCode || evt.which;
    if (evt.shiftKey === false && keyCode === 9) {
      evt.preventDefault();
      this.cachedFirstElement.focus();
    }
  };

  maintainFocus = (firstElement, modalBodyElm) => () => {
    let lastElement = Modal.getLastTabbableChild(modalBodyElm);
    if (lastElement === null) lastElement = firstElement;

    if (this.cachedFirstElement !== firstElement) {
      if (this.cachedFirstElement instanceof HTMLElement) {
        this.cachedFirstElement.removeEventListener('keydown', this.setFocusOnLastTabbableElement);
      }
      if (firstElement instanceof HTMLElement) {
        this.cachedFirstElement = firstElement;
        this.cachedFirstElement.addEventListener('keydown', this.setFocusOnLastTabbableElement);
      }
    }
    if (this.cachedLastElement !== lastElement) {
      if (this.cachedLastElement instanceof HTMLElement) {
        this.cachedLastElement.removeEventListener('keydown', this.setFocusOnFirstTabbableElement);
      }
      if (lastElement instanceof HTMLElement) {
        this.cachedLastElement = lastElement;
        this.cachedLastElement.addEventListener('keydown', this.setFocusOnFirstTabbableElement);
      }
    }
  };

  render(props, translateKey) {
    const closeText = translateKey('close', 'Close');
    const modalFragment = document.createRange().createContextualFragment(modalTemplate(closeText));

    const modalTemp = this.node;
    this.node = getFirstElementChild(modalFragment);
    this.dialog = this.node.querySelector(dialogSelector);

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.closable = this.props.closable;
    this.closeOnSubmit = this.props.closeOnSubmit;
    this.beforeShow = this.props.beforeShow;
    this.beforeClose = this.props.beforeClose;
    this.afterClose = this.props.afterClose;
    this.onSubmit = this.props.onSubmit;
    this.innerHTML = this.props.innerHTML;
    this.isFullScreen = this.props.isFullScreen;
    if (this.props.isOpen) {
      this.isOpen = this.props.isOpen;
    }

    document.removeEventListener(modalOpenRequestedEventName, this.open);
    document.removeEventListener(modalCloseRequestedEventName, this.close);
    document.addEventListener(modalOpenRequestedEventName, this.open);
    document.addEventListener(modalCloseRequestedEventName, this.close);

    const scrollFunction = this.scrollHandler();

    this.node.removeEventListener('scroll', scrollFunction);
    this.node.addEventListener('scroll', scrollFunction);
    window.onresize = scrollFunction;

    modalTemp.parentNode.replaceChild(this.node, modalTemp);
    this.dialogMutationObserver = new MutationObserver(this.maintainFocus(
      this.dialog.querySelector(closeBtnSelector),
      this.dialog.querySelector(modalBodySelector)
    ));
    this.dialogMutationObserver.observe(this.dialog.querySelector(modalBodySelector), {
      attributes: true,
      childList: true,
      subtree: true
    });
  }
}
