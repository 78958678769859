import questionAnswerTemplate, { answers, answersLoading } from './question_answers_template';
import emptyElement, { getFirstElementChild, isBoolean } from '../../utils/utils';
import styles from './question_answers.css';
import {
  clientPrimaryRadioButtonsBeforeBorder,
  clientPrimaryRadioPillButtonsBorder
} from '../../css/client_colors.js';

let initialQuestionAnswersId = 0;

export default class QuestionAnswers {
  static generateId() {
    initialQuestionAnswersId += 1;
    return `questionAnswers${initialQuestionAnswersId - 1}`;
  }

  defaultProps = {
    id: QuestionAnswers.generateId(),
    isLoading: false,
    isHidden: false,
    className: '',
    required: false,
    question: '',
    answers: [],
    isPillStyle: false,
    name: QuestionAnswers.generateId(),
    selectedValue: '',
    onChange: () => {}
  };

  set id(x) {
    this.node.setAttribute('id', `give-widget_${x}`);
    this.props.id = x;
  }

  get id() {
    return this.props.id;
  }

  set required(x) {
    const optional = this.node.querySelector(`.${styles.optional}`);
    if (!x) {
      optional.classList.remove(`${styles.hidden}`);
    } else {
      optional.classList.add(`${styles.hidden}`);
    }
  }

  get question() {
    return this.props.question;
  }

  set question(x) {
    emptyElement(this.questionElement);
    const textFrag = document.createRange().createContextualFragment(x);
    let { firstChild } = textFrag;
    while (firstChild) {
      this.questionElement.appendChild(firstChild);
      ({ firstChild } = textFrag);
    }
    this.props.question = x;
  }

  set answers(x = []) {
    emptyElement(this.answersListElement);
    this.props.answers = x;
    const answersFrag = document.createRange().createContextualFragment(answers(x));
    let { firstChild } = answersFrag;
    while (firstChild) {
      this.answersListElement.appendChild(firstChild);
      firstChild.querySelector('input').addEventListener('focus', this.onFocus);
      ({ firstChild } = answersFrag);
    }
    this.name = this.props.name;
  }

  get answers() {
    return this.props.answers;
  }

  set isPillStyle(x) {
    if (x) {
      this.answersListElement.classList.remove(styles.radioButtons);
      this.answersListElement.classList.add(styles.pills);
      this.props.isPillStyle = true;
    } else {
      this.answersListElement.classList.add(styles.radioButtons);
      this.answersListElement.classList.remove(styles.pills);
      this.props.isPillStyle = false;
    }
  }

  get isPillStyle() {
    return this.props.isPillStyle;
  }

  set name(x) {
    const answerRadios = Array.prototype.slice.call(this.answersListElement.querySelectorAll('input[type="radio"]'));
    answerRadios.forEach((answerRadioElm) => {
      answerRadioElm.setAttribute('name', x);
    });
    this.props.name = x;
  }

  get name() {
    return this.props.name;
  }

  set selectedValue(x) {
    this.props.selectedValue = x;
    const answersLabels = Array.prototype.slice.call(this.answersListElement.querySelectorAll('label'));
    answersLabels.forEach((answerLabel) => {
      const answerRadioElement = answerLabel.querySelector('input[type="radio"]');
      if (answerRadioElement.value === x) {
        answerRadioElement.setAttribute('checked', 'checked');
        if (this.props.isPillStyle) {
          answerLabel.classList.add(clientPrimaryRadioPillButtonsBorder);
        } else {
          answerLabel.classList.add(clientPrimaryRadioButtonsBeforeBorder);
        }
      } else {
        answerRadioElement.removeAttribute('checked');
        if (this.props.isPillStyle) {
          answerLabel.classList.remove(clientPrimaryRadioPillButtonsBorder);
        } else {
          answerLabel.classList.remove(clientPrimaryRadioButtonsBeforeBorder);
        }
      }
    });
  }

  get selectedValue() {
    return this.props.selectedValue;
  }

  set onChange(x) {
    this.props.onChange = x;
  }

  get onChange() {
    return this.props.onChange;
  }

  set isHidden(x) {
    if (isBoolean(x)) {
      this.props.isHidden = x;
      if (x) {
        this.node.classList.add(styles.hidden);
      } else {
        this.node.classList.remove(styles.hidden);
      }
    } else {
      console.error('please send a boolean value to isHidden of question answers component');
    }
  }

  get isHidden() {
    return this.props.isHidden;
  }

  set className(x) {
    if (x !== '') {
      this.node.classList.add(x);
      this.props.className = x;
    }
  }

  get className() {
    return this.props.className;
  }

  set isLoading(x) {
    if (isBoolean(x)) {
      emptyElement(this.answersListElement);
      this.props.isLoading = x;
      if (x) {
        this.questionElement.setAttribute('aria-busy', 'true');
        this.answersListElement.setAttribute('aria-busy', 'true');
        this.node.classList.add(styles.loading, styles.loadingShimmer);
        const frag = document.createRange().createContextualFragment(answersLoading());
        this.answersListElement.appendChild(frag);
      } else {
        this.questionElement.setAttribute('aria-busy', 'false');
        this.answersListElement.setAttribute('aria-busy', 'false');
        this.node.classList.remove(styles.loading, styles.loadingShimmer);
      }
    }
  }

  get isLoading() {
    return this.props.isLoading;
  }

  onFocus = (evt) => {
    const list = this.node.querySelectorAll('li');
    Array.prototype.forEach.call(list, (li) => {
      if (li.contains(evt.target)) {
        li.classList.add(styles.focus);
      } else {
        li.classList.remove(styles.focus);
      }
    });
  };

  onBlur = (evt) => {
    const list = this.node.querySelectorAll('li');
    Array.prototype.forEach.call(list, (li) => {
      if (li.contains(evt.target)) {
        li.classList.remove(styles.focus);
      }
    });
  };

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for QuestionAnswers component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props, translateKeyFn) {
    const frag = document.createRange().createContextualFragment(questionAnswerTemplate(translateKeyFn));
    const temp = this.node;

    this.node = getFirstElementChild(frag);
    this.questionElement = frag.querySelector(`.${styles.question}`);
    this.answersListElement = frag.querySelector('ul');

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.isLoading = this.props.isLoading;
    this.question = this.props.question;
    this.answers = this.props.answers;
    this.isPillStyle = this.props.isPillStyle;
    this.name = this.props.name;
    this.selectedValue = this.props.selectedValue;
    this.onChange = this.props.onChange;
    this.isHidden = this.props.isHidden;
    this.className = this.props.className;
    this.required = this.props.required;
    this.onChangeTimer = '';

    this.answersListElement.addEventListener('change', (evt) => {
      const val = evt.target.value;
      this.selectedValue = val;

      window.clearTimeout(this.onChangeTimer);
      this.onChangeTimer = window.setTimeout(() => {
        this.props.onChange(evt);
      }, 300);
    });

    this.answersListElement.addEventListener('focusout', this.onBlur);

    temp.parentNode.replaceChild(this.node, temp);
  }
}
