import pillBoxListTemplate, { pillBoxItemTemplate } from './pillbox_template';
import { getFirstElementChild } from '../../utils/utils.js';

let initialPillboxId = 0;

class PillBoxList {
  static generateId() {
    initialPillboxId += 1;
    return `pillBoxList${initialPillboxId - 1}`;
  }

  defaultProps = {
    id: PillBoxList.generateId(),
    data: [],
    onRemovePillBoxItem: () => {}
  };

  set id(x) {
    this.node.setAttribute('id', `give-widget_${x}`);
    this.props.id = x;
  }

  get id() {
    return this.props.id;
  }

  set data(list = []) {
    if (Array.isArray(list)) {
      this.pillBoxItemNodes.forEach((pillBoxItemNode) => {
        pillBoxItemNode.parentNode.removeChild(pillBoxItemNode);
      });
      this.props.data = [];
      this.pillBoxItemNodes = [];
      list.forEach((listItem) => this.push(listItem));
    } else {
      console.error('Please pass an array to data in pillboxList Component');
    }
  }

  get data() {
    return this.props.data;
  }

  set onRemovePillBoxItem(x) {
    this.props.onRemovePillBoxItem = x;
  }

  get onRemovePillBoxItem() {
    return this.props.onRemovePillBoxItem;
  }

  pillBoxItemNodes = [];

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to PillBox component`);
    }
  }

  pop() {
    let temp = this.pillBoxItemNodes.length - 1;
    while (
      temp >= 0 &&
      this.pillBoxItemNodes[temp] &&
      this.pillBoxItemNodes[temp].classList.contains('js-isRemovable') === false
    ) {
      temp -= 1;
    }
    if (temp >= 0) {
      const pillBoxItemNode = this.pillBoxItemNodes.splice(temp, 1);
      this.props.data.splice(temp, 1);
      const id = pillBoxItemNode[0].getAttribute('data-item-id');
      pillBoxItemNode[0].parentNode.removeChild(pillBoxItemNode[0]);
      this.props.onRemovePillBoxItem({ systemUserId: parseInt(id, 10) });
    }
  }

  push(listItem) {
    const removeItemText = this.translateKey(
      'give-widget-remove-nominee',
      `Remove ${listItem.text} from list of recipients`,
      '{name}',
      listItem.text
    );
    const pillBoxItemFragment = document
      .createRange()
      .createContextualFragment(pillBoxItemTemplate(listItem, removeItemText));
    const firstElementChild = getFirstElementChild(pillBoxItemFragment);
    this.handleClickEventsForPillBoxItem(firstElementChild);
    this.pillBoxItemNodes.push(firstElementChild);
    this.node.appendChild(firstElementChild);
    this.props.data.push(listItem);
  }

  handleClickEventsForPillBoxItem(pillBoxItemNode) {
    if (pillBoxItemNode.classList.contains('js-isRemovable')) {
      pillBoxItemNode.querySelector('.js-remove').addEventListener('click', (evt) => {
        pillBoxItemNode.parentNode.removeChild(pillBoxItemNode);
        const id = parseInt(pillBoxItemNode.getAttribute('data-item-id'), 10);
        let index = -1;
        this.props.data.forEach((item, idx) => {
          if (index === -1 && item.id === id) {
            index = idx;
          }
        });
        this.props.data.splice(index, 1);
        this.pillBoxItemNodes.splice(index, 1);
        this.props.onRemovePillBoxItem({ systemUserId: id });
        evt.stopPropagation();
      });
    }
  }

  render(props, translateKey) {
    this.translateKey = translateKey;

    const pillBoxListFragment = document
      .createRange()
      .createContextualFragment(pillBoxListTemplate(this.props));

    const pillBoxListTemp = this.node;
    this.node = getFirstElementChild(pillBoxListFragment);
    this.pillBoxItemNodes = [];

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.data = this.props.data;
    this.onRemovePillBoxItem = this.props.onRemovePillBoxItem;

    pillBoxListTemp.parentNode.replaceChild(this.node, pillBoxListTemp);
  }
}

export default PillBoxList;
