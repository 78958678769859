import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Chip from '@octanner/prism-core/Chip';
import Search, { SearchOptionCard } from '@octanner/prism-core/Search';
import useDebounce from '@octanner/prism-core/hooks/useDebounce';

import { queryUsers } from '../../state/services/api';
import useTranslation from '../../utils/useTranslation';
import { preferOrFirstLastName } from '../../utils/utils';

const minCharacters = 3;

const selectAvatarSrc = ({ thumb, profile, original }) =>
  (thumb?.src || profile?.src || original?.src) ||
  ((typeof thumb === 'string' && thumb) || (typeof profile === 'string' && profile) || (typeof original === 'string' && original)) ||
  '';

const RecipientSearch = ({
  dispatch,
  state,
  selectedRecipients,
  onRecipientsChanged
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = React.useState(null);
  const debouncedText = useDebounce(searchText);
  const [users, setUsers] = React.useState([]);

  const onChange = useCallback((selected) => {
    if (selectedRecipients === selected) return; // TODO: workaround for PRISM-388

    onRecipientsChanged(dispatch, state, t, selected);
  }, [onRecipientsChanged, dispatch, selectedRecipients, state, t]);

  React.useEffect(() => {
    if (debouncedText && debouncedText.length >= minCharacters) {
      setLoading(true);
      queryUsers(debouncedText).then((res) => {
        setUsers(res);
        setLoading(false);
      });
      return () => {
        setLoading(false);
      };
    } else if (debouncedText === '') {
      setUsers([]);
    }
    return null;
  }, [debouncedText]);

  const hasMinChars = searchText && searchText.length >= minCharacters;

  return (
    <Search
      loading={loading}
      options={users}
      optionKey="systemUserId"
      emptyText={hasMinChars
        ? t('give-widget-no-results-found-label', `No one found matching ${searchText}`, '{val}', searchText)
        : t('minimum-characters', 'Please enter at least three (3) characters to search.')}
      placeholder={t('give-widget-who-noticed-great-work', 'Who have you noticed do great work?')}
      selectedOptions={selectedRecipients}
      onChange={onChange}
      onSearch={setSearchText}
      renderOption={(option) => (
        <SearchOptionCard
          avatarUrl={selectAvatarSrc(option.avatar)}
          showAvatar
          title={option.fullName}
          subTitle={<>
            <div>{`${t('manager', 'Manager')}: ${option.manager?.fullName}`}</div>
            <div>{option.department}</div>
          </>}
        />
      )}
      renderChip={(option) => (
        <Chip label={preferOrFirstLastName(option)} />
      )}
    />
  );
};

RecipientSearch.propTypes = {
  dispatch: PropTypes.func,
  state: PropTypes.object,
  selectedRecipients: PropTypes.array,
  onRecipientsChanged: PropTypes.func
};

export default RecipientSearch;
