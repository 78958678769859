import { getFirstElementChild } from '../../utils/utils';
import eCardTileTemplate from './ecard_tile_template';
import styles from './ecard_tile.css';
import { clientPrimaryFill } from '../../css/client_colors.js';

let initialEcardId = 0;

export default class ECardTile {
  defaultProps = {
    class: '',
    card: {},
    onClickPreview: () => {},
    onClickFavorite: () => {},
    onSelectTile: () => {},
    favorited: false,
    index: 0,
    id: ECardTile.generateId()
  };

  static generateId() {
    initialEcardId += 1;
    return `ecard${initialEcardId - 1}`;
  }

  get id() {
    return this.props.id;
  }

  set id(x) {
    this.props.id = x;
    this.node.setAttribute('id', `${x}`);
  }

  set favorited(x) {
    this.props.favorited = x;
    if (x) {
      this.favButton.classList.add(clientPrimaryFill);
    } else {
      this.favButton.classList.remove(clientPrimaryFill);
    }
  }

  get card() {
    return this.props.card;
  }

  constructor(selector, index) {
    const node = document.querySelector(selector);
    this.index = index;
    if (node === null) {
      console.error(`Invalid selector for ECardTile component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props = {}, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    const template = eCardTileTemplate(this.props, translateKeyFn, this.index);
    const eCardTileFragment = document.createRange().createContextualFragment(template);
    const eCardTileTemp = this.node;
    this.node = getFirstElementChild(eCardTileFragment);
    this.favButton = this.node.querySelector(`.${styles.favBtn}`);
    this.overlay = this.node.querySelector(`.${styles.overlay}`);
    this.previewButton = this.node.querySelector(`.${styles.previewBtn}`);
    this.selectButton = this.node.querySelector(`.${styles.selectBtn}`);
    this.favorited = this.props.favorited;
    eCardTileTemp.parentNode.replaceChild(this.node, eCardTileTemp);

    this.favButton.addEventListener('click', this.onClickFavorite);
    this.previewButton.addEventListener('click', this.onClickPreview);
    this.selectButton.addEventListener('click', this.onSelectTile);
    this.node.addEventListener('focusin', this.showPreviewBtn);
    this.node.addEventListener('focusout', this.hidePreviewBtn);
    this.id = this.props.id;
  }

  onClickFavorite = () => {
    this.favorited = !this.props.favorited;
    this.props.onClickFavorite(this.props.favorited, this.props.card.eproductName);
  };

  onClickPreview = (evt) => {
    this.props.onClickPreview(evt, this.props.index);
  };

  onSelectTile = (evt) => {
    this.props.onSelectTile(evt, this.card);
  }

  showPreviewBtn = () => {
    this.overlay.classList.add(styles.show);
  }

  hidePreviewBtn = () => {
    // This works in IE but not Chrome, FF because Chrome and FF set
    // document.activeElement to the document body instead of the next focused element.
    // The Chrome/FF solution is in the CSS.
    if (!this.node.contains(document.activeElement)) {
      this.overlay.classList.remove(styles.show);
    }
  }
}
