import styles from './modal.css';
import { remove } from '../../assets/icons';

const modalBodyId = 'modal-body';
const modalHeadId = 'modal-head';
const modalHeaderId = 'modal-header';

export const modalBodySelector = '#modal-body';
export const modalHeadSelector = '#modal-head';
export const modalHeaderSelector = `#${modalHeaderId}`;

const modalTemplate = (closeText) =>
  `<div class="js-modalWrapper ${styles.modalWrapper}">
    <div
      class="js-modalDialog fs-unmask ${styles.modalDialog} ${styles.fullSize}"
      aria-hidden="true" 
      aria-modal="true"
      tabindex="-1"
      role="dialog" 
      
    >
      <button class="${styles.close} js-close" type="button">${remove}
        <span class="${styles['sr-only']}">${closeText}</span>
      </button>
     
      <header id=${modalHeaderId}  class="${styles.heading}" aria-live="assertive">
       <h1 id="${modalHeadId}" ></h1>
      </header>
      <div class="${styles.body}" id="${modalBodyId}" role="document">
      </div>
    </div>
  </div>`;

export default modalTemplate;
