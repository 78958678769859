import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './textbox.css';

export const optionalTextSelector = `div.${styles.textboxContainer} > p.${styles.optional}`;
export const textboxSelector = `div.${styles.textboxContainer} > div.${prismUiCss['psm-form__textarea']}`;
export const charCounterSelector = `div.${styles.textboxContainer} > p.${prismUiCss['psm-form__max-chars']}`;
export const loadingDivSelector = `div.${styles.textboxContainer} > div.${styles.loading}.${prismUiCss['psm-form__textarea']}`;
export const textAreaSelector = `div.${styles.textboxContainer} > textarea.${prismUiCss['psm-form__textarea']}`;

const textboxTemplate = (translateKeyFn) => `<section>
    <button id="textbox-toggle-button" class="${styles.hidden} ${styles.toggleButton}" type="button"></button>
    <div class="${styles.textboxContainer}">
      <label class="${prismUiCss['psm-form__label']}"></label>
      <p class="${styles.optional}">(${translateKeyFn ? translateKeyFn('optional', 'Optional') : 'Optional'})</p>
      <textarea class="fs-mask ${prismUiCss['psm-form__textarea']}" autocomplete="off"></textarea>
      <p class="${prismUiCss['psm-form__max-chars']} ${styles.hidden}"></p>
      <div class="${styles.hidden} ${styles.loading} ${prismUiCss['psm-form__textarea']}"></div>
    </div>
  </section>`;

export default textboxTemplate;
