import selectBoxTemplate, { selectBoxOptionTemplate } from './select-box-template';
import emptyElement, { getFirstElementChild } from '../../utils/utils';
import './select-box.css';

let initialSelectBoxId = 0;

class SelectBox {
  static generateId() {
    initialSelectBoxId += 1;
    return `selectBox${initialSelectBoxId - 1}`;
  }

  listItems = [];

  defaultProps = {
    id: SelectBox.generateId(),
    data: [],
    selectedValue: '',
    ariaLabel: '',
    ariaLabelledBy: '',
    onChange: () => {},
    className: '',
    selectBoxElementId: ''
  };

  set id(x) {
    this.node.setAttribute('id', `give-widget_${x}`);
    this.props.id = x;
  }

  get id() {
    return this.props.id;
  }

  set data(list) {
    if (Array.isArray(list)) {
      this.props.data = list;
      emptyElement(this.selectBoxElement);
      this.listItems = [];
      const listHTML = list.reduce(
        (acc, { text = '', value = '' }, index) => acc + selectBoxOptionTemplate(text, value, index, list),
        ''
      );
      const frag = document.createRange().createContextualFragment(listHTML);
      Array.prototype.slice.call(frag.childNodes).forEach((child) => {
        if (child.nodeType === 1) {
          this.listItems.push(child);
          this.selectBoxElement.appendChild(child);
        }
      });
    } else {
      console.error('Please pass an array to data in selectBox component');
    }
  }

  get data() {
    return this.props.data;
  }

  set selectedValue(x) {
    this.props.selectedValue = x;
    this.listItems.forEach((listItem) => {
      if (listItem.value === `${x}`) {
        listItem.setAttribute('selected', true);
      } else {
        listItem.removeAttribute('selected');
      }
    });
  }

  get selectedValue() {
    return this.props.selectedValue;
  }

  set ariaLabel(x) {
    this.props.ariaLabel = x;
    if (x && x !== '') {
      this.selectBoxElement.setAttribute('aria-label', x);
    } else {
      this.selectBoxElement.removeAttribute('aria-label');
    }
  }

  get ariaLabel() {
    return this.props.ariaLabel;
  }

  set ariaLabelledBy(x) {
    this.props.ariaLabelledBy = x;
    if (x && x !== '') {
      this.selectBoxElement.setAttribute('aria-labelledBy', x);
    } else {
      this.selectBoxElement.removeAttribute('aria-labelledby');
    }
  }

  get ariaLabelledBy() {
    return this.props.ariaLabelledBy;
  }

  set onChange(x) {
    this.props.onChange = x;
  }

  get onChange() {
    return this.props.onChange;
  }

  set className(x) {
    this.props.className = x;
    if (x !== '') {
      const classes = x.split(' ');
      classes.forEach((someClass) => {
        this.node.classList.add(someClass);
      });
    }
  }

  constructor(selectBoxPlaceholder) {
    if (document.querySelector(selectBoxPlaceholder) !== null) {
      this.node = document.querySelector(selectBoxPlaceholder);
    } else {
      console.error(`Selector ${selectBoxPlaceholder} not present in document. Please pass a valid selector.`);
    }
  }

  change = () => {
    const index = this.selectBoxElement.selectedIndex;
    const data = this.props.data[index];
    this.props.onChange(data);
    this.props.selectedValue = data.value;
    this.selectBoxElement.setAttribute('aria-hidden', true);
    this.selectBoxElement.setAttribute('aria-expanded', false);
  };

  onClicking = () => {
    this.selectBoxElement.setAttribute('aria-hidden', false);
    this.selectBoxElement.setAttribute('aria-expanded', true);
  };

  render(props) {
    const selectBoxTemplateFragment = document
      .createRange()
      .createContextualFragment(selectBoxTemplate());
    const selectBoxTemp = this.node;

    this.node = getFirstElementChild(selectBoxTemplateFragment);
    this.selectBoxElement = this.node.querySelector('select');

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.ariaLabel = this.props.ariaLabel;
    this.ariaLabelledBy = this.props.ariaLabelledBy;
    this.role = this.props.role;
    this.data = this.props.data;
    this.selectedValue = this.props.selectedValue;
    this.className = this.props.className;

    this.selectBoxElement.addEventListener('change', this.change);
    this.selectBoxElement.setAttribute('aria-expanded', true);
    this.selectBoxElement.setAttribute('aria-hidden', false);
    this.selectBoxElement.id = this.props.selectBoxElementId;
    this.selectBoxElement.addEventListener('click', this.onClicking);
    selectBoxTemp.parentNode.replaceChild(this.node, selectBoxTemp);
  }
}

export default SelectBox;
