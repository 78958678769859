import LoadingView from '../views/loading_view/loading_view';
import UserSearchView from '../views/user_search_view/user_search_view';
import NominationWizardView from '../views/nomination-wizard/nomination-wizard';
import ProgramDetailsView from '../views/program_details_view/program_details_view';
import ECardDetailsView from '../views/ecard_details_view/ecard_details_view';
import ECardSelectionView from '../views/ecard_selection_view/ecard_selection_view';
import ECardMessageView from '../views/ecard_message_view/ecard_message_view';
import DescriptionView from '../views/description_view/description_view';
import SubmissionView from '../views/submission_view/submission_view';
import BulkUploadView from '../views/bulk_upload_view/bulk_upload_view';
import {
  loadingViewSelector,
  userSearchViewSelector,
  programDetailsViewSelector,
  eCardDetailsViewSelector,
  nominationWizardViewSelector,
  eCardSelectionViewSelector,
  eCardMessageViewSelector,
  descriptionViewSelector,
  submissionViewSelector,
  bulkUploadViewSelector
} from '../app_template';
import * as views from '../views/views-list';
import * as selectState from './select_view_state';
import * as shouldReRender from './should_rerender';
import { getHeaderText } from '../components/modal_header/modal_header_template';
import {
  setHeader
} from './utils';

export const viewChanged = ({ viewNumber: { type = '' } = {} } = {}) => type === 'updated';

export const hideOtherViews = (app, viewNumber) => {
  if (app.loadingView && viewNumber !== views.LOADING_VIEW) {
    app.loadingView.hide();
  }
  if (app.userSearchView && viewNumber !== views.USER_SEARCH_VIEW) {
    app.userSearchView.hide();
  }
  if (app.bulkUploadView && viewNumber !== views.BULK_UPLOAD_VIEW) {
    app.bulkUploadView.hide();
  }
  if (app.nominationWizardView && viewNumber !== views.NOMINATION_WIZARD_VIEW) {
    app.nominationWizardView.hide();
  }
  if (app.programDetailsView && viewNumber !== views.PROGRAM_DETAILS_VIEW) {
    app.programDetailsView.hide();
  }
  if (app.eCardDetailsView && viewNumber !== views.ECARD_DETAILS_VIEW) {
    app.eCardDetailsView.hide();
  }
  if (app.eCardSelectionView && viewNumber !== views.ECARD_SELECTION_VIEW) {
    app.eCardSelectionView.hide();
  }
  if (app.eCardMessageView && viewNumber !== views.ECARD_MESSAGE_VIEW) {
    app.eCardMessageView.hide();
  }
  if (app.descriptionView && viewNumber !== views.DESCRIPTION_VIEW) {
    app.descriptionView.hide();
  }
  if (app.submissionView && viewNumber !== views.SUBMISSION_VIEW) {
    app.submissionView.hide();
  }
};

// One function per view. Determines whether to render or re-render that view.

export function renderLoadingView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff) && this.loadingView === undefined) {
    hideOtherViews(this, state.viewNumber);
    this.loadingView = new LoadingView(loadingViewSelector);
  }
  this.loadingView.render(selectState.loadingView(state), stateDiff);
}

export function renderUserSearchView(dispatch, state, stateDiff, prevState) {
  this.appreciateModal.isFullScreen = true;
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);

    if (this.userSearchView === undefined) {
      this.userSearchView = new UserSearchView(userSearchViewSelector, dispatch, state.recognizerId, state.useRecommendedGive);
    }
    this.userSearchView.render(dispatch, selectState.userSearchView(state), stateDiff, this.translations.translateKey);
  } else if (shouldReRender.userSearchView(state.viewNumber, stateDiff)) {
    this.userSearchView.update(dispatch, selectState.userSearchView(state), stateDiff, this.translations.translateKey);
    hideOtherViews(this, state.viewNumber);
  }
  if (prevState.viewNumber === views.BULK_UPLOAD_VIEW) {
    const bulkLink = document.querySelector('#bulkUploadButton');
    if (bulkLink) {
      bulkLink.focus();
    }
  } else if (viewChanged(stateDiff)) {
    this.appreciateModal.focusCloseBtn();
  }
}

export function renderBulkUploadView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    this.appreciateModal.focusCloseBtn();
    if (this.bulkUploadView === undefined) {
      this.bulkUploadView = new BulkUploadView(bulkUploadViewSelector);
    }
    this.bulkUploadView.render(dispatch, selectState.bulkUploadView(state), stateDiff, this.translations.translateKey, this.translations);
  } else if (shouldReRender.bulkUploadView(state.viewNumber, stateDiff)) {
    this.bulkUploadView.update(dispatch, selectState.bulkUploadView(state), stateDiff, this.translations.translateKey);
  }
}

export function renderProgramDetailsView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    if (this.programDetailsView === undefined) {
      this.programDetailsView = new ProgramDetailsView(programDetailsViewSelector);
    }
    this.programDetailsView.render(
      dispatch,
      selectState.programDetailsView(state),
      stateDiff,
      this.translations.translateKey,
      this.translations.translateClientStrings
    );
    const headerText = getHeaderText(state.selectedRecognitionType, this.translations.translateKey);
    // document.querySelector(modalHeadSelector).innerText = headerText;
    setHeader(headerText);
  } else if (shouldReRender.programDetailsView(state.viewNumber, stateDiff)) {
    this.programDetailsView.update(dispatch, selectState.programDetailsView(state), stateDiff, this.translations.translateKey);
  }
}

export function renderNominationWizardView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    this.appreciateModal.focusCloseBtn();
    if (this.nominationWizardView === undefined) {
      this.nominationWizardView = new NominationWizardView(nominationWizardViewSelector, dispatch, selectState.nominationWizardView(state));
    } else if (!state.wizardFlowFinished) {
      this.nominationWizardView.previouslySelectedQuestionAndAnswers = [];
    }
    this.nominationWizardView.render(dispatch, selectState.nominationWizardView(state), this.translations.translateKey);
  } else if (shouldReRender.nominationWizardView(state.viewNumber, stateDiff)) {
    this.nominationWizardView.render(dispatch, selectState.nominationWizardView(state), this.translations.translateKey);
  }
}

export function renderDescriptionView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    if (this.descriptionView === undefined) {
      this.descriptionView = new DescriptionView(descriptionViewSelector, dispatch, selectState.descriptionView(state));
    }
    this.descriptionView.render(dispatch, selectState.descriptionView(state), this.translations.translateKey);
    const headerText = getHeaderText(state.selectedRecognitionType, this.translations.translateKey);
    setHeader(headerText);
  } else if (shouldReRender.descriptionView(state.viewNumber, stateDiff)) {
    this.descriptionView.update(dispatch, selectState.descriptionView(state), stateDiff, this.translations.translateKey);
  }
}

export function renderSubmissionView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    this.appreciateModal.isFullScreen = false;
    hideOtherViews(this, state.viewNumber);
    if (this.submissionView === undefined) {
      this.submissionView = new SubmissionView(submissionViewSelector);
    }
    this.submissionView.render(dispatch, selectState.submissionView(state), this.translations.translateKey, this.translations.translateDateFormat);
    const headerText = this.translations.translateKey('give-widget-thank-you', 'Thank you');
    setHeader(headerText);
  }
}

export function renderECardDetailsView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    if (this.eCardDetailsView === undefined) {
      this.eCardDetailsView = new ECardDetailsView(eCardDetailsViewSelector);
    }
    this.eCardDetailsView.render(
      dispatch,
      selectState.eCardDetailsView(state),
      stateDiff,
      this.translations.translateKey,
      this.translations.translateClientStrings
    );
  } else if (shouldReRender.eCardDetailsView(state.viewNumber, stateDiff)) {
    this.eCardDetailsView.update(dispatch, selectState.eCardDetailsView(state), stateDiff, this.translations.translateKey);
  }
}

export function renderECardSelectionView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    if (this.eCardSelectionView === undefined) {
      this.eCardSelectionView = new ECardSelectionView(eCardSelectionViewSelector);
    }
    this.eCardSelectionView.render(dispatch, selectState.eCardSelectionView(state), this.translations.translateKey);
  } else if (shouldReRender.eCardSelectionView(state.viewNumber, stateDiff)) {
    this.eCardSelectionView.update(selectState.eCardSelectionView(state), stateDiff);
  }
}

export function renderECardMessageView(dispatch, state, stateDiff) {
  if (viewChanged(stateDiff)) {
    hideOtherViews(this, state.viewNumber);
    if (this.eCardMessageView === undefined) {
      this.eCardMessageView = new ECardMessageView(eCardMessageViewSelector);
    }
    this.eCardMessageView.render(dispatch, selectState.eCardMessageView(state), this.translations.translateKey);
  } else if (shouldReRender.eCardMessageView(state.viewNumber, stateDiff)) {
    this.eCardMessageView.update(
      dispatch,
      selectState.eCardMessageView(state),
      stateDiff,
      this.translations.translateKey,
      this.translations.translateDateFormat
    );
  }
}

export default {
  [views.MODAL_CLOSED]: () => {},
  [views.LOADING_VIEW]: renderLoadingView,
  [views.USER_SEARCH_VIEW]: renderUserSearchView,
  [views.BULK_UPLOAD_VIEW]: renderBulkUploadView,
  [views.PROGRAM_DETAILS_VIEW]: renderProgramDetailsView,
  [views.NOMINATION_WIZARD_VIEW]: renderNominationWizardView,
  [views.DESCRIPTION_VIEW]: renderDescriptionView,
  [views.SUBMISSION_VIEW]: renderSubmissionView,
  [views.ECARD_DETAILS_VIEW]: renderECardDetailsView,
  [views.ECARD_SELECTION_VIEW]: renderECardSelectionView,
  [views.ECARD_MESSAGE_VIEW]: renderECardMessageView
};
