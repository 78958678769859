import { modalHeaderSelector } from '../../components/modal/modal_template';
import styles from './submission_view.css';

export const submissionHeadingSelector = '#submissionHeading';

const messageId = 'messageId';
export const messageSelector = '#messageId';

const doneButtonId = 'doneButtonId';
export const doneButtonSelector = '#doneButtonId';

const appreciateButtonId = 'appreciateButtonId';
export const appreciateButtonSelector = '#appreciateButtonId';

export const instantEvent = (state, translateKeyFn) => {
  if (String(state.selectedRecognitionType).toLowerCase() === 'ecard') {
    if (state.groupDetails && state.groupDetails.count > 0) {
      if (state.isCcMigratedCustomer) {
        return `${translateKeyFn(
          'cc-group-ecard-future-delivery-success',
          'Your eCard has been scheduled; please visit Profile > History > Group to view file status'
        )}`;
      }
      return `${translateKeyFn(
        'group-ecard-future-delivery-success',
        'Your eCard has been scheduled; please visit My Account > History > Group to view file status'
      )}`;
    }
    return `${translateKeyFn(
      'give-widget-ecard-submission-message',
      `The ${translateKeyFn('|ecard-label|', state.selectedRecognitionType)} you just created is now on its way.`
    )}`;
  }
  return `${translateKeyFn(
    'give-widget-nomination-submission-message',
    `The ${translateKeyFn('|nomination-label|', state.selectedRecognitionType)} you just created is now on its way.`
  )} `;
};

export const scheduledEvent = (state, translateKeyFn, translateDateFormat) => `${translateKeyFn(
  'give-widget-ecard-later-submission-message',
  `The ${translateKeyFn('|ecard-label|', state.selectedRecognitionType)} you just created will send on`
)} ${translateDateFormat(state.scheduledDeliveryDate, state.dateFormat || 'mm/dd/yyyy')}`;

const submissionViewTemplate = () =>
  `<div>
    <section id="${messageId}" class="${styles.message}"></section>
    <form aria-live="off">
      <div class="${styles.appreciateButtonWrapper}"><button id="${appreciateButtonId}" type="button">Appreciate Someone Else</button></div>
      <div class="${styles.doneButtonWrapper}"><button id="${doneButtonId}" type="button">Done</button></div>
    </form>
    <style id="modal-margin-override">
    ${modalHeaderSelector}{
      margin-top: 40px  !important;
    }
    </style>
  </div>`;

export default submissionViewTemplate;
