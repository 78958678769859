import prismUiCss from '@octanner/prism-ui-css/prism.css';
import checkboxTemplate, { checkboxTitle } from './checkbox_template.js';
import { getFirstElementChild } from '../../utils/utils';
import styles from './checkbox.css';
import { clientPrimaryBackground } from '../../css/client_colors.js';

let checkboxId = 0;

export default class Checkbox {
  static generateId = () => {
    checkboxId += 1;
    return `checkbox${checkboxId - 1}`;
  };

  defaultProps = {
    checked: false,
    text: '',
    title: '',
    className: '',
    isHidden: false,
    isToggleStyle: true,
    id: Checkbox.generateId(),
    onChange: () => {}
  };

  set id(x) {
    this.node.setAttribute('id', `give-widget_${x}`);
    this.input.setAttribute('id', `give-widget_${x}input`);
    this.wrapper.setAttribute('for', `give-widget_${x}input`);
    this.props.id = x;
  }

  set className(x) {
    if (x !== '') {
      this.node.classList.add(x);
      this.props.className = x;
    }
  }

  set text(x) {
    this.props.text = x;
    this.label.innerHTML = x;
  }

  set checked(x) {
    this.props.checked = x;
    this.input.checked = x;
    if (x === true) {
      this.toggle.classList.add(prismUiCss['psm-toggle--active']);
      this.toggle.classList.add(clientPrimaryBackground);
    } else {
      this.toggle.classList.remove(clientPrimaryBackground);
      this.toggle.classList.remove(prismUiCss['psm-toggle--active']);
    }
  }

  set isHidden(x) {
    this.props.isHidden = x;
    if (x) {
      this.node.classList.add(styles.hidden);
    } else {
      this.node.classList.remove(styles.hidden);
    }
  }

  set title(x) {
    this.props.title = x;
    if (this.titleElement) {
      this.node.removeChild(this.titleElement);
    }
    if (x) {
      this.node.insertAdjacentHTML('afterbegin', checkboxTitle(x));
      this.titleElement = this.node.querySelector('legend');
    } else {
      this.titleElement = undefined;
    }
  }

  onFocus = () => {
    this.toggle.classList.add(prismUiCss['psm-toggle--focus']);
  };

  onBlur = () => {
    this.toggle.classList.remove(prismUiCss['psm-toggle--focus']);
  };

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for Checkbox component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props) {
    this.props = { ...this.defaultProps, ...props };

    const frag = document.createRange().createContextualFragment(checkboxTemplate(this.props));
    const temp = this.node;

    this.node = getFirstElementChild(frag);
    this.input = this.node.querySelector('input');
    this.label = this.node.querySelector(`.${prismUiCss['psm-toggle__label']}`);
    this.toggle = this.node.querySelector(`.${prismUiCss['psm-toggle']}`);
    this.wrapper = this.node.querySelector(`.${prismUiCss['psm-toggle__wrapper']}`);
    this.id = this.props.id;
    this.text = this.props.text;
    this.title = this.props.title;
    this.onChange = this.props.onChange;
    this.className = this.props.className;
    this.checked = this.props.checked;
    this.isHidden = this.props.isHidden;

    this.input.addEventListener('click', (evt) => {
      this.checked = evt.target.checked;
      this.props.onChange(this.input.checked);
    });

    this.input.addEventListener('focus', this.onFocus);
    this.input.addEventListener('blur', this.onBlur);

    temp.parentNode.replaceChild(this.node, temp);
  }
}
