import styles from './program_details_view.css';
import commonStyles from '../../css/common.css';
import { search as searchIcon } from '../../assets/icons';

const modalHeaderId = 'modalHeader';
export const modalHeaderSelector = '#modalHeader';

const programDetailsNext = 'programsNext';
export const programDetailsNextSelector = '#programsNext';

const programDetailsHeadingId = 'programDetailsHeading';
export const programDetailsHeadingSelector = '#programDetailsHeading';

const ccUserSearchContainerId = 'ccUserSearchContainer';
export const ccUserSearchContainerSelector = '#ccUserSearchContainer';

const ccSelectedUsersPillBoxId = 'ccSelectedUsersPillBox';
export const ccSelectedUsersPillBoxSelector = '#ccSelectedUsersPillBox';

const ccUserSearchId = 'ccUserSearch';
export const ccUserSearchSelector = '#ccUserSearch';

const ccUserSearchListId = 'ccUserSearchList';
export const ccUserSearchListSelector = '#ccUserSearchList';

const ccUserSearchHeading = 'ccUserSearchHeading';
export const ccUserSearchHeadingSelector = '#ccUserSearchHeading';

const ccUserSearchPlaceholderId = 'ccUserSearchPlaceholder';
export const ccUserSearchPlaceholderSelector = '#ccUserSearchPlaceholder';

const recipientsApproversAndPresentersSectionId = 'recipientsApproversAndPresentersSection';
export const recipientsApproversAndPresentersSectionSelector = '#recipientsApproversAndPresentersSection';

const noteToApproverId = 'noteToApproverText';
export const noteToApproverSelector = '#noteToApproverText';

const awardDescriptionId = 'awardDescription';
export const awardDescriptionSelector = '#awardDescription';

const termsOfServiceId = 'tos';
export const termsOfServiceSelector = '#tos';

const optionalWizardLinkId = 'optionalWizardLink';
export const optionalWizardLinkSelector = '#optionalWizardLink';

const errorMsgId = 'errorMsg';
export const errorMsgSelector = '#errorMsg';

export const optionalWizardLinkTemplate = (needHelpAwardLevel = '') =>
  `<button id="wizard-link" class="${styles.linkButton}" type="button">${needHelpAwardLevel}</button>`;

const programDetailsViewTemplate = (translateKey) =>
  `<article id="programDetailsView" class="${styles.program_details_view}">
    <header id="${modalHeaderId}"></header>
    <form aria-live="off">
      <div id="${programDetailsHeadingId}"></div>
      <div id="${styles.programDetailsViewBody}">
        <div id="${termsOfServiceId}"></div>
        <div id="${errorMsgId}"></div>
        <div id="program-list"></div>
        <div class="${styles.hidden}" id="${awardDescriptionId}">
        </div>
        <div id="award-list"></div>
        <div id="${optionalWizardLinkId}" class="${styles.hidden} ${styles.wizardLink}"></div>
        <div id="corporate-value-list"></div>
        <section id="${ccUserSearchContainerId}" class="${styles.hidden}">
          <label id="${ccUserSearchHeading}" class="${styles.subheading}">${translateKey('give-widget-cc-label', 'CC:')}</label>
          <div class="${styles.searchPlaceholder}" id="${ccUserSearchPlaceholderId}">
            ${searchIcon}
            <ul id="${ccSelectedUsersPillBoxId}" class="${styles.pillBoxList}"></ul>
            <div id="${ccUserSearchId}"></div>
            <div id="${ccUserSearchListId}"></div>
          </div>
        </section>
        <section class="${styles.recipientsApproversAndPresenters}" id="${recipientsApproversAndPresentersSectionId}">
        </section>
        <section id="${noteToApproverId}" class="${styles.hidden}"></section>
        <div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv}">
          <div class="${styles.nextButtonWrapper}"><button id="${programDetailsNext}" class="${styles.hidden}" type="button">Next</button></div>
        </div>
      </div>
    </form>
  </article>`;

export default programDetailsViewTemplate;
