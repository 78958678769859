import styles from './ecard_selection_view.css';
import commonStyles from '../../css/common.css';

export const modalHeaderSelector = '#modalHeader';

const eCardSelectionViewTemplate = (translateKeyFn = () => {}) =>
  `<article id="eCardSelectionView">
    <form aria-live="off">
      <div id="ecard-heading"></div>
      <div class="${styles.detailsSummary}"></div>
      <h2 id="ecard-subheading" class="${styles.subheading}">${translateKeyFn('give-widget-add-media', 'Add media') || 'Add media'}</h2>
      <div id="ecard-tabs"></div>
      <div id="ecard-upload"></div>
      <div id="ecard-list"></div>
      <div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv}"></div>
    </form>
  </article>`;

export default eCardSelectionViewTemplate;
