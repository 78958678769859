import { createContext, useContext } from 'react';

export const TranslationContext = createContext({
  translateKey: (key, fallback = '', replace = '', value = '') =>
    fallback.replace(replace, value)
});

const useTranslation = () => {
  const {
    translateKey,
    translateDateFormat,
    translateClientStrings
  } = useContext(TranslationContext);
  return { t: translateKey, translateDateFormat, translateClientStrings };
};
export default useTranslation;
