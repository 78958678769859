import styles from './ecard_list_container.css';
import { remove } from '../../assets/icons';
import { clientPrimaryBackground } from '../../css/client_colors.js';
import { findPreviewType, getEcardUrl } from '../../utils/utils';

export const eCardItemsTemplate = (list = []) =>
  list.reduce((temp, card, i) => (
    `${temp}<li id="ecard-tile-${i}"></li>`
  ), '');

export const previewElement = (card) => {
  // These are listed in order of precedence.
  const imageUrl = getEcardUrl(card);
  const urls = [card.videoUrl, card.normalUrl, card.largeUrl, card.thumbNailUrl];

  const videoUrl = findPreviewType(urls, '.mp4');
  if (videoUrl !== '') {
    return `<video
        src="${videoUrl}"
        class="${styles.previewContent}"
        aria-label="${card.description !== '' ? card.description : card.text}"
        autoplay inline>
      </video>`;
  }

  if (imageUrl !== '') {
    return `<div class="${styles.eCardImgWrapper}">
        <img src="${imageUrl}" class="${styles.eCardImg}" title="${card.text}" alt="${card.text}">
      </div>`;
  }
  return `<div class="${styles.defaultPreview}"></div>`;
};

export const previewTemplate = (card, selectButtonText, translateKeyFn) =>
  `<div class="${styles.preview} ${styles.collapsed}">
    <span class="${styles.eCardTitle}">"${card.text}"</span>
    <button class="${styles.close}" aria-label="${translateKeyFn('give-widget-close-preview', 'close preview')}" type="button">${remove}</button>
    ${previewElement(card)}
    <button class="${styles.select} ${clientPrimaryBackground}" type="button">${selectButtonText}</button>
  </div>`;

export const categoryTemplate = (category) =>
  `<option value="${category}">${category}</option>`;

const eCardListContainerTemplate = (translateKeyFn) =>
  `<section class="${styles.eCardListContainer}" aria-live="off">
    <form>
      <div id="filter-ecard"></div>
      <ul class="${styles.eCardList}" id="give-widget_ecard-list" tabindex="-1" role="listbox"
      aria-label="${translateKeyFn('give-widget-ecard-list', 'Ecard Image list')}">
      </ul>
      <button class="${styles.linkButton} ${styles.loadMore}" type="button" id="give-widget_ecard-load-more">
        ${translateKeyFn('give-ecard-load-more', 'Load more')}
      </button>
    </form>
  </section>`;

export default eCardListContainerTemplate;
