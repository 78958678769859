import { getFirstElementChild } from '../../utils/utils';
import modalHeaderTemplate from './modal_header_template';

export default class ModalHeader {
  defaultProps = {
    selectedRecognitionType: ''
  };

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for ModalHeader component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props = {}, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    const template = modalHeaderTemplate(this.props.selectedRecognitionType, translateKeyFn);
    const modalHeaderFragment = document.createRange().createContextualFragment(template);
    const modalHeaderTemp = this.node;
    this.node = getFirstElementChild(modalHeaderFragment);
    modalHeaderTemp.parentNode.replaceChild(this.node, modalHeaderTemp);
  }
}
