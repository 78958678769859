// Select subset of state for each view

export const loadingView = (state = {}) => ({
  showPopupMessage: state.showPopupMessage
});

export const userSearchView = (state = {}) => ({
  messages: state.messages,
  corporateValueList: state.corporateValueList,
  descriptionOfAchievement: state.descriptionOfAchievement,
  descriptionOfAchievementText: state.descriptionOfAchievementText,
  selectedProgramNomination: state.selectedProgramNomination,
  selectedProgramECard: state.selectedProgramECard,
  selectedECardAmount: state.selectedECardAmount,
  editRecipient: state.editRecipient,
  followedEmployees: state.followedEmployees,
  giveOptions: state.giveOptions,
  userInfo: state.userInfo,
  recognitionTypes: state.recognitionTypes,
  proxyOptions: state.proxyOptions,
  recognizerId: state.recognizerId,
  recipients: state.recipients,
  customerFlags: state.customerFlags,
  selectedRecognitionType: state.selectedRecognitionType,
  awardList: state.awardList,
  programList: state.programList,
  selectedAwardLevel: state.selectedAwardLevel,
  selectedCorporateValueECard: state.selectedCorporateValueECard,
  selectedCorporateValueNomination: state.selectedCorporateValueNomination,
  termsOfServiceChecked: state.termsOfServiceChecked,
  wizard: state.wizard,
  wizardFlowFinished: state.wizardFlowFinished,
  ...state.giveOptions
});

export const nominationWizardView = (state = {}) => ({
  wizard: state.wizard,
  selectedProgramNomination: state.selectedProgramNomination,
  recipients: state.recipients,
  selectedRecognitionType: state.selectedRecognitionType,
  recognizerId: state.recognizerId,
  selectedAwardLevel: state.selectedAwardLevel,
  awardList: state.awardList,
  eCardRecommended: state.eCardRecommended,
  wizardFlowFinished: state.wizardFlowFinished,
  wizardOptional: state.wizardOptional,
  recommendedAwardLevel: state.recommendedAwardLevel,
  customerFlags: state.customerFlags
});

export const programDetailsView = (state = {}) => ({
  giveOptions: state.giveOptions,
  termsOfServiceChecked: state.termsOfServiceChecked,
  recipients: state.recipients,
  corporateValueList: state.corporateValueList,
  ccUsers: state.ccUsers,
  approverList: state.approverList,
  presenterList: state.presenterList,
  programList: state.programList,
  selectedCorporateValueNomination: state.selectedCorporateValueNomination,
  awardList: state.awardList,
  recognitionType: state.recognitionType,
  recognizerId: state.recognizerId,
  selectedRecognitionType: state.selectedRecognitionType,
  selectedProgramNomination: state.selectedProgramNomination,
  selectedAwardLevel: state.selectedAwardLevel,
  nomineesUpdated: state.nomineesUpdated,
  noteToApprover: state.noteToApprover,
  noteToApproverText: state.noteToApproverText,
  wizardOptional: state.wizardOptional,
  wizard: state.wizard,
  wizardFlowFinished: state.wizardFlowFinished,
  wizardUsed: state.wizardUsed,
  editRecipient: state.editRecipient,
  messages: state.messages,
  customerFlags: state.customerFlags
});

export const eCardDetailsView = (state = {}) => ({
  ccUsers: state.ccUsers,
  corporateValueList: state.corporateValueList,
  customerFlags: state.customerFlags,
  eCardAmountList: state.eCardAmountList,
  eCardList: state.eCardList,
  eCardNotifyManagerWhenViewed: state.eCardNotifyManagerWhenViewed,
  eCardNotifyManagerWhenViewedChecked: state.eCardNotifyManagerWhenViewedChecked,
  eCardNotifyWhenViewed: state.eCardNotifyWhenViewed,
  eCardNotifyWhenViewedChecked: state.eCardNotifyWhenViewedChecked,
  giveOptions: state.giveOptions,
  groupDetails: state.groupDetails,
  messages: state.messages,
  programList: state.programList,
  recipients: state.recipients,
  recognizerId: state.recognizerId,
  selectedCorporateValueECard: state.selectedCorporateValueECard,
  selectedECardAmount: state.selectedECardAmount,
  selectedProgramECard: state.selectedProgramECard,
  selectedRecognitionType: state.selectedRecognitionType
});

export const descriptionView = (state = {}) => ({
  recipients: state.recipients,
  recognizerId: state.recognizerId,
  noteToApproverText: state.noteToApproverText,
  wizard: state.wizard,
  approverList: state.approverList,
  presenterList: state.presenterList,
  ccUsers: state.ccUsers,
  selectedCCUsers: state.selectedCCUsers,
  selectedProgramNomination: state.selectedProgramNomination,
  selectedAwardLevel: state.selectedAwardLevel,
  selectedCorporateValueNomination: state.selectedCorporateValueNomination,
  descriptionOfAchievement: state.descriptionOfAchievement,
  thankYouNote: state.thankYouNote,
  descriptionOfAchievementText: state.descriptionOfAchievementText,
  thankYouNoteText: state.thankYouNoteText,
  selectedRecognitionType: state.selectedRecognitionType,
  isPrivacyEnabledNomination: state.isPrivacyEnabledNomination,
  privacy: state.privacy,
  transactionId: state.transactionId,
  recommendedAwardLevel: state.recommendedAwardLevel,
  boiLabelLine1OverrideField: state.boiLabelLine1OverrideField,
  boiText: state.boiText,
  messages: state.messages,
  customerFlags: state.customerFlags
});

export const eCardSelectionView = (state = {}) => ({
  customerFlags: state.customerFlags,
  eCardAmountList: state.eCardAmountList,
  eCardList: state.eCardList,
  eCardNotifyManagerWhenViewedChecked: state.eCardNotifyManagerWhenViewedChecked,
  eCardNotifyWhenOpened: state.eCardNotifyWhenOpened,
  eCardNotifyWhenViewedChecked: state.eCardNotifyWhenViewedChecked,
  eCardUpload: state.isECardUploadEnabled,
  groupDetails: state.groupDetails,
  recipients: state.recipients,
  recognizerId: state.recognizerId,
  selectedCorporateValueECard: state.selectedCorporateValueECard,
  selectedECard: state.selectedECard,
  selectedECardAmount: state.selectedECardAmount,
  selectedProgramECard: state.selectedProgramECard,
  selectedRecognitionType: state.selectedRecognitionType,
  uploadedECard: state.uploadedECard
});

export const eCardMessageView = (state = {}) => ({
  recipients: state.recipients,
  recognizerId: state.recognizerId,
  selectedProgramECard: state.selectedProgramECard,
  eCardAmountList: state.eCardAmountList,
  selectedECardAmount: state.selectedECardAmount,
  selectedCorporateValueECard: state.selectedCorporateValueECard,
  eCardNotifyWhenViewedChecked: state.eCardNotifyWhenViewedChecked,
  eCardNotifyManagerWhenViewedChecked: state.eCardNotifyManagerWhenViewedChecked,
  eCardList: state.eCardList,
  selectedECard: state.selectedECard,
  scheduleDateConfig: state.scheduleDateConfig,
  scheduledDeliveryDate: state.scheduledDeliveryDate,
  selectedRecognitionType: state.selectedRecognitionType,
  descriptionOfAchievement: state.descriptionOfAchievement,
  descriptionOfAchievementText: state.descriptionOfAchievementText,
  dateFormat: state.dateFormat,
  isPrivacyEnabledECard: state.isPrivacyEnabledECard,
  privacy: state.privacy,
  groupDetails: state.groupDetails,
  selectedCCUsers: state.selectedCCUsers,
  uploadedECard: state.uploadedECard,
  messages: state.messages,
  customerFlags: state.customerFlags,
  transactionId: state.transactionId
});

export const bulkUploadView = (state = {}) => ({
  customerFlags: state.customerFlags,
  recognizerId: state.recognizerId,
  recognitionTypes: state.recognitionTypes,
  groupDetails: state.groupDetails,
  groupECardHelpShow: state.giveOptions ? state.giveOptions.showHelpGuide : false,
  ...state.stp
});

export const submissionView = (state = {}) => ({
  selectedRecognitionType: state.selectedRecognitionType,
  scheduledDeliveryDate: state.scheduledDeliveryDate,
  dateFormat: state.dateFormat,
  groupDetails: state.groupDetails,
  customerFlags: state.customerFlags,
  ...state.stp
});
