import { getFirstElementChild } from '../../utils/utils';
import * as views from '../../views/views-list';
import styles from './recipient_header.css';
import recipientHeaderTemplate from './recipient_header_template';

export default class RecipentHeader {
  defaultProps = {
    recipients: [],
    selectedRecognitionType: '',
    bulkUploadCount: ''
  };

  static goToUserSearch = (dispatch) => () => {
    dispatch({
      type: 'SET_VIEW_NUMBER_AND_EDIT_RECIPIENT',
      payload: {
        viewNumber: views.USER_SEARCH_VIEW,
        editRecipient: true
      }
    });
  }

  static gotoBulkUpload = (dispatch) => () => {
    dispatch({
      type: 'SET_VIEW_NUMBER',
      payload: views.BULK_UPLOAD_VIEW
    });
  }

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for RecipentHeader component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props = {}, dispatch, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    const template = recipientHeaderTemplate(this.props.recipients, this.props.selectedRecognitionType, this.props.bulkUploadCount, translateKeyFn);
    const recipientHeaderFragment = document.createRange().createContextualFragment(template);
    const recipientHeaderTemp = this.node;
    this.node = getFirstElementChild(recipientHeaderFragment);
    recipientHeaderTemp.parentNode.replaceChild(this.node, recipientHeaderTemp);

    this.editButton = this.node.querySelector(`.${styles.editButton}`);
    this.editButton.addEventListener('click', this.props.bulkUploadCount
      ? RecipentHeader.gotoBulkUpload(dispatch)
      : RecipentHeader.goToUserSearch(dispatch));
  }
}
