import selectApproverPresenterTemplate,
{
  imageSelector,
  headingSelector,
  fullNameSelector,
  selectionTypeSelector,
  imageTemplate,
  headingTemplate,
  fullNameTemplate,
  selectionTypeTemplate
} from './select-approver-presenter-template';
import styles from './select-approver-presenter.css';
import { getFirstElementChild } from '../../utils/utils';
import List from '../list/list';
import AutoComplete from '../autocomplete/autocomplete';
import SelectBox from '../select-box/select-box';
import { queryUsers } from '../../state/services/api';
import { transformUserSearchResults } from '../../state/reducers/root_reducer';

let initialSelectApproverPresenterId = 0;

export default class SelectApproverPresenter {
  static generateId() {
    initialSelectApproverPresenterId += 1;
    return `selectApproverPresenter${initialSelectApproverPresenterId - 1}`;
  }

  listComp;
  autoCompleteComp;
  selectBoxComp;

  defaultProps = {
    id: SelectApproverPresenter.generateId(),
    fullName: '',
    initials: '',
    imageSrc: '',
    heading: '',
    selection: {
      type: 'provided',
      options: [],
      placeholder: '',
      value: ''
    },
    eligible: true,
    className: '',
    approverPresenter: '',
    onChange: () => {},
    translateKeyFn: () => {}
  }

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to SelectApproverPresenter component`);
    }
  }

  set id(id) {
    this.props.id = id;
    this.node.setAttribute('id', `give-widget_${id}`);
  }

  get id() {
    return this.props.id;
  }

  set className(className) {
    if (className) {
      this.props.className = className;
      this.node.classList.add(className);
    }
  }

  get className() {
    return this.props.className;
  }

  set approverPresenter(approverPresenter) {
    this.props.approverPresenter = approverPresenter;
  }

  get approverPresenter() {
    return this.props.approverPresenter;
  }

  set eligible(eligible) {
    this.props.eligible = eligible;
    if (eligible) {
      this.fullNameNode.classList.remove(styles.error);
    } else {
      this.fullNameNode.classList.add(styles.error);
    }
  }

  get eligible() {
    return this.props.eligible;
  }

  set fullName(fullName) {
    this.props.fullName = fullName;
    const {
      type = '',
      placeholder = ''
    } = this.selection;
    const fullNameFrag = document.createRange().createContextualFragment(fullNameTemplate(type, fullName, placeholder, this.id));
    const temp = getFirstElementChild(fullNameFrag);
    this.fullNameNode.parentNode.replaceChild(fullNameFrag, this.fullNameNode);
    this.fullNameNode = temp;

    const imageFrag = document.createRange().createContextualFragment(imageTemplate(this.imageSrc, this.initials, this.fullName));
    const temp1 = getFirstElementChild(imageFrag);
    this.imageNode.parentNode.replaceChild(imageFrag, this.imageNode);
    this.imageNode = temp1;

    this.fullNameNode.removeEventListener('click', this.toggleSelectionType);
    this.fullNameNode.addEventListener('click', this.toggleSelectionType);
  }

  get fullName() {
    return this.props.fullName;
  }

  set initials(initials) {
    this.props.initials = initials;
    const imageFrag = document.createRange().createContextualFragment(imageTemplate(this.props.imageSrc, this.props.initials, this.props.fullName));
    const temp = getFirstElementChild(imageFrag);
    this.imageNode.parentNode.replaceChild(imageFrag, this.imageNode);
    this.imageNode = temp;
  }

  get initials() {
    return this.props.initials;
  }

  set imageSrc(imageSrc) {
    this.props.imageSrc = imageSrc;
    const imageFrag = document.createRange().createContextualFragment(imageTemplate(this.props.imageSrc, this.props.initials, this.props.fullName));
    const temp = getFirstElementChild(imageFrag);
    this.imageNode.parentNode.replaceChild(imageFrag, this.imageNode);
    this.imageNode = temp;
  }

  get imageSrc() {
    return this.props.imageSrc;
  }

  set heading(heading) {
    this.props.heading = heading;
    const headingFrag = document.createRange().createContextualFragment(headingTemplate(this.props.heading));
    const temp = getFirstElementChild(headingFrag);
    this.headingNode.parentNode.replaceChild(headingFrag, this.headingNode);
    this.headingNode = temp;
  }

  get heading() {
    return this.props.heading;
  }

  set selection(selection) {
    this.props.selection = { ...selection };
    const {
      type = 'provided'
    } = selection;
    const frag = document.createRange().createContextualFragment(selectionTypeTemplate(type, this.props.id, this.props.approverPresenter));
    const temp = getFirstElementChild(frag);
    this.selectionNode.parentNode.replaceChild(frag, this.selectionNode);
    this.selectionNode = temp;
  }

  get selection() {
    return this.props.selection;
  }

  set translateKeyFn(translateKeyFn) {
    this.props.translateKeyFn = translateKeyFn;
  }

  get translateKeyFn() {
    return this.props.translateKeyFn;
  }

  set onChange(onChange) {
    this.props.onChange = onChange;
  }

  get onChange() {
    return this.props.onChange;
  }

  onEscapeCallback = () => {
    this.autoComplete.blur();
  }

  onSelectCallback = (selectedItemData) => {
    this.list.isOpen = false;
    this.autoComplete.clear();
    this.fullNameNode.classList.toggle(styles.hidden);
    this.fullNameNode.focus();
    this.headingNode.classList.toggle(styles.hidden);
    this.selectionNode.classList.toggle(styles.hidden);
    this.onChange(selectedItemData);
  }

  minCharactersCallback = () => {
    this.list.isLoading = false;
    this.list.isOpen = true;
    this.list.noDataMessage = this.translateKeyFn('minimum-characters');
    this.list.messageError = false;
    this.list.data = [];
  }

  source = (searchTerm) => {
    this.list.isOpen = true;
    this.list.isLoading = true;
    return queryUsers(searchTerm);
  }

  customRenderer = (val, results) => {
    if (results.length > 0) {
      this.list.noDataMessage = '';
      this.list.isOpen = true;
    } else {
      this.list.isOpen = false;
      this.list.noDataMessage = `${this.translateKeyFn('give-widget-no-results-found-label', `No one found matching ${val}`, '{val}', `${val}`)}`;
      this.list.messageError = true;
    }
    this.list.isLoading = false;
    this.list.data = results;
  }

  valueCleared = () => {
    this.list.isLoading = false;
    this.list.data = [];
    this.list.isOpen = false;
    this.list.noDataMessage = '';
  }

  selectBoxOnChange = (...args) => {
    this.fullNameNode.classList.toggle(styles.hidden);
    this.fullNameNode.focus();
    this.headingNode.classList.toggle(styles.hidden);
    this.selectionNode.classList.toggle(styles.hidden);
    this.onChange(...args);
  }

  focus = () => {
    this.fullNameNode.focus();
  };

  toggleSelectionType = () => {
    const {
      type = '',
      placeholder = '',
      options = [],
      value = ''
    } = this.selection;

    if (this.selection.type !== 'provided' && this.selection.type !== 'none') {
      this.fullNameNode.classList.toggle(styles.hidden);
      this.headingNode.classList.toggle(styles.hidden);
      this.selectionNode.classList.toggle(styles.hidden);
    }

    if (type === 'search') {
      const searchNode = this.selectionNode.querySelector('[id$="_search"]');
      const listNode = this.selectionNode.querySelector('[id$="_list"]');
      const searchNodeSelector = `#${searchNode.getAttribute('id')}`;
      const listNodeSelector = `#${listNode.getAttribute('id')}`;
      this.list = new List(listNodeSelector);
      this.autoComplete = new AutoComplete(searchNodeSelector);
      this.list.render({
        isLoading: false,
        isOpen: false,
        isClosable: true,
        data: [],
        className: this.props.approverPresenter === 'presenter' ? styles.presenterListContainer : styles.listContainer,
        unSelectableItems: [],
        onEscapeCallback: this.onEscapeCallback,
        onSelectCallback: this.onSelectCallback,
        translateKeyFn: this.translateKeyFn,
        id: listNode.getAttribute('id')
      });
      this.autoComplete.render({
        placeholder,
        minCharactersCallback: this.minCharactersCallback,
        source: this.source,
        transformResults: transformUserSearchResults,
        customRenderer: this.customRenderer,
        rendererId: this.list.id,
        handleKeyDown: this.list.handleKeys,
        valueCleared: this.valueCleared,
        id: listNode.getAttribute('id')
      });
      this.autoComplete.focus();
    } else if (type === 'select') {
      const selectBoxNode = this.selectionNode;
      const selectBoxSelector = `#${selectBoxNode.getAttribute('id')}`;
      this.selectBoxComp = new SelectBox(selectBoxSelector);
      this.selectBoxComp.render({
        data: options,
        selectedValue: value || options[0].value,
        onChange: this.selectBoxOnChange,
        className: styles.selectBox,
        ariaLabel: options[0].text
      });
    }
  }

  render(props) {
    const tempFrag = document.createRange().createContextualFragment(selectApproverPresenterTemplate());
    const temp = getFirstElementChild(tempFrag);
    this.node.parentNode.replaceChild(tempFrag, this.node);
    this.node = temp;
    this.imageNode = this.node.querySelector(imageSelector);
    this.headingNode = this.node.querySelector(headingSelector);
    this.fullNameNode = this.node.querySelector(fullNameSelector);
    this.selectionNode = this.node.querySelector(selectionTypeSelector);

    this.props = { ...this.defaultProps, ...props };

    this.id = this.props.id;
    this.className = this.props.className;
    this.selection = this.props.selection;
    this.fullName = this.props.fullName;
    this.eligible = this.props.eligible;
    this.initials = this.props.initials;
    this.imageSrc = this.props.imageSrc;
    this.heading = this.props.heading;
    this.translateKeyFn = this.props.translateKeyFn;

    this.onChange = this.props.onChange;
  }
}
