import loadingViewTemplate from './loading_view_template';
import { getFirstElementChild } from '../../utils/utils';
import styles from './loading_view.css';

export default class LoadingView {
  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to loading_view component.`);
    }
  }

  render(dispatch, state) {
    const popupString = 'Please enable popups in order to log in.';
    const loadingViewFragment = document.createRange().createContextualFragment(loadingViewTemplate(popupString));
    const loadingViewTemp = this.node;
    this.node = getFirstElementChild(loadingViewFragment);

    if (state.showPopupMessage) {
      this.node.classList.add(styles.showPopupMessage);
    } else {
      this.node.classList.remove(styles.showPopupMessage);
    }

    loadingViewTemp.parentNode.replaceChild(this.node, loadingViewTemp);
    this.node.classList.add('give-widget-current-view');
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
