import styles from './date_picker.css';
import { get, isDateAEqualToDateB } from './utils';

const datePickerControlsSelectYearId = 'datePickerControlsYearSelect';
export const datePickerControlsSelectYearSelector = `#${datePickerControlsSelectYearId}`;

const datePickerControlsSelectMonthId = 'datePickerControlsMonthSelect';
export const datePickerControlsSelectMonthSelector = `#${datePickerControlsSelectMonthId}`;

const datePickerControlsNextMonthButtonId = 'datePickerControlsNextMonthButton';
export const datePickerControlsNextMonthButtonSelector = `#${datePickerControlsNextMonthButtonId}`;

const datePickerControlsPreviousMonthButtonId = 'datePickerControlsPreviousMonthButton';
export const datePickerControlsPreviousMonthButtonSelector = `#${datePickerControlsPreviousMonthButtonId}`;

const datePickerGridBodyId = 'datePickerGridBody';
export const datePickerGridBodySelector = `#${datePickerGridBodyId}`;

export const datePickerCurrentlyFocusedDateSelector = `.${styles.notEmpty}[tabIndex="0"]`;
export const datePickerFallbackDateToFocusSelector = `.${styles.datePickerTable} tbody tr:first-child .${styles.notEmpty}[role="link"]`;
export const datePickerFocusableElements = `.${styles.notEmpty}[aria-disabled="false"]`;
export const datePickerContainerSelector = `.${styles.datePicker}`;

export const datePickerSelectYearHTML = (years, selectedYear) => `<select id="${datePickerControlsSelectYearId}" class="${
  styles.selectWithoutDefaultStyling
}">
  ${years.reduce(
    (acc, year, idx) =>
      `${acc}
      <option 
        value="${year}" 
        ${year === selectedYear ? 'selected' : ''}
        aria-label="${year} ${idx + 1} of ${years.length}"
      >${year}</option>`,
    ''
  )}
</select>`;

export const datePickerSelectMonthHTML = (months, selectedMonth) =>
  `<select id="${datePickerControlsSelectMonthId}" class="${styles.selectWithoutDefaultStyling}">
  ${months.reduce(
    (acc, { long, isDisabled }, idx) =>
      `${acc}
      <option 
        value="${idx + 1}"
        ${idx + 1 === selectedMonth ? 'selected' : ''}
        ${isDisabled ? 'disabled' : ''}
        aria-label="${long} ${idx + 1} of ${months.length}"
      >${long}</option>`,
    ''
  )}
</select>`;

export const datePickerBodyHTML = (selectedMonth, dateToFocusOn, chunkOfDates) =>
  chunkOfDates.reduce(
    (outerAcc, dateChunk) => `${outerAcc}
      <tr role="presentation">
        ${dateChunk.reduce(
    (innerAcc, {
      dayNumber, formattedDate, isDisabled, dateObj
    }) => `${innerAcc}
          ${
  get(dateObj, 'MM') !== selectedMonth
    ? `<td role="gridcell" class="${styles.isEmpty}"></td>`
    : `<td aria-label="${formattedDate}" aria-live="off"
          ${isDisabled ? 'aria-disabled="true"' : 'aria-disabled="false"'}
          ${isDisabled ? 'aria-hidden="true"' : 'aria-hidden="false"'}
          role="gridcell"
          ${
  isDateAEqualToDateB(dateToFocusOn, dateObj)
    ? 'tabindex="0"'
    : 'tabindex="-1"'
}
                class="${styles.notEmpty}"
                data-date-obj="${dateObj}"
                >
                  <span aria-hidden="true">
                    ${dayNumber}
                  </span>
                </td>`
}`,
    ''
  )}
      </tr>`,
    ''
  );

const datePickerHTML = (
  years,
  months,
  daysOfTheWeek,
  dates,
  selectedMonth,
  selectedYear,
  dateToFocusOn,
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  className
) => `
    <div class="${styles.datePicker} ${styles.hidden} ${className}" role="dialog" aria-hidden="true" aria-label="calendar">
      <div class="${styles.controls}">
        <button type="button" aria-label="Click here to goto previous month" 
          class="${styles.prevButton}"
          id="${datePickerControlsPreviousMonthButtonId}"
          ${isPreviousButtonDisabled ? 'disabled' : ''}
        ></button>
        <span>
          <label for="datePickerMonthSelect" class="${styles.hidden}">Select a Month</label>
          ${datePickerSelectMonthHTML(months, selectedMonth)}
          <label for="datePickerYearSelect" class="${styles.hidden}">Select a Month</label>
              ${datePickerSelectYearHTML(years, selectedYear)}
        </span>
        <button type="button" aria-label="Click here to goto next month" 
          class="${styles.nextButton}"
          id="${datePickerControlsNextMonthButtonId}"
          ${isNextButtonDisabled ? 'disabled' : ''}
        ></button>
      </div>
      <table cellpadding="0" cellspacing="0" class="${styles.datePickerTable}" aria-label=
      "calendar" role="application">
        <thead>
          <tr role="presentation">
            ${daysOfTheWeek.reduce((acc, { long, short }) => `${acc}
              <th scope="col" title="${long}" aria-label="${long}" class="${styles.heading}">
                <span aria-hidden="true">${short}</span>
              </th>
            `, '')}
          </tr>
        </thead>
        <tbody id="${datePickerGridBodyId}">
          ${datePickerBodyHTML(selectedMonth, dateToFocusOn, dates)}
        </tbody>
      </table>
    </div>
    `;

export const datePickerContainerHTML = () => '<div></div>';

export default datePickerHTML;
