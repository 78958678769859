const VALUE_CREATED = 'created';
const VALUE_UPDATED = 'updated';
const VALUE_DELETED = 'deleted';
const VALUE_UNCHANGED = 'unchanged';

const isFunction = (obj) => Object.prototype.toString.call(obj) === '[object Function]';
const isDate = (obj) => Object.prototype.toString.call(obj) === '[object Date]';

const compareValues = (value1, value2) => {
  if (value1 === value2) {
    return VALUE_UNCHANGED;
  }
  if (isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime()) {
    return VALUE_UNCHANGED;
  }
  if (typeof value2 === 'undefined') {
    return VALUE_DELETED;
  }
  return VALUE_UPDATED;
};

const computeDifferenceBetweenTwoObjects = (obj1, obj2) => {
  if (isFunction(obj1) || isFunction(obj2)) {
    throw new Error('Invalid argument. Function given, object expected.');
  }

  const diff = Object.keys(obj1).reduce((acc, key) => {
    if (isFunction(obj1[key]) === false && isFunction(obj2[key]) === false) {
      const result = compareValues(obj1[key], obj2[key]);
      if (result !== VALUE_UNCHANGED) {
        acc[key] = {
          type: result,
          data: obj2[key] === undefined ? obj1[key] : obj2[key]
        };
      }
    }
    return acc;
  }, {});
  const result = Object.keys(obj2).reduce((acc, key) => {
    if (isFunction(obj2[key]) === false && typeof obj1[key] === 'undefined') {
      acc[key] = {
        type: VALUE_CREATED,
        data: obj2[key]
      };
    }
    return acc;
  }, diff);
  return result;
};

export default computeDifferenceBetweenTwoObjects;
