import modalStyles from '../components/modal/modal.css';

export const clientPrimaryColor = 'oct-client-color';
export const clientPrimaryFill = 'oct-client-fill';
export const clientPrimaryStroke = 'oct-client-stroke';
export const clientPrimaryBackground = 'oct-client-background';
export const clientPrimaryRadioButtonsBeforeBorder = 'oct-client-radio-button-before-border';
export const clientPrimaryRadioPillButtonsBorder = 'oct-client-radio-pill-button-border';
export const clientPrimaryBorder = 'oct-client-border';
export const clientPrimaryBorderColor = 'oct-client-border-color';
export const prismClientPrimaryBackgroundHover = 'prism-ui oct-client-background-hover';
const prismClientPrimaryBackgroundHoverSelector = '.prism-ui.oct-client-background-hover';

const validateHexColor = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  let color = str.toString().replace(/[^0-9a-f]/gi, '');
  if (color.length >= 3 && color.length < 6) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }
  return color;
};

function changeLuminosity(color, change) {
  const black = 0;
  const white = 255;
  let newColor = '';
  let c;
  for (let i = 0; i < 3; i++) {
    c = parseInt(color.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(black, c + (change * white)), white)).toString(16);
    newColor += (`00${c}`).substr(c.length);
  }
  return newColor;
}

function getContrastRatio(color1, color2) {
  // See https://www.w3.org/TR/WCAG20-TECHS/G18.html
  const relativeLuminance = (hexColor) => {
    const r = parseInt(hexColor.slice(0, 2), 16) / 255;
    const g = parseInt(hexColor.slice(2, 4), 16) / 255;
    const b = parseInt(hexColor.slice(4, 6), 16) / 255;

    const convert = (color) => (color <= 0.03928 ? color / 12.92 : ((color + 0.055) / 1.055) ** 2.4);

    return (0.2126 * convert(r)) + (0.7152 * convert(g)) + (0.0722 * convert(b));
  };

  let contrastRatio = ((relativeLuminance(color1) + 0.05) / (relativeLuminance(color2) + 0.05));
  if (contrastRatio < 1) {
    contrastRatio = 1 / contrastRatio;
  }
  return contrastRatio;
}

const getBackgroundColor = (hexBackgroundColor) => {
  const textColor = 'ffffff';
  let newBgColor = hexBackgroundColor;
  let contrastRatio = getContrastRatio(hexBackgroundColor, textColor);

  while (contrastRatio < 4.5) {
    newBgColor = changeLuminosity(newBgColor, -0.1);
    contrastRatio = getContrastRatio(newBgColor, textColor);
  }
  return newBgColor;
};

function textOnClientColor(clientColor) {
  const white = 'ffffff';
  const color = validateHexColor(clientColor);
  const modifiedColor = getBackgroundColor(color);

  // 2 is an arbitrary cutoff, below which we don't modify
  // the client color and just use dark text
  if (getContrastRatio(color, white) < 2) {
    return {
      background: color,
      text: '212121', // --gray-900
      clientTextColor: modifiedColor
    };
  }
  return {
    background: modifiedColor,
    text: white,
    clientTextColor: modifiedColor
  };
}

export default ({ primaryHexColor }) => {
  const colors = textOnClientColor(primaryHexColor);

  return `#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryColor} {
  color: #${colors.clientTextColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryFill} {
  fill: #${primaryHexColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryStroke} {
  stroke: #${primaryHexColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBackground},
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBackground}:hover,
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBackground}:active,
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBackground}:disabled,
[id^="give-widget_appreciateButton"].${clientPrimaryBackground},
[id^="give-widget_appreciateButton"].${clientPrimaryBackground}:hover,
[id^="give-widget_appreciateButton"].${clientPrimaryBackground}:active,
[id^="give-widget_appreciateButton"].${clientPrimaryBackground}:disabled {
  background: #${colors.background};
  color: #${colors.text};
}
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBorder},
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBorder}:hover,
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBorder}:active,
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryBorder}:disabled,
[id^="give-widget_appreciateButton"].${clientPrimaryBorder},
[id^="give-widget_appreciateButton"].${clientPrimaryBorder}:hover,
[id^="give-widget_appreciateButton"].${clientPrimaryBorder}:active,
[id^="give-widget_appreciateButton"].${clientPrimaryBorder}:disabled {
  border: 1px solid #${primaryHexColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} li.${clientPrimaryBorderColor},
#oct-appreciateModal .${modalStyles.modalWrapper} form.${clientPrimaryBorderColor} {
  border-color: #${primaryHexColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} .${clientPrimaryRadioPillButtonsBorder} {
  box-shadow: 0 0 0 2px #${primaryHexColor};
}
#oct-appreciateModal .${modalStyles.modalWrapper} li label.${clientPrimaryRadioButtonsBeforeBorder}::before {
  border: solid 7px #${primaryHexColor};
}

#oct-appreciateModal .${modalStyles.modalWrapper} ${prismClientPrimaryBackgroundHoverSelector}:hover {
  background: #${colors.background};
  color: #${colors.text};
}

#oct-appreciateModal > #give-widget_appreciate-button {
  background: #${colors.background};
  border: 1px solid #${primaryHexColor};
  color: #${colors.text};
}

#oct-appreciateModal > #give-widget_appreciate-button:hover {
  color: #${colors.text};
}`;
};
