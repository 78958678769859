import styles from './description_view.css';
import commonStyles from '../../css/common.css';

export const modalHeaderSelector = '#modalHeader';

const descriptionHeadingId = 'descriptionHeading';
export const descriptionHeadingSelector = '#descriptionHeading';

const detailsHeadingId = 'detailsHeading';
export const detailsHeadingSelector = '#detailsHeading';

export const privacySelectBoxSelector = '#privacySelectBox';
const privacySelectBoxId = 'privacySelectBox';

export const privacySelectBoxWrapperSelector = '#privacySelectBoxWrapper';
export const privacySelectBoxWrapperId = 'privacySelectBoxWrapper';

const descriptionOfAchievementId = 'descriptionText';
export const descriptionOfAchievementSelector = '#descriptionText';

const thankYouNoteId = 'thankYouNoteText';
export const thankYouNoteSelector = '#thankYouNoteText';

const boiCostId = 'boiCostText';
export const boiCostSelector = '#boiCostText';

const errorMsgId = 'errorMsg';
export const errorMsgSelector = '#errorMsg';

const submitButtonId = 'submit';
export const submitButtonSelector = '#submit';

const descriptionViewTemplate = (translateKeyFn) =>
  `<article class="${styles.description_view}">
    <form>
      <div id="${descriptionHeadingId}"></div>
      <div id="${detailsHeadingId}"></div>
      <div id="${errorMsgId}"></div>
      <section class="${styles.privacyWrapper}" id="${privacySelectBoxWrapperId}">
        <p>${translateKeyFn('give-widget-who-should-see-this-label', 'Display on company social wall?')}</p>
        <div id="${privacySelectBoxId}"></div>
      </section>
      <section id="${descriptionOfAchievementId}"></section>
      <section id="${thankYouNoteId}"></section>
      <section id="${boiCostId}"></section>
      <div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv}">
        <div class="${styles.submitButtonWrapper}"><button id="${submitButtonId}" type="button">Submit</button></div>
      </div>
    </form>
  </article>`;

export default descriptionViewTemplate;
