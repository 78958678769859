import NominationWizardTemplate, {
  nominationWizardItemTemplate,
  nominationWizardAwardLevelsTemplate,
  nominationWizardButtonTemplate,
  loadingQuestionTemplate
} from './nomination-wizard-template';
import * as views from '../views-list';
import QuestionAnswers from '../../components/question_answers/question_answers';
import Button from '../../components/button/button';
import emptyElement, { getFirstElementChild, positionElement, setHeader } from '../../utils/utils';
import styles from './nomination-wizard.css';
import { getWizard } from '../../state/services/api';
import { getHeaderText } from '../../components/modal_header/modal_header_template';

export default class NominationWizard {
  previouslySelectedQuestionAndAnswers = [];
  questionAnswersComponents = [];
  recommendedAwardLevelsComponent = {};
  nextButtonComponent = {};

  constructor(selector, dispatch, state) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for NominationWizard component: ${selector}`);
    } else {
      this.node = node;
      getWizard(state, dispatch, 0);
    }
  }

  getNextWizardQuestion = (
    previouslySelectedQuestionAndAnswer = [],
    {
      selectedProgramNomination = {}, recipients = [], selectedRecognitionType = '', recognizerId = '', wizard = [], wizardOptional = false
    } = {},
    dispatch,
    index
  ) => {
    this.questionAnswerListNode.insertAdjacentHTML('beforeend', loadingQuestionTemplate());
    const newWizard = wizard.map((wizardItem) => {
      const temp = previouslySelectedQuestionAndAnswer.filter(({ question }) => question.value === wizardItem.question.value);
      if (temp.length > 0) {
        return { ...wizardItem, selectedAnswer: temp[0].answer };
      }
      return wizardItem;
    });
    const answerIds = newWizard
      .slice(0, index + 1)
      .reduce((acc, item) => `${acc}${item.selectedAnswer},`, '')
      .replace(/,$/, '');

    return getWizard({
      selectedProgramNomination,
      recipients,
      selectedRecognitionType,
      recognizerId,
      answerIds,
      wizardOptional,
      wizard
    }, dispatch).then((res) => {
      const loadingDiv = this.questionAnswerListNode.querySelector(`.${styles.loadingShimmer}`);
      if (loadingDiv) {
        this.questionAnswerListNode.removeChild(loadingDiv);
      }
      if (res.wizard) {
        dispatch({
          type: 'SET_ECARD_RECOMMENDED',
          payload: false
        });
        const awardLevels = document.getElementById('recommended-award-levels');
        const nextButton = document.querySelector('.wizard-next-wrapper');
        if (awardLevels && nextButton) {
          awardLevels.classList.add('hidden');
          nextButton.classList.add('hidden');
        }
        const newQuestion = res.wizard.input.question;
        const temp = previouslySelectedQuestionAndAnswer.filter(({ question }) => question.value === newQuestion.value);
        if (temp.length > 0) {
          const data = {
            question: res.wizard.input.question,
            answers: res.wizard.input.answerId.options,
            selectedAnswer: temp.answer
          };
          this.getNextWizardQuestion(
            previouslySelectedQuestionAndAnswer,
            {
              selectedProgramNomination,
              recipients,
              selectedRecognitionType,
              recognizerId,
              wizard: newWizard.slice(0, index + 1).concat([data]),
              wizardOptional
            },
            dispatch,
            index + 1
          );
        } else if (wizardOptional === true) {
          dispatch({
            type: 'SET_WIZARD',
            payload: newWizard.slice(0, index + 1).concat({
              question: res.wizard.input.question,
              answers: res.wizard.input.answerId.options,
              selectedAnswer: ''
            })
          });
        } else {
          dispatch({
            type: 'SET_WIZARD_AND_AWARD_LIST',
            payload: {
              wizard: newWizard.slice(0, index + 1).concat({
                question: res.wizard.input.question,
                answers: res.wizard.input.answerId.options,
                selectedAnswer: ''
              }),
              awardList: []
            }
          });
        }
      } else {
        dispatch({
          type: 'SET_WIZARD',
          payload: newWizard
        });
      }
    });
  };

  initializeAllSubComponents(state, translateKeyFn) {
    const html = state.wizard.reduce(
      (acc, item, index) => `${acc}${nominationWizardItemTemplate(index)}`,
      ''
    );
    const frag = document.createRange().createContextualFragment(html);
    emptyElement(this.questionAnswerListNode);
    this.questionAnswersComponents = [];
    let { firstChild } = frag;
    let index = 0;
    while (firstChild) {
      if (firstChild.nodeType === 1) {
        // 1 is an element node
        this.questionAnswerListNode.appendChild(firstChild);
        const questionAnswers = new QuestionAnswers(`#nom-wizard-question-${index}`);
        index += 1;
        this.questionAnswersComponents.push(questionAnswers);
      }
      ({ firstChild } = frag);
    }

    const recommendedAwardLevelsHTML = nominationWizardAwardLevelsTemplate(
      state.eCardRecommended,
      translateKeyFn(`${state.selectedProgramNomination.value}/recommendation-body`)
    );

    const recommendedAwardLevelsFrag = document
      .createRange()
      .createContextualFragment(recommendedAwardLevelsHTML);
    const recommendedAwardLevelsNode = getFirstElementChild(recommendedAwardLevelsFrag);
    this.node.appendChild(recommendedAwardLevelsNode);
    this.recommendedAwardLevelsComponent = new QuestionAnswers('#recommended-award-levels');

    const nextButtonHTML = nominationWizardButtonTemplate();
    const nextButtonFrag = document.createRange().createContextualFragment(nextButtonHTML);
    const nextButtonNode = getFirstElementChild(nextButtonFrag);
    this.node.appendChild(nextButtonNode);
    this.nextButtonComponent = new Button('#wizard-next');
  }

  renderAllSubComponents(dispatch, state, translateKeyFn) {
    const headerText = getHeaderText(state.selectedRecognitionType, translateKeyFn);
    setHeader(headerText);
    state.wizard.forEach(({ question, answers, selectedAnswer }, index) => {
      this.questionAnswersComponents[index].render({
        question: question.value,
        required: true,
        answers,
        selectedValue: selectedAnswer,
        name: `nom-wizard-question-${index}`,
        onChange: (evt) => {
          let idx = -1;
          const { target: { value } = {} } = evt;
          this.previouslySelectedQuestionAndAnswers.forEach((item, i) => {
            if (item.question.value === question.value && idx === -1) idx = i;
          });
          if (idx > -1) this.previouslySelectedQuestionAndAnswers[idx].answer = value;
          else {
            idx = this.previouslySelectedQuestionAndAnswers.push({ question, answer: value });
          }
          return this.getNextWizardQuestion(this.previouslySelectedQuestionAndAnswers, state, dispatch, idx);
        },
        id: `wizard-question${index}`
      }, translateKeyFn);
    });

    if (state.wizardFlowFinished === true) {
      const recommendedAwardLevel = NominationWizard.findRecommendedAwardLevel(state.recommendedAwardLevel);
      if (recommendedAwardLevel.text === 'eCard') {
        recommendedAwardLevel.text = translateKeyFn('ecard-label', 'eCard');
      }
      const nextButtonWrapper = document.querySelector('.wizard-next-wrapper');
      if (nextButtonWrapper) {
        nextButtonWrapper.classList.remove('hidden');
      }

      // TODO: Add 'disabled' property to whichever answers need to be disabled
      this.recommendedAwardLevelsComponent.render({
        question: `${translateKeyFn('give-widget-recommended-award-level-label', 'Recommended Award Level:')} ${recommendedAwardLevel.text}`,
        answers: state.recommendedAwardLevel,
        selectedValue: state.selectedAwardLevel.value || recommendedAwardLevel.value,
        required: true,
        isPillStyle: true,
        name: 'recommendedAwardLevelId',
        onChange: (evt) => {
          const newRecommendedAwardLevel = state.recommendedAwardLevel;
          const selectedAwardlevel = { value: evt.target.value, selected: true, text: evt.target.parentElement.textContent };
          NominationWizard.selectAwardLevel(dispatch, selectedAwardlevel, newRecommendedAwardLevel);
        },
        id: 'wizard-levels'
      });

      this.nextButtonComponent.render({
        title: translateKeyFn('next', 'Next'),
        onClick: () => {
          NominationWizard.goToNextView(dispatch, state, recommendedAwardLevel);
        },
        id: 'wizard-next'
      });

      const ctaDiv = document.querySelector(`#${styles.callToActionDiv}`);
      ctaDiv.classList.remove(`${styles.hidden}`);
      positionElement(`#${styles.callToActionDiv}`);
      const article = document.querySelector('#nomination-wizard');
      article.style.marginBottom = '60px';
      const recommendedAwardLevels = document.querySelector('.give-widget-current-view > fieldset > ul');
      if (recommendedAwardLevels) {
        recommendedAwardLevels.scrollIntoView(true);
      }
    }
  }

  static selectAwardLevel(dispatch, { value, selected, text }, recommendedAwardLevel) {
    const selectedRecognitionType = value === 'eCard' ? 'ECard' : 'Nomination';
    dispatch({
      type: 'SET_AWARD_LEVEL_AND_SELECTED_RECOGNITION_TYPE',
      payload: {
        selectedRecognitionType,
        selectedAwardLevel: { value, selected, text },
        recommendedAwardLevel
      }
    });
  }

  static goToNextView(dispatch, { customerFlags, selectedAwardLevel, selectedProgramNomination }, recommendedAwardLevel) {
    if (customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      const awardLevel = selectedAwardLevel.value ? selectedAwardLevel : recommendedAwardLevel;
      const isEcard = awardLevel?.value === 'eCard';
      dispatch({
        type: 'FINISH_WIZARD_AND_RETURN',
        payload: {
          viewNumber: views.USER_SEARCH_VIEW,
          selectedAwardLevel: awardLevel,
          selectedProgramNomination,
          selectedRecognitionType: isEcard ? 'ECard' : 'Nomination',
          wizardFlowFinished: true
        }
      });
    } else {
      let recommendedECard = false;
      if (selectedAwardLevel.value) {
        if (selectedAwardLevel.value === 'eCard') {
          recommendedECard = true;
        }
      } else if (recommendedAwardLevel.value && recommendedAwardLevel.value === 'eCard') {
        recommendedECard = true;
      }
      if (recommendedECard) {
        dispatch({
          type: 'SET_VIEW_NUMBER_AND_SELECTED_AWARD_LEVEL_AND_SET_SELECTED_RECOGNITION_TYPE',
          payload: {
            viewNumber: views.ECARD_DETAILS_VIEW,
            selectedRecognitionType: 'ECard',
            selectedAwardLevel: selectedAwardLevel.value ? selectedAwardLevel : recommendedAwardLevel
          }
        });
      } else {
        dispatch({
          type: 'SET_VIEW_NUMBER_AND_WIZARD_USED',
          payload: {
            viewNumber: views.PROGRAM_DETAILS_VIEW,
            wizardUsed: true,
            selectedAwardLevel: selectedAwardLevel.value ? selectedAwardLevel : recommendedAwardLevel
          }
        });
      }
    }
  }

  static findRecommendedAwardLevel(awardLevelList = []) {
    const selectedAwardLevelIndex = awardLevelList.reduce((acc, awardLevel, index) => {
      if (acc === -1 && awardLevel.selected) {
        return index;
      }
      return acc;
    }, -1);
    if (selectedAwardLevelIndex === -1) {
      return awardLevelList[0];
    }
    return awardLevelList[selectedAwardLevelIndex];
  }

  static isAwardLevelInList(selectedAwardLevel, awardLevelList) {
    return awardLevelList.reduce((acc, awardLevel) => (
      acc || selectedAwardLevel.value === awardLevel.value
    ), false);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }

  render(dispatch, state, translateKeyFn) {
    const nominationWizardViewFragment = document
      .createRange()
      .createContextualFragment(NominationWizardTemplate(translateKeyFn));
    const nominationWizardViewTemp = this.node;
    this.node = getFirstElementChild(nominationWizardViewFragment);
    nominationWizardViewTemp.parentNode.replaceChild(this.node, nominationWizardViewTemp);
    this.questionAnswerListNode = this.node.querySelector('ul');
    this.initializeAllSubComponents(state, translateKeyFn);
    this.node.classList.add('give-widget-current-view');
    this.renderAllSubComponents(dispatch, state, translateKeyFn);
  }
}
