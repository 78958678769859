import {
  formLockStateHandler,
  getFirstElementChild,
  removeAllHtml,
  translateSubmitErrorMessages,
  positionElement,
  setHeader
} from '../../utils/utils';
import eCardMessageViewTemplate, * as selectors from './ecard_message_view_template';
import DetailsSummary from '../../components/details_summary/details_summary';
import Textbox from '../../components/textbox/textbox';
import Button from '../../components/button/button';
import SelectBox from '../../components/select-box/select-box';
import RecipientHeader from '../../components/recipient_header/recipient_header';
import styles from './ecard_message_view.css';
import * as views from '../views-list';
import { submitECard } from '../../state/services/api';
import { errorMsgTemplate } from '../../components/common_templates/common_templates';
import DatePicker from '../../components/date_picker/date_picker';
import { getLanguage } from '../../state/services/fetch_api';
import { getHeaderText } from '../../components/modal_header/modal_header_template';

export default class ECardMessageView {
  static goEditECard = (dispatch) =>
    () => {
      dispatch({
        type: 'SET_VIEW_NUMBER',
        payload: views.ECARD_SELECTION_VIEW
      });
    };

  static setPrivacySettings = (dispatch) => ({ value = false } = {}) => {
    dispatch({
      type: 'SET_PRIVACY',
      payload: value
    });
  }

  static messageSectionOnChange = (dispatch) => (message) => {
    dispatch({
      type: 'SET_DESCRIPTION_TEXT',
      payload: removeAllHtml(message)
    });
  }

  static isSubmitButtonDisabled(state) {
    return state.descriptionOfAchievement.required && state.descriptionOfAchievementText.trim() === '';
  }

  static errorMessageListChanged = ({ messages: { type = '', data } = {} } = {}) => type === 'updated' && Array.isArray(data);

  scheduleButtonOnClick = (dispatch) => {
    const scheduleButton = document.querySelector(`.${styles.scheduleButton}`);
    const today = new Date();
    const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
    this.pikaday = new DatePicker({
      lang: getLanguage().replace('_', '-'),
      trigger: scheduleButton,
      minDate: tomorrow,
      format: 'MM/dd/yyyy',
      defaultDate: tomorrow,
      container: document.querySelector(`.${styles.calendar}`),
      className: `${styles.calendar}`,
      reposition: false,
      onSelect: (formattedDate, date) => {
        dispatch({
          type: 'SET_SCHEDULED_DELIVERY_DATE',
          payload: date
        });
      }
    });
  };

  onClickSubmit = (event, dispatch, state, translateKeyFn) => {
    this.submitButton.isDisabled = true;
    this.submitButton.isLoading = true;
    this.scheduleButton.isDisabled = true;
    this.messageSection.setReadonlyAfterSubmitButton = true;
    const formLockState = formLockStateHandler();
    formLockState.lock();
    submitECard(dispatch, state, translateKeyFn).finally(() => {
      formLockState.unlock();
    });
    event.preventDefault();
    event.stopPropagation();
  };

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to ecard_message_view component `);
    }
  }

  initializeAllSubComponents() {
    this.pikaday = null;
    this.customizeECardHeader = new RecipientHeader(selectors.ecardHeaderSelector);
    this.detailsSummary = new DetailsSummary(selectors.ecardDetailsSelector);
    this.messageSection = new Textbox(selectors.ecardMessageSelector);
    this.scheduleButton = new Button(selectors.scheduleButtonSelector);
    this.submitButton = new Button(selectors.submitButtonSelector);
    this.privacySelectBox = new SelectBox(selectors.privacySelectBoxSelector);
    this.errorMsg = this.node.querySelector(selectors.errorMsgSelector);
  }

  renderAllSubComponents(dispatch, state, translateKeyFn) {
    const headerText = getHeaderText(state.selectedRecognitionType, translateKeyFn);
    setHeader(headerText);

    this.customizeECardHeader.render({
      recipients: state.recipients,
      selectedRecognitionType: state.selectedRecognitionType,
      bulkUploadCount: state.groupDetails ? state.groupDetails.count : ''
    }, dispatch, translateKeyFn);

    this.detailsSummary.render({
      selectedRecognitionType: state.selectedRecognitionType,
      selectedProgram: state.selectedProgramECard,
      selectedCorporateValue: state.selectedCorporateValueECard,
      eCardAmountList: state.eCardAmountList,
      selectedECardAmount: state.selectedECardAmount,
      eCardNotifyWhenViewedChecked: state.eCardNotifyWhenViewedChecked
    }, dispatch, translateKeyFn);

    this.editECardButton.addEventListener('click', ECardMessageView.goEditECard(dispatch));

    const playButton = document.querySelector(`.${styles.playBtn}`);
    if (playButton) {
      const videoDom = document.querySelector(`.${styles.eCardPreview} .eCardPreviewVideo`);
      if (videoDom) {
        playButton.addEventListener('click', () => {
          if (videoDom.paused === false) {
            videoDom.pause();
          } else {
            videoDom.play();
            playButton.classList.add(`${styles.hidden}`);
          }
        });
        videoDom.addEventListener('ended', () => {
          playButton.classList.remove(`${styles.hidden}`);
        });
      }
    }

    if (state.isPrivacyEnabledECard === false) {
      this.node.querySelector(selectors.privacySelectBoxWrapperSelector).classList.add(styles.hidden);
    } else {
      this.privacySelectBox.render({
        data: [
          { text: translateKeyFn('give-widget-privacy-everyone', 'Yes'), value: false },
          { text: translateKeyFn('give-widget-privacy-managers', 'No'), value: true }
        ],
        selectedValue: false,
        onChange: ECardMessageView.setPrivacySettings(dispatch),
        isHidden: !state.isPrivacyEnabledECard,
        id: 'ecard-message-privacy',
        ariaLabelledBy: selectors.privacySelectBoxWrapperId
      });
    }

    this.messageSection.render({
      maxLength: state.descriptionOfAchievement.max,
      required: state.descriptionOfAchievement.required,
      disabled: state.descriptionOfAchievement.disabled,
      title: `${translateKeyFn('eproduct-message-label', 'Write Message')}`,
      text: state.descriptionOfAchievementText,
      className: styles.ecardMessage,
      onChange: ECardMessageView.messageSectionOnChange(dispatch),
      id: 'ecard-message',
      ctaDiv: `#${styles.callToActionDiv}`
    }, translateKeyFn);

    this.scheduleButton.render({
      isSubmitButton: false,
      isPrimary: false,
      className: styles.scheduleButton,
      isDisabled: state.scheduleDateConfig && state.scheduleDateConfig.disabled,
      title: translateKeyFn('give-widget-ecard-schedule-for-later-label', 'Schedule for later'),
      id: 'ecard-message-schedule'
    });

    this.submitButton.render({
      isSubmitButton: true,
      isDisabled: ECardMessageView.isSubmitButtonDisabled(state),
      title: translateKeyFn('send', 'Send'),
      onClick: (evt) => this.onClickSubmit(evt, dispatch, state, translateKeyFn),
      className: styles.submitButton,
      id: 'ecard-message-submit'
    });
    this.scheduleButtonOnClick(dispatch);
  }

  render(dispatch, state, translateKeyFn) {
    let images = {};
    if (state.uploadedECard.imagePreview) {
      images.largeUrl = state.uploadedECard.imagePreview;
    } else {
      images = state.selectedECard;
    }
    const eCardMessageViewFragment = document.createRange()
      .createContextualFragment(eCardMessageViewTemplate(images, translateKeyFn));
    const eCardMessageViewTemp = this.node;
    this.node = getFirstElementChild(eCardMessageViewFragment);
    eCardMessageViewTemp.parentNode.replaceChild(this.node, eCardMessageViewTemp);
    this.editECardButton = this.node.querySelector(`.${styles.editECardBtn}`);
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, translateKeyFn);
    positionElement(`#${styles.callToActionDiv}`);
    this.node.classList.add('give-widget-current-view');
    document.querySelector('.js-close').focus();
  }

  update(dispatch, state, stateDiff, translateKeyFn, translateDateFormat) {
    if (this.errorMsg) {
      this.errorMsg.classList.add(styles.hidden);
    }
    if (ECardMessageView.errorMessageListChanged(stateDiff)) {
      this.errorMsg.classList.remove(styles.hidden);
      this.errorMsg.innerHTML = errorMsgTemplate(translateSubmitErrorMessages(state.messages[state.messages.length - 1].message, translateKeyFn));
      this.submitButton.isLoading = false;
    }
    this.submitButton.onClick = (evt) => this.onClickSubmit(evt, dispatch, state, translateKeyFn);
    if (stateDiff.descriptionOfAchievementText && stateDiff.descriptionOfAchievementText.type === 'updated') {
      this.submitButton.isDisabled = ECardMessageView.isSubmitButtonDisabled(state);
    }
    if (stateDiff.scheduledDeliveryDate && stateDiff.scheduledDeliveryDate.type === 'updated') {
      if (stateDiff.scheduledDeliveryDate.data !== '') {
        // Translations requested, will add them in after they come back.
        this.pikaday.destroy();
        this.pikaday = null;
        this.submitButton.title = `${translateKeyFn('send', 'Send')} ${translateDateFormat(
          stateDiff.scheduledDeliveryDate.data,
          state.dateFormat || 'mm/dd/yyyy'
        )}`;
        this.scheduleButton.title = translateKeyFn('give-widget-ecard-cancel-schedule-for-later-label', 'Cancel Schedule for later');
        this.scheduleButton.isDisabled = false;
        this.scheduleButton.onClick = () => {
          dispatch({
            type: 'SET_SCHEDULED_DELIVERY_DATE',
            payload: ''
          });
        };
      } else {
        this.scheduleButton.title = translateKeyFn('give-widget-ecard-schedule-for-later-label', 'Schedule for later');
        this.submitButton.title = translateKeyFn('send', 'Send');
        this.submitButton.isDisabled = ECardMessageView.isSubmitButtonDisabled(state);
        this.scheduleButtonOnClick(dispatch);
      }
    }
    positionElement(`#${styles.callToActionDiv}`);
  }

  show() {
    this.node.classList.remove(styles.hidden);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
