import styles from './modal_header.css';

export const getHeaderText = (selectedRecognitionType, translateKeyFn) => {
  const isNomination = selectedRecognitionType === 'Nomination';
  const customizeText = translateKeyFn('give-widget-customize', 'Customize');
  return `${customizeText} ${(isNomination ? translateKeyFn('nomination-label', 'Award') : translateKeyFn('ecard-label', 'eCard'))}`;
};

export default function modalHeaderTemplate(selectedRecognitionType, translateKeyFn) {
  const headerText = getHeaderText(selectedRecognitionType, translateKeyFn);

  return `<header class="${styles.heading}" tabindex="0" aria-live="polite">
    <h1 class="${styles.heading}">${headerText}</h1>
    </header>`;
}
