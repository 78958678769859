import approverPresenterSectionTemplate, {
  approverPresenterSectionTemplateRowTemplate,
  approverPresenterSectionLoadingTemplate,
  approverPlaceholderSelector,
  presenterPlaceholderSelector,
  approverPresenterSectionListSelector,
  approverPresenterSectionHeadingSelector,
  errorMessagePlaceholderSelector
} from './approver-presenter-section-template';
import styles from './approver-presenter-section.css';
import emptyElement, { getFirstElementChild, preferOrFirstLastName } from '../../utils/utils';
import SelectApproverPresenter from '../select-approver-presenter/select-approver-presenter';

let approverPresenterSectionId = 0;

class ApproverPresenterSection {
  static generateId() {
    approverPresenterSectionId += 1;
    return `approverPresenterSection${approverPresenterSectionId - 1}`;
  }

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.approverPresenterSectionNode = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to ApproverPresenterSection component`);
    }
  }

  defaultProps = {
    id: ApproverPresenterSection.generateId(),
    data: {
      recipients: [],
      approvers: [],
      presenters: []
    },
    isHidden: false,
    isLoading: false,
    approverOnChange: () => {},
    presenterOnChange: () => {},
    heading: '',
    translateKeyFn: () => {}
  };

  set id(id) {
    this.props.id = id;
    this.approverPresenterSectionNode.setAttribute('id', id);
  }

  get id() {
    return this.props.id;
  }

  set data(data) {
    const focusedElementId = document.activeElement.id;
    this.props.data = data;
    emptyElement(this.approverPresenterListNode);
    const { recipients = [], approvers = [], presenters = [] } = data;
    const showRecipientFullName = recipients.length > 1;
    recipients.forEach(({
      systemUserId = '', preferName = '', firstName = '', lastName = ''
    }, index) => {
      const frag = document
        .createRange()
        .createContextualFragment(approverPresenterSectionTemplateRowTemplate(
          showRecipientFullName &&
            preferOrFirstLastName({ preferName, firstName, lastName }),
          index,
          this.translateKeyFn
        ));
      const approverPlaceholderNode = frag.querySelector(approverPlaceholderSelector);
      const presenterPlaceholderNode = frag.querySelector(presenterPlaceholderSelector);
      const errorMessageNode = frag.querySelector(errorMessagePlaceholderSelector);
      this.approverPresenterListNode.appendChild(frag);
      if (approvers[index]) {
        const approverPlaceholderId = approverPlaceholderNode.getAttribute('id');
        const selectApproverComp = new SelectApproverPresenter(`#${approverPlaceholderId}`);
        const id = `nomination-approver-${systemUserId}`;

        selectApproverComp.render({
          ...approvers[index],
          heading: this.translateKeyFn('approver', 'Approver'),
          className: styles.loadingCol1,
          translateKeyFn: this.translateKeyFn,
          onChange: this.approverOnChange.bind(null, index),
          approverPresenter: 'approver',
          id
        });

        if (focusedElementId.indexOf(id) === 0) {
          selectApproverComp.focus();
        }
      }
      if (presenters[index]) {
        const presenterPlaceholderId = presenterPlaceholderNode.getAttribute('id');
        const selectPresenterComp = new SelectApproverPresenter(`#${presenterPlaceholderId}`);
        const id = `nomination-presenter-${systemUserId}`;

        selectPresenterComp.render({
          ...presenters[index],
          heading: this.translateKeyFn('presenter', 'Presenter'),
          className: styles.loadingCol1,
          translateKeyFn: this.translateKeyFn,
          onChange: this.presenterOnChange.bind(null, index),
          approverPresenter: 'presenter',
          id
        });

        if (focusedElementId.indexOf(id) === 0) {
          selectPresenterComp.focus();
        }
      }
      if ((approvers[index] && approvers[index].eligible === false) || (presenters[index] && presenters[index].eligible === false)) {
        errorMessageNode.classList.remove(styles.hidden);
      } else {
        errorMessageNode.classList.add(styles.hidden);
      }
    });
  }

  set isLoading(isLoading) {
    this.props.isLoading = isLoading;
    if (isLoading) {
      if (this.approverPresenterHeadingNode) {
        this.approverPresenterHeadingNode.classList.add(styles.hidden);
      }
      emptyElement(this.approverPresenterListNode);
      this.approverPresenterSectionNode.setAttribute('aria-busy', 'true');
      const frag = document.createRange().createContextualFragment(approverPresenterSectionLoadingTemplate());
      const temp = getFirstElementChild(frag);
      this.approverPresenterListNode.parentNode.replaceChild(temp, this.approverPresenterListNode);
      this.approverPresenterListNode.classList.add(styles.loading, styles.loadingShimmer);
      this.approverPresenterListNode = temp;
    } else {
      if (this.approverPresenterHeadingNode) {
        this.approverPresenterHeadingNode.classList.remove(styles.hidden);
      }
      if (this.approverPresenterListNode) {
        this.approverPresenterListNode.classList.remove(styles.loading, styles.loadingShimmer);
      }
      this.approverPresenterSectionNode.setAttribute('aria-busy', 'false');
    }
  }

  set isHidden(isHidden) {
    this.props.isHidden = isHidden;
    if (this.props.isHidden === true) {
      this.approverPresenterSectionNode.classList.add(styles.hidden);
    } else {
      this.approverPresenterSectionNode.classList.remove(styles.hidden);
    }
  }

  set heading(heading) {
    this.props.heading = heading;
    emptyElement(this.approverPresenterHeadingNode);
    this.approverPresenterHeadingNode.appendChild(document.createTextNode(heading));
  }

  get heading() {
    return this.props.heading;
  }

  set translateKeyFn(translateKeyFn) {
    this.props.translateKeyFn = translateKeyFn;
  }

  get translateKeyFn() {
    return this.props.translateKeyFn;
  }

  set approverOnChange(onChange) {
    this.props.approverOnChange = onChange;
  }

  set presenterOnChange(onChange) {
    this.props.presenterOnChange = onChange;
  }

  get approverOnChange() {
    return this.props.approverOnChange;
  }

  get presenterOnChange() {
    return this.props.presenterOnChange;
  }

  render = (props) => {
    const frag = document.createRange().createContextualFragment(approverPresenterSectionTemplate());
    const temp = getFirstElementChild(frag);
    this.approverPresenterSectionNode.parentNode.replaceChild(frag, this.approverPresenterSectionNode);
    this.approverPresenterSectionNode = temp;
    this.approverPresenterListNode = this.approverPresenterSectionNode.querySelector(approverPresenterSectionListSelector);
    this.approverPresenterHeadingNode = this.approverPresenterSectionNode.querySelector(approverPresenterSectionHeadingSelector);

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.data = this.props.data;
    this.isLoading = this.props.isLoading;
    this.isHidden = this.props.isHidden;
    this.approverOnChange = this.props.approverOnChange;
    this.presenterOnChange = this.props.presenterOnChange;
    this.heading = this.props.heading;
    this.translateKeyFn = this.props.translateKeyFn;
  };
}

export default ApproverPresenterSection;
