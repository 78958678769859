export const MODAL_CLOSED = 'modal closed';
export const LOADING_VIEW = 'loading view';
export const USER_SEARCH_VIEW = 'user search view';
export const BULK_UPLOAD_VIEW = 'bulk upload view';
export const PROGRAM_DETAILS_VIEW = 'program details view';
export const NOMINATION_WIZARD_VIEW = 'nomination wizard view';
export const DESCRIPTION_VIEW = 'description view';
export const SUBMISSION_VIEW = 'submission view';
export const ECARD_DETAILS_VIEW = 'ecard details view';
export const ECARD_SELECTION_VIEW = 'ecard selection view';
export const ECARD_MESSAGE_VIEW = 'ecard message view';
