// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import Alert from '@octanner/prism-core/Alert';
import Divider from '@octanner/prism-core/Divider';
import TextField from '@octanner/prism-core/TextField';
import FormControl from '@octanner/prism-core/FormControl';
import FormHelperText from '@octanner/prism-core/FormHelperText';
import PrismButton from '@octanner/prism-core/Button';
import CircularProgress from '@octanner/prism-core/CircularProgress';
import ListSubheader from '@octanner/prism-core/ListSubheader';
import MenuItem from '@octanner/prism-core/MenuItem';
import Select from '@octanner/prism-core/Select';
import userSearchViewTemplate, {
  proxyLabelSelector,
  searchListSelector,
  sendEcardSelector,
  sendNominationSelector,
  userSearchSelector,
  selectedUsersPillBoxSelector,
  recognitionTypesErrorMessageSelector,
  searchListHeadingSelector,
  currentlySelectedPeopleForRecognitionSelector,
  proxyContainerSelector,
  proxySelectBoxSelector,
  proxySelectSelector,
  searchResultsTitle,
  recommendedTitle,
  suggestedCoWorkersSelector,
  bulkUploadSelector,
  uploadAFileSelector,
  descriptionOfAchievementSelector
} from './user_search_view_template';
import emptyElement, {
  getFirstElementChild,
  isObject,
  uuidGenerator,
  preferOrFirstLastName,
  getParamValue,
  removeAllHtml,
  positionElement,
  setHeader
} from '../../utils/utils';
import ReactProviders from '../../utils/react_providers';
import FetchApi from '../../state/services/fetch_api';
import PillBox from '../../components/pillbox/pillbox';
import List from '../../components/list/list';
import Button from '../../components/button/button';
import AutoComplete from '../../components/autocomplete/autocomplete';
import SelectBox from '../../components/select-box/select-box';
import RecipientSearch from '../recognize_view/recipient-search';
import styles from './user_search_view.css';
import { transformUserSearchResults } from '../../state/reducers/root_reducer';
import {
  transformRecipients,
  queryUsers,
  getApprovers,
  getProgramsAndAwardLevels,
  getECardConfig,
  getWizard
} from '../../state/services/api';
import * as views from '../views-list';
import getConfig from '../../env';

class UserSearchView {
  userSearchList;
  eCardButton;
  autoComplete;
  pillBox;
  proxySelectBox;
  followedEmployeesLoading = false;
  searchText = '';

  getFollowingUsers(dispatch) {
    this.followedEmployeesLoading = true;
    FetchApi(`${getConfig('API_BASE_URL')}/api/users/current/following?idsOnly=false&pageSize=50&page=0`)
      .then(
        (res) => {
          this.followedEmployeesLoading = false;
          dispatch({
            type: 'SET_FOLLOWED_EMPLOYEES',
            payload: { data: res, status: { code: 200 } }
          });
        },
        (err) =>
          dispatch({
            type: 'SET_FOLLOWED_EMPLOYEES',
            payload: { data: [], status: { code: err.status } }
          })
      );
  }

  getRecommendedUsers(dispatch, loggedInUserId) {
    this.followedEmployeesLoading = true;
    FetchApi(`${getConfig('API_BASE_URL')}/api/recommendedGive/recommendations/${loggedInUserId}?pageSize=10&pageNum=0&idsOnly=false`)
      .then(
        (res) => {
          this.followedEmployeesLoading = false;
          // unSelectableItems setter needs to happen after component loading is set to false which requires a timeout
          setTimeout(() => {
            this.userSearchList.unSelectableItems = this.currentlySelectedEmployees.slice(0);
          });
          const formatUserList = { ...res, collection: res.recommendedUsers };
          dispatch({
            type: 'SET_FOLLOWED_EMPLOYEES',
            payload: { data: formatUserList, status: { code: 200 } }
          });
        },
        (err) => {
          this.followedEmployeesLoading = false;
          dispatch({
            type: 'SET_FOLLOWED_EMPLOYEES',
            payload: { data: [], status: { code: err.status } }
          });
        }
      );
  }

  static getRecognitionTypes = (() => {
    let id = 0;
    return (dispatch, recipientIds, recognizerId, isProxyUser) => {
      const fetchPromise = FetchApi(`${getConfig('API_BASE_URL')}/api/give/types`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recipientIds,
          recognizerId: isProxyUser ? null : recognizerId.toString(),
          proxyRecognizerId: isProxyUser ? recognizerId.toString() : null,
          bulk: ''
        })
      });
      id += 1;
      fetchPromise.id = id;
      fetchPromise
        // We are doing this to handle asynchronous nature of fetch
        .then((res) => (fetchPromise.id >= id ? res : 'reject'))
        .then(
          (res) => {
            if (typeof res !== 'string' && res !== 'reject') {
              dispatch({
                type: 'SET_RECOGNITION_TYPES',
                payload: { data: res, status: { code: 200 } }
              });
            }
          },
          (err) =>
            Promise.resolve(err.json()).then(
              (errAsJSON) => {
                dispatch({
                  type: 'SET_RECOGNITION_TYPES',
                  payload: { data: errAsJSON, status: { code: err.status } }
                });
              },
              () => {
                dispatch({
                  type: 'SET_RECOGNITION_TYPES',
                  payload: { data: {}, status: { code: err.status } }
                });
              }
            )
        );
      return fetchPromise;
    };
  })();

  static getProxyPermissions = (dispatch) =>
    FetchApi(`${getConfig('API_BASE_URL')}/api/give/proxyUsers`)
      .then(
        (res) =>
          dispatch({
            type: 'SET_PROXY_OPTIONS',
            payload: { data: { proxyOptions: res.proxyUsers }, status: { code: 200 } }
          }),
        (err) =>
          dispatch({
            type: 'SET_PROXY_OPTIONS',
            payload: { data: { proxyOptions: [] }, status: { code: err.status } }
          })
      );

  // Should Render/Update Function
  static shouldUpdateUserSearchListDataToFollowedEmployees = ({ followedEmployees: { type = '', data = [] } = {} } = {}) =>
    type === 'updated' && Array.isArray(data);

  static shouldUpdateRecognitionTypeButtons = ({ recognitionTypes: { type = '', data = {} } = {} } = {}) => type === 'updated' && isObject(data);

  static shouldUpdatePossibleProxyOptionsSelectBox = ({ userInfo = { type: '' }, proxyOptions = { type: '' } } = {}) =>
    (userInfo.type === 'updated' && isObject(userInfo.data)) || (proxyOptions.type === 'updated' && Array.isArray(proxyOptions.data));

  static shouldUpdateRecognizerId = ({ recognizerId: { type = '', data = '' } = {} } = {}) => (
    type === 'updated' && `${data}`.length > 0
  );

  static shouldCallGetApprovers = ({
    selectedRecognitionType = { type: '', data: '' },
    selectedProgramNomination = { type: '', data: {} },
    selectedAwardLevel = { type: '', data: {} }
  } = {}) =>
    ((selectedRecognitionType.type === 'updated' && selectedRecognitionType.data === 'Nomination') ||
      (selectedProgramNomination.type === 'updated' && Object.keys(selectedProgramNomination.data).length > 0) ||
      (selectedAwardLevel.type === 'updated' && Object.keys(selectedAwardLevel.data).length > 0));

  static shouldCallGetEcardConfig = ({
    selectedRecognitionType = { type: '', data: '' },
    selectedProgramECard = { type: '', data: {} }
  } = {}) =>
    ((selectedRecognitionType.type === 'updated' && selectedRecognitionType.data !== 'Nomination') ||
      (selectedProgramECard.type === 'updated' && Object.keys(selectedProgramECard.data).length > 0));

  // Reducers, selectors and transforms
  static selectHasECard = ({ recognitionTypes: { hasECard = false } = {} } = {}) => hasECard;

  static selectHasNomination = ({ recognitionTypes: { hasNomination = false } = {} } = {}) => hasNomination;

  static selectHasError = ({ recognitionTypes: { error = false } = {} } = {}) => error;

  static selectErrorCode = ({ recognitionTypes: { code } = {} } = {}) => code;

  static selectErrorMessage = (message) => message || '';

  static transformDataFromListToPillBox = ({
    preferName = '', firstName = '', lastName = '', systemUserId = '', isRemovable = true
  } = {}) => ({
    text: preferOrFirstLastName({ preferName, firstName, lastName }),
    isRemovable,
    id: systemUserId
  });

  static composePossibleProxyOptions = ({ userInfo: { systemUserId = '' } = {}, proxyOptions = [] } = {}) => {
    const temp1 = `${systemUserId}`.length > 0 ? [{ text: 'myself', value: systemUserId }] : [];
    const temp2 = proxyOptions.map((proxyOptionItem) => ({
      text: proxyOptionItem.name,
      value: proxyOptionItem.systemUserId
    }));
    return temp1.concat(temp2);
  };

  static fetchRecognitionLevels(dispatch, recipients, recognizerId) {
    getProgramsAndAwardLevels(dispatch, recipients, recognizerId)
      .then((programList) => {
        dispatch({
          type: 'SET_PROGRAM_LIST',
          payload: programList
        });

        if (programList.length === 1 && !programList.every((program) => program.wizardRequired)) {
          const defaultLevel = UserSearchView.findDefaultRecognitionLevel(programList) || UserSearchView.pickDefaultRecognitionLevel(programList);
          const selectedProgram = UserSearchView.findParentProgram(defaultLevel, programList);

          UserSearchView.selectRecognitionLevel(defaultLevel, selectedProgram, dispatch);
        }
      });
  }

  constructor(containerSelector, dispatch, loggedInUserId, useRecommendedGive) {
    if (document.querySelector(containerSelector) !== null) {
      this.node = document.querySelector(containerSelector);
      this.searchText = getParamValue('widget-appreciation-to') || '';
      if (useRecommendedGive) {
        this.getRecommendedUsers(dispatch, loggedInUserId);
      } else {
        this.getFollowingUsers(dispatch);
      }
      UserSearchView.getProxyPermissions(dispatch);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to user_search_view component`);
    }
  }

  onSelectUser = (dispatch, state, translateKey, selectedItemData) => {
    this.currentlySelectedEmployees.push(selectedItemData);
    const recipients = this.currentlySelectedEmployees.map((currentlySelectedEmployee) => currentlySelectedEmployee.systemUserId);
    this.nominationButton.isDisabled = true;
    this.nominationButton.isLoading = true;
    this.eCardButton.isDisabled = true;
    this.eCardButton.isLoading = true;
    this.nomineesUpdated = true;
    const isProxyUser = !(state.userInfo && state.userInfo.systemUserId === state.recognizerId);
    UserSearchView.getRecognitionTypes(dispatch, recipients.join(','), state.recognizerId, isProxyUser);
    emptyElement(this.node.querySelector(currentlySelectedPeopleForRecognitionSelector));
    this.node
      .querySelector(currentlySelectedPeopleForRecognitionSelector)
      .appendChild(document.createTextNode(`Added ${selectedItemData.fullName} to the queue of people
        to send recognition to. Press backspace to remove ${selectedItemData.fullName} from list of people.`));

    this.userSearchList.data = this.followedEmployees.slice(0);
    this.userSearchList.noDataMessage = '';

    emptyElement(this.searchListHeading);
    if (this.followedEmployees.length > 0) {
      this.searchListHeading.appendChild(document.createTextNode(recommendedTitle(translateKey)));
    }

    if (this.isRequiredToRestrictSingleRecipient && this.currentlySelectedEmployees.length > 0) {
      this.userSearchList.unSelectableItems = this.followedEmployees.slice(0);
      this.autoComplete.isDisabled = true;
    } else {
      this.userSearchList.unSelectableItems = this.currentlySelectedEmployees.slice(0);
      this.autoComplete.focus();
    }
    this.autoComplete.clear();
    this.pillBox.data = this.pillBox.data.slice(0).concat(UserSearchView.transformDataFromListToPillBox(selectedItemData));
  };

  onRemovePillBoxItem = (dispatch, state, removedItem) => {
    const index = this.currentlySelectedEmployees.reduce((acc, emp, idx) => {
      if (acc === -1 && emp.systemUserId === removedItem.systemUserId) return idx;
      return acc;
    }, -1);
    let removedSelectedEmployee;
    if (index !== -1) {
      removedSelectedEmployee = this.currentlySelectedEmployees.splice(index, 1);
    }
    emptyElement(this.node.querySelector(currentlySelectedPeopleForRecognitionSelector));
    this.node
      .querySelector(currentlySelectedPeopleForRecognitionSelector)
      .appendChild(document.createTextNode(`Removed ${removedSelectedEmployee[0].fullName} from the list of people to send recognition`));
    this.userSearchList.unSelectableItems = this.currentlySelectedEmployees.slice(0);
    const recipients = this.currentlySelectedEmployees.map((currentlySelectedEmployee) => currentlySelectedEmployee.systemUserId);
    this.nominationButton.isDisabled = true;
    this.eCardButton.isDisabled = true;
    this.nomineesUpdated = true;
    if (recipients.length > 0) {
      this.nominationButton.isLoading = true;
      this.eCardButton.isLoading = true;
      const isProxyUser = !(state.userInfo && state.userInfo.systemUserId === state.recognizerId);
      UserSearchView.getRecognitionTypes(dispatch, recipients.join(','), state.recognizerId, isProxyUser);
    }

    if (this.isRequiredToRestrictSingleRecipient && this.userSearchList.unSelectableItems.length === 0) {
      this.autoComplete.isDisabled = false;
      this.autoComplete.focus();
    }
  };

  onChangeRecipients = (dispatch, state, translateKey, recipients) => {
    const filteredRecipients = recipients.filter((recipient) => recipient.systemUserId !== this.currentlySelectedRecognizerId &&
      state.userInfo.systemUserId !== recipient.systemUserId);
    this.currentlySelectedEmployees = [...filteredRecipients];
    this.nomineesUpdated = true;
    if (recipients.length !== this.currentlySelectedEmployees.length) {
      dispatch({ type: 'ADD_APP_MESSAGE', payload: translateKey('no-self-nomination', 'Sorry. You cannot send recognition to yourself') });
    }

    if (this.currentlySelectedEmployees.length > 0) {
      UserSearchView.fetchRecognitionLevels(dispatch, this.currentlySelectedEmployees, state.recognizerId);
    }

    dispatch({
      type: 'SET_RECOGNIZER_ID_RECIPIENTS_ID_SELECTED_RECOGNITION_TYPE_TRANSACTION_ID',
      payload: {
        recipients: { recipients: this.currentlySelectedEmployees },
        recognizerId: { data: { value: this.currentlySelectedRecognizerId } },
        selectedRecognitionType: state.selectedRecognitionType,
        viewNumber: views.USER_SEARCH_VIEW,
        wizardUsed: false,
        nomineesUpdated: this.nomineesUpdated
      }
    });
  }

  static awardToV1 = (awardV2) => ({ ...awardV2, value: awardV2.awardLevelId, text: awardV2.awardLevelName })

  static awardToV2 = (awardV1) => ({ ...awardV1, awardLevelId: awardV1.value, awardLevelName: awardV1.text })

  static programToV1 = (programV2) => ({ ...programV2, value: programV2.programId, text: programV2.programName })

  renderRecipients = (state, t, dispatch) => {
    ReactDOM.render(
      (
        <ReactProviders translateKey={t}>
          <RecipientSearch
            dispatch={dispatch}
            state={state}
            selectedRecipients={this.currentlySelectedEmployees}
            onRecipientsChanged={this.onChangeRecipients}
          />
        </ReactProviders>
      ),
      document.querySelector('#recipient-search')
    );
  }

  static extractAwardLevels = (program) => [].concat(program.EcardAwardLevels).concat(program.NominnationAwardLevels);

  static pickLowestMonetaryLevel = (awardLevels) => {
    const monetaryLevels = awardLevels.filter((level) => level.pointValue);
    return monetaryLevels.length ? monetaryLevels[0] : awardLevels[0];
  }

  static findDefaultRecognitionLevel = (programList) => {
    const awardLevels = [].concat(...programList.map(UserSearchView.extractAwardLevels));
    return awardLevels.filter((award) => award.default)[0];
  }

  static pickDefaultRecognitionLevel = (programList) => {
    const awardLevels = [].concat(...programList.map(UserSearchView.extractAwardLevels));
    return UserSearchView.pickLowestMonetaryLevel(awardLevels);
  }

  static selectRecognitionLevel = (recognitionLevel, selectedProgram, dispatch) => {
    const recognitionType = selectedProgram.NominnationAwardLevels.indexOf(recognitionLevel) > -1 ? 'Nomination' : 'ECard';

    if (recognitionType === 'Nomination') {
      dispatch({
        type: 'SET_STREAMLINED_FLOW_RECOGNITION',
        payload: {
          selectedProgramNomination: UserSearchView.programToV1(selectedProgram),
          wizard: [],
          recommendedAwardLevel: [],
          wizardFlowFinished: false,
          eCardRecommended: false,
          selectedAwardLevel: UserSearchView.awardToV1(recognitionLevel),
          selectedRecognitionType: recognitionType,
          selectedProgramECard: {},
          selectedECardAmount: {}
        }
      });
    } else {
      dispatch({
        type: 'SET_STREAMLINED_FLOW_RECOGNITION',
        payload: {
          selectedProgramNomination: {},
          wizard: [],
          recommendedAwardLevel: [],
          wizardFlowFinished: false,
          eCardRecommended: false,
          selectedAwardLevel: {},
          selectedRecognitionType: recognitionType,
          selectedProgramECard: UserSearchView.programToV1(selectedProgram),
          selectedECardAmount: UserSearchView.awardToV1(recognitionLevel)
        }
      });
    }
  };

  static findParentProgram = (recognitionLevel, programList) =>
    programList.filter((program) =>
      recognitionLevel.awardLevelId === program.programId ||
        UserSearchView.extractAwardLevels(program).some((level) => level.awardLevelId === recognitionLevel.awardLevelId))[0];

  renderSuggestions = (state, t, dispatch) => {
    const {
      followedEmployees
    } = state;
    const unselectedRecommends = followedEmployees
      .filter((user) => !this.currentlySelectedEmployees.filter((u) => u.systemUserId === user.systemUserId).length)
      .filter((user, i) => i < 3);

    ReactDOM.render(
      (
        <ReactProviders translateKey={t}>
          <span className={styles.recipientsList}>
            {t('give-widget-suggested-coworkers', 'Suggested coworkers')}:{' '}
            {this.followedEmployeesLoading
              ? <CircularProgress size={14} className={styles.recipientsLoader} />
              : unselectedRecommends.map((user, i) => (
                <React.Fragment key={user.systemUserId}>
                  <PrismButton
                    key={user.systemUserId}
                    className={styles.linkButton}
                    type="button"
                    variant="text"
                    underline="none"
                    onClick={() => {
                      const recipients = [...this.currentlySelectedEmployees, user];
                      this.onChangeRecipients(dispatch, state, t, recipients);
                    }}
                  >
                    {user.fullName}
                  </PrismButton>{i < unselectedRecommends.length - 1 && ', '}
                </React.Fragment>
              ))}
          </span>
        </ReactProviders>
      ),
      document.querySelector(suggestedCoWorkersSelector)
    );
  }

  static renderAwardLevelSelector(state, t, dispatch) {
    const {
      programList,
      selectedAwardLevel,
      selectedECardAmount,
      selectedProgramECard,
      selectedProgramNomination
    } = state;

    if (!programList?.length) return;

    const selectWizard = (program) => {
      const selectedProgram = UserSearchView.programToV1(program);
      dispatch({
        type: 'SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_NOMINATION',
        payload: {
          programList,
          selectedProgramNomination: selectedProgram
        }
      });
      dispatch({
        type: 'SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_ECARD',
        payload: {
          programList,
          selectedProgramECard: selectedProgram
        }
      });

      getWizard({ ...state, selectedProgramNomination: selectedProgram }, dispatch).then((res) => {
        const { input: { question = {}, answerId: { options: answers = [] } } } = res.wizard;
        dispatch({
          type: 'SET_WIZARD_AND_VIEW_NUMBER',
          payload: {
            wizard: [{
              question,
              answers,
              selectedAnswer: ''
            }],
            viewNumber: views.NOMINATION_WIZARD_VIEW,
            nomineesUpdated: false
          }
        });
      });
    };

    const selectValue = (() => {
      if (selectedECardAmount.awardLevelName === 'None' && selectedECardAmount.awardLevelId === '') {
        const eCardEmptyProgram = programList.filter((program) => program.programId === selectedProgramECard.programId)[0];
        return eCardEmptyProgram.EcardAwardLevels[0];
      }

      // TODO: finding selected value is only needed when converting between v1 levels and v2 levels
      const levels = [].concat(...programList.map(UserSearchView.extractAwardLevels));
      const selectedIndex = levels.map((level) => level.awardLevelId).indexOf(selectedAwardLevel.value || selectedECardAmount.value);
      return selectedIndex > -1 ? levels[selectedIndex] : null;
    })();

    const getSelectableLevels = (program) => {
      if (program.wizardEnabled && program.wizardRequired) {
        const eCards = program.EcardAwardLevels;
        return program.programId === selectedProgramNomination?.programId ? eCards.concat(selectValue) : eCards;
      }

      return UserSearchView.extractAwardLevels(program);
    };

    const reactEle = (
      <div className={styles.recognitionLevels}>
        <Select
          fullWidth
          label={t('give-widget-recognition-level', 'Recognition level')}
          value={selectValue?.awardLevelId || ''}
        >
          {programList.map((program, i) => [
            i > 0 && <Divider key={`divider${program.programId}`} />,
            programList.length > 1 && <ListSubheader key={`subheader${program.programId}`}>{program.programName}</ListSubheader>,
            ...getSelectableLevels(program)?.map((award) => (
              <MenuItem
                key={award.awardLevelId || program.programId}
                value={award.awardLevelId}
                onClick={() => {
                  UserSearchView.selectRecognitionLevel(award, program, dispatch);
                }}
              >
                {program.EcardAwardLevels.indexOf(award) > -1 && award.pointValue
                  ? t('give-widget-award-points', '{awardName} ({count} points)')
                    .replace('{awardName}', award.awardLevelName || t('ecard-label', 'eCard'))
                    .replace('{count}', award.pointValue)
                  : award.awardLevelName || t('ecard-label', 'eCard')
                }
              </MenuItem>
            )),
            program.wizardEnabled && (
              <MenuItem
                key={program.programId}
                className={styles.wizardOption}
                onClick={() => { selectWizard(program); }}
              >
                {t('give-widget-get-recommendation', 'Get a recommendation')}
              </MenuItem>
            )
          ])}
        </Select>
      </div>
    );
    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector('#recognition-levels'));
  }

  renderCompanyValueSelector = (state, t, dispatch) => {
    const {
      corporateValueList,
      selectedAwardLevel,
      selectedCorporateValueECard,
      selectedCorporateValueNomination,
      selectedProgramECard,
      selectedProgramNomination,
      selectedRecognitionType
    } = state;
    const isLoading =
      (selectedRecognitionType === 'ECard' && !selectedProgramNomination?.value && !selectedProgramECard?.value) ||
      (selectedRecognitionType === 'Nomination' && !selectedAwardLevel?.value) ||
      !corporateValueList?.options?.length ||
      corporateValueList.disabled;

    const reactEle = isLoading ? null : (
      <FormControl className={styles.companyValues} fullWidth>
        <Select
          fullWidth
          label={t('corporate-value-label', 'Company value')}
          aria-describedby="my-helper-text"
          name="corporateValueList"
          required={corporateValueList?.required}
          onChange={({ target }) => {
            dispatch({
              type: 'SET_SELECTED_CORPORATE_VALUE_ECARD_AND_SELECTED_CORPORATE_VALUE_NOMINATION',
              payload: {
                selectedCorporateValueECard: target,
                selectedCorporateValueNomination: target
              }
            });
          }}
          value={selectedCorporateValueNomination?.value || selectedCorporateValueECard?.value || ''}
        >
          {corporateValueList?.options?.map((companyValue) => (
            <MenuItem key={companyValue.value} value={companyValue.value}>{companyValue.text}</MenuItem>
          ))}
        </Select>
        {!corporateValueList?.required && (
          <FormHelperText id="my-helper-text" className={styles.helperText}>
            {t('optional', 'Optional')}
          </FormHelperText>
        )}
      </FormControl>
    );
    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector('#company-values'));
  }

  static renderRecipientSearchError = (state, t, dispatch) => {
    const {
      messages
    } = state;

    const reactEle = messages.length === 0 || messages[messages.length - 1].message === '' ? null : (
      <Alert
        color="error"
        onClose={() => {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: '' });
        }}
      >
        {messages[messages.length - 1].message}
      </Alert>
    );

    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector('#recipient-search-error'));
  };

  renderSpecialInstructions = ({
    descriptionOfAchievement,
    selectedProgramECard,
    selectedProgramNomination
  }, translateKey) => {
    if (Object.keys(selectedProgramECard).length === 0 && Object.keys(selectedProgramNomination).length === 0) return;

    const displayAlert = ((!!selectedProgramECard?.value || !!selectedProgramNomination?.value) &&
      descriptionOfAchievement?.disabled === false);

    const bodyTranslated = Object.keys(selectedProgramECard).length > 0 ?
      translateKey(`${selectedProgramECard.value}/eproducts-search-special-instructions-body`) :
      translateKey(`${selectedProgramNomination.value}/nomination-special-instructions-body`);

    const stripHtmlNotLinks = (body) => {
      const links = Array.from(
        body.matchAll(/<a href=["'](.+?)["']>(.*?)<\/a>/gi),
        ([, href, childrenWithHtml]) => {
          const children = childrenWithHtml.replace(/<span.+?>|<\/span>/gi, '');
          return { href, children };
        }
      );

      const strippedBody = body
        .replace(/<p\s+.*?>(.*?)<\/p>/gi, '\n$1\n')
        .replace(/<br>|<br \/>/gi, '\n')
        .replace(/&nbsp;/gi, ' ')
        .split(/<a href=.+?>.*?<\/a>/gi)
        .map((split) => split.replace(/<.+?>|<\/.+?>/gi, ''));
      return [strippedBody, links];
    };

    const [bodyFragments, links] = stripHtmlNotLinks(bodyTranslated);

    const reactEle = displayAlert && bodyTranslated !== '' ? (
      <Alert
        classes={{
          root: `${styles.specialInstructions}`
        }}
        color="info"
      >
        <div>
          {bodyFragments.map((split, i) => (
            <React.Fragment key={split}>
              {split}
              {i < links.length && (
                <a href={links[i].href} target="_blank" rel="noreferrer noopener">
                  {links[i].children}
                </a>
              )}
            </React.Fragment>
          ))}
        </div>
      </Alert>
    ) : null;

    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector('#special-instructions'));
  };

  renderDescriptionOfAchievement = ({
    descriptionOfAchievement,
    descriptionOfAchievementText
  }, translateKey, dispatch) => {
    const doaContainer = document.querySelector(descriptionOfAchievementSelector);
    if (descriptionOfAchievement === '' ||
      (descriptionOfAchievement.disabled && !doaContainer.hasChildNodes())) return;

    if (descriptionOfAchievement.disabled && doaContainer.hasChildNodes()) {
      doaContainer.classList.add(styles.hidden);
      doaContainer.classList.remove(styles.descriptionOfAchievement);
    } else {
      doaContainer.classList.add(styles.descriptionOfAchievement);
      doaContainer.classList.remove(styles.hidden);
      const reactEle = (
        <TextField
          displayCount
          fullWidth
          helperText={`${translateKey('give-widget-description-of-achievement-helper', 'Be specific about why and what you are recognizing')}`}
          label={`${translateKey('give-widget-add-description-of-achievement-label', 'Add description of achievement?')}`}
          maxLength={descriptionOfAchievement.max}
          multiline
          onChange={(e) => {
            dispatch({
              type: 'SET_DESCRIPTION_TEXT',
              payload: removeAllHtml(e.target.value)
            });
          }}
          required={descriptionOfAchievement.required}
          rows={5}
          value={descriptionOfAchievementText}
        />
      );

      ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, doaContainer);
    }
  }

  renderNextButton = ({
    corporateValueList,
    descriptionOfAchievement,
    descriptionOfAchievementText,
    selectedAwardLevel,
    selectedCorporateValueECard,
    selectedCorporateValueNomination,
    selectedProgramECard,
    selectedRecognitionType,
    recipients
  }, translateKey, dispatch) => {
    const isDisabled = !recipients.length || descriptionOfAchievement === '' ||
      (descriptionOfAchievement !== '' && descriptionOfAchievement.required && descriptionOfAchievementText === '') ||
      (selectedRecognitionType === 'ECard' && !selectedProgramECard.value) ||
      (selectedRecognitionType === 'Nomination' && !selectedAwardLevel.value) || corporateValueList === '' ||
      (corporateValueList.required && selectedRecognitionType === 'ECard' && !selectedCorporateValueECard.value) ||
      (corporateValueList.required && selectedRecognitionType === 'Nomination' && !selectedCorporateValueNomination.value);

    const reactEle = (
      <PrismButton
        classes={{
          root: 'oct-client-background oct-client-border'
        }}
        disabled={isDisabled}
        type="button"
        onClick={() => {
          dispatch({
            type: 'SET_VIEW_NUMBER',
            payload: selectedRecognitionType === 'Nomination' ? views.PROGRAM_DETAILS_VIEW : views.ECARD_DETAILS_VIEW
          });
        }}
      >
        {translateKey('next', 'Next')}
      </PrismButton>
    );
    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector('#next-button'));
  };

  initializeAllSubComponents() {
    this.userSearchList = new List(searchListSelector);
    this.nominationButton = new Button(sendNominationSelector);
    this.eCardButton = new Button(sendEcardSelector);
    this.autoComplete = new AutoComplete(userSearchSelector);
    this.pillBox = new PillBox(selectedUsersPillBoxSelector);
    this.proxySelectBox = new SelectBox(proxySelectBoxSelector);
    this.bulkUploadButton = new Button(bulkUploadSelector);
  }

  renderAllSubComponents(dispatch, state, translateKey) {
    const possibleProxyOptions = UserSearchView.composePossibleProxyOptions(state);
    const selectedValue = state.recognizerId;
    this.searchListHeading = this.node.querySelector(searchListHeadingSelector);

    this.proxySelectBox.render({
      data: possibleProxyOptions,
      selectedValue,
      ariaLabelledBy: proxyLabelSelector.slice(1),
      onChange: ({ value = '' } = {}) => {
        const isProxyUser = !(state.userInfo && state.userInfo.systemUserId === value);
        dispatch({ type: 'SET_RECOGNIZER_ID', payload: { data: { value } } });
        const recipientIds = transformRecipients(this.currentlySelectedEmployees);
        if (recipientIds !== '') {
          if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
            UserSearchView.fetchRecognitionLevels(dispatch, this.currentlySelectedEmployees, state.recognizerId);
          } else {
            UserSearchView.getRecognitionTypes(dispatch, recipientIds, value, isProxyUser);
          }
        }
      },
      selectBoxElementId: proxySelectSelector.slice(1)
    });

    if (possibleProxyOptions.length <= 1) {
      this.node.querySelector(proxyContainerSelector).classList.add(styles.hidden);
    }

    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.userSearchList.render({
        isLoading: this.followedEmployeesLoading,
        data: this.followedEmployees,
        isOpen: this.followedEmployeesLoading,
        className: styles.listContainer,
        unSelectableItems: this.currentlySelectedEmployees,
        unSelectableMessage: translateKey('give-widget-user-already-selected', 'This person has already been added.'),
        onEscapeCallback: this.autoComplete.blur,
        onSelectCallback: this.onSelectUser.bind(this, dispatch, state, translateKey),
        translateKeyFn: translateKey,
        id: 'user-search'
      });

      this.eCardButton.render({
        title: translateKey('give-widget-send-ecard-label', 'Send eCard'),
        className: styles.sendEcardButton,
        onClick: () => {
          const uuid = uuidGenerator();
          dispatch({
            type: 'SET_RECOGNIZER_ID_AND_RECIPIENTS_ID_AND_SELECTED_RECOGNITION_TYPE_AND_TRANSACTION_ID',
            payload: {
              recipients: { recipients: this.currentlySelectedEmployees },
              recognizerId: { data: { value: this.currentlySelectedRecognizerId } },
              selectedRecognitionType: 'ECard',
              transactionId: uuid,
              viewNumber: views.ECARD_DETAILS_VIEW
            }
          });
        },
        isDisabled: !(state.recognitionTypes && state.recognitionTypes.hasECard),
        isHidden: state.givePermission && !state.givePermission.ecard,
        id: 'user-ecard'
      });

      this.nominationButton.render({
        title: translateKey('give-widget-send-nomination-label', 'Send Nomination'),
        className: styles.sendNominationButton,
        onClick: () => {
          const uuid = uuidGenerator();
          dispatch({
            type: 'SET_RECOGNIZER_ID_RECIPIENTS_ID_SELECTED_RECOGNITION_TYPE_TRANSACTION_ID',
            payload: {
              recipients: { recipients: this.currentlySelectedEmployees },
              recognizerId: { data: { value: this.currentlySelectedRecognizerId } },
              selectedRecognitionType: 'Nomination',
              transactionId: uuid,
              viewNumber: views.PROGRAM_DETAILS_VIEW,
              wizardUsed: false,
              nomineesUpdated: this.nomineesUpdated
            }
          });
        },
        isDisabled: !(state.recognitionTypes && state.recognitionTypes.hasNomination),
        isHidden: state.givePermission && !state.givePermission.nomination,
        id: 'user-nomination'
      });

      this.pillBox.render({
        data: this.currentlySelectedEmployees.map(UserSearchView.transformDataFromListToPillBox),
        onRemovePillBoxItem: this.onRemovePillBoxItem.bind(this, dispatch, state),
        id: 'user-pill-box'
      }, translateKey);

      this.autoComplete.render({
        placeholder: this.isRequiredToRestrictSingleRecipient ? translateKey(
          'give-widget-select-a-person-label-restricted',
          'Who would you like to appreciate? (Only one recipient can be selected)'
        ) : translateKey(
          'give-widget-select-a-person-label',
          'Who would you like to appreciate?'
        ),
        minCharactersCallback: () => {
          this.userSearchList.noDataMessage = translateKey('minimum-characters', 'Please enter at least three (3) characters to search.');
          this.userSearchList.messageError = false;
          this.userSearchList.data = [];
          this.userSearchList.unSelectableItems = [];
          emptyElement(this.searchListHeading);
        },
        source: (searchTerm) => {
          this.hasValueCleared = false;
          this.userSearchList.isLoading = true;
          return queryUsers(searchTerm);
        },
        transformResults: transformUserSearchResults,
        customRenderer: (val, results) => {
          emptyElement(this.searchListHeading);
          if (results.length > 0) {
            this.searchListHeading.appendChild(document.createTextNode(searchResultsTitle(translateKey)));
            this.userSearchList.noDataMessage = '';
          } else {
            this.userSearchList.noDataMessage =
              `${translateKey('give-widget-no-results-found-label', `No one found matching ${val}`, '{val}', `${val}`)}`;
            this.userSearchList.messageError = true;
          }
          this.userSearchList.isLoading = false;
          this.userSearchList.data = results;
          this.userSearchList.unSelectableItems = this.currentlySelectedEmployees.slice(0);
        },
        rendererId: `give-widget_${this.userSearchList.id}`,
        handleKeyDown: this.userSearchList.handleKeys,
        valueCleared: () => {
          this.userSearchList.isLoading = false;
          this.userSearchList.data = this.followedEmployees.slice(0);
          this.userSearchList.noDataMessage = '';
          this.userSearchList.unSelectableItems = this.currentlySelectedEmployees.slice(0);
          emptyElement(this.searchListHeading);
          if (this.followedEmployees.length > 0) {
            this.searchListHeading.appendChild(document.createTextNode(recommendedTitle(translateKey)));
          }
          if (this.hasValueCleared && this.currentlySelectedEmployees.length > 0) {
            this.pillBox.pop();
          }
          this.hasValueCleared = true;
        },
        id: styles.userAutoComplete,
        isDisabled: this.isRequiredToRestrictSingleRecipient && this.currentlySelectedEmployees.length > 0
      });
    }

    if (this.hasBulkUploadPermission) {
      if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        const uploadAFileButtonNode = document.querySelector(uploadAFileSelector);
        ReactDOM.render(<PrismButton classes={{ root: `${styles.linkButton} ${styles.bulkLink}` }}
          onClick={() => {
            const uuid = uuidGenerator();
            dispatch({
              type: 'SET_RECOGNIZER_ID_AND_RECIPIENTS_ID_AND_SELECTED_RECOGNITION_TYPE_AND_TRANSACTION_ID',
              payload: {
                recipients: { recipients: [] },
                recognizerId: { data: { value: this.currentlySelectedRecognizerId } },
                selectedRecognitionType: 'eCard',
                transactionId: uuid,
                viewNumber: views.BULK_UPLOAD_VIEW,
                wizardUsed: false
              }
            });
          }
          } type='button' underline='none' variant='text'>{translateKey('upload-a-file', 'Upload a file')}</PrismButton>, uploadAFileButtonNode);
      } else {
        this.bulkUploadButton.render({
          title: translateKey('give-widget-group-ecard', 'Group eCard'),
          className: `${styles.linkButton} ${styles.bulkLink} ${styles.bulkLinkOld}`,
          isPrimary: false,
          onClick: () => {
            const uuid = uuidGenerator();
            dispatch({
              type: 'SET_RECOGNIZER_ID_AND_RECIPIENTS_ID_AND_SELECTED_RECOGNITION_TYPE_AND_TRANSACTION_ID',
              payload: {
                recipients: { recipients: [] },
                recognizerId: { data: { value: this.currentlySelectedRecognizerId } },
                selectedRecognitionType: 'eCard',
                transactionId: uuid,
                viewNumber: views.BULK_UPLOAD_VIEW,
                wizardUsed: false
              }
            });
          }
        });
      }
    }
    if (this.searchText.length > 0) {
      const { autoCompleteInputNode = {} } = this.autoComplete;
      autoCompleteInputNode.value = this.searchText;
    }
  }

  render(dispatch, state, statediff, translateKey) {
    this.currentlySelectedEmployees = state.recipients || [];
    this.currentlySelectedRecognizerId = '';
    this.followedEmployees = state.followedEmployees || [];
    this.hasValueCleared = false;
    this.hasBulkUploadPermission = state.hasBulkPermission;
    this.nomineesUpdated = false;
    this.isRequiredToRestrictSingleRecipient = state.isRequiredToRestrictSingleRecipient;

    const userSearchViewFragment = document.createRange()
      .createContextualFragment(userSearchViewTemplate(translateKey, this.hasBulkUploadPermission));
    const userSearchViewTemp = this.node;
    this.node = getFirstElementChild(userSearchViewFragment);
    userSearchViewTemp.parentNode.replaceChild(this.node, userSearchViewTemp);
    setHeader(translateKey('give-widget-send-appreciation-label', 'Send Appreciation'));

    this.currentlySelectedRecognizerId = state.recognizerId;
    this.initializeAllSubComponents(translateKey);
    if (state.recipients && state.recipients.length > 0) {
      const recipientIds = state.recipients.map((recipient) => recipient.systemUserId).join(',');
      const isProxyUser = !(state.userInfo && state.userInfo.systemUserId === state.recognizerId);
      if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        if (!state.programList) {
          UserSearchView.fetchRecognitionLevels(dispatch, state.recipients, state.recognizerId);
        } else if (state.wizardFlowFinished) {
          // navigated here from the wizard view
          if ((state.selectedAwardLevel.value || state.selectedECardAmount.value) === 'eCard') {
            // picked "eCard" in the wizard
            const program = state.programList
              .filter((p) => p.programId === (state.selectedProgramNomination || state.selectedProgramECard).programId)[0];
            const selectedEcard = UserSearchView.pickLowestMonetaryLevel(program.EcardAwardLevels);
            UserSearchView.selectRecognitionLevel(selectedEcard, program, dispatch);
          } else {
            const levels = [].concat(...state.programList.map(UserSearchView.extractAwardLevels));
            const selectedIndex = levels
              .map((level) => level.awardLevelId)
              .indexOf(state.selectedAwardLevel.value || state.selectedECardAmount.value);
            const selectedAwardLevel = selectedIndex > -1 ? levels[selectedIndex] : null;
            const program = UserSearchView.findParentProgram(selectedAwardLevel, state.programList);
            UserSearchView.selectRecognitionLevel(selectedAwardLevel, program, dispatch);
          }
        }
      } else {
        UserSearchView.getRecognitionTypes(dispatch, recipientIds, state.recognizerId, isProxyUser);
      }
    }
    this.renderAllSubComponents(dispatch, state, translateKey);
    this.node.classList.add('give-widget-current-view');
    if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      document.getElementById('recipient-search').classList.add(styles.searchPlaceholderOld);
      this.node.classList.add(`${styles.streamLined}`);
      const mockRecognitionTypes = {
        selectRecognitionType: { input: { recognitionType: { options: [{ value: 'ECard' }, { value: 'Nomination' }] } } }
      };
      dispatch({
        type: 'SET_RECOGNITION_TYPES',
        payload: { data: mockRecognitionTypes, status: { code: 200 } }
      });

      const buttonContainer = document.querySelector(`.${styles.buttonContainer}`);
      buttonContainer.parentElement.removeChild(buttonContainer);

      this.renderRecipients(state, translateKey, dispatch);

      this.renderSuggestions(state, translateKey, dispatch);
      UserSearchView.renderAwardLevelSelector(
        state,
        translateKey,
        dispatch
      );
      this.renderSpecialInstructions({
        descriptionOfAchievement: state.descriptionOfAchievement,
        selectedProgramECard: state.selectedProgramECard,
        selectedProgramNomination: state.selectedProgramNomination
      }, translateKey);
      this.renderDescriptionOfAchievement(
        {
          descriptionOfAchievement: state.descriptionOfAchievement,
          descriptionOfAchievementText: state.descriptionOfAchievementText
        },
        translateKey,
        dispatch
      );

      this.renderCompanyValueSelector(state, translateKey, dispatch);
      this.renderNextButton({
        corporateValueList: state.corporateValueList,
        descriptionOfAchievement: state.descriptionOfAchievement,
        descriptionOfAchievementText: state.descriptionOfAchievementText,
        selectedAwardLevel: state.selectedAwardLevel,
        selectedCorporateValueECard: state.selectedCorporateValueECard,
        selectedCorporateValueNomination: state.selectedCorporateValueNomination,
        selectedProgramECard: state.selectedProgramECard,
        selectedRecognitionType: state.selectedRecognitionType,
        recipients: state.recipients
      }, translateKey, dispatch);

      positionElement(`#${styles.callToActionDiv}`);
    } else if (this.autoComplete) {
      document.getElementById('recipient-search').classList.add(styles.searchPlaceholderOld);
    }
    this.node.classList.add('give-widget-current-view');

    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      const callToActionDiv = document.querySelector(`#${styles.callToActionDiv}`);
      callToActionDiv.parentElement.removeChild(callToActionDiv);
    }
  }

  update(dispatch, state, stateDiff, translateKey) {
    if (this.isRequiredToRestrictSingleRecipient && this.currentlySelectedEmployees.length > 0) {
      this.userSearchList.unSelectableItems = this.followedEmployees.slice(0);
    }
    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      if (UserSearchView.shouldUpdateUserSearchListDataToFollowedEmployees(stateDiff)) {
        this.followedEmployees = state.followedEmployees;
        if (this.searchText.length === 0) {
          this.userSearchList.noDataMessage = '';
          if (this.followedEmployees.length > 0) {
            this.searchListHeading.appendChild(document.createTextNode(recommendedTitle(translateKey)));
          }
          this.userSearchList.data = this.followedEmployees.slice(0);
        }

        this.userSearchList.isLoading = false;

        if (this.searchText.length > 0 && this.followedEmployees.length > 0) {
          const { autoCompleteInputNode = {} } = this.autoComplete;
          autoCompleteInputNode.focus();
          this.searchText = '';
        }
      }
    } else {
      UserSearchView.renderRecipientSearchError(
        state,
        translateKey,
        dispatch
      );
    }

    if (UserSearchView.shouldUpdateRecognizerId(stateDiff)) {
      this.currentlySelectedRecognizerId = state.recognizerId;
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        this.userSearchList.onSelectCallback = this.onSelectUser.bind(this, dispatch, state, translateKey);
        this.pillBox.onRemovePillBoxItem = this.onRemovePillBoxItem.bind(this, dispatch, state);
      }
    }

    if (UserSearchView.shouldUpdateRecognitionTypeButtons(stateDiff)) {
      const hasError = UserSearchView.selectHasError(state);
      const recognitionTypesErrorMessageNode = this.node.querySelector(recognitionTypesErrorMessageSelector);
      if (hasError) {
        emptyElement(recognitionTypesErrorMessageNode);
        const errorMessage = UserSearchView.selectErrorCode(state) === 401
          ? translateKey('give-widget-error-session-timeout', 'Your session has timed out. Please log in again to continue.')
          : UserSearchView.selectErrorMessage(
            translateKey('give-widget-users-selected-have-no-recognition-eligibility', 'The user(s) selected have no recognition eligibility')
          );
        recognitionTypesErrorMessageNode.appendChild(document.createTextNode(errorMessage));
        recognitionTypesErrorMessageNode.classList.remove(styles.hidden);
      } else {
        recognitionTypesErrorMessageNode.classList.add(styles.hidden);
      }
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        this.eCardButton.isLoading = false;
        this.nominationButton.isLoading = false;
        this.eCardButton.isDisabled = !UserSearchView.selectHasECard(state);
        this.nominationButton.isDisabled = !UserSearchView.selectHasNomination(state);
      }
    }

    if (UserSearchView.shouldUpdatePossibleProxyOptionsSelectBox(stateDiff)) {
      const possibleProxyOptions = UserSearchView.composePossibleProxyOptions(state);
      if (possibleProxyOptions.length > 1) {
        this.proxySelectBox.data = possibleProxyOptions;
        this.proxySelectBox.selectedValue = possibleProxyOptions[0].value;
        this.node.querySelector(proxyContainerSelector).classList.remove(styles.hidden);
      } else {
        this.node.querySelector(proxyContainerSelector).classList.add(styles.hidden);
      }
    }
    if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      positionElement(`#${styles.callToActionDiv}`);
      this.renderRecipients(state, translateKey, dispatch);

      this.renderSuggestions(state, translateKey, dispatch);

      UserSearchView.renderAwardLevelSelector(
        state,
        translateKey,
        dispatch
      );

      this.renderSpecialInstructions({
        descriptionOfAchievement: state.descriptionOfAchievement,
        selectedProgramECard: state.selectedProgramECard,
        selectedProgramNomination: state.selectedProgramNomination
      }, translateKey);

      this.renderDescriptionOfAchievement(
        {
          descriptionOfAchievement: state.descriptionOfAchievement,
          descriptionOfAchievementText: state.descriptionOfAchievementText
        },
        translateKey,
        dispatch
      );
      this.renderCompanyValueSelector(state, translateKey, dispatch);

      this.renderNextButton({
        corporateValueList: state.corporateValueList,
        descriptionOfAchievement: state.descriptionOfAchievement,
        descriptionOfAchievementText: state.descriptionOfAchievementText,
        selectedAwardLevel: state.selectedAwardLevel,
        selectedCorporateValueECard: state.selectedCorporateValueECard,
        selectedCorporateValueNomination: state.selectedCorporateValueNomination,
        selectedProgramECard: state.selectedProgramECard,
        selectedRecognitionType: state.selectedRecognitionType,
        recipients: state.recipients
      }, translateKey, dispatch);

      if (UserSearchView.shouldCallGetApprovers(stateDiff)) {
        getApprovers(dispatch, state);
      }
      if (UserSearchView.shouldCallGetEcardConfig(stateDiff)) {
        getECardConfig(dispatch, state);
      }
    }
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }

  show() {
    this.node.classList.remove(styles.hidden);
  }
}

export default UserSearchView;
