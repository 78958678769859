import styles from './details_summary.css';

const eCardOpts = (props, translateKeyFn) =>
  `${props.eCardAmountList.options.length > 1 && props.selectedECardAmount.text ? `<li>${props.selectedECardAmount.text}</li>` : ''}
  <li>${props.eCardNotifyWhenViewedChecked
    ? translateKeyFn('give-widget-notifications-on') || 'Notifications on'
    : translateKeyFn('give-widget-notifications-off') || 'Notifications off'}</li>`;

const nominationOpts = (props) =>
  `${props.selectedAwardLevel.text ? `<li>${props.selectedAwardLevel.text}</li>` : ''}`;

const detailsSummaryTemplate = (props, translateKeyFn) =>
  `<div class="${styles.detailsSummaryList}">
    <ul>
      ${props.selectedProgram.text ? `<li>${props.selectedProgram.text}</li>` : ''}
      ${!props.selectedCorporateValue.text ? '' : `<li>${props.selectedCorporateValue.text}</li>`}
      ${props.selectedRecognitionType === 'ECard' ? eCardOpts(props, translateKeyFn) : nominationOpts(props)}
    </ul>
    <a class="${styles.editButton}" id="give-widget-details-edit" tabindex="0">
      ${translateKeyFn ? translateKeyFn('edit', 'Edit') : 'Edit'}
    </a>
  </div>`;

export default detailsSummaryTemplate;
