import styles from './app.css';
import buildClientStyles from './css/client_colors.js';

const appreciateModalId = 'appreciateModal';
export const appreciateModalSelector = '#appreciateModal';

const loadingViewId = 'loadingView';
export const loadingViewSelector = '#loadingView';

const userSearchViewId = 'userSearchView';
export const userSearchViewSelector = '#userSearchView';

const bulkUploadViewId = 'bulkUploadView';
export const bulkUploadViewSelector = '#bulkUploadView';

const programDetailsViewId = 'programDetailsView';
export const programDetailsViewSelector = '#programDetailsView';

const eCardDetailsViewId = 'eCardDetailsView';
export const eCardDetailsViewSelector = '#eCardDetailsView';

const nominationWizardViewId = 'nominationWizardView';
export const nominationWizardViewSelector = '#nominationWizardView';

const eCardSelectionViewId = 'eCardSelectionView';
export const eCardSelectionViewSelector = '#eCardSelectionView';

const eCardMessageViewId = 'eCardMessageView';
export const eCardMessageViewSelector = '#eCardMessageView';

const descriptionViewId = 'descriptionView';
export const descriptionViewSelector = '#descriptionView';

const submissionViewId = 'submissionView';
export const submissionViewSelector = '#submissionView';

export const clientColorStylesId = 'give-widget-clientColorStyles';

const nonceTemplate = (nonce) => `nonce="${nonce}"`;
export const clientColorStylesTmpl = (theme, nonce) =>
  `<style id="${clientColorStylesId}" ${nonce ? nonceTemplate(nonce) : ''}>${buildClientStyles(theme)}</style>`;

const appTemplate = (id) =>
  `<div id="${id}"><div id="oct-appreciateModal">
<div id="${appreciateModalId}" class="${styles.hide}"></div>
<div id="${loadingViewId}" class="${styles.hide}">
</div></div>`;

export const getButtonTemplate = () => {
  const random = `${Math.floor(Math.random() * 100000)}`;
  const appreciateButtonId = `give-widget_appreciateButton-${random}`;
  return {
    html: `<button id="${appreciateButtonId}" class="${styles.hide} main-give-appreciate-button" type="button"></button>`,
    id: appreciateButtonId
  };
};

export const viewsPlaceholder = () => `<div id="${userSearchViewId}"></div>
          <div id="${bulkUploadViewId}"></div>
          <div id="${programDetailsViewId}"></div>
          <div id="${eCardDetailsViewId}"></div>
          <div id="${nominationWizardViewId}"></div>
          <div id="${eCardSelectionViewId}"></div>
          <div id="${eCardMessageViewId}"></div>
          <div id="${descriptionViewId}"></div>
          <div id="${submissionViewId}"></div>`;

export default appTemplate;
