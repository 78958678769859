import FetchApi from './fetch_api';
import * as views from '../../views/views-list';
import getConfig from '../../env';
import {
  getUserIdsFromApproverPresenters,
  transformedUserData,
  mergeUserImagesforProvidedSelectionType,
  mergeUserImagesforSelectSelectionType
} from '../reducers/root_reducer';

export const transformRecipients = (recipients) =>
  recipients.reduce((acc, employee) => `${acc},${employee.systemUserId.toString()}`, '').substring(1);

export const transformApproverAndPresenterIds = (list, rst) => {
  if (list.length === 0) return rst;
  rst.push(parseInt(list[0].value, 10));
  return transformApproverAndPresenterIds(list.slice(1), rst);
};

export const transformScheduledDate = (date) => {
  if (date === '') return '';
  const dateObj = new Date(date);
  return `${(dateObj.getMonth() + 1)}-${dateObj.getDate()}-${dateObj.getFullYear()}`;
};

export const transformWizardAnswers = (wizard) => {
  const answers = wizard.map((item) => item.selectedAnswer);
  if (answers.length > 0 && answers[0] !== '') {
    return answers;
  }
  return [];
};

export const retrieveRecommendedAwardLevel = (awardList) => {
  const recommendedAwardLevel = awardList.filter((award) => award.selected && award.value !== 'eCard');
  if (recommendedAwardLevel.length > 0) {
    return recommendedAwardLevel[0].value;
  }
  return '';
};

export const getGiveInfo = (dispatch) => {
  if (!window.GiveWidget) {
    window.GiveWidget = {};
  }
  if (!window.GiveWidget.giveInfoPromise) {
    window.GiveWidget.giveInfoPromise = FetchApi(`${getConfig('API_BASE_URL')}/api/performance/givePermissions`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  return window.GiveWidget.giveInfoPromise.then(
    (res) => {
      dispatch({ type: 'SET_GIVE_OPTIONS', payload: res });
      return res;
    },
    (err) =>
      err.json().then((errJson) => {
        dispatch({ type: 'SET_GIVE_OPTIONS', payload: errJson });
        if (!errJson.error || (errJson.error && !(errJson.error.indexOf('no give eligibility') > -1))) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Something went wrong' });
          // Check if we want to make getGiveInfo Call
        }
        throw err;
      })
  );
};

export const getSystemUserIds = (dispatch, coreRecipients) => {
  const coreRecipientsIds = coreRecipients.map((val) => {
    if (val.id) {
      return val.id;
    }
    return '';
  }).join(',');
  return FetchApi(`${getConfig('API_BASE_URL')}/api/users/systemUserIds?coreIds=${coreRecipientsIds}`)
    .then(
      (res) => {
        const recipients = res.map((user) => {
          let recipient = {};
          coreRecipients.forEach((coreRecipient) => {
            if (coreRecipient.id === user.coreIdentityId) {
              recipient = coreRecipient;
            }
          });
          return { ...recipient, systemUserId: user.systemUserId };
        });
        dispatch({
          type: 'SET_RECIPIENT_IDS_AND_SET_INITIAL_RECIPIENTS',
          payload: {
            recipients: { recipients },
            initialRecipients: { initialRecipients: [...recipients] }
          }
        });
        return res;
      },
      (err) => {
        err.json().then(() => {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Something went wrong' });
          throw err;
        });
      }
    );
};

export const getCurrentUserInfo = (dispatch) => {
  if (!window.GiveWidget.getCurrentUserInfoPromise) {
    window.GiveWidget.getCurrentUserInfoPromise = FetchApi(`${getConfig('API_BASE_URL')}/api/users/current`);
  }
  return window.GiveWidget.getCurrentUserInfoPromise.then((res) => {
    dispatch({
      type: 'SET_USER_INFO_AND_RECOGNIER_ID_AND_STP',
      payload: { userInfo: res, recognizerId: { data: { value: res.id } }, stp: res.customer }
    });
    return res;
  }, (err) => {
    console.error(err);
    throw err;
  });
};

// POST '/api/give/programs' after select type, store program info in store
export const getPrograms = (dispatch, {
  recipients = [], recognizerId = '', selectedRecognitionType = '', groupDetails = {}
}, t) => {
  let url = `${getConfig('API_BASE_URL')}/api/give/programs`;
  let body = {
    recipientIds: transformRecipients(recipients),
    recognitionType: selectedRecognitionType,
    recognizerId
  };
  if (Object.keys(groupDetails).length > 0) {
    url = `${getConfig('API_BASE_URL')}/api/give/group/programs`;
    body = {
      fileId: groupDetails.fileId,
      recognitionType: selectedRecognitionType,
      recognizerId
    };
  }
  return FetchApi(url, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then((res) => {
      // check list length:
      // if there is only 1 program available:
      // saves programId to store, proceed with wizard call
      // if there are more than 1 programs available:
      // saves programIds to store, display radio buttons on the page
      const {
        selectProgram: { input: { programId: { options = [] } = {} } = {} } = {},
        selectBulkProgram: { input: { programId: { options: bulkOptions = [] } = {} } = {} } = {}
      } = res;
      if (options.concat(bulkOptions).length === 1) {
        if (selectedRecognitionType === 'Nomination') {
          dispatch({
            type: 'SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_NOMINATION',
            payload: {
              programList: options.concat(bulkOptions),
              selectedProgramNomination: options.concat(bulkOptions)[0]
            }
          });
        } else {
          dispatch({
            type: 'SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_ECARD',
            payload: {
              programList: options.concat(bulkOptions),
              selectedProgramECard: options.concat(bulkOptions)[0]
            }
          });
        }
      } else {
        dispatch({
          type: 'SET_PROGRAM_LIST',
          payload: options.concat(bulkOptions)
        });
      }
      return options.concat(bulkOptions);
    })
    .catch(() => {
      dispatch({
        type: 'ADD_APP_MESSAGE',
        payload: {
          message: t('give-widget-error', 'Sorry, there was an error'),
          actionLabel: t('try-again', 'Try again'),
          onAction: () => {
            getPrograms(
              dispatch,
              {
                recipients, recognizerId, selectedRecognitionType, groupDetails
              },
              t
            );
          }
        }
      });
    });
};

export const getProgramsAndAwardLevels = (dispatch, recipients = [], recognizerId = '') => {
  const url = `${getConfig('API_BASE_URL')}/api/give/programsAndAwardLevels`;
  const body = {
    recipientIds: recipients.map((recipient) => recipient.systemUserId).join(','),
    recognizerId
  };
  return FetchApi(url, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then((res) => res.programs.map(([program]) => {
      if (program.EcardAwardLevels.length || program.NominnationAwardLevels.length) return program;

      // consider programs with no award levels to be standalone eCard awards, with a programId but no awardLevelId
      return {
        ...program,
        EcardAwardLevels: [{
          awardLevelId: '',
          awardLevelName: program.programName
        }]
      };
    }));
};

export const getWizard = (
  {
    selectedProgramNomination = {},
    recipients = [],
    recognizerId = '',
    answerIds = [],
    wizardOptional = false,
    wizard = []
  } = {},
  dispatch
) =>
  FetchApi(`${getConfig('API_BASE_URL')}/api/give/wizard`, {
    method: 'post',
    body: JSON.stringify({
      programId: selectedProgramNomination.value,
      recipientIds: transformRecipients(recipients),
      recognitionType: 'Nomination',
      recognizerId: `${recognizerId}`,
      answerIds
    })
  }).then((res) => {
    if (res.selectAwardLevel && wizard.length > 0) {
      // We've gone through the wizard.
      // If the wizard array is empty, this is the first wizard call, and it's just not used.
      if (wizardOptional === true) {
        dispatch({
          type: 'SET_ECARD_RECOMMENDED_AND_WIZ_FLOW_FINISHED_AND_RECOMMENDED_AWARD_LEVEL',
          payload: {
            eCardRecommended: res.selectAwardLevel.input.eCardRecommended.value === 'true',
            wizardFlowFinished: true,
            recommendedAwardLevel: res.selectAwardLevel.input,
            selectedAwardLevel: {}
          }
        });
      } else {
        dispatch({
          type: 'SET_AWARD_LIST_AND_ECARD_RECOMMENDED_AND_WIZ_FLOW_FINISHED_AND_RECOMMENDED_AWARD_LEVEL',
          payload: {
            awardList: res.selectAwardLevel.input,
            eCardRecommended: res.selectAwardLevel.input.eCardRecommended.value === 'true',
            wizardFlowFinished: true,
            recommendedAwardLevel: res.selectAwardLevel.input,
            selectedAwardLevel: {}
          }
        });
      }
    }
    return res;
  }, (err) => {
    err.json().then((errJson) => {
      dispatch({ type: 'ADD_APP_MESSAGE', payload: errJson.error.message });
    });
  });

export const getAwardLevels = (dispatch, {
  selectedRecognitionType = '', selectedProgramNomination = {}, recipients = [], recognizerId = ''
} = {}) =>
  FetchApi(`${getConfig('API_BASE_URL')}/api/give/awardLevels`, {
    method: 'POST',
    body: JSON.stringify({
      programId: selectedProgramNomination.value,
      recognitionType: selectedRecognitionType,
      recipientIds: transformRecipients(recipients),
      recognizerId
    })
  }).then((res) => {
    if (res.selectAwardLevel) {
      dispatch({ type: 'SET_AWARD_LIST', payload: res.selectAwardLevel.input });
    }
    return res.selectAwardLevel.input;
  });

export const getClientTheme = (dispatch) => {
  if (!window.GiveWidget.getClientThemePromise) {
    window.GiveWidget.getClientThemePromise = FetchApi(`${getConfig('API_BASE_URL')}/api/performance/theme`, {
      method: 'get'
    });
  }
  return window.GiveWidget.getClientThemePromise.then((res) => {
    if (res) {
      dispatch({ type: 'SET_CLIENT_THEME', payload: res });
    }
    return res;
  }, (err) => {
    // ToDo: when the above call fails we need to update state with default colors
    console.error(err);
    throw err;
  });
};

export const uploadImage = (image) => {
  const uploadImageData = new FormData();
  uploadImageData.append('eCardImage', image);
  return FetchApi(`${getConfig('API_BASE_URL')}/api/give/eCardImage`, {
    method: 'POST',
    body: uploadImageData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((res) => res.imageId);
};

export const getUserImages = (userIds = '') => FetchApi(`${getConfig('API_BASE_URL')}/api/users?ids=${userIds}`);

export const getApprovers = (
  dispatch,
  {
    customerFlags = {},
    selectedAwardLevel = {},
    selectedProgramNomination = {},
    eCardRecommended = false,
    recipients = [],
    selectedRecognitionType = '',
    recognizerId = ''
  }
) =>
  FetchApi(`${getConfig('API_BASE_URL')}/api/give/approvers`, {
    method: 'POST',
    body: JSON.stringify({
      programId: selectedProgramNomination.value,
      awardLevelId: selectedAwardLevel.value,
      recipientIds: transformRecipients(recipients),
      eCardRecommended,
      recognizerId: `${recognizerId}`,
      recognitionType: selectedRecognitionType
    })
  }).then(
    (res) => {
      const {
        selectApproversAndPresenters: {
          input: {
            approverIds = [],
            presenterIds = []
          }
        }
      } = res;
      const userIds = getUserIdsFromApproverPresenters(approverIds, presenterIds);
      if (userIds) {
        return getUserImages(userIds)
          .then((userDataRes) => {
            const temp = transformedUserData(userDataRes);
            let newApproverIds = mergeUserImagesforProvidedSelectionType(temp, approverIds);
            newApproverIds = mergeUserImagesforSelectSelectionType(temp, newApproverIds);
            let newPresenterIds = mergeUserImagesforProvidedSelectionType(temp, presenterIds);
            newPresenterIds = mergeUserImagesforSelectSelectionType(temp, newPresenterIds);
            res.selectApproversAndPresenters.input.approverIds = newApproverIds;
            res.selectApproversAndPresenters.input.presenterIds = newPresenterIds;
            return res;
          });
      } return res;
    },
    (err) =>
      err.json().then((errJson) => {
        dispatch({ type: 'ADD_APP_MESSAGE', payload: errJson.error.message });
        throw err;
      })
  ).then(
    (res) => {
      if (res) {
        const {
          selectApproversAndPresenters: {
            input: {
              corporateValueId = '',
              ccUserIds = {},
              approverIds = {},
              presenterIds = {},
              message = {},
              thankYouNote = {},
              boiLabelLine1OverrideField = {},
              noteToApprover = {}
            } = {}
          } = {},
          privateRecognitionEnabled = false
        } = res;
        if (res && res.selectApproversAndPresenters && res.selectApproversAndPresenters.input) {
          const approverInfo = {
            corporateValueList: corporateValueId,
            ccUsers: ccUserIds,
            approverList: approverIds,
            presenterList: presenterIds,
            descriptionOfAchievement: message,
            thankYouNote,
            noteToApprover,
            boiLabelLine1OverrideField,
            isPrivacyEnabledNomination: privateRecognitionEnabled
          };
          dispatch({
            type: 'SET_APPROVER_ETC',
            payload: approverInfo
          });
          if (corporateValueId.options && corporateValueId.options.length === 1 && !customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
            dispatch({
              type: 'SET_SELECTED_CORPORATE_VALUE_NOMINATION',
              payload: corporateValueId.options[0]
            });
          }
          return res.selectApproversAndPresenters;
        }
      }
      return null;
    },
    (err) => {
      throw err;
    }
  );

export const submitNomination = (
  dispatch,
  {
    recipients = [],
    recognizerId = '',
    noteToApproverText = '',
    approverList = [],
    presenterList = [],
    selectedProgramNomination = {},
    selectedAwardLevel = {},
    selectedCCUsers = [],
    selectedCorporateValueNomination = {},
    descriptionOfAchievementText = '',
    thankYouNoteText = '',
    boiText = '',
    privacy = false,
    transactionId = '',
    wizard = [],
    recommendedAwardLevel = []
  },
  t
) => {
  const url = `${getConfig('API_BASE_URL')}/api/give`;

  return FetchApi(url, {
    method: 'POST',
    body: JSON.stringify({
      recipientIds: transformRecipients(recipients),
      recognitionType: 'Nomination',
      recognizerId: `${recognizerId}`,
      noteToApprover: noteToApproverText,
      approverIds: transformApproverAndPresenterIds(approverList, []),
      presenterIds: transformApproverAndPresenterIds(presenterList, []),
      ccUserIds: transformApproverAndPresenterIds(selectedCCUsers, []),
      programId: selectedProgramNomination.value,
      awardLevelId: selectedAwardLevel.value,
      corporateValueId: parseInt(selectedCorporateValueNomination.value, 10),
      message: descriptionOfAchievementText,
      thankYouNote: thankYouNoteText,
      boiLabelLine1OverrideField: boiText,
      privateRecognition: privacy,
      answerIds: transformWizardAnswers(wizard),
      recommendedAwardLevelId: retrieveRecommendedAwardLevel(recommendedAwardLevel),
      transactionId
    })
  }).then(
    (res) => {
      if (res.status === 200) {
        dispatch({
          type: 'SET_VIEW_NUMBER_TRANSACTION_ID',
          payload: {
            viewNumber: views.SUBMISSION_VIEW,
            transactionId: null
          }
        });
      }
    },
    (err) =>
      err.json().then((errJson) => {
        if (errJson.statusText &&
          (errJson.statusText === 'Budget peek failed' || errJson.statusText.indexOf('does not have sufficient funds') > -1)) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Unfortunately there isn\'t enough budget to fulfill your request at this time.' });
          throw err;
        } else if (errJson.error && errJson.error.errorType && errJson.error.errorType.indexOf('RECOGNITION_LIMIT_REACHED') > -1) {
          dispatch({
            type: 'ADD_APP_MESSAGE',
            payload: t('recognition-limit-reached', errJson.error.message)
          });
        } else if (errJson.status && errJson.status === 503) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Your submission is being processed. Please check your account history.' });
          throw err;
        } else if (errJson.status && errJson.status === 400 && errJson.statusText.indexOf('INVALID_PRESENTER') > -1) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'The selected presenter you chose was invalid; please try again.' });
          throw err;
        } else if (errJson.statusText.indexOf('expired') > -1) {
          dispatch({
            type: 'ADD_APP_MESSAGE',
            payload: t('give-widget-error-session-timeout', 'Your session has timed out. Please log in again to continue.')
          });
        } else {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: errJson.statusText });
          throw err;
        }
      })
  );
};

export const getECardConfig = (
  dispatch,
  {
    recipients = [],
    recognizerId = '',
    selectedProgramECard = {},
    groupDetails = {}
  }
) => {
  let url = `${getConfig('API_BASE_URL')}/api/give/eProductProgramInfo`;
  let body = {
    recipientIds: transformRecipients(recipients),
    recognizerId,
    programId: selectedProgramECard.value
  };
  if (Object.keys(groupDetails).length > 0) {
    url = `${getConfig('API_BASE_URL')}/api/give/group/eProductInfo`;
    body = {
      recognizerId,
      programId: selectedProgramECard.value,
      recognitionType: 'ECard',
      fileId: groupDetails.fileId
    };
  }
  return FetchApi(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((res) => {
    const {
      eProductProgInfo: { input: eProductProgInfoInput = {} } = {},
      submitGroupEProduct: { input: submitGroupEProductInput = {} } = {},
      groupEProductInfo: { input: groupEProductInfoInput = {} } = {}
    } = res;
    const data = { ...eProductProgInfoInput, ...submitGroupEProductInput, ...groupEProductInfoInput };
    const eCardConfigPayload = {
      corporateValueList: data.corporateValueId,
      eCardAmountList: data.awardLevelId,
      descriptionOfAchievement: data.message,
      eCardNotifyWhenViewed: data.notifyViewed,
      eCardNotifyManagerWhenViewed: data.ccManager,
      scheduleDateConfig: data.scheduledDeliverDate,
      ccUsers: data.ccUserIds,
      dateFormat: res.dateFormat,
      isPrivacyEnabledECard: res.privateRecognitionEnabled,
      isECardUploadEnabled: res.ecardUploadPhotoEnabled
    };
    dispatch({
      type: 'SET_ECARD_CONFIG',
      payload: eCardConfigPayload
    });

    if (data.corporateValueId.options && data.corporateValueId.options.length === 1) {
      dispatch({
        type: 'SET_SELECTED_CORPORATE_VALUE_ECARD',
        payload: data.corporateValueId.options[0]
      });
    }

    if (data.awardLevelId && data.awardLevelId.options && data.awardLevelId.options.length === 1) {
      dispatch({
        type: 'SET_SELECTED_ECARD_AMOUNT',
        payload: data.awardLevelId.options[0]
      });
    }
  });
};

export const getECardList = (
  dispatch,
  {
    recipients = [],
    recognizerId = '',
    selectedProgramECard = {},
    groupDetails = {}
  }
) => {
  let url = `${getConfig('API_BASE_URL')}/api/give/eProducts`;
  let body = {
    recipientIds: transformRecipients(recipients),
    recognizerId,
    programId: selectedProgramECard.value,
    recognitionType: 'ECard'
  };
  if (groupDetails.fileId) {
    url = `${getConfig('API_BASE_URL')}/api/give/group/eProducts`;
    body = {
      recipientIds: transformRecipients(recipients),
      recognizerId,
      programId: selectedProgramECard.value,
      recognitionType: 'ECard',
      fileId: groupDetails.fileId
    };
  }
  return FetchApi(url, {
    method: 'POST',
    body: JSON.stringify(body)
  }).then((res) => {
    const {
      selectEProduct: { input: { eProductId = {}, favoriteEproducts = [] } = {} } = {},
      selectBulkEProduct: { input: { eProductId: bulkEProductId = {}, favoriteEproducts: bulkFavoriteEProducts = [] } = {} } = {}
    } = res;
    dispatch({
      type: 'SET_ECARD_LIST',
      payload: {
        eProducts: Object.keys(eProductId).length > 0 ? eProductId : bulkEProductId,
        favorites: favoriteEproducts.concat(bulkFavoriteEProducts)
      }
    });
  });
};

export const favoriteECard = (
  dispatch,
  {
    favorited = '',
    eCardName = '',
    program = ''
  }
) => FetchApi(`${getConfig('API_BASE_URL')}/api/give/favoriteEproduct`, {
  method: 'POST',
  body: JSON.stringify({
    isFavorite: favorited,
    eProductName: eCardName,
    programId: program
  })
}).catch(console.error);

export const selectECardImage = (uploadedECard, selectedECard) => {
  if (uploadedECard.uploadedFile) {
    return uploadImage(uploadedECard.uploadedFile).then((imageId) => ({
      imageId
    }));
  }
  return Promise.resolve({
    eProductId: selectedECard.value
  });
};

export const submitECard = (
  dispatch,
  {
    recipients = [],
    recognizerId = '',
    selectedProgramECard = {},
    selectedCorporateValueECard = {},
    descriptionOfAchievementText = '',
    selectedECardAmount = '',
    selectedECard = {},
    selectedCCUsers = [],
    scheduledDeliveryDate = '',
    eCardNotifyWhenViewedChecked = false,
    eCardNotifyManagerWhenViewedChecked = false,
    privacy = false,
    groupDetails = {},
    uploadedECard = {},
    transactionId = ''
  },
  t
) => {
  let submitReqBody,
    url;
  const commonBody = {
    awardLevelId: selectedECardAmount.value,
    corporateValueId: parseInt(selectedCorporateValueECard.value, 10),
    message: descriptionOfAchievementText,
    privateRecognition: privacy,
    programId: selectedProgramECard.value,
    recognitionType: 'ECard',
    recognizerId: `${recognizerId}`,
    eCardTransactionId: transactionId,
    scheduledDeliverDate: transformScheduledDate(scheduledDeliveryDate) // MM-dd-yyyy
  };

  if (Object.keys(groupDetails).length > 0) {
    url = `${getConfig('API_BASE_URL')}/api/give/group/submitEProduct`;
    submitReqBody = {
      ...commonBody,
      ccManager: eCardNotifyManagerWhenViewedChecked,
      ccUserIds: transformApproverAndPresenterIds(selectedCCUsers),
      copySender: false,
      fileId: groupDetails.fileId,
      fileRowsCount: groupDetails.count,
      notifyViewed: false
    };
  } else {
    url = `${getConfig('API_BASE_URL')}/api/give/submitEProduct`;
    submitReqBody = {
      ...commonBody,
      ccUserIds: transformApproverAndPresenterIds(selectedCCUsers, []),
      copySender: true,
      notifyViewed: eCardNotifyWhenViewedChecked,
      recipientIds: transformRecipients(recipients)
    };
  }
  return selectECardImage(uploadedECard, selectedECard).then((eCardImage) => FetchApi(url, {
    method: 'POST',
    body: JSON.stringify({
      ...submitReqBody,
      ...eCardImage
    })

  })).then(
    (res) => {
      if (res.status === 200) {
        dispatch({
          type: 'SET_VIEW_NUMBER_TRANSACTION_ID',
          payload: {
            viewNumber: views.SUBMISSION_VIEW,
            transactionId: null
          }
        });
      }
    },
    (err) =>
      err.json().then((errJson) => {
        if (errJson.statusText &&
          (errJson.statusText === 'Budget peek failed' || errJson.statusText.indexOf('does not have sufficient funds') > -1)) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Unfortunately there isn\'t enough budget to fulfill your request at this time.' });
          throw err;
        } else if (errJson.error && errJson.error.code && errJson.error.code === 500 &&
                    errJson.error.message && errJson.error.message.toLowerCase().indexOf('server error') > -1) {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: 'Something went wrong, please try again.' });
          throw err;
        } else if (errJson.error && errJson.error.errorType && errJson.error.errorType.indexOf('RECOGNITION_LIMIT_REACHED') > -1) {
          dispatch({
            type: 'ADD_APP_MESSAGE',
            payload: t('recognition-limit-reached', errJson.error.message)
          });
        } else if (errJson.statusText.indexOf('expired') > -1) {
          dispatch({
            type: 'ADD_APP_MESSAGE',
            payload: t('give-widget-error-session-timeout', 'Your session has timed out. Please log in again to continue.')
          });
        } else if (errJson.statusText && errJson.statusText.indexOf('budget-transaction-failed') > -1) {
          dispatch({
            type: 'ADD_APP_MESSAGE',
            payload: t('error-budget-transaction-failed', 'Oops... Something went wrong. Please resubmit at a later time.')
          });
          throw err;
        } else {
          dispatch({ type: 'ADD_APP_MESSAGE', payload: errJson.statusText });
          throw err;
        }
      })
  );
};

export const getCustomerFlags = (dispatch, { customerId } = {}) => {
  if (!window.GiveWidget.getCustomerFlagsPromise) {
    window.GiveWidget.getCustomerFlagsPromise = FetchApi(`${getConfig('API_BASE_URL')}/api/customers/${customerId}/properties`, {
      method: 'GET'
    });
  }
  return window.GiveWidget.getCustomerFlagsPromise
    .then((res) => res.map((prop) => ({ ...prop, propertyValue: prop.propertyValue === 'false' ? false : prop.propertyValue })))
    .then((res) => {
      dispatch({ type: 'SET_CUSTOMER_FLAGS', payload: res });
      return res;
    });
};

export const canApprove = (systemUserId, programId, awardLevelId) =>
  FetchApi(`${getConfig('API_BASE_URL')}/api/give/canApprove?systemUserId=${systemUserId}&programId=${programId}&awardLevelId=${awardLevelId}`);

export const canPresent = (systemUserId, programId, awardLevelId) =>
  FetchApi(`${getConfig('API_BASE_URL')}/api/give/canPresent?systemUserId=${systemUserId}&programId=${programId}&awardLevelId=${awardLevelId}`);

export const queryUsers = (searchTerm) => FetchApi(`${getConfig('API_BASE_URL')}/api/search/v2/users?q=${searchTerm}`);

export const bulkUploadFile = (customerId, recognizerId) => (file) => {
  const uploadFormData = new window.FormData();
  uploadFormData.append('customerId', customerId);
  uploadFormData.append('recipientsFile', file);
  uploadFormData.append('recognizerId', recognizerId);
  return FetchApi(`${getConfig('API_BASE_URL')}/api/give/group/validateFiledata`, {
    method: 'POST',
    body: uploadFormData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const fetchGroupTypes = (fileId) => FetchApi(`${getConfig('API_BASE_URL')}/api/give/group/types`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    fileId
  })
});
