import prismUiCss from '@octanner/prism-ui-css/prism.css';

const getImage = (imageSrc, initials, name) => {
  if (!imageSrc) {
    if (initials === undefined || initials === null) {
      return `
        <div class="${prismUiCss['psm-avatar--md']}">
          <div class="${prismUiCss['psm-avatar__silhouette']}" alt="${name}" role="img"></div>
        </div>
        `;
    }
    return `
      <div class="${prismUiCss['psm-avatar--md']}" alt="${name}">
        <span class="${prismUiCss['psm-avatar__text']}">${initials}<span>
      </div>`;
  } else if (imageSrc.indexOf('/default/') === -1) {
    return `
      <div class="${prismUiCss['psm-avatar--md']}">
        <img src="${imageSrc}" class="fs-exclude ${prismUiCss['psm-avatar__img']}" alt="${initials}"></img>
      </div>
    `;
  }
  return `
    <div class="${prismUiCss['psm-avatar--md']}" alt="${name}">
      <span class="${prismUiCss['psm-avatar__text']}">${initials}<span>
    </div>`;
};

export default getImage;
