import promise from 'promise-polyfill';
import getAuthToken from './lib/auth_token';

// To add to window
if (!window.Promise) {
  window.Promise = promise;
}

let { fetch } = window;
let lang = 'en-US';
let userAccessToken = '';
let xClientID = 'give-widget';

export const setFetchFunc = (fetchFunc) => {
  fetch = fetchFunc;
};

export const setXClientID = (clientID) => {
  xClientID = clientID;
};

export const getXClientID = () => xClientID;

export const setLanguage = (language) => {
  lang = language;
};

export const getLanguage = () => lang;

let tokenName;

export const setTokenName = (name) => {
  tokenName = name;
};

export const setUserAccessToken = (token) => {
  userAccessToken = token;
};

/**
 * In order to maintain consistency accross the project we are naming headers with first Character
 * as uppercase. For example its Content-Type not content-type.
 * HTTP headers are case-insensitive, but object keys are case-sensitive.
 * As we are storing headers as Object Keys and keys inside an Object are case-sensitive we need to be carefull.
 */

const FetchApi = (url = '', options = {}) => {
  const defaultOptions = {
    method: 'get',
    headers: {
      ...getAuthToken(tokenName, userAccessToken),
      'Accept-Language': `${lang.replace('_', '-')};q=0.8`,
      'X-Client-ID': xClientID,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  };
  // since Object.assign doesn't do deep merge, we need to do it ourselves
  const finalHeaders = { ...defaultOptions.headers, ...options.headers };
  const finalOptions = { ...defaultOptions, ...options, headers: finalHeaders };
  if (finalOptions.headers['Content-Type'] && finalOptions.headers['Content-Type'].indexOf('multipart/form-data') !== -1) {
    delete finalOptions.headers['Content-Type'];
  }
  return fetch(url, finalOptions).then((res) => {
    if (res.ok) {
      const responseHeaders = res.headers;
      if (responseHeaders.get('Content-Type').indexOf('application/json') !== -1) {
        return res.json();
      }
      return res.text();
    }
    // call returned a non-200 status
    return Promise.reject(res);
  });
};

export default FetchApi;
