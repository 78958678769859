import { getFirstElementChild, positionElement, setHeader } from '../../utils/utils';
import eCardSelectionViewTemplate from './ecard_selection_view_template.js';
import ECardListContainer from '../../components/ecard_list_container/ecard_list_container';

import RecipientHeader from '../../components/recipient_header/recipient_header';
import DetailsSummary from '../../components/details_summary/details_summary';
import { favoriteECard } from '../../state/services/api';
import TabSelector from '../../components/tab_selector/tab_selector.js';
import ImageUpload from '../../components/image_upload/image_upload.js';
import styles from './ecard_selection_view.css';
import * as views from '../views-list';
import { getHeaderText } from '../../components/modal_header/modal_header_template';

export default class ECardSelectionView {
  modalHeader;
  customizeECardHeader;
  subheading;
  eCardListContainer;

  static selectECard = (dispatch) => // Send the correct card when the function is invoked in ecard_tile.js
    (evt, card) =>
      dispatch({
        type: 'SET_SELECTED_ECARD_AND_VIEW',
        payload: {
          viewNumber: views.ECARD_MESSAGE_VIEW,
          uploadedECard: {},
          selectedECard: card
        }
      });

  static favoriteEproduct = (dispatch, state) =>
    (isFavorited, eproductName) =>
      favoriteECard(dispatch, { favorited: isFavorited, eCardName: eproductName, program: state.selectedProgramECard.value });

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to ecard_selection_view component `);
    }
  }

  initializeAllSubComponents() {
    this.customizeECardHeader = new RecipientHeader('#ecard-heading');
    this.eCardListContainer = new ECardListContainer('#ecard-list');
    this.eCardTabSelect = new TabSelector('#ecard-tabs');
    this.eCardImageUpload = new ImageUpload('#ecard-upload');
    this.detailsSummary = new DetailsSummary(`.${styles.detailsSummary}`);
  }

  tabSelectHandler(i) {
    // switch which ecard component is showing
    if (i === 'eCard') {
      this.eCardImageUpload.node.classList.add(styles.hidden);
      this.eCardListContainer.node.classList.remove(styles.hidden);
    } else if (i === 'Upload') {
      this.eCardListContainer.node.classList.add(styles.hidden);
      this.eCardImageUpload.node.classList.remove(styles.hidden);
    }
    positionElement(`#${styles.callToActionDiv}`);
  }

  renderAllSubComponents(dispatch, state, translateKeyFn) {
    const headerText = getHeaderText(state.selectedRecognitionType, translateKeyFn);
    setHeader(headerText);

    this.customizeECardHeader.render({
      recipients: state.recipients,
      selectedRecognitionType: state.selectedRecognitionType,
      bulkUploadCount: state.groupDetails ? state.groupDetails.count : ''
    }, dispatch, translateKeyFn);

    const sortedECardList = state.eCardList.favorites ? state.eCardList.favorites.concat(state.eCardList.others) : [];

    this.eCardListContainer.render({
      eCardList: sortedECardList,
      filterCategories: (state.eCardList.filters && state.eCardList.filters.categories),
      onSelectECard: ECardSelectionView.selectECard(dispatch),
      onFavoriteECard: ECardSelectionView.favoriteEproduct(dispatch, state),
      uploadTabsShowing: state.eCardUpload
    }, translateKeyFn);

    const eCardNotifyWhenViewedChecked = Object.keys(state.groupDetails).length > 0 ?
      state.eCardNotifyManagerWhenViewedChecked : state.eCardNotifyWhenViewedChecked;

    this.detailsSummary.render({
      selectedRecognitionType: state.selectedRecognitionType,
      selectedProgram: state.selectedProgramECard,
      selectedCorporateValue: state.selectedCorporateValueECard,
      eCardAmountList: state.eCardAmountList,
      selectedECardAmount: state.selectedECardAmount,
      eCardNotifyWhenViewedChecked
    }, dispatch, translateKeyFn);

    if (state.eCardUpload) {
      this.eCardImageUpload.render({
        onCancel: () => {
          dispatch({
            type: 'SET_UPLOADED_ECARD',
            payload: {
              uploadedECard: {}
            }
          });
        },
        onSelect: () => {
          dispatch({
            type: 'SET_VIEW_NUMBER',
            payload: views.ECARD_MESSAGE_VIEW
          });
        },
        onUpload: (uploadedECard) => {
          dispatch({
            type: 'SET_UPLOADED_ECARD',
            payload: {
              uploadedECard,
              selectedECard: {}
            }
          });
          positionElement(`#${styles.callToActionDiv}`);
        },
        translateKeyFn,
        uploadedECard: state.uploadedECard
      });
      this.eCardTabSelect.render({
        selectedTab: state.uploadedECard.imagePreview ? 'Upload' : 'eCard',
        tabs: [{
          tabId: 'eCard',
          name: translateKeyFn('give-widget-use-ecard', 'Use an eCard')
        },
        {
          tabId: 'Upload',
          name: translateKeyFn('give-widget-use-photo', 'Use your photo')
        }],
        onSelect: this.tabSelectHandler.bind(this)
      });
    } else {
      const addImageDom = document.querySelector(`.${styles.subheading}`);
      if (addImageDom) {
        addImageDom.classList.add(styles.hidden);
      }
    }
  }

  render(dispatch, state, translateKeyFn) {
    const eCardSelectionViewFragment = document.createRange().createContextualFragment(eCardSelectionViewTemplate(translateKeyFn));
    const eCardSelectionViewTemp = this.node;
    this.node = getFirstElementChild(eCardSelectionViewFragment);
    eCardSelectionViewTemp.parentNode.replaceChild(this.node, eCardSelectionViewTemp);
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, translateKeyFn);
    this.node.classList.add('give-widget-current-view');
    positionElement(`#${styles.callToActionDiv}`);
    document.querySelector('.js-close').focus();
  }

  update(state) {
    this.eCardListContainer.eCardList = state.eCardList.options;
    this.eCardListContainer.filterCategories = state.eCardList.filters.categories;
    positionElement(`#${styles.callToActionDiv}`);
  }

  show() {
    this.node.classList.remove(styles.hidden);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
