import programDetailsViewTemplate, {
  modalHeaderSelector,
  programDetailsHeadingSelector,
  ccSelectedUsersPillBoxSelector,
  ccUserSearchSelector,
  ccUserSearchListSelector,
  programDetailsNextSelector,
  ccUserSearchHeadingSelector,
  ccUserSearchContainerSelector,
  ccUserSearchPlaceholderSelector,
  recipientsApproversAndPresentersSectionSelector,
  noteToApproverSelector,
  awardDescriptionSelector,
  errorMsgSelector,
  optionalWizardLinkSelector,
  optionalWizardLinkTemplate,
  termsOfServiceSelector
} from './program_details_view_template';
import specialInstructionTemplate, { errorMsgTemplate } from '../../components/common_templates/common_templates';
import * as views from '../views-list';
import {
  getPrograms,
  getWizard,
  getAwardLevels,
  getApprovers,
  queryUsers,
  canApprove,
  canPresent
} from '../../state/services/api';
import emptyElement, {
  getFirstElementChild,
  isObject,
  removeAllHtml,
  removeScriptTags,
  preferOrFirstLastName,
  positionElement
} from '../../utils/utils';
import Button from '../../components/button/button';
import QuestionAnswers from '../../components/question_answers/question_answers';
import PillBox from '../../components/pillbox/pillbox';
import List from '../../components/list/list';
import AutoComplete from '../../components/autocomplete/autocomplete';
import Textbox from '../../components/textbox/textbox';
import Checkbox from '../../components/checkbox/checkbox';
import ModalHeader from '../../components/modal_header/modal_header';
import RecipientHeader from '../../components/recipient_header/recipient_header';
import ApproverPresenterSection from '../../components/approver-presenter-section/approver-presenter-section';
import styles from './program_details_view.css';
import { transformUserSearchResults } from '../../state/reducers/root_reducer';
import { clientPrimaryBackground } from '../../css/client_colors.js';

export default class ProgramDetailsView {
  modalHeader;
  customizeAwardHeader;
  termsOfServiceCheckbox;
  awardLevelDescriptionSection;
  pickAProgramQAComp;
  optionalWizardLink;
  pickAwardQAComp;
  pickCoreCompetencyQAComp;
  ccUserSearch;
  ccUsersPillboxListComp;
  ccUserSearchAutoCompleteComp;
  ccUserSearchListComp;
  nextButtonComp;
  editUserButtonComponent;
  approverPresenterSectionComp;
  selectedCCUsers = [];

  static shouldCallWizardAPI = (
    { termsOfServiceChecked: { type: tosCheckedType } = {}, selectedProgramNomination: { type: selectedProgramNominationType } = {} } = {},
    {
      giveOptions = {}, termsOfServiceChecked, wizardFlowFinished, editRecipient, selectedProgramNomination = {}
    } = {}
  ) =>
    !!(
      (!giveOptions.termsOfService || (giveOptions.termsOfService && termsOfServiceChecked)) &&
      (editRecipient || !wizardFlowFinished) &&
      selectedProgramNomination &&
      selectedProgramNomination.value &&
      (tosCheckedType === 'updated' || selectedProgramNominationType === 'updated')
    );

  static programListChanged = ({ programList: { type = '', data } = {} } = {}) => type === 'updated' && Array.isArray(data);

  static errorMessageListChanged = ({ messages: { type = '', data } = {} } = {}) => type === 'updated' && Array.isArray(data);

  static selectedProgramChanged = ({ selectedProgramNomination: { type = '', data } = {} } = {}) => type === 'updated' && isObject(data);

  static awardListChanged = ({ awardList: { type = '', data } = {} } = {}) => type === 'updated' && Array.isArray(data);

  static wizardChanged = ({
    wizard: { type: wizardType = '' } = {},
    wizardOptional: { type: wizardOptionalType = '' } = {}
  } = {}) => wizardType === 'updated' || wizardOptionalType === 'updated'

  static selectedAwardLevelChanged = ({ selectedAwardLevel: { type = '', data } = {} } = {}) => type === 'updated' && data.value && data.value !== '';

  static corporateValueListChanged = ({ corporateValueList: { type = '', data } = {} } = {}) => type === 'updated' && isObject(data);

  static selectedCorporateValueChanged = ({ selectedCorporateValueNomination: { type = '', data } = {} } = {}) =>
    type === 'updated' && `${data.id}`.length > 0;

  static ccUsersChanged = ({ ccUsers: { type = '', data = {} } = {} } = {}) => type === 'updated' && Array.isArray(data.prePopulatedValues);

  static recipientsApproversPresentersChanged = ({
    approverList: { type: approverListType = '' } = {},
    presenterList: { type: presenterListType = '' } = {},
    recipients: { type: recipientsType = '' } = {}
  } = {}) => approverListType === 'updated' || presenterListType === 'updated' || recipientsType === 'updated';

  static noteToApproverChanged = ({
    noteToApprover: { type: noteToApproverType = '' } = {}
  } = {}) => noteToApproverType === 'updated';

  static isApproversPresenterListValid = (list) => list.reduce((acc, { 'selection-type': selectionType, value, eligible = false }) => {
    if (acc === false) {
      return false;
    } else if (selectionType === 'search' && eligible === false) {
      return false;
    } else if (selectionType === 'select' && (value === '' || value === undefined || value === null)) {
      return false;
    }
    return acc;
  }, true);

  static shouldNextButtonEnabled = (state) =>
    (state.selectedProgramNomination.value && state.selectedProgramNomination.value !== '') &&
    (state.selectedAwardLevel.value && state.selectedAwardLevel.value !== '') &&
    (
      (
        state.corporateValueList.required &&
        state.selectedCorporateValueNomination.value !== '' &&
        state.selectedCorporateValueNomination.value !== undefined
      ) ||
      (state.corporateValueList.required === false || state.corporateValueList === '')
    ) &&
    (
      (state.noteToApprover.required && state.noteToApproverText.trim() !== '') ||
      (state.noteToApprover.required === false)
    ) &&
    (
      ProgramDetailsView.isApproversPresenterListValid(state.approverList) &&
      ProgramDetailsView.isApproversPresenterListValid(state.presenterList)
    );

  static getPrePopulatedCCUsers = ({ ccUsers: { prePopulatedValues = [], editable } = {} } = {}) =>
    prePopulatedValues.map(({ name = '', value = '' }) => ({
      text: name,
      id: value,
      isRemovable: editable
    }));

  static transformIdToSystemUserId = (collection = []) => collection.map(({ id: systemUserId = '' }) => ({ systemUserId }));

  static checkWizardResponse(state, dispatch, res) {
    const { wizard } = res;
    if (wizard) {
      const { input: { question = {}, answerId: { options: answers = [], required = false } } } = wizard;
      if (required === false) {
        // The wizard is optional
        dispatch({
          type: 'SET_WIZARD_AND_WIZARD_OPTIONAL',
          payload: {
            wizard: [{ question, answers, selectedAnswer: '' }],
            wizardOptional: true
          }
        });
        return getAwardLevels(dispatch, state).then((awardsInfo) => ProgramDetailsView.checkSelectedAwardLevel(state, dispatch, awardsInfo));
      }
      // The wizard is mandatory
      if (state.wizard && state.wizard.length > 0 && state.editRecipient === true) {
        dispatch({
          type: 'SET_WIZARD_AND_VIEW_NUMBER',
          payload: {
            wizard: state.wizard,
            viewNumber: views.NOMINATION_WIZARD_VIEW,
            nomineesUpdated: false,
            wizardOptional: false
          }
        });
      } else {
        dispatch({
          type: 'SET_WIZARD_AND_VIEW_NUMBER',
          payload: {
            wizard: [{ question, answers, selectedAnswer: '' }],
            viewNumber: views.NOMINATION_WIZARD_VIEW,
            nomineesUpdated: false,
            wizardOptional: false
          }
        });
      }
      return res;
    }
    return getAwardLevels(dispatch, state).then((awardsInfo) => ProgramDetailsView.checkSelectedAwardLevel(state, dispatch, awardsInfo));
  }

  static checkSelectedProgram(state, dispatch, programsList) {
    if (state.selectedProgramNomination && state.selectedProgramNomination.value) {
      const selected = programsList.filter((program) => program.value === state.selectedProgramNomination.value);

      if (selected.length === 0) {
        dispatch({
          type: 'SET_SELECTED_CORPORATE_VALUE_NOMINATION',
          payload: {}
        });
        dispatch({
          type: 'SET_AWARD_LEVEL',
          payload: {}
        });
        dispatch({
          type: 'SET_SELECTED_PROGRAM_NOMINATION',
          payload: {}
        });
      }
    }
  }

  static checkSelectedAwardLevel(state, dispatch, awardsInfo) {
    if (state.selectedAwardLevel && state.selectedAwardLevel.value) {
      const { awardLevelId: { options: newAwardsList } } = awardsInfo;
      const selected = newAwardsList.filter((awardLevel) => awardLevel.value === state.selectedAwardLevel.value);

      if (selected.length === 0) {
        dispatch({
          type: 'SET_SELECTED_CORPORATE_VALUE_NOMINATION',
          payload: {}
        });
        dispatch({
          type: 'SET_AWARD_LEVEL',
          payload: {}
        });
      }
    }
  }

  static checkSelectedCorporateValue(state, dispatch, data) {
    if (state.selectedCorporateValueNomination && state.selectedCorporateValueNomination.value) {
      const { input: { corporateValueId: { options: corporateValueList } = {} } = {} } = data;
      let selected = [];
      if (Array.isArray(corporateValueList)) {
        selected = corporateValueList.filter((awardLevel) => awardLevel.value === state.selectedCorporateValueNomination.value);
      }

      if (selected.length === 0) {
        dispatch({
          type: 'SET_SELECTED_CORPORATE_VALUE_NOMINATION',
          payload: {}
        });
      }
    }
  }

  static shouldHidePickAwardQAComp(state) {
    return !state.selectedProgramNomination.value || state.awardList.length === 0;
  }

  static shouldHidePickCoreCompetencyQAComp(state) {
    return !state.selectedProgramNomination.value || !state.corporateValueList.options || state.corporateValueList.options.length === 0;
  }

  static coreCompetencyIsLoading(state) {
    return !state.selectedProgramNomination.value ||
      !state.selectedAwardLevel.value ||
      !state.corporateValueList.options ||
      state.corporateValueList.options.length === 0;
  }

  static shouldHideApproverPresenter(state) {
    return !(state.selectedProgramNomination.value && state.selectedAwardLevel.value && state.approverList && state.approverList.length > 0);
  }

  static editUserButtonComponentOnClick = (dispatch) => () => {
    dispatch({
      type: 'SET_VIEW_NUMBER',
      payload: views.USER_SEARCH_VIEW
    });
  };

  static termsOfServiceCheckboxOnChange = (dispatch) => (evt) => {
    dispatch({ type: 'SET_TOS', payload: { termsOfServiceChecked: evt } });
  };

  static pickAProgramQACompOnChange = (dispatch) => (evt) => {
    dispatch({
      type: 'SET_SELECTED_PROGRAM_NOMINATION',
      payload: {
        selectedProgramNomination: { value: evt.target.value, text: evt.target.parentElement.textContent },
        wizard: [],
        recommendedAwardLevel: [],
        wizardFlowFinished: false,
        eCardRecommended: false
      }
    });
  };

  static pickAwardQACompOnChange = (dispatch) => (evt) => {
    dispatch({
      type: 'SET_AWARD_LEVEL',
      payload: {
        value: evt.target.value,
        text: evt.target.parentElement.textContent
      }
    });
  };

  static pickCoreCompetencyQACompOnChange = (dispatch) => (evt) => {
    dispatch({
      type: 'SET_SELECTED_CORPORATE_VALUE_NOMINATION',
      payload: { value: evt.target.value, text: evt.target.parentElement.textContent }
    });
  };

  static transformApproverPresenterList = (list = [], isApprover, translateKeyFn) => list.map(({
    initials = '?',
    'selection-type': type = 'provided',
    imageThumbUrl: imageSrc = '',
    imageSrc: selectedImgSrc = '',
    options = [],
    firstName = '',
    preferName = '',
    lastName = '',
    value = '',
    eligible = true
  }) => {
    const placeholderText = isApprover
      ? translateKeyFn('give-widget-select-approver', 'Select an Approver')
      : translateKeyFn('give-widget-select-presenter', 'Select a Presenter');
    const image = !imageSrc ? selectedImgSrc : imageSrc;
    const selectOptions = [{ text: placeholderText, value: '' }].concat(options);
    let name = '';
    if (!firstName && !preferName && !lastName) {
      name = placeholderText;
    } else {
      name = preferOrFirstLastName({ preferName, firstName, lastName });
    }
    return {
      initials,
      fullName: name,
      imageSrc: image,
      eligible,
      selection: {
        type,
        options: selectOptions,
        placeholder: placeholderText,
        value
      }
    };
  });

  static approverPresenterSectionCompOnChange = (
    list = [],
    dispatch,
    actionName,
    recognizerId,
    disallowRecognizer,
    selectedAwardLevelValue,
    selectedProgramNominationValue,
    recipients
  ) =>
    (index, {
      systemUserId = '', fullName = '', preferName = '', firstName = '', lastName = '', imageSrc = '', initials = '', text = '', value
    } = {}) => {
      let permissionsPromise;
      const selectionType = list[index]['selection-type'];
      const beginning = list.slice(0, index);
      const end = list.slice(index + 1);

      if (selectionType === 'search') {
        if (recipients[index].systemUserId === systemUserId && (disallowRecognizer)) {
          permissionsPromise = Promise.resolve({ eligible: false });
        } else if (actionName === 'SET_APPROVER_LIST') {
          permissionsPromise = canApprove(systemUserId, selectedProgramNominationValue, selectedAwardLevelValue);
        } else if (actionName === 'SET_PRESENTER_LIST') {
          permissionsPromise = canPresent(systemUserId, selectedProgramNominationValue, selectedAwardLevelValue);
        }

        permissionsPromise.then((res) => {
          dispatch({
            type: actionName,
            payload: beginning.concat({
              ...list[index],
              ...res,
              fullName,
              preferName,
              firstName,
              lastName,
              imageSrc,
              value: systemUserId,
              initials
            }, end)
          });
        });
      } else if (selectionType === 'select') {
        dispatch({
          type: actionName,
          payload: beginning.concat({
            ...list[index],
            fullName: text,
            firstName,
            preferName,
            lastName,
            value,
            placeholder: text,
            initials
          }, end)
        });
      }
    };

  ccUsersPillboxListCompOnRemovePillBoxItem = (removedItem) => {
    const index = this.selectedCCUsers.reduce((acc, ccUser, idx) => {
      if (acc === -1 && ccUser.id === removedItem.systemUserId) return idx;
      return acc;
    }, -1);

    if (index !== -1) {
      this.selectedCCUsers.splice(index, 1);
    } else {
      // prePopulatedUsers are not included in the selectedUsers. So, in order to remove prepopulated users added the block.
      const prePopulatedindex = this.prePopulatedCCUsers.reduce((acc, ccUser, idx) => {
        if (acc === -1 && ccUser.id === removedItem.systemUserId) return idx;
        return acc;
      }, -1);
      if (prePopulatedindex !== -1) {
        this.prePopulatedCCUsers.splice(prePopulatedindex, 1);
      }
    }
    this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);

    positionElement(`#${styles.callToActionDiv}`);
  };

  ccUserSearchListCompOnEscapeCallback = () => {
    this.ccUserSearchAutoCompleteComp.focus();
  };

  ccUserSearchListCompOnSelectCallback = ({
    preferName = '', firstName = '', lastName, systemUserId
  }) => {
    this.selectedCCUsers = this.selectedCCUsers.concat([
      {
        text: preferOrFirstLastName({ preferName, firstName, lastName }),
        id: systemUserId,
        isRemovable: true
      }
    ]);
    this.ccUserSearchListComp.isOpen = false;
    this.ccUserSearchListComp.data = [];
    this.ccUserSearchListComp.noDataMessage = '';
    this.ccUserSearchAutoCompleteComp.focus();
    this.ccUserSearchAutoCompleteComp.clear();
    this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);
    this.ccUsersPillboxListComp.data = this.allCCUsers;
    positionElement(`#${styles.callToActionDiv}`);
  };

  ccUserSearchAutoCompleteCompMinCharactersCallback = (translateKeyFn) => () => {
    this.ccUserSearchListComp.noDataMessage = translateKeyFn('minimum-characters', 'Please enter at least three (3) characters to search.');
    this.ccUserSearchListComp.messageError = false;
    this.ccUserSearchListComp.data = [];
  };

  ccUserSearchAutoCompleteCompSource = (searchTerm) => {
    this.hasValueCleared = false;
    this.ccUserSearchListComp.isLoading = true;
    return queryUsers(searchTerm);
  };

  ccUserSearchAutoCompleteCompCustomRenderer = (translateKeyFn) => (val, results) => {
    this.ccUserSearchListComp.isOpen = true;
    this.ccUserSearchListComp.isLoading = false;
    this.ccUserSearchListComp.data = results;
    this.ccUserSearchListComp.unSelectableItems = ProgramDetailsView.transformIdToSystemUserId(this.allCCUsers);
    if (results.length > 0) {
      this.ccUserSearchListComp.noDataMessage = '';
    } else {
      this.ccUserSearchListComp.noDataMessage =
        `${translateKeyFn('give-widget-no-results-found-label', `No one found matching ${val}`, '{val}', `${val}`)}`;
      this.ccUserSearchListComp.messageError = true;
    }
  };

  ccUserSearchAutoCompleteCompValueCleared = () => {
    this.ccUserSearchListComp.noDataMessage = '';
    this.ccUserSearchListComp.isOpen = false;
    this.ccUserSearchListComp.data = [];
    if (this.hasValueCleared) this.ccUsersPillboxListComp.pop();
    this.hasValueCleared = true;
  };

  static noteToApproverCompOnChange = (dispatch) => (text) => {
    dispatch({
      type: 'SET_NOTE_TO_APPROVER',
      payload: removeAllHtml(text)
    });
  };

  nextButtonCompOnChange = (dispatch) => () => {
    dispatch({
      type: 'SET_VIEW_NUMBER_AND_SELECTED_CC_USERS',
      payload: {
        selectedCCUsers: this.allCCUsers,
        viewNumber: views.DESCRIPTION_VIEW,
        nomineesUpdated: false
      }
    });
  };

  static wizardLinkOnClick = (dispatch, state) => () => {
    if (state.wizardFlowFinished) {
      dispatch({
        type: 'SET_WIZARD_AND_VIEW_NUMBER',
        payload: {
          wizard: state.wizard,
          viewNumber: views.NOMINATION_WIZARD_VIEW,
          nomineesUpdated: false
        }
      });
    } else {
      dispatch({
        type: 'SET_WIZARD_AND_VIEW_NUMBER',
        payload: {
          wizard: [{
            question: state.wizard[0].question,
            answers: state.wizard[0].answers,
            selectedAnswer: ''
          }],
          viewNumber: views.NOMINATION_WIZARD_VIEW,
          nomineesUpdated: false
        }
      });
    }
  };

  showHideOptionalWizardLink(dispatch, state, translateKeyFn) {
    emptyElement(this.optionalWizardLink);
    if (state.wizardOptional && state.wizard && state.wizard.length > 0) {
      const needHelpAwardLevel = translateKeyFn('need-help-award-level');
      const html = optionalWizardLinkTemplate(needHelpAwardLevel);
      const frag = document.createRange().createContextualFragment(html);
      this.optionalWizardLink.classList.remove(styles.hidden);
      this.optionalWizardLink.onclick = ProgramDetailsView.wizardLinkOnClick(dispatch, state);
      this.optionalWizardLink.appendChild(frag);
    } else {
      this.optionalWizardLink.classList.add(styles.hidden);
    }
  }

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to program_details_view Comp `);
    }
  }

  initializeAllSubComponents() {
    this.modalHeader = new ModalHeader(modalHeaderSelector);
    this.customizeAwardHeader = new RecipientHeader(programDetailsHeadingSelector);
    this.termsOfServiceCheckbox = new Checkbox(termsOfServiceSelector);
    this.errorMsg = this.node.querySelector(errorMsgSelector);
    this.awardLevelDescriptionSection = this.node.querySelector(awardDescriptionSelector);
    this.pickAProgramQAComp = new QuestionAnswers('#program-list');
    this.optionalWizardLink = this.node.querySelector(optionalWizardLinkSelector);
    this.pickAwardQAComp = new QuestionAnswers('#award-list');
    this.pickCoreCompetencyQAComp = new QuestionAnswers('#corporate-value-list');

    this.ccUserSearch = this.node.querySelector(ccUserSearchContainerSelector);
    this.ccUsersPillboxListComp = new PillBox(ccSelectedUsersPillBoxSelector);
    this.ccUserSearchAutoCompleteComp = new AutoComplete(ccUserSearchSelector);
    this.ccUserSearchListComp = new List(ccUserSearchListSelector);
    this.nextButtonComp = new Button(programDetailsNextSelector);
    this.noteToApproverComp = new Textbox(noteToApproverSelector);
    this.approverPresenterSectionComp = new ApproverPresenterSection(recipientsApproversAndPresentersSectionSelector);
  }

  renderAllSubComponents(dispatch, state, translateKeyFn, translateClientTextFn) {
    this.customizeAwardHeader.render({
      recipients: state.recipients,
      selectedRecognitionType: state.selectedRecognitionType
    }, dispatch, translateKeyFn);

    if (state.giveOptions.termsOfService) {
      translateClientTextFn(994923).then((res) => {
        this.termsOfServiceCheckbox.render({
          text: res,
          checked: state.termsOfServiceChecked,
          isToggleStyle: false,
          onChange: ProgramDetailsView.termsOfServiceCheckboxOnChange(dispatch),
          id: 'program-terms'
        });
      });
    }

    this.showHideOptionalWizardLink(dispatch, state, translateKeyFn);

    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.pickAProgramQAComp.render({
        question: `${translateKeyFn('give-widget-select-a-program-label', 'Select a Program:')}`,
        className: styles.questionAnswer,
        answers: state.programList,
        required: true,
        selectedValue: state.selectedProgramNomination.value,
        isPillStyle: true,
        isHidden: !(state.programList && state.programList.length > 1),
        isLoading: !state.wizardUsed,
        name: 'programList',
        onChange: ProgramDetailsView.pickAProgramQACompOnChange(dispatch),
        id: 'program-list'
      }, translateKeyFn);

      this.computeAwardLevelDescription(state, translateKeyFn);

      this.pickAwardQAComp.render({
        isHidden: ProgramDetailsView.shouldHidePickAwardQAComp(state),
        className: styles.questionAnswer,
        required: true,
        question: `${translateKeyFn('give-widget-select-nomination-level-label', 'Select Nomination Level:')}`,
        answers: state.awardList,
        selectedValue: state.selectedAwardLevel.value,
        isPillStyle: true,
        name: 'awardList',
        onChange: ProgramDetailsView.pickAwardQACompOnChange(dispatch),
        id: 'program-award'
      }, translateKeyFn);

      this.pickCoreCompetencyQAComp.render({
        isLoading: ProgramDetailsView.coreCompetencyIsLoading(state),
        isHidden: ProgramDetailsView.shouldHidePickCoreCompetencyQAComp(state),
        className: styles.questionAnswer,
        question: `${translateKeyFn('corporate-value-label', 'Select the Core Competency')}:`,
        required: state.corporateValueList.required,
        answers: state.corporateValueList.options,
        selectedValue: state.selectedCorporateValueNomination.value,
        isPillStyle: true,
        name: 'corporateValueList',
        onChange: ProgramDetailsView.pickCoreCompetencyQACompOnChange(dispatch),
        id: 'program-competency'
      }, translateKeyFn);
    }

    this.showHideCCUsers(state);
    this.prePopulatedCCUsers = ProgramDetailsView.getPrePopulatedCCUsers(state);
    this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);

    this.ccUsersPillboxListComp.render({
      onRemovePillBoxItem: this.ccUsersPillboxListCompOnRemovePillBoxItem,
      data: this.allCCUsers,
      id: 'program-users'
    }, translateKeyFn);

    this.ccUserSearchListComp.render({
      isOpen: false,
      isClosable: true,
      unSelectableItems: ProgramDetailsView.transformIdToSystemUserId(this.allCCUsers),
      unSelectableMessage: translateKeyFn('give-widget-user-already-selected', 'This person has already been added.'),
      onEscapeCallback: this.ccUserSearchListCompOnEscapeCallback,
      onSelectCallback: this.ccUserSearchListCompOnSelectCallback,
      translateKeyFn,
      id: 'program-user-search'
    });

    this.ccUserSearchAutoCompleteComp.render({
      isDisabled: !state.ccUsers.editable,
      minCharactersCallback: this.ccUserSearchAutoCompleteCompMinCharactersCallback(translateKeyFn),
      source: this.ccUserSearchAutoCompleteCompSource,
      transformResults: transformUserSearchResults,
      customRenderer: this.ccUserSearchAutoCompleteCompCustomRenderer(translateKeyFn),
      rendererId: `give-widget_${this.ccUserSearchListComp.id}`,
      handleKeyDown: this.ccUserSearchListComp.handleKeys,
      valueCleared: this.ccUserSearchAutoCompleteCompValueCleared,
      placeholder: state.ccUsers.editable ? translateKeyFn('give-widget-cc-placeholder', 'Type to add someone else') : '',
      id: 'program-auto-complete',
      labelledby: ccUserSearchHeadingSelector.slice(1)
    });

    this.noteToApproverComp.render({
      title: removeScriptTags(translateKeyFn('additional-notes-to-approver-label', 'Notes to Approvers')),
      maxLength: state.noteToApprover.max,
      required: state.noteToApprover.required,
      disabled: state.noteToApprover.disabled,
      isHidden: !state.selectedAwardLevel.value,
      text: state.noteToApproverText,
      toggleText: removeScriptTags(translateKeyFn('additional-notes-to-approver-label', 'Notes to Approvers')),
      onChange: ProgramDetailsView.noteToApproverCompOnChange(dispatch),
      id: 'program-note',
      ctaDiv: `#${styles.callToActionDiv}`
    }, translateKeyFn);

    const enabled = ProgramDetailsView.shouldNextButtonEnabled(state);
    this.nextButtonComp.render({
      title: translateKeyFn('next', 'Next'),
      isDisabled: !enabled,
      className: `${styles.nextButton} ${clientPrimaryBackground}`,
      onClick: this.nextButtonCompOnChange(dispatch),
      id: 'program-next'
    });

    this.approverPresenterSectionComp.render({
      data: {
        recipients: state.recipients,
        approvers: ProgramDetailsView.transformApproverPresenterList(
          state.approverList,
          true,
          translateKeyFn
        ),
        presenters: ProgramDetailsView.transformApproverPresenterList(
          state.presenterList,
          false,
          translateKeyFn
        )
      },
      isLoading: false,
      isHidden: ProgramDetailsView.shouldHideApproverPresenter(state),
      approverOnChange: ProgramDetailsView.approverPresenterSectionCompOnChange(
        state.approverList,
        dispatch,
        'SET_APPROVER_LIST',
        state.recognizerId,
        true,
        state.selectedAwardLevel.value,
        state.selectedProgramNomination.value,
        state.recipients
      ),
      presenterOnChange: ProgramDetailsView.approverPresenterSectionCompOnChange(
        state.presenterList,
        dispatch,
        'SET_PRESENTER_LIST',
        state.recognizerId,
        false,
        state.selectedAwardLevel.value,
        state.selectedProgramNomination.value,
        state.recipients
      ),
      heading: translateKeyFn('give-widget-approval-path-label', 'Approval Path:'),
      translateKeyFn
    });
  }

  render(dispatch, state, stateDiff, translateKeyFn, translateClientTextFn) {
    const programViewFragment = document.createRange().createContextualFragment(programDetailsViewTemplate(translateKeyFn));
    const programViewTemp = this.node;
    this.node = getFirstElementChild(programViewFragment);
    programViewTemp.parentNode.replaceChild(this.node, programViewTemp);
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, translateKeyFn, translateClientTextFn);
    if (!state.wizardUsed) {
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        getPrograms(dispatch, state, translateKeyFn).then((programList) => ProgramDetailsView.checkSelectedProgram(state, dispatch, programList));
      }
    }
    this.currentlySelectedCCUsers = [];

    if (
      (state.selectedAwardLevel && state.selectedAwardLevel.value && (state.wizardUsed || state.nomineesUpdated)) ||
      state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW
    ) {
      this.setUpGetApprovers(state, dispatch, translateKeyFn);
    }
    positionElement(`#${styles.callToActionDiv}`);
    this.node.classList.add('give-widget-current-view');
    document.querySelector('.js-close').focus();
  }

  update(dispatch, state, stateDiff, translateKeyFn) {
    if (this.errorMsg) {
      this.errorMsg.classList.add(styles.hidden);
    }
    if (ProgramDetailsView.shouldCallWizardAPI(stateDiff, state)) {
      // Making sync call for getwizard for Nomination Special Instructions popup not to display
      getWizard(state, dispatch)
        .then((res) => {
          if (typeof res !== 'undefined') {
            if (ProgramDetailsView.selectedProgramChanged(stateDiff)) {
              this.pickAProgramQAComp.selectedValue = state.selectedProgramNomination.value;
              this.computeAwardLevelDescription(state, translateKeyFn);
              this.pickAwardQAComp.isHidden = ProgramDetailsView.shouldHidePickAwardQAComp(state);
              if (!state.selectedAwardLevel.value) {
                if (this.pickAwardQAComp.isHidden === false) {
                  this.pickAwardQAComp.isLoading = true;
                }
                if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
                  this.pickCoreCompetencyQAComp.isHidden = true;
                }
                this.ccUserSearch.classList.add(styles.hidden);
                this.approverPresenterSectionComp.isHidden = true;
                this.noteToApproverComp.isHidden = true;
                dispatch({
                  type: 'SET_CC_USERS',
                  payload: {}
                });
              }
            }
            return ProgramDetailsView.checkWizardResponse(state, dispatch, res);
          }
          return '';
        });
    }

    if (ProgramDetailsView.errorMessageListChanged(stateDiff)) {
      const { message, onAction, actionLabel } = state.messages[state.messages.length - 1];
      this.errorMsg.innerHTML = errorMsgTemplate(message, actionLabel);
      this.errorMsg.classList.remove(styles.hidden);
      const errorActionButton = this.errorMsg.querySelector('button');
      if (errorActionButton) {
        errorActionButton.onclick = onAction;
      }
      this.approverPresenterSectionComp.isHidden = true;
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        this.pickCoreCompetencyQAComp.isHidden = true;
      }
      this.ccUserSearch.classList.add(styles.hidden);
      this.noteToApproverComp.isHidden = true;
    }

    if (ProgramDetailsView.programListChanged(stateDiff)) {
      this.pickAProgramQAComp.isLoading = false;
      this.pickAProgramQAComp.answers = state.programList;
      this.pickAProgramQAComp.isHidden = state.programList.length <= 1;
      this.pickAProgramQAComp.selectedValue = state.selectedProgramNomination.value;
    }

    if (ProgramDetailsView.wizardChanged(stateDiff)) {
      this.showHideOptionalWizardLink(dispatch, state, translateKeyFn);
    }

    if (ProgramDetailsView.awardListChanged(stateDiff)) {
      this.pickAwardQAComp.isHidden = ProgramDetailsView.shouldHidePickAwardQAComp(state);
      this.pickAwardQAComp.isLoading = false;
      this.pickAwardQAComp.question = `${translateKeyFn('award-level-label', 'Pick Nomination Level')}:`;
      this.pickAwardQAComp.name = 'awardList';
      this.pickAwardQAComp.answers = state.awardList;
      this.pickAwardQAComp.required = true;
      if (state.selectedAwardLevel.value) {
        this.pickAwardQAComp.selectedValue = state.selectedAwardLevel.value;
      }
    }

    if (ProgramDetailsView.selectedAwardLevelChanged(stateDiff)) {
      this.pickAwardQAComp.selectedValue = state.selectedAwardLevel.value;
      this.setUpGetApprovers(state, dispatch, translateKeyFn);
    }

    if (ProgramDetailsView.corporateValueListChanged(stateDiff)) {
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        this.pickCoreCompetencyQAComp.isLoading = false;
        this.pickCoreCompetencyQAComp.isHidden = !state.corporateValueList.options || state.corporateValueList.options.length === 0;
        this.pickCoreCompetencyQAComp.answers = state.corporateValueList.options;
        this.pickCoreCompetencyQAComp.required = state.corporateValueList.required;
        this.pickCoreCompetencyQAComp.selectedValue = state.selectedCorporateValueNomination.value;
      }
      this.noteToApproverComp.isHidden = state.noteToApprover.disabled;
    }

    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && ProgramDetailsView.selectedCorporateValueChanged(stateDiff)) {
      this.pickCoreCompetencyQAComp.selectedValue = state.selectedCorporateValueNomination.value;
    }

    if (ProgramDetailsView.ccUsersChanged(stateDiff)) {
      this.showHideCCUsers(state);
      this.node.querySelector(ccUserSearchPlaceholderSelector).classList.remove(styles.loadingShimmer);
      this.prePopulatedCCUsers = ProgramDetailsView.getPrePopulatedCCUsers(state);
      this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);
      this.ccUsersPillboxListComp.data = this.allCCUsers;
      this.ccUserSearchListComp.unSelectableItems = ProgramDetailsView.transformIdToSystemUserId(this.allCCUsers);
      this.ccUserSearchAutoCompleteComp.isDisabled = !state.ccUsers.editable;
      if (state.ccUsers.editable) {
        const placeholder = translateKeyFn('give-widget-cc-placeholder', 'Type to add someone else') || 'Type to add someone else';
        this.ccUserSearchAutoCompleteComp.placeholder = placeholder;
      }
    }

    if (ProgramDetailsView.recipientsApproversPresentersChanged(stateDiff)) {
      this.approverPresenterSectionComp.isLoading = false;
      this.approverPresenterSectionComp.approverOnChange = ProgramDetailsView.approverPresenterSectionCompOnChange(
        state.approverList,
        dispatch,
        'SET_APPROVER_LIST',
        state.recognizerId,
        true,
        state.selectedAwardLevel.value,
        state.selectedProgramNomination.value,
        state.recipients
      );
      this.approverPresenterSectionComp.presenterOnChange = ProgramDetailsView.approverPresenterSectionCompOnChange(
        state.presenterList,
        dispatch,
        'SET_PRESENTER_LIST',
        state.recognizerId,
        false,
        state.selectedAwardLevel.value,
        state.selectedProgramNomination.value,
        state.recipients
      );
      this.approverPresenterSectionComp.data = {
        recipients: state.recipients,
        approvers: ProgramDetailsView.transformApproverPresenterList(
          state.approverList,
          true,
          translateKeyFn
        ),
        presenters: ProgramDetailsView.transformApproverPresenterList(
          state.presenterList,
          false,
          translateKeyFn
        )
      };
      this.approverPresenterSectionComp.isHidden = false;
    }

    if (ProgramDetailsView.noteToApproverChanged(stateDiff)) {
      this.noteToApproverComp.isLoading = false;
      this.noteToApproverComp.disabled = state.noteToApprover.disabled;
      this.noteToApproverComp.maxLength = state.noteToApprover.max;
      this.noteToApproverComp.text = state.noteToApproverText;
      this.noteToApproverComp.isHidden = state.noteToApprover.disabled;
      this.noteToApproverComp.required = state.noteToApprover.required || state.noteToApproverText;
    }

    if (ProgramDetailsView.shouldNextButtonEnabled(state)) {
      this.nextButtonComp.isDisabled = false;
    } else {
      this.nextButtonComp.isDisabled = true;
    }

    positionElement(`#${styles.callToActionDiv}`);
  }

  setUpGetApprovers(state, dispatch, translateKeyFn) {
    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.pickCoreCompetencyQAComp.isHidden = ProgramDetailsView.shouldHidePickCoreCompetencyQAComp(state);
      this.pickCoreCompetencyQAComp.isLoading = true;
    }
    this.approverPresenterSectionComp.isHidden = false;
    this.approverPresenterSectionComp.isLoading = true;
    this.ccUserSearch.classList.add(styles.hidden);
    this.showHideCCUsers(state);
    this.node.querySelector(ccUserSearchPlaceholderSelector).classList.add(styles.loadingShimmer);
    this.approverPresenterSectionComp.isLoading = true;
    // Setting required to true so we don't display (Optional) text as it's loading
    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.pickCoreCompetencyQAComp.required = true;
    }
    this.noteToApproverComp.isLoading = true;
    this.noteToApproverComp.isHidden = this.noteToApproverComp.isLoading || !state.noteToApprover || state.noteToApprover.disabled;
    getApprovers(dispatch, state).then((data) => ProgramDetailsView.checkSelectedCorporateValue(state, dispatch, data)).catch(() => {
      // html radio buttons cannot be unchecked, so re-render award levels to get DOM back to the unselected state
      // eslint-disable-next-line no-self-assign
      this.pickAwardQAComp.answers = this.pickAwardQAComp.answers;
      dispatch({
        type: 'ADD_APP_MESSAGE',
        payload: translateKeyFn(
          'give-widget-error-loading-information',
          'Sorry, we couldn\'t load the information needed. Try selecting the program again.'
        )
      });
    });
  }

  computeAwardLevelDescription(state, translateKeyFn) {
    if (state.selectedProgramNomination.value && state.selectedProgramNomination.value !== '') {
      const headingTranslated = translateKeyFn(`${state.selectedProgramNomination.value}/nomination-special-instructions-title`);
      const bodyTranslated = translateKeyFn(`${state.selectedProgramNomination.value}/nomination-special-instructions-body`);
      if (headingTranslated || bodyTranslated) {
        const frag = document.createRange().createContextualFragment(specialInstructionTemplate(headingTranslated, bodyTranslated));
        const temp = getFirstElementChild(frag);
        this.awardLevelDescriptionSection.parentNode.replaceChild(temp, this.awardLevelDescriptionSection);
        this.awardLevelDescriptionSection = temp;
      } else {
        this.awardLevelDescriptionSection.innerHTML = '';
      }
    }
  }

  showHideCCUsers(state) {
    if (state.selectedProgramNomination.value && state.ccUsers.used) {
      this.ccUserSearch.classList.remove(styles.hidden);
    } else {
      this.selectedCCUsers = [];
      this.ccUserSearch.classList.add(styles.hidden);
    }
  }

  show() {
    this.node.classList.remove(styles.hidden);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
