import styles from './common_templates.css';

const specialInstructionTemplate = (specialInstructionsHeading, specialInstructionsBody) =>
  `<article class="${styles.specialInstructions}">
    <header>
      <h2 class="${styles.subheading} ${specialInstructionsHeading ? '' : styles.hidden}">${specialInstructionsHeading}</h2>
    </header>
    ${specialInstructionsBody}
  </article>`;

export const errorMsgTemplate = (errorMsg, actionText) =>
  `<div class="${styles.errorMsg}">
    ${errorMsg}
    ${actionText ? `<button type="button" class="${styles.errorButton}">
      ${actionText}
    </button>` : ''}
  </div>
`;

export default specialInstructionTemplate;
