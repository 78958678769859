import styles from './approver-presenter-section.css';

const approverPlaceholderId = 'approverPlaceholder';
export const approverPlaceholderSelector = '[id^="approverPlaceholder"]';

const presenterPlaceholderId = 'presenterPlaceHolder';
export const presenterPlaceholderSelector = '[id^="presenterPlaceHolder"]';

const approverPresenterSectionHeadingId = 'approverPresenterSectionHeading';
export const approverPresenterSectionHeadingSelector = '#approverPresenterSectionHeading';

const approverPresenterSectionListId = 'approverPresenterSectionList';
export const approverPresenterSectionListSelector = '#approverPresenterSectionList';

const errorMessagePlaceholderId = 'errorMessagePlaceholder';
export const errorMessagePlaceholderSelector = '[id^=errorMessagePlaceholder]';

export const approverPresenterSectionTemplateRowTemplate = (recipientName, index, translateKeyFn = (a, b) => b) => `
    <li class="${styles.item}">
      ${recipientName ? `<p class="fs-mask ${styles.recipientName}">
        ${translateKeyFn('give-widget-for-recipient-header', 'For {recipientName}', '{recipientName}', recipientName)}
      </p>` : ''}
      <div id="${approverPlaceholderId}_${index}"></div>
      <div id="${presenterPlaceholderId}_${index}"></div>
      <div id="${errorMessagePlaceholderId}_${index}" class="${styles.hidden} ${styles.errorMsg}">
        Error: Selected user is not eligible. Please edit.
      </div>
    </li>`;

export const approverPresenterSectionLoadingTemplate = () => `
    <ul class="${styles.approverPresenterSectionList}">
      ${[0].reduce(
    (acc) => `${acc}<li class="${styles.item}">
            <p class="${styles.loadingFor}"></p>
            <span class="${styles.loadingCol1}">
              <span class="${styles.loadingCircularImage}"></span>
              <span class="${styles.loadingHeading}"></span>
              <span class="${styles.loadingFullName}"></span>
            </span>
            <span class="${styles.loadingCol1}">
              <span class="${styles.loadingCircularImage}"></span>
              <span class="${styles.loadingHeading}"></span>
              <span class="${styles.loadingFullName}"></span>
            </span>
          </li>
        `,
    ''
  )}
    </ul>
`;

const approverPresenterSectionTemplate = () => `
  <article>
    <heading>
      <h2 id="${approverPresenterSectionHeadingId}" class="${styles.subheading}"></h2>
    </heading>
    <ul class="${styles.approverPresenterSectionList}" id="${approverPresenterSectionListId}">
    </ul>
  </article>`;

export default approverPresenterSectionTemplate;
