import React from 'react';
import ReactDOM from 'react-dom';
import PrismButton from '@octanner/prism-core/Button';
import bulkUploadTemplate, {
  cancelBulkUploadSelector,
  sendEcardSelector,
  bulkUploadFormSelector,
  bulkUploadFileInputSelector,
  bulkUploadErrorMessageSelector,
  auxTextNode1Selector,
  auxTextNode2Selector,
  bulkUploadRemoveFileSlector,
  bulkUploadFileLabelSelector
} from './bulk_upload_template';
import emptyElement, {
  getFirstElementChild,
  isObject, positionElement,
  setHeader
} from '../../utils/utils';
import ReactProviders from '../../utils/react_providers';
import Button from '../../components/button/button';
import styles from './bulk_upload_view.css';
import * as views from '../views-list';
import { clientPrimaryBorder } from '../../css/client_colors';
import * as api from '../../state/services/api';

export default class BulkUploadView {
  static stopDefaultBrowserFunctionality(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  static isElementNode = (node) => node instanceof window.HTMLElement && node.nodeType === 1;

  static filterElementNodes = (...nodes) => nodes.filter(BulkUploadView.isElementNode);

  static addClass = (className, ...nodes) => {
    nodes.forEach((node) => {
      node.classList.add(className);
      if (className === styles.hidden) {
        node.setAttribute('tabindex', '-1');
      }
    });
    return nodes;
  };

  static removeClass = (className, ...nodes) => {
    nodes.forEach((node) => {
      node.classList.remove(className);
      if (className === styles.hidden) {
        node.removeAttribute('tabindex');
      }
    });
    return nodes;
  };

  static setProperty = (key, value, ...nodes) => nodes.forEach((node) => Object.assign(node, { [key]: value })) || nodes;

  static prependTextNode = (text, ...nodes) =>
    nodes.forEach((node) => node.insertBefore(document.createTextNode(text), (node.childNodes[0] || null))) || nodes;

  static appendTextNode = (text, ...nodes) => nodes.forEach((node) => node.insertBefore(document.createTextNode(text), null)) || nodes;

  static removeAllTextNodes = (node) =>
    [].slice.call(node.childNodes).forEach((childNode) => (childNode.nodeType === 3 ? node.removeChild(childNode) : false));

  static removeTextNodes = (...nodes) => nodes.forEach(BulkUploadView.removeAllTextNodes) || nodes;

  static formAddHighlight = ({ currentTarget }) =>
    BulkUploadView.addClass(clientPrimaryBorder, ...BulkUploadView.filterElementNodes(currentTarget));

  static formRemoveHighlight = ({ currentTarget }) =>
    BulkUploadView.removeClass(clientPrimaryBorder, ...BulkUploadView.filterElementNodes(currentTarget));

  static showErrorMessage = (errorMessageNode) => BulkUploadView.removeClass(styles.hidden, ...BulkUploadView.filterElementNodes(errorMessageNode));

  static hideErrorMessage = (errorMessageNode) => BulkUploadView.addClass(styles.hidden, ...BulkUploadView.filterElementNodes(errorMessageNode));

  static addErrorMessage = (errorMessageNode, message = '') =>
    BulkUploadView.appendTextNode(message, ...BulkUploadView.filterElementNodes(errorMessageNode).map(emptyElement));

  static shouldUpdateRecognitionTypeButtons = ({ recognitionTypes: { type = '', data = {} } = {} } = {}) => type === 'updated' && isObject(data);

  static selectHasError = ({ recognitionTypes: { error = false } = {} } = {}) => error;

  static selectErrorMessage = ({ recognitionTypes: { message = '' } = {} } = {}) => message;

  static selectHasECard = ({ recognitionTypes: { hasECard = false } = {} } = {}) => hasECard;

  static addEventListener = (node, fn, ...eventNames) => {
    eventNames.map((eventName) => node.addEventListener(eventName, fn));
  }

  static pickErrorMessage = (status = 0, message, translateKeyFn) => {
    if (status === 400 && message.toUpperCase().indexOf('LAST NAME AND EMPLOYEE ID') > -1 &&
        message.toUpperCase().indexOf('FIRST NAME, LAST NAME AND EMAIL ADDRESS') > -1) {
      return translateKeyFn('error-bulk-missing-header');
    } else if (status === 400 && message.toUpperCase().indexOf('LAST NAME AND EMPLOYEE ID') > -1) {
      return translateKeyFn('error-bulk-missing-data');
    } else if (status === 400 && (message.toUpperCase().indexOf('FIRST NAME') > -1 ||
        message.toUpperCase().indexOf('LAST NAME AND EMAIL ADDRESS') > -1)) {
      return translateKeyFn('error-group-email-format');
    } else if (status === 413) {
      return translateKeyFn('error-bulk-file-too-large');
    }
    return translateKeyFn('error-bulk-invalid-file');
  }

  static triggerDisableButtonActions = (dispatch) => {
    dispatch({
      type: 'SET_RECOGNITION_TYPES',
      payload: { data: {}, status: { code: 200 } }
    });
    dispatch({
      type: 'SET_GROUP_DETAILS',
      payload: {}
    });
    dispatch({
      type: 'SET_PROGRAM_LIST',
      payload: []
    });
  }

  static gotoUserSearchView = (dispatch) => {
    dispatch({
      type: 'SET_VIEW_NUMBER',
      payload: views.USER_SEARCH_VIEW
    });
  }

  static gotoProgramDetailsView = (dispatch, selectedRecognitionType) => () => {
    dispatch({
      type: 'SET_GROUP_PROGRAM_DETAILS_VIEW',
      payload: {
        viewNumber: views.ECARD_DETAILS_VIEW,
        selectedRecognitionType
      }
    });
  }

  static validateDroppedFilesCount = (errorMessageNode, translateKeyFn, files = []) => {
    if (files.length > 1) {
      BulkUploadView.addErrorMessage(errorMessageNode, translateKeyFn('give-widget-upload-single-file-message', 'Please upload  only one file'));
      BulkUploadView.showErrorMessage(errorMessageNode);
      return [];
    } else if (files.length === 0) {
      return [];
    }
    BulkUploadView.hideErrorMessage(errorMessageNode);
    return files;
  }

  static uploadFiles =
    (customerId, recognizerId, files = []) =>
      Promise.all(files.map(api.bulkUploadFile(customerId, recognizerId)))

  static bulkFileUploadErrorHandler =
    (errorMessageNode, translateKeyFn) =>
      (errResponse) => {
        const { status } = errResponse;
        return errResponse.json().then(({ error: { message = '' } = {} } = {}) => {
          BulkUploadView.addErrorMessage(errorMessageNode, BulkUploadView.pickErrorMessage(status, message, translateKeyFn));
          BulkUploadView.addClass(styles.error, ...BulkUploadView.showErrorMessage(errorMessageNode));
          BulkUploadView.removeClass(styles.hidden, errorMessageNode);
          return [];
        });
      }

  static bulkFileUploadSuccessHandler =
    (errorMessageNode, labelNode, auxText1Node, auxText2Node, removeFileNode, bulkUploadFileInputNode, translateKeyFn, dispatch) =>
      ([{ count, fileName, fileId }]) => {
        BulkUploadView.addClass(styles.hidden, errorMessageNode, labelNode, auxText1Node, bulkUploadFileInputNode);
        BulkUploadView.removeClass(styles.hidden, removeFileNode, auxText2Node);
        BulkUploadView.removeTextNodes(removeFileNode, auxText2Node);
        BulkUploadView.prependTextNode(fileName, removeFileNode);
        BulkUploadView.prependTextNode(translateKeyFn('give-widget-bulk-upload-employee-count', `${count} employees`), auxText2Node);
        dispatch({
          type: 'SET_GROUP_DETAILS',
          payload: { fileId, count, fileName }
        });
        return [{ count, fileName, fileId }];
      }

  static getGroupProgramTypes = (filesData) =>
    Promise.all(filesData.map(({ fileId }) => api.fetchGroupTypes(fileId)))

  static getGroupProgramTypesSuccessHandler = (dispatch) => ([res]) => {
    if (res) {
      dispatch({
        type: 'SET_RECOGNITION_TYPES',
        payload: { data: res, status: { code: 200 } }
      });
    }
    return [res];
  }

  static getGroupProgramTypesErrorHandler = (dispatch) => (errResponse) => {
    const { status } = errResponse;
    return errResponse.json().then((res) => {
      dispatch({
        type: 'SET_RECOGNITION_TYPES',
        payload: { data: res, status: { code: status } }
      });
    });
  }

  static removeFile = (labelNode, auxText1Node, auxText2Node, removeFileNode, fileInputNode) => {
    BulkUploadView.addClass(styles.hidden, removeFileNode, auxText2Node);
    BulkUploadView.removeClass(styles.hidden, labelNode, auxText1Node, fileInputNode);
    BulkUploadView.setProperty('value', '', fileInputNode);
  }

  static cancelBulkUploadButton = (dispatch) => () => {
    BulkUploadView.triggerDisableButtonActions(dispatch);
    BulkUploadView.gotoUserSearchView(dispatch);
  }

  static removeFileOnClick = (
    dispatch,
    bulkUploadFileLabelNode,
    auxTextNode1,
    auxTextNode2,
    bulkUploadRemoveFileNode,
    bulkUploadFileInputNode
  ) => () => {
    BulkUploadView.triggerDisableButtonActions(dispatch);
    BulkUploadView.removeFile(bulkUploadFileLabelNode, auxTextNode1, auxTextNode2, bulkUploadRemoveFileNode, bulkUploadFileInputNode);
  }

  static bulkUploadFormOnDrop = (
    errorMessageNode,
    translateKeyFn,
    customerId,
    recognizerId,
    bulkFileUploadSuccessFn,
    bulkFileUploadErrorFn,
    getGroupProgramTypesSuccessFn,
    getGroupProgramTypesErrorFn
  ) => ({ dataTransfer: { files: droppedFiles = [] } = {}, target: { files: targetFiles = [] } = {} }) => {
    // we need to destructure to convert FileList to an array.
    let files = [].slice.call(droppedFiles).concat([].slice.call(targetFiles));
    files = BulkUploadView.validateDroppedFilesCount(errorMessageNode, translateKeyFn, files);
    BulkUploadView.uploadFiles(customerId, recognizerId, files)
      .then(bulkFileUploadSuccessFn, bulkFileUploadErrorFn)
      .then(BulkUploadView.getGroupProgramTypes)
      .then(getGroupProgramTypesSuccessFn, getGroupProgramTypesErrorFn);
  }

  renderNextButton = (state, translateKey, dispatch) => {
    const { recognitionTypes, groupDetails } = state;
    const isDisabled = !(recognitionTypes && recognitionTypes?.hasECard && groupDetails?.fileId);

    const reactEle = (
      <PrismButton
        classes={{
          root: 'oct-client-background oct-client-border'
        }}
        disabled={isDisabled}
        type="button"
        onClick={BulkUploadView.gotoProgramDetailsView(dispatch, 'ECard')}
      >
        {translateKey('next', 'Next')}
      </PrismButton>
    );
    ReactDOM.render(<ReactProviders>{reactEle}</ReactProviders>, document.querySelector(`#${styles.nextButton}`));
  };

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to Bulk Upload View `);
    }
  }

  initializeAllSubComponents() {
    this.cancelBulkUploadButton = new Button(cancelBulkUploadSelector);
    this.eCardButton = new Button(sendEcardSelector);
  }

  renderAllSubComponents(dispatch, state, stateDiff, translateKey) {
    this.cancelBulkUploadButton.render({
      title: translateKey('cancel', 'cancel'),
      className: styles.linkButton,
      isPrimary: false,
      onClick: BulkUploadView.cancelBulkUploadButton(dispatch)
    });
    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.eCardButton.render({
        title: translateKey('give-widget-send-ecard-label', 'Send eCard'),
        className: styles.sendEcardButton,
        onClick: BulkUploadView.gotoProgramDetailsView(dispatch, 'ECard'),
        isDisabled: !(state.recognitionTypes && state.recognitionTypes.hasECard && state.groupDetails.fileId)
      });
    } else {
      const buttonContainer = document.querySelector(`.${styles.buttonContainer}`);
      buttonContainer.parentElement.removeChild(buttonContainer);
    }
  }

  update(dispatch, state, stateDiff, translateKeyFn) {
    if (BulkUploadView.shouldUpdateRecognitionTypeButtons(stateDiff)) {
      const hasError = BulkUploadView.selectHasError(state);
      const errorMessageNode = this.node.querySelector(bulkUploadErrorMessageSelector);
      if (hasError) {
        BulkUploadView.addErrorMessage(errorMessageNode, BulkUploadView.selectErrorMessage(state));
        BulkUploadView.removeClass(styles.hidden, errorMessageNode);
      } else {
        BulkUploadView.addClass(styles.hidden, errorMessageNode);
      }
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
        BulkUploadView.setProperty('isLoading', false, this.eCardButton);
        BulkUploadView.setProperty('isDisabled', !BulkUploadView.selectHasECard(state), this.eCardButton);
      } else {
        this.renderNextButton(state, translateKeyFn, dispatch);
      }
    }
    positionElement(`#${styles.callToActionDiv}`);
  }

  render(dispatch, state, stateDiff, translateKeyFn, translations) {
    const language = translations.applicationLanguage.replace(/-/g, '_') || 'en_US';
    const groupHelpGuideLink = state.isCcMigratedCustomer ?
      'https://www.octanner.com/customer-support/guides/group-deposits.html' :
      `http://www.eawardcenter.com/demo/HelpGuide/${language}.pdf`;
    const bulkUploadTemplateFrag = document.createRange().createContextualFragment(bulkUploadTemplate(
      translateKeyFn,
      state.groupECardHelpShow,
      state.isCcMigratedCustomer,
      groupHelpGuideLink
    ));
    const temp = getFirstElementChild(bulkUploadTemplateFrag);
    this.node.parentNode.replaceChild(bulkUploadTemplateFrag, this.node);
    this.node = temp;
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, stateDiff, translateKeyFn);
    setHeader(translateKeyFn('give-widget-send-appreciation-label', 'Send Appreciation'));

    if (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW) {
      this.renderNextButton(state, translateKeyFn, dispatch);
    } else {
      const nextButton = document.querySelector(`#${styles.nextButton}`);
      nextButton.parentElement.removeChild(nextButton);
    }
    document.querySelector('.js-close').focus();

    const bulkUploadForm = this.node.querySelector(bulkUploadFormSelector);
    this.bulkUploadFileInputNode = this.node.querySelector(bulkUploadFileInputSelector);
    this.errorMessageNode = this.node.querySelector(bulkUploadErrorMessageSelector);
    this.bulkUploadFileLabelNode = this.node.querySelector(bulkUploadFileLabelSelector);
    this.auxTextNode1 = this.node.querySelector(auxTextNode1Selector);
    this.auxTextNode2 = this.node.querySelector(auxTextNode2Selector);
    this.bulkUploadRemoveFileNode = this.node.querySelector(bulkUploadRemoveFileSlector);

    const bulkFileUploadSuccessFn = BulkUploadView.bulkFileUploadSuccessHandler(
      this.errorMessageNode,
      this.bulkUploadFileLabelNode,
      this.auxTextNode1,
      this.auxTextNode2,
      this.bulkUploadRemoveFileNode,
      this.bulkUploadFileInputNode,
      translateKeyFn,
      dispatch
    );
    const bulkFileUploadErrorFn = BulkUploadView.bulkFileUploadErrorHandler(this.errorMessageNode, translateKeyFn);

    const getGroupProgramTypesSuccessFn = BulkUploadView.getGroupProgramTypesSuccessHandler(dispatch);
    const getGroupProgramTypesErrorFn = BulkUploadView.getGroupProgramTypesErrorHandler(dispatch);

    // Firefox ignores :focus css, so we need to add this class to show focused input element
    BulkUploadView.addEventListener(
      this.bulkUploadFileInputNode,
      () => this.bulkUploadFileInputNode.classList.add(styles.hasFocus),
      'focus'
    );

    BulkUploadView.addEventListener(
      this.bulkUploadFileInputNode,
      () => this.bulkUploadFileInputNode.classList.remove(styles.hasFocus),
      'blur'
    );

    BulkUploadView.addEventListener(
      this.bulkUploadFileInputNode,
      (evt) => {
        if (evt.keyCode === 13) {
          evt.preventDefault();
          this.bulkUploadFileInputNode.click();
        }
      },
      'keypress'
    );

    BulkUploadView.addEventListener(
      bulkUploadForm,
      BulkUploadView.stopDefaultBrowserFunctionality,
      'drag',
      'dragstart',
      'dragend',
      'dragover',
      'dragenter',
      'dragleave',
      'drop'
    );

    BulkUploadView.addEventListener(
      this.bulkUploadRemoveFileNode,
      BulkUploadView.removeFileOnClick(
        dispatch,
        this.bulkUploadFileLabelNode,
        this.auxTextNode1,
        this.auxTextNode2,
        this.bulkUploadRemoveFileNode,
        this.bulkUploadFileInputNode
      ),
      'click'
    );

    BulkUploadView.addEventListener(bulkUploadForm, BulkUploadView.formAddHighlight, 'dragover', 'dragenter');

    BulkUploadView.addEventListener(bulkUploadForm, BulkUploadView.formRemoveHighlight, 'dragleave', 'dragend', 'drop');

    BulkUploadView.addEventListener(
      bulkUploadForm,
      BulkUploadView.bulkUploadFormOnDrop(
        this.errorMessageNode,
        translateKeyFn,
        state.customerId,
        state.recognizerId,
        bulkFileUploadSuccessFn,
        bulkFileUploadErrorFn,
        getGroupProgramTypesSuccessFn,
        getGroupProgramTypesErrorFn
      ),
      'drop', 'change'
    );

    if (state.groupDetails && state.groupDetails.fileId) {
      BulkUploadView.addClass(styles.hidden, this.errorMessageNode, this.bulkUploadFileLabelNode, this.auxTextNode1);
      BulkUploadView.removeClass(styles.hidden, this.bulkUploadRemoveFileNode, this.auxTextNode2);
      BulkUploadView.removeTextNodes(this.bulkUploadRemoveFileNode, this.auxTextNode2);
      BulkUploadView.prependTextNode(state.groupDetails.fileName, this.bulkUploadRemoveFileNode);
      BulkUploadView.prependTextNode(translateKeyFn(
        'give-widget-bulk-upload-employee-count',
        `${state.groupDetails.count} employees`
      ), this.auxTextNode2);
    }
    BulkUploadView.addClass('give-widget-current-view', this.node);
    positionElement(`#${styles.callToActionDiv}`);
  }

  hide() {
    BulkUploadView.addClass(styles.hidden, this.node);
    BulkUploadView.removeClass('give-widget-current-view', this.node);
  }

  show() {
    BulkUploadView.removeClass(styles.hidden, this.node);
  }
}
