import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './loading_view.css';

const popupMessageTemplate = (popupMessage) => `<div>
  <div class="${styles.loadingSpinner} ${prismUiCss['psm-spinner--lg']}">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="${styles.popupMessage}">${popupMessage}</div>
</div>`;

export default popupMessageTemplate;
