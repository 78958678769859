import { getFirstElementChild } from '../../utils/utils';
import detailsSummaryTemplate from './details_summary_template';
import styles from './details_summary.css';
import * as views from '../../views/views-list';

export default class DetailsSummary {
  defaultProps = {
    selectedRecognitionType: '',
    selectedProgram: {},
    selectedCorporateValue: {},
    selectedAwardLevel: {},
    selectedECardAmount: {},
    eCardNotifyWhenViewedChecked: true
  };

  static goToDetails(props, dispatch) {
    if (props.selectedRecognitionType === 'ECard') {
      dispatch({
        type: 'SET_VIEW_NUMBER',
        payload: views.ECARD_DETAILS_VIEW
      });
    } else {
      dispatch({
        type: 'SET_VIEW_NUMBER_AND_WIZARD_USED',
        payload: {
          viewNumber: views.PROGRAM_DETAILS_VIEW,
          wizardUsed: false,
          selectedAwardLevel: props.selectedAwardLevel
        }
      });
    }
  }

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for DetailsSummary component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props = {}, dispatch, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    const template = detailsSummaryTemplate(this.props, translateKeyFn);
    const detailsSummaryFragment = document.createRange().createContextualFragment(template);
    const detailsSummaryTemp = this.node;
    this.node = getFirstElementChild(detailsSummaryFragment);
    detailsSummaryTemp.parentNode.replaceChild(this.node, detailsSummaryTemp);

    this.editButton = this.node.querySelector(`.${styles.editButton}`);
    this.editButton.addEventListener('click', DetailsSummary.goToDetails.bind(this, this.props, dispatch));
  }
}
