import eCardDetailsViewTemplate, {
  eCardDetailsHeadingSelector,
  ccSelectedUsersPillBoxSelector,
  ccUserSearchHeadingSelector,
  ccUserSearchSelector,
  ccUserSearchListSelector,
  eCardDetailsNextSelector,
  ccUserSearchPlaceholderSelector,
  ccUserSearchContainerSelector,
  specialInstructionsSelector,
  eCardAmountListSelector,
  personalNotificationSelector,
  corporateValueListSelector,
  noECardMsgSelector,
  programListSelector
} from './ecard_details_view_template';
import specialInstructionTemplate, { errorMsgTemplate } from '../../components/common_templates/common_templates';
import * as views from '../views-list';
import {
  getPrograms,
  getECardConfig,
  getECardList,
  queryUsers
} from '../../state/services/api';
import {
  getFirstElementChild,
  isObject,
  preferOrFirstLastName,
  positionElement,
  setHeader
} from '../../utils/utils';
import Button from '../../components/button/button';
import QuestionAnswers from '../../components/question_answers/question_answers';
import PillBox from '../../components/pillbox/pillbox';
import List from '../../components/list/list';
import AutoComplete from '../../components/autocomplete/autocomplete';
import Checkbox from '../../components/checkbox/checkbox';
import RecipientHeader from '../../components/recipient_header/recipient_header';
import { getHeaderText } from '../../components/modal_header/modal_header_template';
import styles from './ecard_details_view.css';
import { transformUserSearchResults } from '../../state/reducers/root_reducer';
import { clientPrimaryBackground } from '../../css/client_colors.js';

export default class ECardDetailsView {
  modalHeader;
  customizeAwardHeader;
  specialInstructionsSection;
  pickAProgramQAComp;
  pickECardAmountQAComp;
  pickCoreCompetencyQAComp;
  notifyWhenViewedComp;
  ccUserSearch;
  ccUsersPillboxListComp;
  ccUserSearchAutoCompleteComp;
  ccUserSearchListComp;
  ccUserSearchListContainer;
  nextButtonComp;
  selectedCCUsers = [];

  static programListChanged = ({ programList: { type = '', data } = {} } = {}) =>
    type === 'updated' && Array.isArray(data);

  static selectedProgramChanged = ({ selectedProgramECard: { type = '', data } = {} } = {}) =>
    type === 'updated' && isObject(data);

  static eCardListChanged = ({ eCardList: { type = '', data } = {} } = {}) =>
    type === 'updated' && isObject(data);

  static corporateValueListChanged = ({ corporateValueList: { type = '', data } = {} } = {}) =>
    type === 'updated' && isObject(data);

  static selectedCorporateValueChanged = ({ selectedCorporateValueECard: { type = '', data } = {} } = {}) =>
    type === 'updated' && data.value.length > 0;

  static eCardAmountListChanged = ({ eCardAmountList: { type = '', data } = {} } = {}) =>
    type === 'updated' && isObject(data);

  static selectedECardAmountChanged = ({ selectedECardAmount: { type = '', data } = {} } = {}) =>
    type === 'updated' && data.value !== '';

  static eCardNotifyWhenViewedChanged = ({
    eCardNotifyWhenViewed: { type = '', data } = {},
    eCardNotifyWhenViewedChecked: { type: checkedType = '', data: checkedData } = {}
  } = {}) =>
    (type === 'updated' && isObject(data)) || (checkedType === 'updated' && typeof checkedData === 'boolean');

  static eCardNotifyCCManagerWhenViewedChanged = ({
    eCardNotifyManagerWhenViewed: { type = '', data } = {},
    eCardNotifyManagerWhenViewedChecked: { type: checkedType = '', data: checkedData } = {}
  } = {}) =>
    (type === 'updated' && isObject(data)) || (checkedType === 'updated' && typeof checkedData === 'boolean');

  static ccUsersChanged = ({ ccUsers: { type = '', data = {} } = {} } = {}) =>
    type === 'updated' && Array.isArray(data.prePopulatedValues);

  static errorMessageListChanged = ({ messages: { type = '', data } = {} } = {}) =>
    type === 'updated' && Array.isArray(data);

  static getPrePopulatedCCUsers = ({ ccUsers: { prePopulatedValues = [], editable } = {} } = {}) =>
    prePopulatedValues.map(({ name = '', value = '' }) => ({
      text: name,
      id: value,
      isRemovable: editable
    }));

  static transformIdToSystemUserId = (collection = []) =>
    collection.map(({ id: systemUserId = '' }) => ({ systemUserId }));

  static shouldNextButtonEnabled = (state) => (
    !!state.selectedProgramECard.value &&
    (
      state.corporateValueList.required === false ||
      (state.corporateValueList.required && !!state.selectedCorporateValueECard.value)
    ) &&
    (
      state.eCardAmountList.required === false ||
      (state.eCardAmountList.required && !!state.selectedECardAmount.text)
    )
  );

  static isCorpValueListHidden(state) {
    return (
      !state.selectedProgramECard.value ||
      !state.corporateValueList.options ||
      state.corporateValueList.options.length === 0 ||
      state.corporateValueList.disabled
    );
  }

  static isECardAmountListHidden(state) {
    return (
      !state.selectedProgramECard.value ||
      !state.eCardAmountList.options ||
      state.eCardAmountList.options.length <= 1 ||
      state.eCardAmountList.disabled
    );
  }

  static getECardInfoForProgram(state, dispatch) {
    getECardConfig(
      dispatch,
      {
        recipients: state.recipients,
        recognizerId: state.recognizerId,
        selectedProgramECard: state.selectedProgramECard,
        groupDetails: state.groupDetails
      }
    );
    getECardList(
      dispatch,
      {
        recipients: state.recipients,
        recognizerId: state.recognizerId,
        selectedProgramECard: state.selectedProgramECard,
        groupDetails: state.groupDetails
      }
    );
  }

  static notifyWhenViewedCompOnChange = (hasGroupDetails, dispatch) => (isChecked) => {
    if (hasGroupDetails) {
      dispatch({
        type: 'SET_ECARD_NOTIFY_MANAGER_WHEN_VIEWED_CHECKED',
        payload: isChecked
      });
    } else {
      dispatch({
        type: 'SET_ECARD_NOTIFY_WHEN_VIEWED_CHECKED',
        payload: isChecked
      });
    }
  }

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to ecard_details_view comp `);
    }
  }

  initializeAllSubComps() {
    this.customizeAwardHeader = new RecipientHeader(eCardDetailsHeadingSelector);
    this.pickAProgramQAComp = new QuestionAnswers(programListSelector);
    this.specialInstructionsSection = this.node.querySelector(specialInstructionsSelector);
    this.pickCoreCompetencyQAComp = new QuestionAnswers(corporateValueListSelector);
    this.pickECardAmountQAComp = new QuestionAnswers(eCardAmountListSelector);
    this.notifyWhenViewedComp = new Checkbox(personalNotificationSelector);
    this.ccUserSearch = this.node.querySelector(ccUserSearchContainerSelector);
    this.ccUsersPillboxListComp = new PillBox(ccSelectedUsersPillBoxSelector);
    this.ccUserSearchAutoCompleteComp = new AutoComplete(ccUserSearchSelector);
    this.ccUserSearchListComp = new List(ccUserSearchListSelector);
    this.errorMsg = this.node.querySelector(noECardMsgSelector);
    this.nextButtonComp = new Button(eCardDetailsNextSelector);
  }

  renderAllSubComps(dispatch, state, translateKeyFn) {
    const isGroupECard = Object.keys(state.groupDetails).length > 0;
    const headerText = getHeaderText(state.selectedRecognitionType, translateKeyFn);
    setHeader(headerText);

    this.customizeAwardHeader.render({
      recipients: state.recipients,
      selectedRecognitionType: state.selectedRecognitionType,
      bulkUploadCount: state.groupDetails ? state.groupDetails.count : ''
    }, dispatch, translateKeyFn);

    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard)) {
      this.pickAProgramQAComp.render({
        question: `${translateKeyFn('give-widget-select-a-program-label', 'Select a Program:')}`,
        className: styles.questionAnswer,
        answers: state.programList && state.programList.length ? state.programList : [{}, {}, {}],
        required: true,
        selectedValue: state.selectedProgramECard.value,
        isPillStyle: true,
        name: 'programList',
        isLoading: true,
        onChange: (evt) => {
          dispatch({
            type: 'SET_SELECTED_PROGRAM_ECARD',
            payload: { value: evt.target.value, text: evt.target.parentElement.textContent }
          });
        },
        id: 'ecard-details-program'
      }, translateKeyFn);

      this.computeSpecialInstructions(state, translateKeyFn);

      this.pickCoreCompetencyQAComp.render({
        isHidden: ECardDetailsView.isCorpValueListHidden(state),
        className: styles.questionAnswer,
        question: `${translateKeyFn('corporate-value-label', 'Core Competency')}:`,
        required: state.corporateValueList.required,
        answers: state.corporateValueList.options,
        selectedValue: state.selectedCorporateValueECard.value,
        isPillStyle: true,
        name: 'corporateValueList',
        onChange: (evt) => {
          dispatch({
            type: 'SET_SELECTED_CORPORATE_VALUE_ECARD',
            payload: { value: evt.target.value, text: evt.target.parentElement.textContent }
          });
        },
        id: 'ecard-details-competency'
      }, translateKeyFn);

      this.pickECardAmountQAComp.render({
        isHidden: ECardDetailsView.isECardAmountListHidden(state),
        className: styles.questionAnswer,
        required: state.eCardAmountList.required,
        question: `${translateKeyFn('award-level-label', 'Award Level')}:`,
        answers: state.eCardAmountList.options,
        selectedValue: state.selectedECardAmount.value,
        isPillStyle: true,
        name: 'eCardAmountList',
        onChange: (evt) => {
          dispatch({
            type: 'SET_SELECTED_ECARD_AMOUNT',
            payload: {
              value: evt.target.value,
              text: evt.target.parentElement.textContent
            }
          });
        },
        id: 'ecard-details-amount'
      }, translateKeyFn);
    }

    const notifyWhenViewedCompText = Object.keys(state.groupDetails).length > 0 ?
      translateKeyFn('group-ecard-cc-manager', 'CC recipient\'s manager')
      : translateKeyFn('give-widget-ecard-email-notification-label', 'Email me when a recipient opens eCard');

    this.notifyWhenViewedComp.render({
      checked: Object.keys(state.groupDetails).length > 0 ? state.eCardNotifyManagerWhenViewedChecked : state.eCardNotifyWhenViewedChecked,
      text: notifyWhenViewedCompText,
      title: `${translateKeyFn('give-ecard-personal-notification', 'Personal Notification')}`,
      isHidden: !state.selectedProgramECard.value,
      onChange: ECardDetailsView.notifyWhenViewedCompOnChange(Object.keys(state.groupDetails).length > 0, dispatch),
      id: 'ecard-details-notify'
    });

    this.showHideUserSearch(state);
    this.prePopulatedCCUsers = ECardDetailsView.getPrePopulatedCCUsers(state);
    this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);

    this.ccUsersPillboxListComp.render({
      onRemovePillBoxItem: (removedItem) => {
        const index = this.selectedCCUsers.reduce((acc, ccUser, idx) => {
          if (acc === -1 && ccUser.id === removedItem.systemUserId) return idx;
          return acc;
        }, -1);

        if (index !== -1) {
          this.selectedCCUsers.splice(index, 1);
        } else {
          // prePopulatedUsers are not included in the selectedUsers. So, in order to remove prepopulated users added the block.
          const prePopulatedindex = this.prePopulatedCCUsers.reduce((acc, ccUser, idx) => {
            if (acc === -1 && ccUser.id === removedItem.systemUserId) return idx;
            return acc;
          }, -1);
          if (prePopulatedindex !== -1) {
            this.prePopulatedCCUsers.splice(prePopulatedindex, 1);
          }
        }
        this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);

        positionElement(`#${styles.callToActionDiv}`);
      },
      data: this.allCCUsers,
      id: 'ecard-details-users'
    }, translateKeyFn);

    this.ccUserSearchListComp.render({
      isOpen: false,
      isClosable: true,
      unSelectableItems: ECardDetailsView.transformIdToSystemUserId(this.allCCUsers),
      unSelectableMessage: translateKeyFn('give-widget-user-already-selected', 'This person has already been added.'),
      onEscapeCallback: () => {
        this.ccUserSearchAutoCompleteComp.focus();
      },
      onSelectCallback: (selectedItemData) => {
        this.selectedCCUsers = this.selectedCCUsers.concat([
          {
            text: preferOrFirstLastName(selectedItemData),
            id: selectedItemData.systemUserId,
            isRemovable: true
          }
        ]);
        this.ccUserSearchListComp.isOpen = false;
        this.ccUserSearchListComp.data = [];
        this.ccUserSearchListComp.noDataMessage = '';
        this.ccUserSearchAutoCompleteComp.focus();
        this.ccUserSearchAutoCompleteComp.clear();
        this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);
        this.ccUsersPillboxListComp.data = this.allCCUsers;
        positionElement(`#${styles.callToActionDiv}`);
      },
      translateKeyFn,
      id: 'ecard-details-user-search'
    });

    this.ccUserSearchAutoCompleteComp.render({
      isDisabled: !state.ccUsers.editable,
      minCharactersCallback: () => {
        this.ccUserSearchListComp.noDataMessage = translateKeyFn('minimum-characters', 'Please enter at least three (3) characters to search.');
        this.ccUserSearchListComp.messageError = false;
        this.ccUserSearchListComp.data = [];
      },
      source: (searchTerm) => {
        this.hasValueCleared = false;
        this.ccUserSearchListComp.isLoading = true;
        return queryUsers(searchTerm);
      },
      transformResults: transformUserSearchResults,
      customRenderer: (val, results) => {
        if (results.length > 0) {
          this.ccUserSearchListComp.noDataMessage = '';
        } else {
          this.ccUserSearchListComp.noDataMessage = `${translateKeyFn(
            'give-widget-no-results-found-label',
            `No one found matching ${val}`,
            '{val}',
            `${val}`
          )}`;
          this.ccUserSearchListComp.messageError = true;
        }
        this.ccUserSearchListComp.isOpen = true;
        this.ccUserSearchListComp.isLoading = false;
        this.ccUserSearchListComp.data = results;
        this.ccUserSearchListComp.unSelectableItems = ECardDetailsView.transformIdToSystemUserId(this.allCCUsers);
      },
      rendererId: `give-widget_${this.ccUserSearchListComp.id}`,
      handleKeyDown: this.ccUserSearchListComp.handleKeys,
      placeholder: state.ccUsers.editable ? translateKeyFn('give-widget-cc-placeholder', 'Type to add someone else') : '',
      valueCleared: () => {
        this.ccUserSearchListComp.noDataMessage = '';
        this.ccUserSearchListComp.isOpen = false;
        this.ccUserSearchListComp.data = [];
        if (this.hasValueCleared) this.ccUsersPillboxListComp.pop();
        this.hasValueCleared = true;
      },
      id: 'ecard-details-auto-complete',
      labelledby: ccUserSearchHeadingSelector.slice(1)
    });

    this.nextButtonComp.render({
      title: translateKeyFn('next', 'Next'),
      isDisabled: !ECardDetailsView.shouldNextButtonEnabled(state),
      className: `${styles.nextButton} ${clientPrimaryBackground}`,
      onClick: () => {
        dispatch({
          type: 'SET_VIEW_NUMBER_AND_SELECTED_CC_USERS',
          payload: {
            selectedCCUsers: this.allCCUsers,
            viewNumber: views.ECARD_SELECTION_VIEW
          }
        });
      },
      id: 'ecard-details-next'
    });
  }

  render(dispatch, state, stateDiff, translateKeyFn, translateClientTextFn) {
    const isGroupECard = Object.keys(state.groupDetails).length > 0;
    if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard)) {
      getPrograms(dispatch, state, translateKeyFn);
    }
    const eCardViewFragment = document.createRange().createContextualFragment(eCardDetailsViewTemplate(translateKeyFn));
    const eCardViewTemp = this.node;
    this.node = getFirstElementChild(eCardViewFragment);
    eCardViewTemp.parentNode.replaceChild(this.node, eCardViewTemp);
    this.initializeAllSubComps();
    this.renderAllSubComps(dispatch, state, translateKeyFn, translateClientTextFn);
    const nextButton = document.querySelector(`.${styles.nextButton}`);
    if (nextButton) {
      positionElement(`#${styles.callToActionDiv}`);
    }
    if (state.selectedProgramECard && state.selectedProgramECard.value) {
      ECardDetailsView.getECardInfoForProgram(state, dispatch);
    }
    this.node.classList.add('give-widget-current-view');
    setTimeout(() => {
      document.querySelector('.js-close').focus();
    }, 100);
  }

  update(dispatch, state, stateDiff, translateKeyFn) {
    this.errorMsg.classList.add(styles.hidden);
    const isGroupECard = Object.keys(state.groupDetails).length > 0;
    if (ECardDetailsView.errorMessageListChanged(stateDiff)) {
      const { message, onAction, actionLabel } = state.messages[state.messages.length - 1];
      this.errorMsg.innerHTML = errorMsgTemplate(message, actionLabel);
      this.errorMsg.classList.remove(styles.hidden);
      const errorActionButton = this.errorMsg.querySelector('button');
      if (errorActionButton) {
        errorActionButton.onclick = onAction;
      }
      if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
        (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard)) {
        this.pickAProgramQAComp.isHidden = true;
        this.pickCoreCompetencyQAComp.isHidden = true;
      }
      this.ccUserSearch.classList.add(styles.hidden);
    }

    if (ECardDetailsView.programListChanged(stateDiff) &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickAProgramQAComp.isLoading = false;
      this.pickAProgramQAComp.answers = state.programList;
      this.pickAProgramQAComp.isHidden = state.programList.length <= 1;
      this.pickAProgramQAComp.selectedValue = state.selectedProgramECard.value;
    }

    if (ECardDetailsView.selectedProgramChanged(stateDiff) &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickAProgramQAComp.selectedValue = state.selectedProgramECard.value;
      this.computeSpecialInstructions(state, translateKeyFn);
      this.node.querySelector(ccUserSearchPlaceholderSelector).classList.add(styles.loadingShimmer);
      this.showHideUserSearch(state);
      this.ccUserSearch.classList.add(styles.loadingShimmer);
      this.pickCoreCompetencyQAComp.isHidden = false;
      this.pickCoreCompetencyQAComp.isLoading = true;
      this.pickCoreCompetencyQAComp.answers = [{}, {}, {}];
      this.pickECardAmountQAComp.isHidden = false;
      this.pickECardAmountQAComp.isLoading = true;
      this.pickECardAmountQAComp.answers = [{}, {}, {}];
      this.notifyWhenViewedComp.isHidden = true;
      ECardDetailsView.getECardInfoForProgram(state, dispatch);
    }

    if (ECardDetailsView.eCardListChanged(stateDiff)) {
      this.errorMsg.classList.add(styles.hidden);
      this.containsNoECards = state.eCardList.favorites.length === 0 && state.eCardList.others.length === 0;
      if (this.containsNoECards) {
        this.errorMsg.classList.remove(styles.hidden);
        this.errorMsg.innerHTML = errorMsgTemplate(translateKeyFn('give-empty-ecard-error', 'No eCard for this program'));
        if (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
          (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard)) {
          this.pickCoreCompetencyQAComp.isLoading = false;
          this.pickCoreCompetencyQAComp.isHidden = true;
          this.pickECardAmountQAComp.isLoading = false;
          this.pickECardAmountQAComp.isHidden = true;
        }
        this.notifyWhenViewedComp.isHidden = true;
        this.ccUserSearch.classList.add(styles.hidden);
        this.ccUserSearch.classList.remove(styles.loadingShimmer);
      }
    }

    if (ECardDetailsView.corporateValueListChanged(stateDiff) && !this.containsNoECards &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickCoreCompetencyQAComp.isLoading = !state.selectedProgramECard.value;
      this.pickCoreCompetencyQAComp.isHidden = ECardDetailsView.isCorpValueListHidden(state);
      this.pickCoreCompetencyQAComp.answers = state.corporateValueList.options;
      this.pickCoreCompetencyQAComp.required = state.corporateValueList.required;
      this.pickCoreCompetencyQAComp.selectedValue = state.selectedCorporateValueECard.value;
    }

    if (ECardDetailsView.selectedCorporateValueChanged(stateDiff) &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickCoreCompetencyQAComp.selectedAnswer = state.selectedCorporateValueECard.value;
    }

    if (ECardDetailsView.eCardAmountListChanged(stateDiff) && !this.containsNoECards &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickECardAmountQAComp.isHidden = ECardDetailsView.isECardAmountListHidden(state);
      this.pickECardAmountQAComp.isLoading = false;
      this.pickECardAmountQAComp.answers = state.eCardAmountList.options;
      this.pickECardAmountQAComp.required = state.eCardAmountList.required;
      this.pickECardAmountQAComp.selectedValue = state.selectedECardAmount.value;
    }

    if (ECardDetailsView.selectedECardAmountChanged(stateDiff) &&
      (!state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW ||
      (state.customerFlags.GIVE_WIDGET_STREAMLINED_FLOW && isGroupECard))) {
      this.pickECardAmountQAComp.selectedValue = `${state.selectedECardAmount.value}`;
    }

    if (ECardDetailsView.eCardNotifyWhenViewedChanged(stateDiff) && !this.containsNoECards) {
      this.notifyWhenViewedComp.isHidden = !state.selectedProgramECard.value;
      this.notifyWhenViewedComp.checked = state.eCardNotifyWhenViewedChecked;
    }

    if (ECardDetailsView.eCardNotifyCCManagerWhenViewedChanged(stateDiff) && !this.containsNoECards) {
      if (state.eCardNotifyManagerWhenViewed && state.eCardNotifyManagerWhenViewed.disabled === true) {
        this.notifyWhenViewedComp.isHidden = true;
      } else {
        this.notifyWhenViewedComp.isHidden = !state.selectedProgramECard.value;
        this.notifyWhenViewedComp.checked = state.eCardNotifyManagerWhenViewedChecked;
      }
    }

    if (ECardDetailsView.ccUsersChanged(stateDiff) && !this.containsNoECards) {
      this.showHideUserSearch(state);
      this.node.querySelector(ccUserSearchPlaceholderSelector).classList.remove(styles.loadingShimmer);
      this.ccUserSearch.classList.remove(styles.loadingShimmer);
      this.prePopulatedCCUsers = ECardDetailsView.getPrePopulatedCCUsers(state);
      this.allCCUsers = this.prePopulatedCCUsers.concat(this.selectedCCUsers);
      this.ccUsersPillboxListComp.data = this.allCCUsers;
      this.ccUserSearchListComp.unSelectableItems = ECardDetailsView.transformIdToSystemUserId(this.allCCUsers);
      this.ccUserSearchAutoCompleteComp.isDisabled = !state.ccUsers.editable;
      if (state.ccUsers.editable) {
        const placeholder = translateKeyFn('give-widget-cc-placeholder', 'Type to add someone else') || 'Type to add someone else';
        this.ccUserSearchAutoCompleteComp.placeholder = placeholder;
      }
    }

    if (ECardDetailsView.shouldNextButtonEnabled(state) && !this.containsNoECards && this.containsNoECards !== undefined) {
      this.nextButtonComp.isDisabled = false;
    } else {
      this.nextButtonComp.isDisabled = true;
    }

    positionElement(`#${styles.callToActionDiv}`);
  }

  computeSpecialInstructions(state, translateKeyFn) {
    if (state.selectedProgramECard.value !== '' && state.selectedProgramECard.value !== undefined) {
      const headingTranslated = translateKeyFn(`${state.selectedProgramECard.value}/eproducts-search-special-instructions-title`) || '';
      const bodyTranslated = translateKeyFn(`${state.selectedProgramECard.value}/eproducts-search-special-instructions-body`) || '';
      if (headingTranslated || bodyTranslated) {
        const frag = document.createRange().createContextualFragment(specialInstructionTemplate(headingTranslated, bodyTranslated));
        const temp = getFirstElementChild(frag);
        this.specialInstructionsSection.parentNode.replaceChild(temp, this.specialInstructionsSection);
        this.specialInstructionsSection = temp;
      } else {
        this.specialInstructionsSection.innerHTML = '';
      }
    }
  }

  showHideUserSearch(state) {
    if (state.ccUsers.used && state.selectedProgramECard.value) {
      this.ccUserSearch.classList.remove(styles.hidden);
    } else {
      this.selectedCCUsers = [];
      this.ccUserSearch.classList.add(styles.hidden);
    }
  }

  show() {
    this.node.classList.remove(styles.hidden);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
