import { isObject } from './utils/utils';
import FetchApi from './state/services/fetch_api';
import getConfig from './env';

class Translations {
  defaultProps = {
    translations: {},
    clientStrings: {},
    applicationLanguage: 'en-US'
  };

  set translations(translationsObject) {
    if (isObject(translationsObject)) {
      this.props.translations = translationsObject;
    } else {
      console.error('Please pass a object for translations');
    }
  }

  get translations() {
    return this.props.translations;
  }

  set clientStrings(x) {
    if (isObject(x)) {
      this.props.clientStrings = x;
    } else {
      console.error('Please pass a object to client Stings');
    }
  }

  get clientStrings() {
    return this.props.clientStrings;
  }

  set applicationLanguage(x) {
    this.props.applicationLanguage = x;
  }

  get applicationLanguage() {
    return this.props.applicationLanguage;
  }

  constructor(translations) {
    this.props = { ...this.defaultProps, ...{ translations } };
    this.translations = this.props.translations;
    this.clientStrings = this.props.clientStrings;
    this.applicationLanguage = this.props.applicationLanguage;
  }

  fetchTranslations = (stp = '') => {
    if (stp === '') {
      return FetchApi(`${getConfig('API_BASE_URL')}/api/translations/locales/greatwork`, {
        headers: {
          'Accept-Language': `${this.applicationLanguage.replace('_', '-')};q=0.8`
        }
      }).then((res) => {
        this.translations = res;
        return res;
      });
    }
    return FetchApi(`${getConfig('API_BASE_URL')}/api/translations/${stp}/greatwork`, {
      headers: {
        'Accept-Language': `${this.applicationLanguage.replace('_', '-')};q=0.8`
      }
    }).then(
      (res) => {
        this.translations = res;
        return res;
      },
      () => this.fetchTranslations('')
    );
  };

  fetchClientStrings = (clientStringURL) => {
    let newClientString = clientStringURL;
    if (clientStringURL && `${clientStringURL}`.indexOf('https://') === -1) {
      newClientString = `${getConfig('API_BASE_URL')}/api/layout/translate/${clientStringURL}`;
    }
    return FetchApi(newClientString, {
      headers: {
        'Accept-Language': `${this.applicationLanguage.replace('_', '-')};q=0.8`,
        'Content-Type': 'text/plain'
      }
    });
  };

  /**
   * Looks up the key from translations Object
   */
  translateKey = (key, defaultValue = '', replaceWord, replaceText) => {
    let lookUpValue = '';
    if (Object.keys(this.translations).length > 0) {
      let temp = this.translations;
      key.split('/').forEach((keyPath) => {
        keyPath.split('|').forEach((optionKey) => {
          if (lookUpValue === '' && temp[optionKey] && typeof temp[optionKey] === 'string') {
            lookUpValue = temp[optionKey];
          } else {
            temp = temp[optionKey] || temp;
          }
        });
      });
      lookUpValue = lookUpValue.replace(/\|eCardLabel\|/g, '|ecard-label|')
        .replace(/\|nominationLabel\|/g, '|nomination-label|');
      const keyMatches = lookUpValue.match(/\|([a-z A-Z 0-9 -]+)\|/g);
      if (keyMatches !== null) {
        lookUpValue = keyMatches.reduce((acc, matchKey) => lookUpValue
          .replace(matchKey, this.translations[matchKey.replace(/\|/g, '').toLowerCase()]), lookUpValue);
      }
    }
    if (lookUpValue === '') console.warn('Cannot translate: ', key);
    lookUpValue = lookUpValue || defaultValue;
    if (replaceWord) {
      lookUpValue = lookUpValue.replace(replaceWord, replaceText);
    }
    return lookUpValue;
  };

  /**
   * displays date in a particular format
   */
  translateDateFormat = (date, dateFormat) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    switch (dateFormat.toLowerCase()) {
    case 'yyyy/mm/dd':
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    case 'dd/mm/yyyy':
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    case 'mm/dd/yyyy':
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    case 'dd mmm yyyy':
      return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    default:
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  };

  translateClientStrings = (clientString) => {
    if (this.clientStrings[`${clientString}${this.applicationLanguage}`] === undefined) {
      return this.fetchClientStrings(clientString)
        .then(
          (res) => {
            this.clientStrings[`${clientString}${this.applicationLanguage}`] = res;
            return Promise.resolve(res);
          },
          () => Promise.resolve('')
        );
    }
    return Promise.resolve(this.clientStrings[`${clientString}${this.applicationLanguage}`]);
  };
}

export default Translations;
