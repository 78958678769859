import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './button.css';

const buttonTemplate = () =>
  `<button class="${prismUiCss['psm-button']} ${styles.button}" type="button">
    <span></span>
    <span></span>
    <span></span>
  </button>`;

export default buttonTemplate;
