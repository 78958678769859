import getImage from '../common_templates/avatar_template';
import styles from './select-approver-presenter.css';
import { search as searchIcon } from '../../assets/icons';

const imageId = 'selectApproverPresenterImage';
export const imageSelector = `#${imageId}`;

const headingId = 'selectApproverPresenterHeading';
export const headingSelector = `#${headingId}`;

const fullNameId = 'selectApproverPresenterFullName';
export const fullNameSelector = `#${fullNameId}`;

const selectionTypeId = 'selectApproverPresenterSelectionType';
export const selectionTypeSelector = `#${selectionTypeId}`;

export const imageTemplate = (imageSrc, initials, name) => getImage(imageSrc, initials, name);

export const headingTemplate = (heading) => `<p class="${styles.sectionHeading}">${heading}</p>`;

export const fullNameTemplate = (selectionType, fullName, placeholder, id) => {
  if (selectionType === 'provided' || selectionType === 'none') {
    return `<p class="${styles.fullName} fs-mask">${fullName}</p>`;
  }
  return `<button class="${styles.linkButton} fs-mask" type="button" id="${id}_nameButton">${fullName || placeholder}</button>`;
};

export const selectionTypeTemplate = (selectionType, id, approverPresenter) => {
  if (selectionType === 'search') {
    return `
    <div class="${styles.searchWrapper} ${styles.hidden} 
    ${approverPresenter === 'approver' ? styles.approver : styles.presenter}" id="${id}_searchWrapper">
      ${searchIcon}
      <div id="${id}_search"></div>
      <div id="${id}_list"></div>
    </div>`;
  } else if (selectionType === 'select') {
    return `<div id="${id}_select" class="${styles.hidden}
    ${approverPresenter === 'approver' ? styles.approver : styles.presenter}"></div>`;
  }
  return `<div id="${selectionTypeId}"></div>`;
};

const selectApproverPresenterTemplate = () => `<div class="${styles.selectApproverPresenter}">
  <span id="${imageId}"></span>
  <div>
    <p id="${headingId}"></p>
    <p id="${fullNameId}" class="fs-mask"></p>
  </div>
  <div id="${selectionTypeId}"></div>
</div>

</div>`;

export default selectApproverPresenterTemplate;
