import ImageUploadTemplate from './image_upload_template';
import { getFirstElementChild } from '../../utils/utils';
import { clientPrimaryBorderColor } from '../../css/client_colors.js';
import styles from './image_upload.css';

class ImageUpload {
  defaultProps = {
    onCancel: () => {},
    onSelect: () => {},
    onUpload: () => {},
    showError: false,
    uploadedECard: {}
  };

  set showError(x) {
    if (x) {
      this.errorMsg.classList.remove(styles.hidden);
    } else {
      this.errorMsg.classList.add(styles.hidden);
    }
    this.select.classList.remove(styles.loading);
    this.select.removeAttribute('disabled');
  }

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to ImageUpload component`);
    }
  }

  showNode = (node) => {
    node.classList.remove(styles.hidden);
  }

  hideNode = (node) => {
    node.classList.add(styles.hidden);
  }

  handleFiles = (file) => {
    this.uploadedFile = file;
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.imagePreview.src = reader.result;
      this.hideNode(this.form);
      this.showNode(this.imagePreviewWrapperNode);
      this.props.onUpload({ uploadedFile: file, imagePreview: reader.result });
    };
  }

  handleClosePreview = () => {
    this.input.value = null;
    this.hideNode(this.imagePreviewWrapperNode);
    this.showNode(this.form);
    this.props.onCancel();
  }

  attachFiles = ({ dataTransfer: { files: droppedFiles = [] } = {}, target: { files: targetFiles = [] } = {} }) => {
    const files = [].slice.call(droppedFiles).concat([].slice.call(targetFiles));
    this.handleFiles(files[0]);
  }

  render(props) {
    this.props = { ...this.defaultProps, ...props };
    const modalFragment = document.createRange().createContextualFragment(ImageUploadTemplate(this.props));

    const modalTemp = this.node;
    this.node = getFirstElementChild(modalFragment);

    modalTemp.parentNode.replaceChild(this.node, modalTemp);

    this.form = this.node.querySelector(`.${styles.form}`);

    ['dragenter', 'dragover', 'dragleave', 'drop', 'drag', 'dragstart', 'dragend'].forEach((eventName) => {
      this.node.addEventListener(eventName, (e) => {
        e.preventDefault();
        e.stopPropagation();
      }, false);
    });

    const highlight = () => {
      this.form.classList.add(clientPrimaryBorderColor);
    };

    const unhighlight = () => {
      this.form.classList.remove(clientPrimaryBorderColor);
    };

    ['dragenter', 'dragover'].forEach((eventName) => {
      this.form.addEventListener(eventName, highlight, false);
    });

    ['dragleave', 'dragend', 'drop'].forEach((eventName) => {
      this.form.addEventListener(eventName, unhighlight, false);
    });

    this.imagePreviewWrapperNode = this.node.querySelector(`.${styles.imagePreviewWrapper}`);
    this.imagePreview = this.node.querySelector(`.${styles.image}`);
    if (this.props.uploadedECard.imagePreview) {
      this.imagePreview.src = this.props.uploadedECard.imagePreview;
      this.hideNode(this.form);
      this.showNode(this.imagePreviewWrapperNode);
    }
    this.input = this.node.querySelector('input');
    this.errorMsg = this.node.querySelector(`.${styles.errorMsg}`);
    this.select = this.node.querySelector(`.${styles.select}`);
    this.select.classList.remove(styles.loading);
    this.select.removeAttribute('disabled');
    this.showError = this.props.showError;
    ['drop', 'change'].forEach((eventName) => {
      this.node.addEventListener(eventName, this.attachFiles);
    });
    this.node.querySelector(`.${styles.cancel}`).addEventListener('click', this.handleClosePreview);
    this.select.addEventListener('click', () => {
      this.select.classList.add(styles.loading);
      this.select.setAttribute('disabled', 'disabled');
      this.props.onSelect();
    });
  }
}

export default ImageUpload;
