import listTemplate, { listItemTemplate, listItemTemplateLoading } from './list_template';
import emptyElement, { isBoolean, getFirstElementChild } from '../../utils/utils';
import styles from './list.css';

const listItemSelector = '[role="option"]';
const listOpenRequestedEventName = 'uiListOpenRequested';
const listCloseRequestedEventName = 'uiListCloseRequested';
const listOpenedEventName = 'uiListOpened';
const listClosedEventName = 'uiListClosed';

let initialListId = 0;

class List {
  static generateId() {
    initialListId += 1;
    return `list${initialListId - 1}`;
  }

  static cycleCurrentIndex(currentIndex, minIndex, maxIndex) {
    if (!currentIndex) return minIndex;
    else if (currentIndex <= minIndex) return maxIndex;
    else if (currentIndex > maxIndex) return minIndex;
    return currentIndex;
  }

  defaultProps = {
    id: List.generateId(),
    className: '',
    data: [],
    unSelectableItems: [],
    isOpen: true,
    isClosable: false,
    isLoading: false,
    noDataMessage: '',
    onEscapeCallback: () => {},
    onSelectCallback: () => {}
  };

  get id() {
    return this.props.id;
  }

  set id(x) {
    this.props.id = x;
    this.listNode.setAttribute('id', `give-widget_${x}`);
  }

  set data(list = []) {
    if (Array.isArray(list)) {
      this.props.data = list;
      this.currentFocusedItem = 0;
      this.hasPseudoFocus = false;
      if (list.length === 0) {
        this.listNode.classList.add(styles.hidden);
        this.listNode.setAttribute('aria-hidden', 'true');
        if (this.noDataMessage !== '') {
          this.noDataMessageNode.classList.remove(styles.hidden);
        }
      } else {
        this.listNode.setAttribute('aria-hidden', 'false');
        this.listNode.classList.remove(styles.hidden);
        this.noDataMessageNode.classList.add(styles.hidden);
      }
      this.listNode.setAttribute('aria-busy', 'false');
      const listItemsHTML = list.reduce((acc, listItem, index) => acc + listItemTemplate(listItem, this.props.translateKeyFn, index), '');
      const listItemsFrag = document.createRange().createContextualFragment(listItemsHTML);
      this.listItems = [];
      emptyElement(this.listNode);
      const fragChildNodes = Array.prototype.slice.call(listItemsFrag.childNodes);
      let index = 0;
      fragChildNodes.forEach((child) => {
        if (child.nodeType === 1) {
          // 1 is for element type node
          if (child.hasAttribute('id') === false) {
            child.setAttribute('id', `give-widget_${this.props.id}_${index}`);
          }
          child.setAttribute('data-index', `${index}`);
          child.addEventListener('click', this.handleListItemClick);
          child.addEventListener('keydown', this.handleListItemKeyDown);
          this.listNode.appendChild(child);
          this.listItems.push(child);
          index += 1;
        }
      });
    } else {
      console.error('Please pass an array for data ');
    }
  }

  get data() {
    return this.props.data;
  }

  // unSelectableItems such as already selected or the user himself
  set unSelectableItems(unSelectableItems = []) {
    if (Array.isArray(unSelectableItems)) {
      this.props.unSelectableItems = unSelectableItems;
      const listItems = this.listNode.querySelectorAll(listItemSelector);
      Array.prototype.slice.call(listItems).forEach((listItem) => {
        listItem.removeAttribute('disabled');
        listItem.removeAttribute('aria-selected');
        listItem.removeAttribute('title');
      });
      unSelectableItems.forEach((unSelectableItem) => {
        const node = this.listNode.querySelector(`[data-item-id="${unSelectableItem.systemUserId}"]`);
        if (node !== null) {
          node.setAttribute('disabled', 'disabled');
          node.setAttribute('aria-selected', 'true');
          node.setAttribute('title', this.props.unSelectableMessage);
        }
      });
    } else {
      console.error('Please pass an array for unSelectableItems');
    }
  }

  get unSelectableItems() {
    return this.props.unSelectableItems;
  }

  set isOpen(x) {
    if (isBoolean(x)) {
      this.props.isOpen = x;
      if (x) {
        this.open({
          type: listOpenRequestedEventName,
          detail: {
            id: this.id
          }
        });
      } else {
        this.close({
          type: listCloseRequestedEventName,
          detail: {
            id: this.id
          }
        });
      }
    } else {
      console.error('Please pass a boolean value');
    }
  }

  get isOpen() {
    return this.props.isOpen;
  }

  set isClosable(x) {
    if (isBoolean(x)) {
      this.props.isClosable = x;
      if (x) {
        this.node.classList.remove(styles.alwaysOpen);
      } else {
        this.node.classList.add(styles.alwaysOpen);
      }
    } else {
      console.error('please pass a boolean value to isClosable');
    }
  }

  get isClosable() {
    return this.props.isClosable;
  }

  set isLoading(x) {
    if (isBoolean(x)) {
      if (x) {
        this.currentFocusedItem = 0;
        this.hasPseudoFocus = false;
        this.props.isLoading = true;
        this.noDataMessageNode.classList.add(styles.hidden);
        this.listNode.setAttribute('aria-busy', 'true');
        this.listItems = [];
        const loadingHTML = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].reduce((acc) => acc + listItemTemplateLoading(), '');
        const loadingFrag = document.createRange().createContextualFragment(loadingHTML);
        emptyElement(this.listNode);
        let { firstChild } = loadingFrag;
        while (firstChild) {
          this.listNode.appendChild(firstChild);
          ({ firstChild } = loadingFrag);
        }
      } else {
        this.listNode.setAttribute('aria-busy', 'false');
        this.props.isLoading = false;
      }
    } else {
      console.error('Please pass in a boolean value to isLoading');
    }
  }

  get isLoading() {
    return this.props.isLoading;
  }

  set noDataMessage(x) {
    emptyElement(this.noDataMessageNode);
    this.noDataMessageNode.appendChild(document.createTextNode(x));
    this.props.noDataMessage = x;
    if (x === '') {
      this.noDataMessageNode.classList.add(styles.hidden);
      this.messageError = false;
    }
  }

  get noDataMessage() {
    return this.props.noDataMessage;
  }

  set messageError(x) {
    if (x) {
      this.noDataMessageNode.classList.add(styles.error);
    } else {
      this.noDataMessageNode.classList.remove(styles.error);
    }
  }

  set onEscapeCallback(x) {
    this.props.onEscapeCallback = x;
  }

  get onEscapeCallback() {
    return this.props.onEscapeCallback;
  }

  set onSelectCallback(x) {
    this.props.onSelectCallback = x;
  }

  get onSelectCallback() {
    return this.props.onSelectCallback;
  }

  set className(x) {
    if (x) {
      this.node.classList.add(x);
      this.props.className = x;
    }
  }

  get className() {
    return this.props.className;
  }

  get unSelectableMessage() {
    return this.props.unSelectableMessage;
  }

  set unSelectableMessage(x) {
    this.props.unSelectableMessage = x;
    this.props.unSelectableItems.forEach((unSelectableItem) => {
      const node = this.listNode.querySelector(`[data-item-id="${unSelectableItem.systemUserId}"]`);
      if (node !== null) {
        node.setAttribute('title', this.props.unSelectableMessage);
      }
    });
  }

  hasPseudoFocus = false;

  constructor(selector) {
    if (document.querySelector(selector) !== null) {
      this.node = document.querySelector(selector);
    } else {
      console.error(`${selector} doesn't exist in document. Please pass a valid container selector to list component`);
    }
  }

  handleListItemClick = (evt) => {
    if (evt.currentTarget.hasAttribute('disabled') === false) {
      const index = parseInt(evt.currentTarget.getAttribute('data-index'), 10);
      const selectedElementData = this.props.data[index];
      const selectedElementId = evt.currentTarget.getAttribute('id');
      this.props.onSelectCallback(selectedElementData, selectedElementId);
    }
    evt.stopPropagation();
  };

  handleListItemKeyDown = (evt) => {
    if ((evt.keyCode || evt.which) === 13 && evt.currentTarget.hasAttribute('disabled') === false) {
      const index = parseInt(evt.currentTarget.getAttribute('data-index'), 10);
      this.currentFocusedItem = index;
      const selectedElementData = this.props.data[index];
      const selectedElementId = evt.currentTarget.getAttribute('id');
      this.props.onSelectCallback(selectedElementData, selectedElementId);
    }
  };

  handleKeysOnOpen = () => {
    this.listNode.removeEventListener('keydown', this.handleKeys);
    this.listNode.addEventListener('keydown', this.handleKeys);
  };

  handleKeysOnClose() {
    this.listNode.removeEventListener('keydown', this.handleKeys);
  }

  handleKeys = (evt) => {
    this.currentFocusedItem = this.currentFocusedItem || 0;
    if (this.props.isLoading === true || this.props.data.length === 0) return -1;
    switch (evt.keyCode || evt.which) {
    case 38: // Up arrow
      return this.handleUpArrow();
    case 40: // down arrow
      return this.handleDownArrow();
    case 13: // enter key
      evt.preventDefault();
      evt.stopPropagation();
      return this.handleEnterKey();
    case 32: // spacebar
      return this.handleSpacebar(evt);
    case 27: // escape key
      evt.preventDefault();
      evt.stopPropagation();
      return this.handleEscapeKey();
    default:
      if (this.hasPseudoFocus) return this.listItems[this.currentFocusedItem].getAttribute('id');
      return -1;
    }
  };

  handleUpArrow() {
    if (this.hasPseudoFocus === false) {
      let temp = this.listItems.length - 1;
      while (temp >= 0 && this.listItems[temp].hasAttribute('disabled')) {
        temp -= 1;
      }
      if (temp < 0) {
        return -1;
      }
      this.listItems[temp].classList.add(styles.active);
      this.listItems[temp].setAttribute('aria-selected', 'true');
      this.listNode.setAttribute('aria-activedescendant', this.listItems[temp].getAttribute('id'));
      this.listItems[temp].scrollIntoView(false);
      this.hasPseudoFocus = true;
      this.currentFocusedItem = temp;
    } else {
      this.listItems[this.currentFocusedItem].classList.remove(styles.active);
      this.listItems[this.currentFocusedItem].removeAttribute('aria-selected');
      const prevValue = this.currentFocusedItem;
      let temp = List.cycleCurrentIndex(prevValue - 1, 0, this.listItems.length - 1);
      while (temp !== prevValue && this.listItems[temp].hasAttribute('disabled')) {
        temp = List.cycleCurrentIndex(temp - 1, 0, this.listItems.length - 1);
      }
      if (temp === prevValue) {
        this.hasPseudoFocus = false;
        this.listItems[temp].classList.remove(styles.active);
        this.listItems[temp].removeAttribute('aria-selected');
        return -1;
      }
      this.currentFocusedItem = temp;
      this.listItems[this.currentFocusedItem].classList.add(styles.active);
      this.listItems[this.currentFocusedItem].setAttribute('aria-selected', 'true');
      this.listNode.setAttribute('aria-activedescendant', this.listItems[this.currentFocusedItem].getAttribute('id'));
      this.listItems[this.currentFocusedItem].scrollIntoView(false);
    }
    return this.listItems[this.currentFocusedItem].getAttribute('id');
  }

  handleDownArrow() {
    if (!this.isOpen) {
      this.isOpen = true;
    }

    if (this.hasPseudoFocus === false) {
      let temp = 0;
      while (temp <= this.listItems.length - 1 && this.listItems[temp].hasAttribute('disabled')) {
        temp += 1;
      }
      if (temp >= this.listItems.length) {
        return -1;
      }
      this.listItems[temp].classList.add(styles.active);
      this.listItems[temp].setAttribute('aria-selected', 'true');
      this.listNode.setAttribute('aria-activedescendant', this.listItems[temp].getAttribute('id'));
      this.listItems[temp].scrollIntoView(false);
      this.hasPseudoFocus = true;
      this.currentFocusedItem = temp;
    } else {
      this.listItems[this.currentFocusedItem].classList.remove(styles.active);
      this.listItems[this.currentFocusedItem].removeAttribute('aria-selected');
      const prevValue = this.currentFocusedItem;
      let temp = List.cycleCurrentIndex(prevValue + 1, 0, this.listItems.length - 1);
      while (temp !== prevValue && this.listItems[temp].hasAttribute('disabled')) {
        temp = List.cycleCurrentIndex(temp + 1, 0, this.listItems.length - 1);
      }
      if (temp === prevValue) {
        this.hasPseudoFocus = false;
        this.listItems[temp].classList.remove(styles.active);
        this.listItems[temp].removeAttribute('aria-selected');
        return -1;
      }
      this.currentFocusedItem = temp;
      this.listItems[this.currentFocusedItem].classList.add(styles.active);
      this.listItems[this.currentFocusedItem].setAttribute('aria-selected', 'true');
      this.listNode.setAttribute('aria-activedescendant', this.listItems[this.currentFocusedItem].getAttribute('id'));
      this.listItems[this.currentFocusedItem].scrollIntoView(false);
    }
    return this.listItems[this.currentFocusedItem].getAttribute('id');
  }

  handleEnterKey() {
    if (this.hasPseudoFocus === true) {
      const selectedElementData = this.props.data[this.currentFocusedItem || 0];
      const selectedElement = this.listItems[this.currentFocusedItem || 0];
      const selectedElementId = this.listItems[this.currentFocusedItem || 0].getAttribute('id');
      if (selectedElement.hasAttribute('disabled') === false) {
        this.props.onSelectCallback(selectedElementData, selectedElementId);
        return selectedElementId;
      }
      return -1;
    }
    return -1;
  }

  handleSpacebar(evt) {
    const result = this.handleEnterKey();
    // We only want to prevent typing a space if we're selecting something from the list
    if (result !== -1) {
      evt.preventDefault();
    }
  }

  handleEscapeKey() {
    if (this.props.isClosable) {
      this.isOpen = false;
    }
    this.hasPseudoFocus = false;
    this.listItems[this.currentFocusedItem].classList.remove(styles.active);
    this.props.onEscapeCallback();
    return -1;
  }

  handleAriaOnOpen() {
    this.listNode.setAttribute('aria-hidden', false);
    this.listNode.setAttribute('aria-expanded', true);
  }

  handleAriaOnClose() {
    this.listNode.setAttribute('aria-hidden', true);
    this.listNode.setAttribute('aria-expanded', false);
  }

  open({ type = '', detail = {} } = {}) {
    if (type === listOpenRequestedEventName && detail.id === this.props.id) {
      this.listNode.classList.remove(styles.hidden);
      this.handleAriaOnOpen();
      this.handleKeysOnOpen();
      this.listNode.dispatchEvent(new CustomEvent(listOpenedEventName, {
        detail,
        bubbles: true
      }));
    }
  }

  close({ type = '', detail = {} } = {}) {
    if (type === listCloseRequestedEventName && detail.id === this.props.id && this.props.isClosable) {
      this.listNode.classList.add(styles.hidden);
      this.handleAriaOnClose();
      this.handleKeysOnClose();
      this.listNode.dispatchEvent(new CustomEvent(listClosedEventName, {
        detail,
        bubbles: true
      }));
    }
  }

  render(props) {
    const listFragment = document.createRange().createContextualFragment(listTemplate());
    const listTemp = this.node;

    this.node = getFirstElementChild(listFragment);
    this.noDataMessageNode = listFragment.querySelector('p');
    this.listNode = listFragment.querySelector('ul');
    this.listNode.ariaLabel = props?.id;

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.currentFocusedItem = 0;
    this.noDataMessage = this.props.noDataMessage;
    this.data = this.props.data;
    this.unSelectableItems = this.props.unSelectableItems;
    this.isOpen = this.props.isOpen;
    this.isClosable = this.props.isClosable;
    this.isLoading = this.props.isLoading;
    this.onEscapeCallback = this.props.onEscapeCallback;
    this.onSelectCallback = this.props.onSelectCallback;
    this.className = this.props.className;

    if (this.id === 'ecard-details-user-search') {
      this.listNode.style.paddingBottom = '72px';
    }
    this.node.removeEventListener(listOpenRequestedEventName, this.open);
    this.node.removeEventListener(listCloseRequestedEventName, this.close);
    this.node.addEventListener(listOpenRequestedEventName, this.open);
    this.node.addEventListener(listCloseRequestedEventName, this.close);
    listTemp.parentNode.replaceChild(this.node, listTemp);
  }
}

export default List;
