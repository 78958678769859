const getCookie = (name) =>
  document.cookie
    .split('; ')
    .filter((cookie) => cookie.split('=')[0] === name)
    .map((cookie) => cookie.match(new RegExp(`${name}=(.*)`))[1])[0];

const getAuthToken = (tokenName, userAccessToken) => {
  const token = userAccessToken.length === 0 ? getCookie(tokenName) : userAccessToken;
  return token ? { Authorization: `Bearer ${token}` } : null;
};

export default getAuthToken;
