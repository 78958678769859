import styles from './image_upload.css';
import { remove } from '../../assets/icons';
import { clientPrimaryBackground } from '../../css/client_colors.js';

// eslint-disable-next-line max-len
const disclaimerText = 'By uploading your image, you certify that you are the copyright owner of the image, or that you have been granted permission to use such Image consistent with the manner and purpose of your use.  You also certify that your image does not violate the Terms of Use of this site.';

const imageUploadTemplate = ({ translateKeyFn = (a, b) => b }) => (
  `<div id="octanner-give-upload-container">
    <form class="${styles.form}" aria-live="off">
      <p>${translateKeyFn('give-widget-upload-text', 'Drag and drop a .gif, .jpg, .jpeg, or .png file')}</p>
      <input type="file" class="${styles.input}" id="octanner-give-fileElem" accept="image/*" autocomplete="off">
      <label class="${styles.button}" for="octanner-give-fileElem" tabindex="0">
        ${translateKeyFn('give-widget-upload-file', 'Upload a file')}
      </label>
    </form>
    <div class="${styles.hidden} ${styles.imagePreviewWrapper}">
      <div class="${styles.errorMsg} ${styles.hidden}">${translateKeyFn('give-widget-upload-failed', 'Upload failed, please try again')}</div>
      <div class="${styles.imageButtonWrapper}">
        <button class="${styles.cancel}" type="button">${remove}</button>
        <img class="${styles.image}"/>
        <button class="${styles.select} ${clientPrimaryBackground}" type="button">${translateKeyFn('select', 'Select')}</button>
      </div>
    </div>
    <p>
      ${translateKeyFn('give-widget-ecard-upload-disclaimer', disclaimerText)}.
    </p>
  </div>`
);

export default imageUploadTemplate;
