import styles from './nomination-wizard.css';
import commonStyles from '../../css/common.css';

export const nominationWizardItemTemplate = (index) => `<li>
        <div id="nom-wizard-question-${index}"></div>
        </li>`;

export const nominationWizardAwardLevelsTemplate = (eCardRecommended, clientText) => {
  const awardLevelsString = `<div class="${styles.hidden}" id="recommended-award-levels"></div>`;
  if (eCardRecommended) {
    return `<div client-text-div">${clientText}${awardLevelsString}</div>`;
  }
  return awardLevelsString;
};

export const nominationWizardButtonTemplate = () =>
  `<div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv} ${styles.hidden}">
    <div class="${styles.button} wizard-next-wrapper"><button id="wizard-next" type="button"></button></div>
    </div>`;

export const loadingQuestionTemplate = () =>
  `<li class="${styles.loadingShimmer}">
    <div class="${styles.loadingQuestion1}"></div>
    <div class="${styles.loadingQuestion2}"></div>
    <div class="${styles.loadingQuestion3}"></div>
  </li>`;

const NominationWizardTemplate = (translateKeyFn) =>
  // list of question + radioButton combinations
  // Progress bar
  // Recommended or enforced award level (appears when questions are complete)
  // Next button (appears when award level is chosen)
  `<article id="nomination-wizard">
    <header class="${styles.heading}">
      <h1 class="${styles.heading}">${translateKeyFn('give-widget-award-level-questions-label', 'Award Level Questions:')}</h1>
    </header>
    <form aria-live="off">
      <ul class=${styles.list}>
      </ul>
    </form>
  </article>`;

export default NominationWizardTemplate;
