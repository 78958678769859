import styles from './autocomplete.css';

const autoCompleteTemplate = () => `
  <div class="fs-mask ${styles.autocomplete} ${styles.mobile_width}" >
    <input type="text" class="${styles.searchInput}" aria-autocomplete="both" aria-haspopup="true"
      autocomplete="off" spellcheck="false" autocorrect="off" autocapitalize="off" aria-autocomplete="i" role="combobox" aria-expanded="true">
    <p class="${styles.hide}" aria-live="polite"></p>
  </div>
  `;

export default autoCompleteTemplate;
