import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './checkbox.css';

export const checkboxTitle = (title) =>
  `<legend class="${styles.subheading}">${title}</legend>`;

const checkboxTemplate = ({ isToggleStyle }) =>
  `<fieldset class="${styles.checkboxContainer} ${isToggleStyle ? styles.toggle : ''}">
    <label class="${styles.wrapper} ${prismUiCss['psm-toggle__wrapper']}">
      <div class="${styles.toggleBackground} ${prismUiCss['psm-toggle']} ${styles.bgOverride}">
        <input type="checkbox" class="${prismUiCss['psm-toggle__checkbox']}" autocomplete="off">
        <div class="${styles.switch} ${prismUiCss['psm-toggle__switch']}"></div>
      </div>
      <span class="${styles.label} ${prismUiCss['psm-toggle__label']}"></span>
    </label>
  </fieldset>`;

export default checkboxTemplate;
