import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './select-box.css';

export const selectBoxOptionTemplate = (text = '', value = '', index, list) =>
  `<option value="${value}" data-index="${index}"  aria-label="${text} ${index + 1} of ${list.length}" >${text}</option>`;

const selectBoxTemplate = () =>
  `<div class="${styles.container} fs-mask" id="give-widget_ecard-list_selectBox">
    <select class="${prismUiCss['psm-input']} ${prismUiCss['psm-select']}" ></select>
  </div>`;

export default selectBoxTemplate;
