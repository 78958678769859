import styles from './user_search_view.css';
import commonStyles from '../../css/common.css';
import { search as searchIcon } from '../../assets/icons';

const userSearchId = 'userSearch';
export const userSearchSelector = '#userSearch';

const proxyLabelId = 'give-widget_proxy-label';
export const proxyLabelSelector = '#give-widget_proxy-label';

const searchListId = 'searchList';
export const searchListSelector = '#searchList';

const searchListHeadingId = 'searchListHeading';
export const searchListHeadingSelector = '#searchListHeading';

const sendEcardId = 'sendEcard';
export const sendEcardSelector = '#sendEcard';

const sendNominationId = 'sendNomination';
export const sendNominationSelector = '#sendNomination';

const selectedUsersPillBoxId = 'selectedUsersPillBox';
export const selectedUsersPillBoxSelector = '#selectedUsersPillBox';

const recognitionTypesErrorMessageId = 'recognitionTypesErrorMessage';
export const recognitionTypesErrorMessageSelector = '#recognitionTypesErrorMessage';

const currentlySelectedPeopleForRecognitionId = 'currentlySelectedPeopleForRecognition';
export const currentlySelectedPeopleForRecognitionSelector = '#currentlySelectedPeopleForRecognition';

const proxyContainerID = 'proxyContainer';
export const proxyContainerSelector = '#proxyContainer';

const proxySelectBoxID = 'proxySelectBox';
export const proxySelectBoxSelector = '#proxySelectBox';

const proxySelectID = 'proxy-select';
export const proxySelectSelector = '#proxy-select';

const bulkUploadId = 'bulkUploadButton';
export const bulkUploadSelector = '#bulkUploadButton';

const suggestedCoWorkersId = 'suggestedCoWorkers';
export const suggestedCoWorkersSelector = '#suggestedCoWorkers';

const uploadAFileId = 'uploadAFile';
export const uploadAFileSelector = '#uploadAFile';

const descriptionOfAchievementId = 'descriptionOfAchievement';
export const descriptionOfAchievementSelector = '#descriptionOfAchievement';

export const recommendedTitle = (translateKey) => translateKey('give-widget-recommended-for-you-label', 'Recommended for you:');

export const searchResultsTitle = (translateKey) => translateKey('give-widget-search-results-label', 'Search Results:');

const userSearchViewTemplate = (translateKey) => `<article class="${styles.userSearchView}">
<p
        class="${styles.recognitionTypesErrorMessage} ${styles.errorMsg} ${styles.hidden}"
        id="${recognitionTypesErrorMessageId}"
        aria-live="polite"
      ></p>


    <form >
      <div class="${styles.proxyContainer}" id="${proxyContainerID}">
        <label id="${proxyLabelId}" for="${proxySelectID}">${translateKey('give-widget-select-proxy-label', 'Send On Behalf Of')}</label>
        <div id="${proxySelectBoxID}"></div>
        
      </div>
      
      <div id="recipient-search-error"></div>
      <p aria-live="polite" class="${styles['sr-only']}" id="${currentlySelectedPeopleForRecognitionId}"></p>
      <div id="recipient-search" class="${styles.searchPlaceholder}">
        ${searchIcon}
        <div class="${styles.searchList}">
        <ul id="${selectedUsersPillBoxId}" class="${styles.pillBoxList}"></ul>
        <div id="${userSearchId}"></div>
        </div>
        <div id="${bulkUploadId}"></div>
      </div>
      <div id="${styles.recipientOptionsContainer}">
        <div id="${suggestedCoWorkersId}"></div>
        <div id="${uploadAFileId}"></div>
      </div>
      <div class="${styles.buttonContainer}">
        <button id="${sendNominationId}" type="button"></button>
        <button id="${sendEcardId}" type="button"></button>
      </div>
      <section class="${styles.sectionContainer}">
        <header>
          <h2 id="${searchListHeadingId}" class="${styles.subheading}"></h2>
        </header>
        <div id="${searchListId}" class="${styles.listContainer}">
        </div>
      </section>
      <div id="recognition-levels"></div>
      <div id="special-instructions"></div>
      <div id="${descriptionOfAchievementId}"></div>
      <div id="company-values"></div>
      <div id="${styles.callToActionDiv}" class="${commonStyles.nextButtonWrapper} ${commonStyles.callToActionDiv}"><div id="next-button"></div></div>
    </form>
  </article>`;

export default userSearchViewTemplate;
