let config = {
  API_BASE_URL: '',
  APPLICATION_LANGUAGE: 'en-US',
  RECOMMENDED_GIVE: true
};

let numberOfTimesConfigSet = 0;

export const setConfig = (cfg) => {
  if (numberOfTimesConfigSet === 0) {
    config = Object.assign(config, cfg);
    numberOfTimesConfigSet += 1;
  }
};

const getConfig = (name) => config[name];

export default getConfig;
