import ribbonImported from '@octanner-ui/tanner-ui-icons/js/bookmark-filled.js';
import removeImported from '@octanner-ui/tanner-ui-icons/js/close.js';
import searchImported from '@octanner-ui/tanner-ui-icons/js/search.js';
import arrowDownImported from '@octanner-ui/tanner-ui-icons/js/arrow-triangle-down.js';
import unlockImported from '@octanner-ui/tanner-ui-icons/js/unlock.js';
import lockImported from '@octanner-ui/tanner-ui-icons/js/lock.js';
import playImported from '@octanner-ui/tanner-ui-icons/js/play.js';

// SVGs are tabbable in IE unless they have focusable="false"
const ribbon = ribbonImported.replace(/<svg/, '<svg focusable="false"');
const remove = removeImported.replace(/<svg/, '<svg focusable="false"');
const search = searchImported.replace(/<svg/, '<svg focusable="false"');
const arrowDown = arrowDownImported.replace(/<svg/, '<svg focusable="false"');
const unlock = unlockImported.replace(/<svg/, '<svg focusable="false"');
const lock = lockImported.replace(/<svg/, '<svg focusable="false"');
const play = playImported.replace(/<svg/, '<svg focusable="false"');

// eslint-disable-next-line object-curly-newline
export { ribbon, remove, search, arrowDown, unlock, lock, play };
