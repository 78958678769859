import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { gql, useQuery } from '@apollo/react-hooks';
import defaultThemeOptions from '@octanner/prism-core/ThemeProvider/defaultTheme';
import ScopedCssBaseline from '@octanner/prism-core/ScopedCssBaseline';
import {
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import { TranslationContext } from './useTranslation';

// export const LAYOUT_QUERY = gql`
//   query getLayout {
//     theme {
//       themeId
//       logo {
//         value
//       }
//       canvasImage {
//         value
//       }
//       primaryColor {
//         value
//       }
//       secondaryColor {
//         value
//       }
//     }
//   }
// `;

const ReactProviders = ({ children, translateKey }) => {
  const [theme, setTheme] = useState(createMuiTheme());
  // const layout = useQuery(LAYOUT_QUERY);

  useEffect(() => {
    // if (layout.loading) return;

    // const themeOptions = layout.data?.theme || {};

    // const newThemeOptions = deepmerge(
    //   defaultThemeOptions,
    //   makePalette(themeOptions),
    // );

    // const newTheme = createMuiTheme(newThemeOptions);
    const newTheme = createMuiTheme(defaultThemeOptions);

    // if (newTheme?.overrides?.MuiButton?.containedPrimary) {
    //   newTheme.overrides.MuiButton.containedPrimary[
    //     '&$disabled'
    //   ] = colorDisabled(newTheme.palette.primary);
    // }

    // if (newTheme?.overrides?.MuiButton?.containedSecondary) {
    //   newTheme.overrides.MuiButton.containedSecondary![
    //     '&$disabled'
    //   ] = colorDisabled(newTheme.palette.secondary);
    // }

    setTheme(newTheme);
  }, [/* layout */]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <TranslationContext.Provider value={{ translateKey }}>
          {children}
        </TranslationContext.Provider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

ReactProviders.propTypes = {
  children: PropTypes.element,
  translateKey: PropTypes.func
};

export default ReactProviders;
