import prismUiCss from '@octanner/prism-ui-css/prism.css';
import textboxTemplate, {
  optionalTextSelector,
  textboxSelector,
  charCounterSelector,
  loadingDivSelector,
  textAreaSelector
} from './textbox_template.js';
import styles from './textbox.css';
import emptyElement, { getFirstElementChild, positionElement } from '../../utils/utils';

let textBoxId = 0;

export default class Textbox {
  static generateId() {
    textBoxId += 1;
    return `textbox${textBoxId - 1}`;
  }

  defaultProps = {
    maxLength: 0, // when it's 0, there's no max
    required: false,
    isLoading: false,
    disabled: false,
    isHidden: false,
    largeType: false,
    title: '',
    text: '',
    toggleText: '',
    className: '',
    onChange: () => {},
    id: Textbox.generateId()
  };

  setCounter = () => {
    emptyElement(this.counter);
    if (this.props.maxLength > 0) {
      const defaultCharsLeftText = 'Characters left';
      const charsLeftText = this.translateKeyFn ? this.translateKeyFn('characters-remain', defaultCharsLeftText) : defaultCharsLeftText;
      this.counter.setAttribute('aria-label', `${this.textLength}/${this.props.maxLength} ${charsLeftText}`);
      this.counter.appendChild(document.createTextNode(`${this.textLength}/${this.props.maxLength}`));
    }
  };

  set className(x) {
    if (x !== '') {
      this.node.classList.add(x);
      this.props.className = x;
    }
  }

  set id(x) {
    this.node.setAttribute('id', `give-widget_${x}`);
    this.textarea.setAttribute('id', `give-widget_${x}_text`);
    this.label.setAttribute('for', `give-widget_${x}_text`);
    this.props.id = x;
  }

  set title(x) {
    this.props.title = x;
    emptyElement(this.label);
    const titleFrag = document.createRange().createContextualFragment(x);
    this.label.appendChild(titleFrag);
  }

  set text(x) {
    this.props.text = x;
    this.textarea.value = x;
    this.setCounter();
  }

  set toggleText(x) {
    this.props.toggleText = x;
    emptyElement(this.toggleButton);
    const toggleTextFrag = document.createRange().createContextualFragment(x);
    this.toggleButton.appendChild(toggleTextFrag);
  }

  set maxLength(x) {
    this.props.maxLength = x;
    if (x > 0) {
      this.textarea.setAttribute('maxlength', x);
      this.counter.classList.remove(styles.hidden);
      this.setCounter();
    } else {
      this.textarea.removeAttribute('maxlength');
      this.counter.classList.add(styles.hidden);
    }
  }

  set required(x) {
    this.props.required = x;
    const optional = this.node.querySelector(optionalTextSelector);
    if (x) {
      this.textboxContainer.classList.remove(styles.hidden);
      this.toggleButton.classList.add(styles.hidden);
      optional.classList.add(styles.hidden);
    } else {
      this.textboxContainer.classList.add(styles.hidden);
      this.toggleButton.classList.remove(styles.hidden);
      optional.classList.remove(styles.hidden);
    }
  }

  set toggledOpen(x) {
    if (x) {
      this.textboxContainer.classList.remove(styles.hidden);
      this.toggleButton.classList.add(styles.hidden);
      this.textarea.focus();
      positionElement(this.ctaDiv);
    }
  }

  set disabled(x) {
    this.props.disabled = x;
    if (x) {
      this.node.classList.add(styles.hidden);
    } else if (!this.props.isHidden) {
      this.node.classList.remove(styles.hidden);
    }
  }

  set isHidden(x) {
    this.props.isHidden = x;
    if (x) {
      this.node.classList.add(styles.hidden);
    } else if (!this.props.disabled) {
      this.node.classList.remove(styles.hidden);
    }
  }

  get textLength() {
    // Per <, >, it encodes to 4 characters. As one char already exists in length, multiplying it with 3
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        return this.totalSymbolCountIntoThree(this.textarea.value) + this.textarea.value.length + this.totalEntersIntoOne(this.textarea.value);
      }
    }
    return this.totalSymbolCountIntoThree(this.textarea.value) + this.textarea.value.length;
  }

  set setReadonlyAfterSubmitButton(isReadonly) {
    this.textarea.setAttribute('readonly', isReadonly);
  }

  totalTextLength(text) {
    // getting maximun textarea length after subtracting lessthan and greaterthan symbols count and its multiplied with 3 from maxLength
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        const count = this.totalEntersIntoOne(text);
        if (count > 0) {
          return (this.props.maxLength - (this.totalSymbolCountIntoThree(text) + count));
        }
      }
    }
    return (this.props.maxLength - this.totalSymbolCountIntoThree(text));
  }

  totalSymbolCountIntoThree = (text) => (((text.match(/</g) || []).length + (text.match(/>/g) || []).length) * 3);

  totalEntersIntoOne = (text) => (((text.match(/\n/g) || []).length) * 1);

  set isLoading(x) {
    this.props.isLoading = x;
    if (x) {
      this.node.querySelector(optionalTextSelector).classList.add(styles.hidden);
      this.node.querySelector(textboxSelector).classList.add(styles.hidden);
      this.node.querySelector(charCounterSelector).classList.add(styles.hidden);
      this.node.querySelector(textAreaSelector).classList.add(styles.hidden);
      this.node.querySelector(loadingDivSelector).classList.remove(styles.hidden);
    } else {
      if (!this.props.required) {
        this.node.querySelector(optionalTextSelector).classList.remove(styles.hidden);
      }
      this.node.querySelector(textAreaSelector).classList.remove(styles.hidden);
      this.node.querySelector(textboxSelector).classList.remove(styles.hidden);
      this.node.querySelector(charCounterSelector).classList.remove(styles.hidden);
      this.node.querySelector(loadingDivSelector).classList.add(styles.hidden);
    }
  }

  get isLoading() {
    return this.props.isLoading;
  }

  constructor(selector) {
    const node = document.querySelector(selector);
    if (node === null) {
      console.error(`Invalid selector for Textbox component: ${selector}`);
    } else {
      this.node = node;
    }
  }

  render(props, translateKeyFn) {
    this.props = { ...this.defaultProps, ...props };
    this.translateKeyFn = translateKeyFn;

    const frag = document.createRange().createContextualFragment(textboxTemplate(translateKeyFn));
    const temp = this.node;

    this.node = getFirstElementChild(frag);
    this.textarea = this.node.querySelector('textarea');
    this.label = this.node.querySelector('label');
    this.toggleButton = this.node.querySelector('#textbox-toggle-button');
    this.textboxContainer = this.node.querySelector(`.${styles.textboxContainer}`);
    this.counter = this.node.querySelector(`.${prismUiCss['psm-form__max-chars']}`);
    this.counter.setAttribute('id', 'counter');
    this.id = this.props.id;
    this.title = this.props.title;
    this.text = this.props.text;
    this.toggleText = this.props.toggleText;
    this.maxLength = this.props.maxLength;
    this.onChange = this.props.onChange;
    this.className = this.props.className;
    this.required = this.props.required;
    this.isLoading = this.props.isLoading;
    this.disabled = this.props.disabled;
    this.isHidden = this.props.isHidden;
    this.ctaDiv = this.props.ctaDiv;

    this.textarea.addEventListener('change', (evt) => {
      const val = evt.target.value;
      this.selectedValue = val;
      this.props.onChange(val);
    });
    this.textarea.addEventListener('input', (evt) => {
      let totallength = this.totalTextLength(this.textarea.value);
      const val = evt.target.value;
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') === -1) {
          const count = this.totalEntersIntoOne(this.textarea.value);
          if (count > 0) {
            totallength += count;
          }
        }
      }
      if (val.length <= totallength && this.textLength <= this.props.maxLength) {
        this.textarea.setAttribute('maxlength', totallength);
        this.selectedValue = val;
        this.props.onChange(val);
      } else {
        this.props.text = this.selectedValue;
        this.textarea.value = this.selectedValue;
      }
    });

    this.textarea.addEventListener('input', this.setCounter);
    this.toggleButton.addEventListener('click', () => {
      this.toggledOpen = true;
    });
    this.textarea.setAttribute('aria-describedby', 'counter');
    temp.parentNode.replaceChild(this.node, temp);
    function outputsize() {
      positionElement('.give-widget-current-view .common--callToActionDiv--30laQ');
    }
    outputsize();
    new MutationObserver(outputsize).observe(this.textarea, {
      attributes: true, attributeFilter: ['style']
    });
  }
}
