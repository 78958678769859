import { modalHeadSelector } from '../components/modal/modal_template';

export const formLockStateHandler = () => {
  class Handler {
    constructor() {
      this.modalElem = document.querySelector('.js-modalDialog');
      this.formElems = [];
      this.anchorElems = [];
    }

    lock() {
      // Form elements including <button>s
      // Not called during Class construction to ensure exact element disabled states are current and correct
      // and not already handled by React's disabling props.
      // Only alter elements NOT already handled by React or another method.
      // Can't use CSS element:not:disabled because it doesn't account for disabled states set as a properties instead of attributes.
      // Alternative method would be to grab all form elements regardless of state, and then check elem.disabled state
      this.modalElem.querySelectorAll(
        'fieldset:enabled, input:enabled, object:enabled, output:enabled, select:enabled, textarea:enabled, button:enabled'
      ).forEach((e) => {
        this.formElems.push(e);
        e.disabled = true;
      });
      // Anchor elements
      this.modalElem.querySelectorAll('a').forEach((e) => {
        this.anchorElems.push({
          elem: e,
          href: e.href
        });
        e.removeAttribute('href');
      });
    }

    unlock() {
      this.formElems.forEach((e) => { e.disabled = false; });
      this.formElems = []; // Reset (see note on Class construction)
      this.anchorElems.forEach((o) => {
        o.elem.setAttribute('href', o.href);
      });
      this.anchorElems = []; // Reset (see note on Class construction)
    }
  }
  return new Handler();
};

const emptyElement = (node) => {
  let { firstChild } = node;
  while (firstChild) {
    node.removeChild(firstChild);
    ({ firstChild } = node);
  }
  return node;
};

export const getFirstElementChild = (node) => {
  const childNodes = Array.prototype.slice.call(node.childNodes);
  for (let index = 0; index < childNodes.length; index += 1) {
    if (childNodes[index].nodeType === 1) {
      // 1 means its an element node
      return childNodes[index];
    }
  }
  return null;
};

export const simplifyWhiteSpace = (str) => str.replace(/(?:\r\n|\r|\n)/g, '').replace(/\s+/g, '');
export const isBoolean = (x) =>
  Object.prototype.toString.call(x).toLowerCase() === '[object boolean]';

export const isObject = (obj) =>
  Object.prototype.toString.call(obj).toLowerCase() === '[object object]';

export const removeAllHtml = (html, allowLinks) => {
  let r = html;
  r = r ? r.replace(/javascript:/g, '')
    .replace(/<img/g, '')
    .replace(/\/img>/g, '')
    .replace(/<style/g, '')
    .replace(/\/style>/g, '')
    .replace(/<script/g, '')
    .replace(/\/script>/g, '')
    .replace(/<svg/g, '')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;') : '';
  // Only trim if newlines are the only things in the text areas.
  if (r.trim() === '') { r = r.trim(); }
  if (allowLinks) {
    // This is a bad idea, but eCards must allow href's and a tags, we try and safely do it..
    r = r.replace('&lt;a href="', '<a href="')
      .replace('target="_blank"&gt;', 'target="_blank" rel="noopener noreferrer">')
      .replace('&lt;/a&gt;', '</a>');
  }
  return r;
};

export const removeScriptTags = (html) => {
  let r = html;
  r = r ? r.replace(/javascript:/g, '').replace(/<script/g, '').replace(/<img/g, '') : '';
  return r;
};

export const preferOrFirstLastName = ({ preferName = '', firstName = '', lastName = '' }) => {
  if (!preferName && !firstName && !lastName) {
    return '';
  }
  return `${(preferName || firstName)} ${lastName}`;
};

// TODO: Modify this to work with translations
export const customizeNames = (recipients, translateKeyFn) => {
  if (!recipients || recipients.length === 0) return '';
  if (recipients.length === 1) return preferOrFirstLastName(recipients[0]);
  if (recipients.length === 2) {
    let translatedNames = translateKeyFn('tasks-details-recipients-two', '{fullName1} and {fullName2}');
    translatedNames = translatedNames.replace(/{fullName1}/g, preferOrFirstLastName(recipients[0]))
      .replace(/{fullName2}/g, preferOrFirstLastName(recipients[1]));
    return translatedNames;
  }
  let translatedNames = translateKeyFn('tasks-details-recipients-multiple', '{fullName1} and <span class="my-other-recipients">{num} others</span>');
  translatedNames = translatedNames.replace(/{fullName1}/g, preferOrFirstLastName(recipients[0]))
    .replace(/{num}/g, recipients.length - 1);
  return translatedNames;
};

export const findPreviewType = (urls, type) => {
  for (let i = 0; i < urls.length; i++) {
    if (typeof urls[i] === 'string' && urls[i].indexOf(type) === (urls[i].length - type.length)) {
      return urls[i];
    }
  }
  return '';
};

export const findMultiplePreviewTypes = (urls, types) => {
  for (let i = 0; i < types.length; i++) {
    const url = findPreviewType(urls, types[i]);
    if (url !== '') {
      return url;
    }
  }
  return '';
};

export const uuidGenerator = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });

export const translateSubmitErrorMessages = (message, translateKeyFn) => {
  if (message.indexOf('budget') > -1) {
    return `${translateKeyFn(
      'budget-peak-header',
      'Sorry, there\'s not enough budget to submit this nomination or ecard'
    )}.<br><br> ${translateKeyFn(
      'budget-peak-message',
      'For group nominations or ecards, try removing recipients whose budget might be depleted. ' +
      'You can also try selecting a lower award level. Contact your Program Administrator to check the budget available'
    )}.`;
  } else if (message.indexOf('account history') > -1) {
    return translateKeyFn('give-submission-system-unavailable-error', 'Your submission is being processed. Please check your account history');
  } else if (message.indexOf('selected presenter') > -1) {
    return translateKeyFn('give-submission-invalid-presenter-error', 'The selected presenter you chose was invalid; please try again');
  }
  return message;
};

export const positionElement = (elmSelector) => {
  // Let other elements finish rendering before we position things
  window.setTimeout(() => {
    const windowRect = document.querySelector('.js-modalWrapper').getBoundingClientRect();
    const dialogRect = document.querySelector('.js-modalDialog').getBoundingClientRect();
    const margins = windowRect.right - dialogRect.right;
    const element = document.querySelector(elmSelector);
    element.style.right = `${margins}px`;
    const elementPos = (window.innerHeight - dialogRect.bottom);
    if (elementPos >= 0) {
      element.style.bottom = `${elementPos}px`;
      element.style.borderRadius = '0 0 3px 3px';
      element.style.boxShadow = 'none';
    } else {
      element.style.bottom = '0px';
      element.style.borderRadius = '0';
      element.style.boxShadow = 'rgba(0, 0, 0, 0.25) 0px -5px 3px -1px';
    }
  }, 0);
};

export const getParamValue = (key) => {
  let value = '';
  const { search = '' } = window.location;
  if (search.length > 0) {
    const queryString = search.replace(/^\?/, '');
    let paramName /* : string */ = '';
    let paramValue /* : string */ = '';
    queryString.split(/&/).forEach((keyValuePair) => {
      paramName = keyValuePair.replace(/=.*$/, '');
      paramValue = keyValuePair.replace(/^[^=]*=/, '');
      if (paramName === key) {
        value = paramValue.replace('%27', '\'');
      }
    });
  }
  return decodeURI(value) || '';
};

export default emptyElement;

export const setHeader = (headerText) => {
  let headerEl = document.querySelector(modalHeadSelector);
  if (!headerEl) {
    return;
  }
  headerEl.setAttribute('aria-label', '');
  headerEl.innerText = '';

  setTimeout(() => {
    headerEl = document.querySelector(modalHeadSelector);
    if (!headerEl) {
      return;
    }
    headerEl.innerText = headerText;
    headerEl.setAttribute('aria-label', `Dialog Modal, ${headerText}, Heading level 1`);
  }, 100);
};

export const getEcardUrl = (ecard) => {
  const imageTypes = ['.svg', '.gif', '.png', '.jpg'];
  const urls = [ecard.normalUrl, ecard.largeUrl, ecard.thumbNailUrl, ecard.videoUrl];
  let eCardUrl = findMultiplePreviewTypes(urls, imageTypes);
  let ecardTemplateUrl = '';
  if (ecard.normalUrl) {
    ecardTemplateUrl = !ecard.normalUrl.includes('.mp4') ? ecard.normalUrl : ecard?.largeUrl;
  } else {
    ecardTemplateUrl = ecard?.largeUrl || ecard?.thumbNailUrl;
  }

  if (ecardTemplateUrl?.includes('cloudinary')) {
    eCardUrl = ecardTemplateUrl;
  }
  return eCardUrl;
};
