import styles from './ecard_message_view.css';
import commonStyles from '../../css/common.css';
import { play } from '../../assets/icons';

export const modalHeaderSelector = '#modalHeader';

export const ecardHeaderSelector = '#ecardHeader';
const ecardHeaderId = 'ecardHeader';

export const ecardDetailsSelector = '#ecardDetails';
const ecardDetailsId = 'ecardDetails';

export const ecardMessageSelector = '#ecardMessage';
const ecardMessageId = 'ecardMessage';

export const scheduleButtonSelector = '#ecardScheduleButton';
const scheduleButtonId = 'ecardScheduleButton';

const errorMsgId = 'eCardErrorMsg';
export const errorMsgSelector = '#eCardErrorMsg';

export const submitButtonSelector = '#ecardSubmitButton';
const submitButtonId = 'ecardSubmitButton';

export const privacySelectBoxSelector = '#privacySelectBox';
const privacySelectBoxId = 'privacySelectBox';

export const privacySelectBoxWrapperSelector = '#privacySelectBoxWrapper';
export const privacySelectBoxWrapperId = 'privacySelectBoxWrapper';

const eCardMessageViewTemplate = (ecard, translateKeyFn) =>
  `<article id="eCardMessageView" class="${styles.ecard_message_view}">
    <form aria-live="off">
      <section id="${ecardHeaderId}"></section>
      <section id="${ecardDetailsId}"></section>
      <div id="${errorMsgId}"></div>
      <section class="${styles.eCardPreview}">
        ${ecard.videoUrl ?
    `<video class="eCardPreviewVideo" src="${ecard.videoUrl}"
      aria-label="${ecard.description !== '' ? ecard.description : ecard.text}"
      poster="${ecard.largeUrl || ecard.normalUrl || ecard.thumbNailUrl}"
      inline
      >
    </video>
       <button class="${styles.playBtn}" aria-label="Play" type="button">${play}</button>` :
    `<img src="${ecard.normalUrl || ecard.largeUrl || ecard.thumbNailUrl}" alt="${ecard.description !== '' ? ecard.description : ecard.text}">`}
      <a class="${styles.editButton} ${styles.editECardBtn}">${translateKeyFn ? translateKeyFn('edit', 'Edit') : 'Edit'}</a>
    </section>
    <section id="${ecardMessageId}"></section>
    <section class="${styles.privacyWrapper}" id="${privacySelectBoxWrapperId}">
      <p>
      ${translateKeyFn
    ? translateKeyFn('give-widget-who-should-see-this-label', 'Display on company social wall?')
    : 'Display on company social wall?'}
      </p>
      <div id="${privacySelectBoxId}"></div>
    </section>
    <div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv}">
      <section id="${submitButtonId}"></section>
      <section id="${scheduleButtonId}"></section>
      <div class="${styles.calendar}"></div>
    </div>
    </form>
  </article>`;

export default eCardMessageViewTemplate;
