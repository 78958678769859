import computeDifferenceBetweenTwoObjects from './lib/object_diff';

export default class Store {
  constructor(rootReducer, state = {}) {
    this.state = state;
    this.currentListeners = [];
    this.nextListeners = this.currentListeners;
    this.isDispatching = false;
    this.rootReducer = rootReducer;
  }

  ensureCanMutateNextListeners() {
    if (this.nextListeners === this.currentListeners) {
      this.nextListeners = this.currentListeners.slice();
    }
  }

  getState = () => this.state;

  dispatch = (action) => {
    if (
      Object.prototype.toString.call(action) !== '[object Object]' ||
      action.constructor !== Object
    ) {
      throw new Error('Actions must be plain objects.');
    }

    if (typeof action.type === 'undefined') {
      throw new Error(`Actions may not have an undefined "type" property. check your ${action} obj`);
    }

    if (this.isDispatching) {
      throw new Error('Reducers may not dispatch actions.');
    }
    let prevState;
    try {
      this.isDispatching = true;
      prevState = { ...this.state };
      this.state = this.rootReducer(this.state, action);
    } finally {
      this.isDispatching = false;
    }
    this.currentListeners = this.nextListeners;
    for (let i = 0; i < this.currentListeners.length; i += 1) {
      const listener = this.currentListeners[i];
      listener(prevState, this.state, computeDifferenceBetweenTwoObjects(prevState, this.state));
    }

    return action;
  };

  subscribe = (listener) => {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function');
    }
    let isSubscribed = true;
    this.ensureCanMutateNextListeners();
    this.nextListeners.push(listener);

    return () => {
      if (isSubscribed === false) {
        return;
      }
      isSubscribed = false;
      const index = this.nextListeners.indexOf(listener);
      this.nextListeners.splice(index, 1);
    };
  };
}
