import 'core-js/es/number/is-nan';
import 'whatwg-fetch';
import './polyfills/custom_event';
import Store from './store/store';
import rootReducer from './state/reducers/root_reducer';
import Modal from './components/modal/modal';
import Button from './components/button/button';
import appTemplate, {
  getButtonTemplate,
  clientColorStylesTmpl,
  clientColorStylesId,
  appreciateModalSelector,
  viewsPlaceholder
} from './app_template';
import Translations from './translations';
import {
  setFetchFunc,
  setLanguage,
  setTokenName,
  setUserAccessToken,
  setXClientID
} from './state/services/fetch_api';
import * as api from './state/services/api';
import * as views from './views/views-list';
import getConfig, { setConfig } from './env.js';
import styles from './app.css';
import renderViews from './utils/render_views';
import { getParamValue } from './utils/utils';

const getAccessToken = (dispatch) => {
  const fedLoginUrlIndex = window.location.href.indexOf('?fedLoginUrl=');
  let fedLoginUrl;
  if (fedLoginUrlIndex > -1) {
    fedLoginUrl = decodeURIComponent(window.location.href.substring(fedLoginUrlIndex + 13));
    const loginWindow = window.open(fedLoginUrl, 'giveanywhere');

    if (loginWindow === null || typeof loginWindow === 'undefined') {
      dispatch({
        type: 'SET_SHOW_POPUP_MESSAGE',
        payload: true
      });
    } else {
      setInterval(() => {
        if (loginWindow.closed) {
          window.location.reload(true);
        }
      }, 1000);
    }
  } else {
    console.error('GiveWidget error: Please pass in fedLoginUrl');
  }

  const eventOrigin = getConfig('API_BASE_URL');
  // const eventOrigin = 'http://localhost:5000';

  function receiveMessage(event) {
    if (event.origin === eventOrigin && event.data.refreshIframe === true) {
      window.location.reload(true);
    }
  }
  window.addEventListener('message', receiveMessage);
};

class App {
  appreciateModal = undefined;
  appreciateButton = undefined;
  loadingView = undefined;
  userSearchView = undefined;
  nominationWizardView = undefined;
  programDetailsView = undefined;
  eCardDetailsView = undefined;
  eCardSelectionView = undefined;
  eCardMessageView = undefined;
  descriptionView = undefined;
  submissionView = undefined;
  translations = undefined;
  applicationLanguage = undefined;

  attachResetStyle = () => {
    const rule = '#oct-appreciateModal *[class^=psm-]';
    const css = `${rule} {
      box-shadow: none;
      box-sizing: border-box;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin: 0;
      overflow: visible;
    }`;
    this.resetStyleEl = document.createElement('style');
    this.resetStyleEl.appendChild(document.createTextNode(css));
    document.head.insertAdjacentElement('afterbegin', this.resetStyleEl);
  }

  constructor(domSelector, opt = {}) {
    const options = (typeof opt === 'string') ? { language: opt } : opt;
    this.attachResetStyle();
    const locale = getParamValue('language') || '';
    const {
      apiBaseUrl = '',
      apiManagerUrl = '',
      coreRecipients = [],
      customButtonSelector = null,
      fetch = window.fetch,
      id = 'oct-appreciate-modal',
      recipients = [],
      startModalOpen = false,
      tokenName = '_access_token',
      tokenRequired = true,
      useRecommendedGive = true,
      userAccessToken = '',
      xClientID = 'give-widget'
    } = options;
    // default params don't apply if null is passed into options
    const language = options.language || (locale.length > 0 ? locale : getConfig('APPLICATION_LANGUAGE'));

    if (apiBaseUrl || apiManagerUrl) {
      setConfig({
        API_BASE_URL: apiBaseUrl,
        RECOMMENDED_GIVE: useRecommendedGive
      });
    }

    if (!domSelector && !customButtonSelector) {
      console.error('GiveWidget: No container selector nor customButtonSelector found.');
    }
    if (domSelector) {
      this.domSelector = domSelector;
      this.node = document.querySelector(this.domSelector);
    }
    this.startModalOpen = startModalOpen;
    this.customButtonSelector = customButtonSelector;
    this.modalWrapperId = id;

    const store = new Store(rootReducer);
    store.subscribe(this.render.bind(this, store.dispatch));
    store.dispatch({ type: 'INIT_EVENT' }); // We are doing this to initialize the store of initial state
    if (startModalOpen) {
      this.instantiateModal();
      store.dispatch({
        type: 'SET_VIEW_NUMBER',
        payload: views.LOADING_VIEW
      });
    }
    setFetchFunc(fetch);
    setLanguage(language);
    setTokenName(tokenName);
    setUserAccessToken(userAccessToken);
    setXClientID(xClientID);
    if (!Array.isArray(coreRecipients) || !Array.isArray(recipients)) {
      console.error('GiveWidget: recipients must be an array');
    } else {
      if (recipients.length > 0) {
        store.dispatch({
          type: 'SET_RECIPIENT_IDS_AND_SET_INITIAL_RECIPIENTS',
          payload: {
            recipients: { recipients },
            initialRecipients: { initialRecipients: [...recipients] }
          }
        });
      }
      if (coreRecipients.length > 0) {
        this.loadingPromise = api.getSystemUserIds(store.dispatch, coreRecipients);
      }
    }

    store.dispatch({
      type: 'SET_USE_RECOMMENDED_GIVE',
      payload: getConfig('RECOMMENDED_GIVE')
    });

    const hasAccessToken = !tokenRequired || document.cookie.indexOf(tokenName) > -1;
    if (hasAccessToken) {
      this.loadingPromise = (this.loadingPromise || Promise.resolve()).then(() =>
        Promise.all([
          api.getGiveInfo(store.dispatch),
          api.getClientTheme(store.dispatch),
          api.getCurrentUserInfo(store.dispatch)
        ]).then((values) => api.getCustomerFlags(store.dispatch, values[2].customer))
          .catch((err) => {
            if ((err.error && err.error.code === 401) || err.status === 401) {
              getAccessToken(store.dispatch);
            }
            console.error(`GiveWidget error: ${err}`);
          }));

      this.translations = new Translations({});
      this.applicationLanguage = language;
      if (customButtonSelector) {
        this.appreciateButton = new Button(customButtonSelector);
      } else if (!startModalOpen) {
        // This is a function because it generates a random id for the button
        const buttonTemplate = getButtonTemplate();
        this.node.insertAdjacentHTML('beforeend', buttonTemplate.html);
        this.appreciateButton = new Button(`#${buttonTemplate.id}`);
      }
    } else {
      getAccessToken(store.dispatch);
    }
  }

  static shouldRenderModal = (
    {
      giveOptions: { type: giveType = '' } = {},
      clientTheme: { type: clientType = '' } = {},
      updateTranslations: { type: updateTranslationsType = '' } = {}
    } = {},
    {
      giveOptions = {},
      clientTheme = {},
      updateTranslations = false
    } = {}
  ) => (
    giveOptions &&
    giveOptions.isGiveEnabled === true &&
    updateTranslations &&
    clientTheme?.primaryHexColor &&
    (giveType === 'updated' || clientType === 'updated' || updateTranslationsType === 'updated')
  );

  static shouldTriggerTranslations = ({ stp: { type = '' } = {} } = {}) => type === 'updated';

  static shouldUpdateTranslations = ({ updateTranslations: { type = '', data = false } = {} } = {}) => type === 'updated' && data === true;

  static shouldCloseModal = ({ setOrigin: { type = '', data = false } = {} } = {}) => type === 'updated' && data === true;

  undefineAllViews = () => {
    this.loadingView = undefined;
    this.userSearchView = undefined;
    this.bulkUploadView = undefined;
    this.nominationWizardView = undefined;
    this.programDetailsView = undefined;
    this.eCardDetailsView = undefined;
    this.eCardSelectionView = undefined;
    this.eCardMessageView = undefined;
    this.descriptionView = undefined;
    this.submissionView = undefined;
  };

  instantiateModal = () => {
    let modalWrapper = document.getElementById(this.modalWrapperId);
    if (!modalWrapper) {
      document.body.insertAdjacentHTML('beforeend', appTemplate(this.modalWrapperId));
      modalWrapper = document.getElementById(this.modalWrapperId);
    }
    this.modalWrapper = modalWrapper;
    this.appreciateModal = new Modal(appreciateModalSelector);
  };

  renderModal = (dispatch) => {
    const triggeringElement = this.startModalOpen ? null : this.appreciateButton.node;
    this.appreciateModal.render({
      innerHTML: viewsPlaceholder(),
      triggeringElement,
      beforeClose: () => {
        dispatch({
          type: 'RESET_TO_BUTTON'
        });
        this.undefineAllViews();
        this.appreciateModal.innerHTML = viewsPlaceholder();
      },
      afterClose: () => {
        const wrapper = document.getElementById(this.modalWrapperId);
        if (wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      }
    }, this.translations.translateKey);
  };

  openModal = (dispatch) => () => {
    this.instantiateModal();
    this.renderModal(dispatch);
    this.appreciateModal.isOpen = true;
    if (this.loadingPromise) {
      this.loadingPromise.then(() => {
        dispatch({
          type: 'SET_VIEW_NUMBER',
          payload: views.USER_SEARCH_VIEW
        });
      });
    } else {
      dispatch({
        type: 'SET_VIEW_NUMBER',
        payload: views.USER_SEARCH_VIEW
      });
    }
  };

  render = (dispatch, prevState, state, stateDiff) => {
    // Only insert the client styles once in case there are
    // other instances of give-widget on the page
    const clientStylesEl = document.getElementById(clientColorStylesId);
    if (stateDiff.clientTheme && stateDiff.clientTheme.type === 'updated' && !clientStylesEl) {
      const nonce = document.querySelector('meta[name="style-nonce"]')?.getAttribute('content');
      document.body.insertAdjacentHTML('beforeend', clientColorStylesTmpl(state.clientTheme, nonce));
    }

    if (App.shouldRenderModal(stateDiff, state)) {
      if (this.customButtonSelector) {
        document.querySelector(this.customButtonSelector).onclick = this.openModal(dispatch).bind(this);
      } else if (!this.startModalOpen) {
        this.appreciateButton.render({
          className: styles.appreciate,
          onClick: this.openModal(dispatch).bind(this),
          title: this.translations.translateKey('user-appreciate', 'appreciate')
        });
      }
      if (this.startModalOpen) {
        this.renderModal(dispatch);
        this.appreciateModal.isOpen = true;
        if (this.loadingPromise) {
          this.loadingPromise.then(() => {
            dispatch({
              type: 'SET_VIEW_NUMBER',
              payload: views.USER_SEARCH_VIEW
            });
          });
        } else {
          dispatch({
            type: 'SET_VIEW_NUMBER',
            payload: views.USER_SEARCH_VIEW
          });
        }
      }
    }

    if (App.shouldTriggerTranslations(stateDiff)) {
      this.translations.applicationLanguage = this.applicationLanguage;
      this.translations.fetchTranslations(state.stp.performanceStp).then(() => {
        dispatch({ type: 'SET_UPDATE_TRANSLATIONS', payload: true });
      }, (err) => {
        dispatch({ type: 'SET_UPDATE_TRANSLATIONS', payload: true });
        console.error(`GiveWidget error: ${err}`);
      });
    }

    renderViews[state.viewNumber].call(this, dispatch, state, stateDiff, prevState);

    if (App.shouldCloseModal(stateDiff)) {
      this.appreciateModal.isOpen = false;
    }
  };
}
export { App as Create, setConfig };
export default App;
