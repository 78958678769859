import TabSelectorTemplate from './tab_selector_template.js';
import { getFirstElementChild } from '../../utils/utils';
import { clientPrimaryBorderColor } from '../../css/client_colors.js';
import styles from './tab_selector.css';

class TabSelector {
  defaultProps = {
    selectedTab: '',
    tabs: [],
    onSelect: () => {}
  };

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to TabSelector component`);
    }
  }

  selectedTabIndex = 0;

  set selectedTab(tabId) {
    const tabElements = this.node.querySelectorAll(`.${styles.tab}`);
    tabElements[this.selectedTabIndex].classList.remove(clientPrimaryBorderColor);
    this.props.selectedTab = tabId;
    this.selectedTabIndex = this.props.tabs.findIndex((tab) => this.props.selectedTab === tab.tabId);
    tabElements[this.selectedTabIndex].classList.add(clientPrimaryBorderColor);
    this.props.onSelect(tabId);
  }

  render(props) {
    this.props = { ...this.defaultProps, ...props };
    const modalFragment = document.createRange().createContextualFragment(TabSelectorTemplate(this.props.tabs));

    const modalTemp = this.node;
    this.node = getFirstElementChild(modalFragment);
    if (this.props.tabs.length) {
      this.selectedTab = this.props.selectedTab || this.props.tabs[0].tabId;
    }

    modalTemp.parentNode.replaceChild(this.node, modalTemp);
    this.node.addEventListener('click', (e) => {
      const tabId = e.target.getAttribute('data-tabid');
      if (typeof tabId === 'string') {
        this.selectedTab = tabId;
      }
    });
  }
}

export default TabSelector;
