import submissionViewTemplate, {
  submissionHeadingSelector,
  messageSelector,
  doneButtonSelector,
  appreciateButtonSelector,
  instantEvent,
  scheduledEvent
} from './submission_view_template';
import { getFirstElementChild } from '../../utils/utils';
import Button from '../../components/button/button';
import styles from './submission_view.css';

export default class SubmissionView {
  submissionHeader;
  message;
  doneButtonComponent;
  appreciateButtonComponent;

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document, please pass a valid container selector to submission_view component `);
    }
  }

  initializeAllSubComponents() {
    this.submissionHeader = this.node.querySelector(submissionHeadingSelector);
    this.message = this.node.querySelector(messageSelector);
    this.doneButtonComponent = new Button(doneButtonSelector);
    this.appreciateButtonComponent = new Button(appreciateButtonSelector);
  }

  renderAllSubComponents(dispatch, state, translateKeyFn, translateDateFormat) {
    if (state.scheduledDeliveryDate) {
      this.message.appendChild(document.createTextNode(scheduledEvent(state, translateKeyFn, translateDateFormat)));
    } else {
      this.message.appendChild(document.createTextNode(instantEvent(state, translateKeyFn)));
    }

    this.doneButtonComponent.render({
      title: translateKeyFn('give-widget-done-label', 'Done'),
      isDisabled: false,
      isPrimary: false,
      className: styles.linkButton,
      onClick: () => {
        dispatch({ type: 'SET_ORIGIN', payload: { setOrigin: true } });
        dispatch({ type: 'RESET_TO_BUTTON' });
      },
      id: 'submission-done'
    });

    this.appreciateButtonComponent.render({
      title: translateKeyFn('give-widget-appreciate-someone-else-label', 'Appreciate Someone Else'),
      isDisabled: false,
      className: styles.appreciateButton,
      onClick: () => {
        dispatch({ type: 'RESET', payload: true });
      },
      id: 'submission-appreciate'
    });
  }

  render(dispatch, state, translateKeyFn, translateDateFormat) {
    const submissionViewFragment = document.createRange().createContextualFragment(submissionViewTemplate());
    const submissionViewTemp = this.node;
    this.node = getFirstElementChild(submissionViewFragment);
    submissionViewTemp.parentNode.replaceChild(this.node, submissionViewTemp);
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, translateKeyFn, translateDateFormat);
    setTimeout(() => {
      document.querySelector('.js-close').focus();
    }, 100);
  }

  hide() {
    this.node.classList.add(styles.hidden);
    const nodetoRemove = document.querySelector('#modal-hider');
    if (nodetoRemove) nodetoRemove.remove();
  }
}
