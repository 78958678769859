import styles from './ecard_details_view.css';
import commonStyles from '../../css/common.css';

export const modalHeaderSelector = '#modalHeader';

const eCardDetailsHeadingId = 'eCardDetailsHeading';
export const eCardDetailsHeadingSelector = '#eCardDetailsHeading';

const specialInstructionsId = 'specialInstructions';
export const specialInstructionsSelector = '#specialInstructions';

const programListId = 'eCardProgramList';
export const programListSelector = '#eCardProgramList';

const corporateValueListId = 'eCardCorporateValueList';
export const corporateValueListSelector = '#eCardCorporateValueList';

const eCardAmountListId = 'eCardAmountList';
export const eCardAmountListSelector = '#eCardAmountList';

const personalNotificationId = 'personalNotification';
export const personalNotificationSelector = '#personalNotification';

const ccUserSearchContainerId = 'eCardCCUserSearchContainer';
export const ccUserSearchContainerSelector = '#eCardCCUserSearchContainer';

const ccUserSearchHeading = 'eCardCCUserSearchHeading';
export const ccUserSearchHeadingSelector = '#eCardCCUserSearchHeading';

const ccUserSearchPlaceholderId = 'eCardCCUserSearchPlaceholder';
export const ccUserSearchPlaceholderSelector = '#eCardCCUserSearchPlaceholder';

const ccSelectedUsersPillBoxId = 'eCardCCSelectedUsersPillBox';
export const ccSelectedUsersPillBoxSelector = '#eCardCCSelectedUsersPillBox';

const ccUserSearchId = 'eCardCCUserSearch';
export const ccUserSearchSelector = '#eCardCCUserSearch';

const ccUserSearchListId = 'eCardCcUserSearchList';
export const ccUserSearchListSelector = '#eCardCcUserSearchList';

const noECardMsgId = 'emptyECard';
export const noECardMsgSelector = '#emptyECard';

const eCardDetailsNext = 'eCardNext';
export const eCardDetailsNextSelector = '#eCardNext';

const eCardDetailsViewTemplate = (translateKey) =>
  `<article class="${styles.ecard_details_view}" id="ecard_details_section">
    
    <form aria-live="off">
      <div id="${eCardDetailsHeadingId}"></div>
      <div>
        <div id="${programListId}"></div>
        <div class="${styles.hidden}" id="${specialInstructionsId}">
        </div>
        <div id="${noECardMsgId}"></div>
        <div id="${corporateValueListId}"></div>
        <div id="${eCardAmountListId}"></div>
        <section id="${personalNotificationId}" class="${styles.hidden}"></section>
        <section id="${ccUserSearchContainerId}" class="${styles.hidden}">
          <label id="${ccUserSearchHeading}" class="${styles.subheading}">${translateKey('give-widget-cc-label', 'CC:')}</label>
          <div id="${ccUserSearchPlaceholderId}" class="${styles.searchPlaceholder}">
            <ul id="${ccSelectedUsersPillBoxId}" class="${styles.pillBoxList}"></ul>
            <div id="${ccUserSearchId}"></div>
            <div id="${ccUserSearchListId}"></div>
          </div>
        </section>
      </div>
      <div id="${styles.callToActionDiv}" class="${commonStyles.callToActionDiv}">
        <div class="${styles.nextButtonWrapper}"><button id="${eCardDetailsNext}" type="button">${translateKey('next', 'Next')}</button></div>
      </div>
    </form>
  </article>`;

export default eCardDetailsViewTemplate;
