import { isObject, isBoolean } from './utils';
import * as views from '../views/views-list';

export const userSearchView = (
  viewNumber,
  {
    messages: { type: messagesType, data: messagesData } = {},
    descriptionOfAchievement: { type: descriptionOfAchievementType, data: descriptionOfAchievementData } = {},
    descriptionOfAchievementText: { type: descriptionOfAchievementTextType, data: descriptionOfAchievementTextData } = {},
    programList: { type: programListType, data: programListData } = {},
    awardList: { type: awardListType, data: awardList } = {},
    corporateValueList = {},
    followedEmployees = {},
    userInfo = {},
    recognitionTypes = {},
    proxyOptions = {},
    recognizerId = {},
    recipients = [],
    selectedRecognitionType = {},
    customerFlags = [],
    selectedAwardLevel = {},
    selectedCorporateValueECard = {},
    selectedCorporateValueNomination = {},
    updateTranslations = {}
  } = {}
) =>
  viewNumber === views.USER_SEARCH_VIEW &&
    ((descriptionOfAchievementType === 'updated' && isObject(descriptionOfAchievementData)) ||
      (descriptionOfAchievementTextType === 'updated' && descriptionOfAchievementTextData !== '') ||
      (awardListType === 'updated' && Array.isArray(awardList)) ||
      (programListType === 'updated' && Array.isArray(programListData)) ||
      (followedEmployees.type === 'updated' && Array.isArray(followedEmployees.data)) ||
      (corporateValueList.type === 'updated' && Array.isArray(corporateValueList.options)) ||
      (userInfo.type === 'updated' && isObject(userInfo.data)) ||
      (recognizerId.type === 'updated' && !!recognizerId.data) ||
      (recognitionTypes.type === 'updated' && isObject(recognitionTypes.data)) ||
      (proxyOptions.type === 'updated' && Array.isArray(proxyOptions.data)) ||
      (recipients.type === 'updated' && recipients.data !== '') ||
      (selectedRecognitionType.type === 'updated' && selectedRecognitionType.data !== '') ||
      (customerFlags.type === 'updated' && customerFlags.data !== '') ||
      (selectedAwardLevel.type === 'updated' && isObject(selectedAwardLevel)) ||
      (selectedCorporateValueECard.type === 'updated' && isObject(selectedCorporateValueECard.data)) ||
      (selectedCorporateValueNomination.type === 'updated' && isObject(selectedCorporateValueNomination.data)) ||
      (updateTranslations.type === 'updated' && updateTranslations.data === true) ||
      (messagesType === 'updated' && Array.isArray(messagesData)));

export const bulkUploadView = (
  viewNumber,
  {
    recognitionTypes = {}
  }
) =>
  viewNumber === views.BULK_UPLOAD_VIEW &&
    (recognitionTypes.type === 'updated' && isObject(recognitionTypes.data));

export const programDetailsView = (
  viewNumber,
  {
    programList: { type: programListType, data: programListData } = {},
    awardList: { type: awardListType, data: awardList } = {},
    corporateValueList: { type: corporateValueListType, data: corporateValueList = {} } = '',
    selectedCorporateValueNomination: { type: selectedCorporateValueNominationType, data: selectedCorporateValueNominationData = {} } = {},
    selectedAwardLevel: { type: selectedAwardLevelType, data: selectedAwardLevel } = {},
    selectedProgramNomination: { type: selectedProgramNominationType, data: selectedProgramNominationData } = {},
    noteToApprover: { type: noteToApproverType, data: noteToApproverData } = {},
    noteToApproverText: { type: noteToApproverTextType } = {},
    termsOfServiceChecked: { type: tosType } = {},
    wizardOptional: { type: wizardOptionalType, data: wizardOptionalData } = {},
    wizard: { type: wizardType, data: wizardData } = {},
    approverList: { type: approverListType, data: approverList } = {},
    presenterList: { type: presenterListType, data: presenterList } = {},
    messages: { type: errorMessageType, data: errorMsgList } = {}
    // What about the CC people?
  } = {}
) =>
  viewNumber === views.PROGRAM_DETAILS_VIEW &&
    ((programListType === 'updated' && Array.isArray(programListData)) ||
      (awardListType === 'updated' && Array.isArray(awardList)) ||
      (corporateValueListType === 'updated' && Array.isArray(corporateValueList.options)) ||
      (selectedCorporateValueNominationType === 'updated' && isObject(selectedCorporateValueNominationData)) ||
      (selectedAwardLevelType === 'updated' && isObject(selectedAwardLevel)) ||
      (selectedProgramNominationType === 'updated' && selectedProgramNominationData !== '') ||
      (noteToApproverType === 'updated' && isObject(noteToApproverData)) ||
      (noteToApproverTextType === 'updated') ||
      (wizardOptionalType === 'updated' && isBoolean(wizardOptionalData)) ||
      (wizardType === 'updated' && Array.isArray(wizardData)) ||
      (approverListType === 'updated' && Array.isArray(approverList)) ||
      (presenterListType === 'updated' && Array.isArray(presenterList)) ||
      (errorMessageType === 'updated' && !!errorMsgList[errorMsgList.length - 1]) ||
      tosType === 'updated');

export const nominationWizardView = (viewNumber, { wizard = {}, awardList = {}, selectedAwardLevel = {} } = {}) =>
  viewNumber === views.NOMINATION_WIZARD_VIEW &&
      (wizard.type === 'updated' || awardList.type === 'updated' || selectedAwardLevel.type === 'updated');

export const descriptionView = (
  viewNumber,
  {
    descriptionOfAchievement: { type: descriptionOfAchievementType, data: descriptionOfAchievementData } = {},
    descriptionOfAchievementText: { type: descriptionOfAchievementTextType } = {},
    thankYouNote: { type: thankYouNoteType, data: thankYouNoteData } = {},
    privacy: { type: privacyType } = {},
    thankYouNoteText: { type: thankYouNoteTextType } = {},
    boiText: { type: boiTextType } = {},
    messages: { type: errorMessageType, data: errorMsgList } = {}
  } = {}
) =>
  viewNumber === views.DESCRIPTION_VIEW &&
    ((descriptionOfAchievementType === 'updated' && isObject(descriptionOfAchievementData)) ||
      descriptionOfAchievementTextType === 'updated' ||
      (thankYouNoteType === 'updated' && isObject(thankYouNoteData)) ||
      (boiTextType === 'updated') ||
      (privacyType === 'updated') ||
      (errorMessageType === 'updated' && !!errorMsgList[errorMsgList.length - 1]) ||
      thankYouNoteTextType === 'updated');

export const eCardDetailsView = (
  viewNumber,
  {
    ccUsers: { type: ccUsersType, data: ccUsersData } = {},
    corporateValueList: { type: corporateValueListType, data: corporateValueList = {} } = {},
    eCardAmountList: { type: eCardAmountListType, data: eCardAmountListData } = {},
    eCardList: { type: eCardListType, data: eCardListData } = {},
    eCardNotifyWhenViewed: { type: eCardNotifyWhenViewedType, data: eCardNotifyWhenViewedData } = {},
    messages: { type: errorMessageType, data: errorMsgList } = {},
    programList: { type: programListType, data: programListData } = {},
    selectedCorporateValueECard: { type: selectedCorporateValueECardType, data: selectedCorporateValueECardData = {} } = {},
    selectedECardAmount: { type: selectedECardAmountType, data: selectedECardAmountData } = {},
    selectedProgramECard: { type: selectedProgramECardType, data: selectedProgramECardData } = {}

  } = {}
) =>
  viewNumber === views.ECARD_DETAILS_VIEW &&
    ((ccUsersType === 'updated' && isObject(ccUsersData)) ||
      (corporateValueListType === 'updated' && Array.isArray(corporateValueList.options)) ||
      (eCardAmountListType === 'updated' && isObject(eCardAmountListData)) ||
      (eCardListType === 'updated' && isObject(eCardListData)) ||
      (eCardNotifyWhenViewedType === 'updated' && typeof eCardNotifyWhenViewedData === 'boolean') ||
      (errorMessageType === 'updated' && !!errorMsgList[errorMsgList.length - 1]) ||
      (programListType === 'updated' && Array.isArray(programListData)) ||
      (selectedCorporateValueECardType === 'updated' && isObject(selectedCorporateValueECardData)) ||
      (selectedECardAmountType === 'updated' && isObject(selectedECardAmountData)) ||
      (selectedProgramECardType === 'updated' && selectedProgramECardData !== '')
    );

export const eCardSelectionView = (viewNumber, { eCardList = {}, uploadedECard = {} } = {}) =>
  viewNumber === views.ECARD_SELECTION_VIEW && (eCardList.type === 'updated' ||
    uploadedECard.type === 'updated');

export const eCardMessageView = (viewNumber, {
  descriptionOfAchievementText = {}, scheduledDeliveryDate = {}, privacy = {}, messages = {}
} = {}) =>
  viewNumber === views.ECARD_MESSAGE_VIEW &&
      (descriptionOfAchievementText.type === 'updated' ||
      messages.type === 'updated' ||
      privacy.type === 'updated' ||
      scheduledDeliveryDate.type === 'updated');
