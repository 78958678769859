import styles from './tab_selector.css';

const tab = ({ tabId, name }) => `<li class="${styles.tab}">
  <button class="${styles.tabButton}" id="give-widget_ecard-tab-${name}" data-tabid="${tabId}" type="button">${name}</button>
</li>`;

const TabSelectorTemplate = (tabs) => `<div class="${styles.tabSelector}">
  <ul class="${styles.tabSelectorList}">
    ${tabs.map(tab).join('')}
  </ul>
</div>`;

export default TabSelectorTemplate;
