import datePickerHTML, {
  datePickerControlsSelectYearSelector,
  datePickerControlsSelectMonthSelector,
  datePickerControlsNextMonthButtonSelector,
  datePickerControlsPreviousMonthButtonSelector,
  datePickerGridBodySelector,
  datePickerBodyHTML,
  datePickerCurrentlyFocusedDateSelector,
  datePickerFocusableElements,
  datePickerContainerSelector,
  datePickerContainerHTML
} from './date_picker_template';
import {
  getCurrentDate,
  getDayNames,
  getMonthNames,
  getYearsBetweenDatesInclusive,
  moveDateByNYears,
  get,
  getSelectableMonths,
  createFragment,
  getChildren,
  getDatePickerGridValues,
  chunk,
  emptyNode,
  moveDateByNDays,
  shouldDisableNextMonthButton,
  shouldDisablePreviousMonthButton,
  getCompatableDateForNextMonth,
  isDateAGreaterThenDateB,
  getCompatableDateForPreviousMonth,
  isDateASmallerThenDateB,
  getCompatableDate,
  formatDate,
  cloneDate
} from './utils';
import { getFirstElementChild } from '../../utils/utils';

import styles from './date_picker.css';

export default class DatePicker {
  elm = null;
  currentSelectedMonth;
  currentSelectedYear;
  datePickerGridFocusableDateElements = [];
  cachedFirstElement = null;
  cachedLastElement = null;

  defaultProps = {
    lang: 'en-US',
    minDate: moveDateByNDays(getCurrentDate(), 1),
    maxDate: moveDateByNYears(getCurrentDate(), 2),
    defaultDate: moveDateByNDays(getCurrentDate(), 1),
    format: 'MM/dd/yyyy',
    onSelect: () => {},
    field: null,
    trigger: null,
    className: '',
    container: document.body
  };

  constructor(props) {
    this.props = { ...this.defaultProps, ...props };
    if (this.props.container === document.body) {
      const elm = getFirstElementChild(createFragment(datePickerContainerHTML()));
      this.props.container.appendChild(elm);
      this.elm = elm;
    } else this.elm = this.props.container;
    this.currentSelectedYear = get(this.props.defaultDate, 'yyyy');
    this.currentSelectedMonth = get(this.props.defaultDate, 'MM');
    this.render();
  }

  getFirstFocusableElement = () => {
    if (shouldDisablePreviousMonthButton(this.props.minDate, this.currentSelectedYear, this.currentSelectedMonth)) {
      return this.elm.querySelector(datePickerControlsSelectMonthSelector);
    }
    return this.elm.querySelector(datePickerControlsPreviousMonthButtonSelector);
  };

  getLastFocusableElement = () => this.elm.querySelector(datePickerCurrentlyFocusedDateSelector);

  nextMonthButtonOnClick = () => {
    this.incrementMonth();
    const currentlyFocusedDateElement = this.elm.querySelector(datePickerCurrentlyFocusedDateSelector);
    const currentlyFocusedDate = new Date(currentlyFocusedDateElement.dataset.dateObj);
    this.render(getCompatableDate(
      this.currentSelectedYear,
      this.currentSelectedMonth,
      get(currentlyFocusedDate, 'dd'),
      this.props.minDate,
      this.props.maxDate
    ));
  };

  previousMonthButtonOnClick = () => {
    this.decrementMonth();
    const currentlyFocusedDateElement = this.elm.querySelector(datePickerCurrentlyFocusedDateSelector);
    const currentlyFocusedDate = new Date(currentlyFocusedDateElement.dataset.dateObj);
    this.render(getCompatableDate(
      this.currentSelectedYear,
      this.currentSelectedMonth,
      get(currentlyFocusedDate, 'dd'),
      this.props.minDate,
      this.props.maxDate
    ));
  };

  incrementMonth = () => {
    this.currentSelectedMonth += 1;
    if (this.currentSelectedMonth > 12) {
      this.currentSelectedYear += 1;
      this.currentSelectedMonth = 1;
    }
    const maxDateYear = get(this.props.maxDate, 'yyyy');
    const maxDateMonth = get(this.props.maxDate, 'MM');
    if (this.currentSelectedYear > maxDateYear || (this.currentSelectedYear === maxDateYear && this.currentSelectedMonth > maxDateMonth)) {
      this.currentSelectedYear = get(this.props.maxDate, 'yyyy');
      this.currentSelectedMonth = get(this.props.maxDate, 'MM');
      return false;
    }
    return true;
  };

  decrementMonth = () => {
    this.currentSelectedMonth -= 1;
    if (this.currentSelectedMonth < 1) {
      this.currentSelectedYear -= 1;
      this.currentSelectedMonth = 12;
    }
    const minDateYear = get(this.props.minDate, 'yyyy');
    const minDateMonth = get(this.props.minDate, 'MM');
    if (this.currentSelectedYear < minDateYear || (minDateYear === this.currentSelectedYear && this.currentSelectedMonth < minDateMonth)) {
      this.currentSelectedYear = get(this.props.minDate, 'yyyy');
      this.currentSelectedMonth = get(this.props.minDate, 'MM');
      return false;
    }
    return true;
  };

  selectedMonthOnChange = ({ target: { value } }) => {
    this.currentSelectedMonth = parseInt(value, 10);
    const currentlyFocusedDateElement = this.elm.querySelector(datePickerCurrentlyFocusedDateSelector);
    const currentlyFocusedDate = new Date(currentlyFocusedDateElement.dataset.dateObj);
    this.render(getCompatableDate(
      this.currentSelectedYear,
      this.currentSelectedMonth,
      get(currentlyFocusedDate, 'dd'),
      this.props.minDate,
      this.props.maxDate
    ));
  };

  selectedYearOnChange = ({ target: { value } }) => {
    this.currentSelectedYear = parseInt(value, 10);
    const maxDateYear = get(this.props.maxDate, 'yyyy');
    const maxDateMonth = get(this.props.maxDate, 'MM');
    const minDateYear = get(this.props.minDate, 'yyyy');
    const minDateMonth = get(this.props.minDate, 'MM');
    if (this.currentSelectedYear < minDateYear) this.currentSelectedYear = minDateYear;
    else if (this.currentSelectedYear > maxDateYear) this.currentSelectedYear = maxDateYear;
    if (this.currentSelectedYear === maxDateYear && this.currentSelectedMonth > maxDateMonth) {
      this.currentSelectedMonth = maxDateMonth;
    } else if (this.currentSelectedYear === minDateYear && this.currentSelectedMonth < minDateMonth) {
      this.currentSelectedMonth = minDateMonth;
    }
    const currentlyFocusedDateElement = this.elm.querySelector(datePickerCurrentlyFocusedDateSelector);
    const currentlyFocusedDate = new Date(currentlyFocusedDateElement.dataset.dateObj);

    this.render(getCompatableDate(
      this.currentSelectedYear,
      this.currentSelectedMonth,
      get(currentlyFocusedDate, 'dd'),
      this.props.minDate,
      this.props.maxDate
    ));
  };

  handleLeftArrow = ({ target }) => {
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    if (curIndex !== 0) {
      const prevElement = this.datePickerGridFocusableDateElements[curIndex - 1];
      const curElement = this.datePickerGridFocusableDateElements[curIndex];
      const isDisabled = prevElement.getAttribute('aria-disabled') !== 'false';
      if (isDisabled === false) {
        curElement.setAttribute('tabindex', '-1');
        prevElement.setAttribute('tabindex', '0');
        prevElement.focus();
        this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
      }
    } else if (this.decrementMonth()) {
      this.render(new Date(this.currentSelectedYear, this.currentSelectedMonth, 0));
    }
  };

  handleRightArrow = ({ target }) => {
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    const maxIndex = this.datePickerGridFocusableDateElements.length - 1;
    if (curIndex !== maxIndex) {
      const nextElement = this.datePickerGridFocusableDateElements[curIndex + 1];
      const curElement = this.datePickerGridFocusableDateElements[curIndex];
      const isDisabled = nextElement.getAttribute('aria-disabled') !== 'false';
      if (isDisabled === false) {
        curElement.setAttribute('tabindex', '-1');
        nextElement.setAttribute('tabindex', '0');
        nextElement.focus();
        this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
      }
    } else if (this.incrementMonth()) {
      this.render(new Date(this.currentSelectedYear, this.currentSelectedMonth - 1, 1));
    }
  };

  handleUpArrow = (evt) => {
    const { target } = evt;
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    if (curIndex - 7 >= 0) {
      const prevElement = this.datePickerGridFocusableDateElements[curIndex - 7];
      const curElement = this.datePickerGridFocusableDateElements[curIndex];
      const isDisabled = prevElement.getAttribute('aria-disabled') !== 'false';
      if (isDisabled === false) {
        curElement.setAttribute('tabindex', '-1');
        prevElement.setAttribute('tabindex', '0');
        prevElement.focus();
        this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
      }
    } else if (this.decrementMonth()) {
      this.render(moveDateByNDays(new Date(target.dataset.dateObj), -7));
    }
    evt.preventDefault();
  };

  handleDownArrow = (evt) => {
    const { target } = evt;
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    const maxIndex = this.datePickerGridFocusableDateElements.length - 1;
    if (curIndex + 7 <= maxIndex) {
      const nextElement = this.datePickerGridFocusableDateElements[curIndex + 7];
      const curElement = this.datePickerGridFocusableDateElements[curIndex];
      const isDisabled = nextElement.getAttribute('aria-disabled') !== 'false';
      if (isDisabled === false) {
        curElement.setAttribute('tabindex', '-1');
        nextElement.setAttribute('tabindex', '0');
        nextElement.focus();
        this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
      }
    } else if (this.incrementMonth()) {
      this.render(moveDateByNDays(new Date(target.dataset.dateObj), 7));
    }
    evt.preventDefault();
  };

  handleHomeKey = ({ target }) => {
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    const dayOfTheWeek = get(new Date(target.dataset.dateObj), 'dayOfTheWeek');
    const moveTo = curIndex - dayOfTheWeek;
    const curElement = this.datePickerGridFocusableDateElements[curIndex];
    let previousElement = this.datePickerGridFocusableDateElements[moveTo];
    if (previousElement === undefined || previousElement === null || previousElement.getAttribute('aria-disabled') === 'true') {
      previousElement = this.datePickerGridFocusableDateElements.slice(0, curIndex + 1).reduce((acc, elm) => {
        if (acc instanceof HTMLElement) return acc;
        else if (elm.getAttribute('aria-disabled') === 'false') return elm;
        return acc;
      }, {});
    }
    if (previousElement) {
      curElement.setAttribute('tabindex', '-1');
      previousElement.setAttribute('tabindex', '0');
      previousElement.focus();
      this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
    }
  };

  handleEndKey = ({ target }) => {
    const curIndex = this.datePickerGridFocusableDateElements.indexOf(target);
    const dayOfTheWeek = get(new Date(target.dataset.dateObj), 'dayOfTheWeek');
    const moveTo = curIndex + 6 - dayOfTheWeek;
    const curElement = this.datePickerGridFocusableDateElements[curIndex];
    let nextElement = this.datePickerGridFocusableDateElements[moveTo];
    if (nextElement === undefined || nextElement === null || nextElement.getAttribute('aria-disabled') === 'true') {
      nextElement = this.datePickerGridFocusableDateElements
        .slice(curIndex)
        .reverse()
        .reduce((acc, elm) => {
          if (acc instanceof HTMLElement) return acc;
          else if (elm.getAttribute('aria-disabled') === 'false') return elm;
          return acc;
        }, {});
    }
    if (nextElement) {
      curElement.setAttribute('tabindex', '-1');
      nextElement.setAttribute('tabindex', '0');
      nextElement.focus();
      this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
    }
  };

  handlePageDownKey = (evt) => {
    const { target, altKey } = evt;
    if (altKey) {
      this.selectedYearOnChange({ target: { value: this.currentSelectedYear + 1 } });
    } else if (this.incrementMonth()) {
      const nextDate = getCompatableDateForNextMonth(new Date(target.dataset.dateObj));
      if (isDateAGreaterThenDateB(nextDate, this.props.maxDate)) this.render(this.props.maxDate);
      else this.render(nextDate);
    }
    evt.preventDefault();
  };

  handlePageUpKey = (evt) => {
    const { target, altKey } = evt;
    if (altKey) {
      this.selectedYearOnChange({ target: { value: this.currentSelectedYear - 1 } });
    } else if (this.decrementMonth()) {
      const previousDate = getCompatableDateForPreviousMonth(new Date(target.dataset.dateObj));
      if (isDateASmallerThenDateB(previousDate, this.props.minDate)) this.render(this.props.minDate);
      else this.render(previousDate);
    }
    evt.preventDefault();
  };

  handleEnterKey = (evt) => {
    const dateObj = new Date(evt.target.dataset.dateObj);
    const formattedDate = formatDate(this.props.format, dateObj, this.props.lang);
    this.hide();
    if (this.props.trigger instanceof HTMLElement) this.props.trigger.focus();
    else if (this.props.field instanceof HTMLInputElement) {
      this.props.field.value = formattedDate;
    }
    this.props.onSelect(formattedDate, cloneDate(dateObj));
    evt.preventDefault();
  };

  handleMouseClick = ({ target }) => {
    let tdElm = target;
    if (tdElm.tagName.toLowerCase() === 'span') tdElm = target.parentNode;
    const dateObj = new Date(tdElm.dataset.dateObj);
    const formattedDate = formatDate(this.props.format, dateObj, this.props.lang);
    this.hide();
    if (this.props.trigger instanceof HTMLElement) this.props.trigger.focus();
    else if (this.props.field instanceof HTMLInputElement) {
      this.props.field.value = formattedDate;
    }
    this.props.onSelect(formattedDate, cloneDate(dateObj));
  };

  handleKeyDown = (evt) => {
    switch (evt.keyCode || evt.which) {
    case 27:
      this.hide();
      break;
    case 13:
      this.handleEnterKey(evt);
      break;
    case 32:
      this.handleEnterKey(evt);
      break;
    case 33: // Page Up Key
      this.handlePageUpKey(evt);
      break;
    case 34: // Page Down Key
      this.handlePageDownKey(evt);
      break;
    case 35: // Home key
      this.handleEndKey(evt);
      break;
    case 36: // Home key
      this.handleHomeKey(evt);
      break;
    case 37: // Left arrow
      this.handleLeftArrow(evt);
      break;
    case 38: // Up arrow
      this.handleUpArrow(evt);
      break;
    case 39: // Right arrow
      this.handleRightArrow(evt);
      break;
    case 40: // Down arrow
      this.handleDownArrow(evt);
      break;
    default:
      break;
    }
  };

  handleFieldFocus = () => {
    this.props.field.classList.add(styles.focus);
    this.show();
  };

  setupEventListeners = () => {
    this.elm.querySelector(datePickerControlsNextMonthButtonSelector).addEventListener('click', this.nextMonthButtonOnClick);
    this.elm.querySelector(datePickerControlsPreviousMonthButtonSelector).addEventListener('click', this.previousMonthButtonOnClick);
    this.elm.querySelector(datePickerControlsSelectMonthSelector).addEventListener('change', this.selectedMonthOnChange);
    this.elm.querySelector(datePickerControlsSelectYearSelector).addEventListener('change', this.selectedYearOnChange);
    this.elm.querySelector(datePickerGridBodySelector).addEventListener('keydown', this.handleKeyDown);
    this.elm.querySelector(datePickerGridBodySelector).addEventListener('click', this.handleMouseClick);
    const submitBtn = document.querySelector('#give-widget_ecard-message-submit');
    if (submitBtn) {
      submitBtn.addEventListener('click', this.hide);
    }
    if (this.props.trigger instanceof HTMLElement) {
      this.props.trigger.addEventListener('click', this.show);
    }
    if (this.props.field instanceof HTMLElement) {
      this.props.field.addEventListener('focus', this.handleFieldFocus);
    }
  };

  show = () => {
    const datePickerContainerElm = this.elm.querySelector(datePickerContainerSelector);
    datePickerContainerElm.classList.remove(styles.hidden);
    datePickerContainerElm.setAttribute('aria-hidden', 'false');
    this.elm.querySelector(datePickerCurrentlyFocusedDateSelector).focus();
  };

  hide = () => {
    const datePickerContainerElm = this.elm.querySelector(datePickerContainerSelector);
    datePickerContainerElm.classList.add(styles.hidden);
    datePickerContainerElm.setAttribute('aria-hidden', 'true');
    if (this.props.field && this.props.field.classList.contains(styles.focus)) this.props.field.classList.remove(styles.focus);
  };

  setFocusOnLastTabbableElement = (evt) => {
    const keyCode = evt.keyCode || evt.which;
    if (evt.shiftKey === true && keyCode === 9) {
      evt.preventDefault();
      this.cachedLastElement.focus();
    }
  };

  setFocusOnFirstTabbableElement = (evt) => {
    const keyCode = evt.keyCode || evt.which;
    if (evt.shiftKey === false && keyCode === 9) {
      evt.preventDefault();
      this.cachedFirstElement.focus();
    }
  };

  maintainFocus = (firstElement, lastElement) => {
    if (this.cachedFirstElement !== firstElement) {
      if (this.cachedFirstElement instanceof HTMLElement) {
        this.cachedFirstElement.removeEventListener('keydown', this.setFocusOnLastTabbableElement);
      }
      if (firstElement instanceof HTMLElement) {
        this.cachedFirstElement = firstElement;
        this.cachedFirstElement.addEventListener('keydown', this.setFocusOnLastTabbableElement);
      }
    }
    if (this.cachedLastElement !== lastElement) {
      if (this.cachedLastElement instanceof HTMLElement) {
        this.cachedLastElement.removeEventListener('keydown', this.setFocusOnFirstTabbableElement);
      }
      if (lastElement instanceof HTMLElement) {
        this.cachedLastElement = lastElement;
        this.cachedLastElement.addEventListener('keydown', this.setFocusOnFirstTabbableElement);
      }
    }
  };

  render = (dateToFocusOn = this.props.defaultDate) => {
    const nextButtonElm = this.elm.querySelector(datePickerControlsNextMonthButtonSelector);
    const prevButtonElm = this.elm.querySelector(datePickerControlsPreviousMonthButtonSelector);
    const monthSelectElm = this.elm.querySelector(datePickerControlsSelectMonthSelector);
    const yearSelectElm = this.elm.querySelector(datePickerControlsSelectYearSelector);
    const gridBodyElm = this.elm.querySelector(datePickerGridBodySelector);
    const nextButtonDisabled = shouldDisableNextMonthButton(this.props.maxDate, this.currentSelectedYear, this.currentSelectedMonth);
    const previousButtonDisabled = shouldDisablePreviousMonthButton(this.props.minDate, this.currentSelectedYear, this.currentSelectedMonth);
    const selectableMonths = getSelectableMonths(this.props.minDate, this.props.maxDate, this.currentSelectedYear, getMonthNames(this.props.lang));
    if (nextButtonElm === null && prevButtonElm === null && monthSelectElm === null && yearSelectElm === null && gridBodyElm === null) {
      this.elm.appendChild(createFragment(datePickerHTML(
        getYearsBetweenDatesInclusive(this.props.minDate, this.props.maxDate),
        selectableMonths,
        getDayNames(this.props.lang),
        chunk(
          getDatePickerGridValues(this.props.minDate, this.props.maxDate, this.currentSelectedYear, this.currentSelectedMonth, this.props.lang),
          7
        ),
        this.currentSelectedMonth,
        this.currentSelectedYear,
        dateToFocusOn,
        nextButtonDisabled,
        previousButtonDisabled,
        this.props.className
      )));
      this.setupEventListeners();
    } else {
      if (nextButtonDisabled)nextButtonElm.setAttribute('disabled', 'disabled');
      else nextButtonElm.removeAttribute('disabled');
      if (previousButtonDisabled) prevButtonElm.setAttribute('disabled', 'disabled');
      else prevButtonElm.removeAttribute('disabled');
      getChildren(monthSelectElm).forEach((elm, idx) => {
        const { isDisabled } = selectableMonths[idx];
        if (isDisabled) elm.setAttribute('disabled', 'disabled');
        else elm.removeAttribute('disabled');
      });
      monthSelectElm.value = this.currentSelectedMonth;
      yearSelectElm.value = this.currentSelectedYear;
      emptyNode(gridBodyElm);
      gridBodyElm.insertAdjacentHTML(
        'beforeend',
        datePickerBodyHTML(
          this.currentSelectedMonth,
          dateToFocusOn,
          chunk(
            getDatePickerGridValues(this.props.minDate, this.props.maxDate, this.currentSelectedYear, this.currentSelectedMonth, this.props.lang),
            7
          )
        )
      );
    }
    if (this.elm.querySelector(datePickerContainerSelector).classList.contains(styles.hidden) === false) {
      this.elm.querySelector(datePickerCurrentlyFocusedDateSelector).focus();
    }
    this.datePickerGridFocusableDateElements = Array.prototype.slice.call(this.elm.querySelectorAll(datePickerFocusableElements));
    this.maintainFocus(this.getFirstFocusableElement(), this.getLastFocusableElement());
  };

  destroy = () => {
    this.elm.querySelector(datePickerControlsNextMonthButtonSelector).removeEventListener('click', this.nextMonthButtonOnClick);
    this.elm.querySelector(datePickerControlsPreviousMonthButtonSelector).removeEventListener('click', this.previousMonthButtonOnClick);
    this.elm.querySelector(datePickerControlsSelectMonthSelector).removeEventListener('change', this.selectedMonthOnChange);
    this.elm.querySelector(datePickerControlsSelectYearSelector).removeEventListener('change', this.selectedYearOnChange);
    this.elm.querySelector(datePickerGridBodySelector).removeEventListener('keydown', this.handleKeyDown);
    this.elm.querySelector(datePickerGridBodySelector).removeEventListener('click', this.handleMouseClick);
    const submitBtn = document.querySelector('#give-widget_ecard-message-submit');
    if (submitBtn) {
      submitBtn.addEventListener('click', this.hide);
    }
    if (this.props.trigger instanceof HTMLElement) {
      this.props.trigger.removeEventListener('click', this.show);
    }
    if (this.props.field instanceof HTMLElement) {
      this.props.field.removeEventListener('focus', this.handleFieldFocus);
    }
    emptyNode(this.elm);
    this.datePickerGridFocusableDateElements = [];
  };
}
