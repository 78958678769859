import styles from './question_answers.css';

export const answerItem = ({ text = '', value = '', isDisabled = false } = {}) =>
  `<label ${isDisabled ? `class="${styles.disabled}"` : ''}>
    <input type="radio" ${isDisabled ? 'disabled="disabled"' : ''} value="${value}" autocomplete="off" 
    class="${styles['sr-only']}" tabindex="0" >${text}
  </label>`;

export const answers = (answersList) =>
  answersList.reduce((acc, item) => `${acc}<li>${answerItem(item)}</li>`, '');

export const answersLoading = (answersList = [1, 2, 3, 4]) => answersList.reduce((acc) => `${acc}<li></li>`, '');

const questionAnswerTemplate = (translateKeyFn) =>
  `<fieldset class="${styles.questionAnswer} ${styles.loading}" role="radiogroup" aria-labelledby="question-heading">
    <legend>
      <span class="${styles.question} ${styles.subheading}">
      </span>
      <span class="${styles.optional} ${styles.hidden}">(${translateKeyFn ? translateKeyFn('optional', 'Optional') : 'Optional'})</span>
    </legend>
    <ul class="${styles.radioButtons}">
    </ul>
  </fieldset>`;

export default questionAnswerTemplate;
