export const getFirstElementChild = (node) => {
  const childNodes = Array.prototype.slice.call(node.childNodes);
  for (let index = 0; index < childNodes.length; index += 1) {
    if (childNodes[index].nodeType === 1) {
      // 1 means its an element node
      return childNodes[index];
    }
  }
  return null;
};

/**
 *
 * @param {*} fn - function to memoize, this is a function that takes one arg
 */
export const memoize = (fn) => {
  const cache = {};
  return (arg) => {
    if (cache[arg]) return cache[arg];

    cache[arg] = fn.call(null, arg);
    return cache[arg];
  };
};
/**
 * We are memoizing slow functions, toLocaleString with options is pretty slow.
 */
export const getDayNamesShort = memoize((lang = 'en-US') => {
  const baseDate = new Date(2018, 8, 1); // this day is a saturday. some point of refrence
  return Array(7)
    .fill(0)
    .map(() => {
      baseDate.setDate(baseDate.getDate() + 1);
      return baseDate.toLocaleString(lang, { weekday: 'short' });
    });
});

export const getDayNamesLong = memoize((lang = 'en-US') => {
  const baseDate = new Date(2018, 8, 1); // this day is a monday. some point of refrence
  return Array(7)
    .fill(0)
    .map(() => {
      baseDate.setDate(baseDate.getDate() + 1);
      return baseDate.toLocaleString(lang, { weekday: 'long' });
    });
});

export const getDayNames = memoize((lang = 'en-US') => {
  const dayNamesShort = getDayNamesShort(lang);
  const dayNamesLong = getDayNamesLong(lang);
  return dayNamesShort.reduce((acc, dayNameShort, idx) => acc.concat({ short: dayNameShort, long: dayNamesLong[idx] }), []);
});

export const getMonthNamesShort = memoize((lang = 'en-US') => {
  const baseDate = new Date(2018, -1, 1);
  return Array(12)
    .fill(0)
    .map(() => {
      baseDate.setMonth(baseDate.getMonth() + 1);
      return baseDate.toLocaleString(lang, { month: 'short' });
    });
});

export const getMonthNamesLong = memoize((lang = 'en-US') => {
  const baseDate = new Date(2018, -1, 1);
  return Array(12)
    .fill(0)
    .map(() => {
      baseDate.setMonth(baseDate.getMonth() + 1);
      return baseDate.toLocaleString(lang, { month: 'long' });
    });
});

export const getMonthNames = memoize((lang = 'en-US') => {
  const monthNamesShort = getMonthNamesShort(lang);
  const monthNamesLong = getMonthNamesLong(lang);
  return monthNamesShort.reduce((acc, monthNameShort, idx) => acc.concat({ short: monthNameShort, long: monthNamesLong[idx] }), []);
});

export const padZero = (length, str) => {
  const typeCastedString = String(str);
  if (typeCastedString.length >= length) return typeCastedString;

  let temp = '';
  while (temp.length < length - typeCastedString.length) {
    temp += '0';
  }
  return temp + typeCastedString;
};

export const get = (date, formatCodeString, lang = 'en-US') => {
  switch (formatCodeString) {
  case 'yyyy':
    return date.getFullYear();
  case 'yy':
    return date
      .getFullYear()
      .toString()
      .slice(-2);
  case 'MM':
    return date.getMonth() + 1;
  case 'MMM':
    return getMonthNamesShort(lang)[date.getMonth()];
  case 'dd':
    return date.getDate();
  case 'EEE':
    return getDayNamesShort(lang)[date.getDay()];
  case 'EEEE':
    return getDayNamesLong(lang)[date.getDay()];
  case 'dayOfTheWeek':
    return date.getDay();
  default:
    return date.toLocaleString(lang);
  }
};

export const formatDate = (format, date, lang) => format
  .replace(/dd/g, padZero(2, get(date, 'dd')))
  .replace(/M{1,3}/g, (match) => (match === 'MMM' ? get(date, 'MMM', lang) : padZero(2, get(date, 'MM'))))
  .replace(/y{1,4}/g, (match) => (match === 'yyyy' ? get(date, 'yyyy') : get(date, 'yy')))
  .replace(/E{1,4}/g, (match) => (match === 'EEEE' ? get(date, 'EEEE', lang) : get(date, 'EEE', lang)));

/**
 *
 * @param {Date} dateA
 * @param {Date} dateB
 */
export const isDateAGreaterThenDateB = (dateA, dateB, useTime = false) => {
  const dateAMonth = get(dateA, 'MM');
  const dateAYear = get(dateA, 'yyyy');
  const dateADate = get(dateA, 'dd');
  const dateBMonth = get(dateB, 'MM');
  const dateBYear = get(dateB, 'yyyy');
  const dateBDate = get(dateB, 'dd');
  if (useTime === false) {
    return (
      dateAYear > dateBYear ||
      (dateAYear === dateBYear && dateAMonth > dateBMonth) ||
      (dateAYear === dateBYear && dateAMonth === dateBMonth && dateADate > dateBDate)
    );
  }
  return dateA.valueOf() > dateB.valueOf();
};

export const isDateASmallerThenDateB = (dateA, dateB, useTime = false) => {
  const dateAMonth = get(dateA, 'MM');
  const dateAYear = get(dateA, 'yyyy');
  const dateADate = get(dateA, 'dd');
  const dateBMonth = get(dateB, 'MM');
  const dateBYear = get(dateB, 'yyyy');
  const dateBDate = get(dateB, 'dd');
  if (useTime === false) {
    return (
      dateAYear < dateBYear ||
      (dateAYear === dateBYear && dateAMonth < dateBMonth) ||
      (dateAYear === dateBYear && dateAMonth === dateBMonth && dateADate < dateBDate)
    );
  }
  return dateA.valueOf() < dateB.valueOf();
};

export const isDateAEqualToDateB = (dateA, dateB, useTime = false) => {
  const dateAMonth = get(dateA, 'MM');
  const dateAYear = get(dateA, 'yyyy');
  const dateADate = get(dateA, 'dd');
  const dateBMonth = get(dateB, 'MM');
  const dateBYear = get(dateB, 'yyyy');
  const dateBDate = get(dateB, 'dd');
  if (useTime === false) {
    return dateAYear === dateBYear && dateAMonth === dateBMonth && dateADate === dateBDate;
  }
  return dateA.valueOf() === dateB.valueOf();
};

export const getCurrentDate = () => new Date();

export const cloneDate = (date) => new Date(date.getTime());

export const getYearsBetweenDatesInclusive = (minDate, maxDate) => {
  const minYear = get(minDate, 'yyyy');
  const maxYear = get(maxDate, 'yyyy');
  return Array(maxYear - minYear)
    .fill(0)
    .map((cur, index) => maxYear - index)
    .concat(minYear);
};

export const getRemainingDatesOfTheMonth = (year, month, day) => {
  const baseDate = new Date(year, month, day);
  const arr = [];
  while (baseDate.getMonth() === month) {
    arr.push(cloneDate(baseDate));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return arr;
};

export const getDatesForMonth = (year, month) => getRemainingDatesOfTheMonth(year, month, 1);

export const getCompatableDateForPreviousMonth = (date) => {
  let baseDate = cloneDate(date);
  baseDate.setMonth(baseDate.getMonth() - 1);
  if (date.getMonth() - baseDate.getMonth() !== 1) {
    baseDate = cloneDate(date);
    baseDate.setDate(0);
  }
  return baseDate;
};

export const getCompatableDateForNextMonth = (date) => {
  const baseDate = cloneDate(date);
  baseDate.setMonth(baseDate.getMonth() + 1);
  if (baseDate.getMonth() - date.getMonth() > 1) {
    baseDate.setDate(0);
  }
  return baseDate;
};

export const getCompatableDate = (year, month, date, minDate, maxDate) => {
  const mth = month - 1;
  const baseDate = new Date(year, mth, date);
  if (baseDate.getMonth() - mth > 0) {
    baseDate.setDate(0);
  }
  if (isDateAGreaterThenDateB(baseDate, maxDate)) return maxDate;
  else if (isDateASmallerThenDateB(baseDate, minDate)) return minDate;
  return baseDate;
};

export const moveDateByNDays = (date, nDays) => {
  const baseDate = cloneDate(date);
  baseDate.setDate(baseDate.getDate() + nDays);
  return baseDate;
};

export const moveDateByNYears = (date, nYears) => {
  const baseDate = cloneDate(date);
  baseDate.setFullYear(baseDate.getFullYear() + nYears);
  return baseDate;
};

export const getNextNDates = (dateObj, n) => {
  const baseDate = cloneDate(dateObj);
  return Array(n)
    .fill(0)
    .map(() => {
      baseDate.setDate(baseDate.getDate() + 1);
      return cloneDate(baseDate);
    });
};

export const getPreviousNDates = (dateObj, n) => {
  const baseDate = cloneDate(dateObj);
  return Array(n)
    .fill(0)
    .map(() => {
      baseDate.setDate(baseDate.getDate() - 1);
      return cloneDate(baseDate);
    })
    .reverse();
};

export const createFragment = (htmlString) => document.createRange().createContextualFragment(htmlString);

export const chunk = (arr, chunkSize) => arr.reduce((acc, item, index) => {
  const chunkIndex = Math.floor(index / chunkSize);
  acc[chunkIndex] = acc[chunkIndex] || [];
  acc[chunkIndex].push(item);
  return acc;
}, []);

export const getChildren = (node) => (node.hasChildNodes() ? Array.prototype.slice.call(node.childNodes).filter((elm) => elm.nodeType === 1) : []);

export const emptyNode = (node) => {
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }
};

export const getSelectableMonths = (minDate, maxDate, currentSelectedYear, monthNames = []) => {
  const minDateMonth = get(minDate, 'MM');
  const minDateYear = get(minDate, 'yyyy');
  const maxDateMonth = get(maxDate, 'MM');
  const maxDateYear = get(maxDate, 'yyyy');
  return monthNames.reduce((acc, { short, long }, idx) => {
    let isDisabled = false;
    if (
      minDateYear > currentSelectedYear ||
      (minDateYear === currentSelectedYear && minDateMonth > idx + 1) ||
      maxDateYear < currentSelectedYear ||
      (maxDateYear === currentSelectedYear && maxDateMonth < idx + 1)
    ) { isDisabled = true; }
    return acc.concat({
      short,
      long,
      isDisabled
    });
  }, []);
};

export const shouldDisableNextMonthButton = (maxDate, currentSelectedYear, currentSelectedMonth) => {
  const maxDateYear = get(maxDate, 'yyyy');
  const maxDateMonth = get(maxDate, 'MM');
  if (currentSelectedYear > maxDateYear || (currentSelectedYear === maxDateYear && currentSelectedMonth >= maxDateMonth)) {
    return true;
  }
  return false;
};

export const shouldDisablePreviousMonthButton = (minDate, currentSelectedYear, currentSelectedMonth) => {
  const minDateYear = get(minDate, 'yyyy');
  const minDateMonth = get(minDate, 'MM');
  if (currentSelectedYear < minDateYear || (minDateYear === currentSelectedYear && currentSelectedMonth <= minDateMonth)) {
    return true;
  }
  return false;
};

export const getDatePickerGridValues = (minDate, maxDate, currentSelectedYear, currentSelectedMonth, lang) => {
  const datesOfTheMonth = getDatesForMonth(currentSelectedYear, currentSelectedMonth - 1).reduce((acc, dateObj) => {
    const fmtDate = formatDate('dd, MMM, yyyy (EEEE)', dateObj, lang);
    const dayNumber = get(dateObj, 'dd');
    return acc.concat({
      dayNumber,
      formattedDate: fmtDate,
      isDisabled: isDateAGreaterThenDateB(dateObj, maxDate) || isDateASmallerThenDateB(dateObj, minDate),
      dateObj
    });
  }, []);
  const { dateObj: firstDateOfTheMonth } = datesOfTheMonth[0];
  const { dateObj: lastDateOfTheMonth } = datesOfTheMonth[datesOfTheMonth.length - 1];
  const amountToPadAtTheStart = firstDateOfTheMonth.getDay();
  const amountToPadAtTheEnd = 6 - lastDateOfTheMonth.getDay();
  const previousNDates = getPreviousNDates(firstDateOfTheMonth, amountToPadAtTheStart).reduce((acc, dateObj) => {
    const fmtDate = formatDate('dd, MMM, yyyy (EEEE)', dateObj, lang);
    const dayNumber = get(dateObj, 'dd');
    return acc.concat({
      dayNumber,
      formattedDate: fmtDate,
      isDisabled: isDateASmallerThenDateB(dateObj, minDate),
      dateObj
    });
  }, []);
  const nextNDates = getNextNDates(lastDateOfTheMonth, amountToPadAtTheEnd).reduce((acc, dateObj) => {
    const fmtDate = formatDate('dd, MMM, yyyy (EEEE)', dateObj, lang);
    const dayNumber = get(dateObj, 'dd');
    return acc.concat({
      dayNumber,
      formattedDate: fmtDate,
      isDisabled: isDateAGreaterThenDateB(dateObj, maxDate),
      dateObj
    });
  }, []);
  return previousNDates.concat(datesOfTheMonth).concat(nextNDates);
};
