import prismUiCss from '@octanner/prism-ui-css/prism.css';
import buttonTemplate from './button_template';
import { getFirstElementChild } from '../../utils/utils';
import { clientPrimaryBackground, clientPrimaryBorder } from '../../css/client_colors.js';
import styles from './button.css';

let initialListId = 0;

class Button {
  static generateId() {
    initialListId += 1;
    return `give-widget_button${initialListId - 1}`;
  }

  // Please dont modify this from outside the components
  defaultProps = {
    id: '',
    onClick: () => {},
    isSubmitButton: false,
    isDisabled: false,
    isHidden: false,
    isPrimary: true,
    isLoading: false,
    title: '',
    className: ''
  };

  set id(x) {
    const id = (x && `give-widget_${x}`) ||
      this.originalElementId ||
      Button.generateId();
    this.node.setAttribute('id', id);
    this.props.id = id;
  }

  get id() {
    return this.props.id;
  }

  set isSubmitButton(x) {
    this.props.isSubmitButton = x;
    if (x === true) this.node.setAttribute('type', 'submit');
    else this.node.setAttribute('type', 'button');
  }

  get isSubmitButton() {
    return this.props.isSubmitButton;
  }

  set isPrimary(x) {
    this.props.isPrimary = x;
    if (x) {
      this.node.classList.add(clientPrimaryBackground);
      this.node.classList.add(clientPrimaryBorder);
      this.node.classList.add(prismUiCss['psm-button--primary']);
    } else {
      this.node.classList.remove(clientPrimaryBackground);
      this.node.classList.remove(clientPrimaryBorder);
      this.node.classList.remove(prismUiCss['psm-button--primary']);
    }
  }

  set isDisabled(x) {
    if (x === 'disabled' || x === true) {
      this.node.setAttribute('disabled', 'disabled');
    } else if (x === false) {
      this.node.removeAttribute('disabled');
    }
    this.props.isDisabled = x;
  }

  get isDisabled() {
    return this.props.isDisabled;
  }

  set isHidden(isHidden) {
    this.props.isHidden = isHidden;
    if (this.props.isHidden === true) {
      this.node.classList.add(styles.hidden);
    } else {
      this.node.classList.remove(styles.hidden);
    }
  }

  set title(text) {
    let index = 0;
    for (index = 0; index < this.node.childNodes.length; index += 1) {
      if (this.node.childNodes[index].nodeType === 3) {
        this.node.replaceChild(document.createTextNode(text), this.node.childNodes[index]);
        break;
      }
    }
    if (index === this.node.childNodes.length) {
      const titleDiv = document.createElement('div');
      const titleText = document.createTextNode(text);
      titleDiv.appendChild(titleText);
      this.node.insertAdjacentElement('afterbegin', titleDiv);
    }
    this.node.setAttribute('title', text);
    this.props.title = text;
  }

  get title() {
    return this.props.title;
  }

  set className(x) {
    if (x !== '') {
      const arr = x.split(' ');
      arr.forEach((y) => {
        this.node.classList.add(y);
      });
      this.props.className = x;
    }
  }

  get className() {
    return this.props.className;
  }

  set onClick(x) {
    this.node.removeEventListener('click', this.props.onClick);
    this.node.addEventListener('click', x);
    this.props.onClick = x;
  }

  get onClick() {
    return this.props.onClick;
  }

  set isLoading(x) {
    this.props.isLoading = x;
    if (x) {
      this.title = '';
      this.node.classList.add(styles.dotsLoader);
      this.node.setAttribute('aria-busy', 'true');
    } else {
      this.title = this.originalTitle;
      this.node.classList.remove(styles.dotsLoader);
      this.node.setAttribute('aria-busy', 'false');
    }
  }

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
      this.originalElementId = this.node.id;
      this.originalTitle = '';
    } else {
      console.error(`${placeholderSelector} doesn't exist in document. Please pass a valid container selector to button component`);
    }
  }

  render(props) {
    const buttonFragment = document.createRange().createContextualFragment(buttonTemplate());

    const buttonTemp = this.node;
    this.node = getFirstElementChild(buttonFragment);
    this.node.classList.add('main-give-appreciate-button');
    this.props = { ...this.defaultProps, ...props };

    this.originalTitle = this.props.title;
    this.id = this.props.id;
    this.onClick = this.props.onClick;
    this.isSubmitButton = this.props.isSubmitButton;
    this.isDisabled = this.props.isDisabled;
    this.isHidden = this.props.isHidden;
    this.title = this.props.title;
    this.isPrimary = this.props.isPrimary;
    this.className = this.props.className;
    this.isLoading = this.props.isLoading;

    buttonTemp.parentNode.replaceChild(this.node, buttonTemp);
  }
}

export default Button;
