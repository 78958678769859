import { customizeNames } from '../../utils/utils';
import styles from './recipient_header.css';

export default function recipientHeaderTemplate(recipients, selectedRecognitionType, groupUploadCount, translateKeyFn) {
  let names,
    recipientText;
  if (recipients.length > 0) {
    names = customizeNames(recipients, translateKeyFn);
    recipientText = recipients.length > 1
      ? translateKeyFn('give-widget-recipients', 'Recipients:')
      : translateKeyFn('give-widget-recipient', 'Recipient:');
  } else {
    names = translateKeyFn('give-widget-recipient-header-bulk', '%{names} employees', '%{names}', groupUploadCount);
    recipientText = groupUploadCount > 1
      ? translateKeyFn('give-widget-recipients', 'Recipients:')
      : translateKeyFn('give-widget-recipient', 'Recipient:');
  }

  return `<div class="${styles.recipientHeader}" aria-label="${recipientText}_${selectedRecognitionType}">
    <div class="${styles.recipientDiv}" id="${recipientText}_${selectedRecognitionType}">
      <span class="${styles.recipientText}">${recipientText}</span> <span class="fs-mask ${styles.names}">${names}</span>
    </div>
    <a class="${styles.editButton}" id="give-widget-recipient-edit_${selectedRecognitionType}" tabindex="0">
      ${translateKeyFn ? translateKeyFn('edit', 'Edit') : 'Edit'}
    </a>
  </div>`;
}
