import autoCompleteTemplate from './autocomplete_template';
import KeyCodes from '../../utils/keycodes';
import emptyElement, { getFirstElementChild } from '../../utils/utils';
import styles from './autocomplete.css';

let initialAutoCompleteId = 0;

class AutoComplete {
  static generateId() {
    initialAutoCompleteId += 1;
    return `autoComplete${initialAutoCompleteId - 1}`;
  }

  defaultProps = {
    id: AutoComplete.generateId(),
    isDisabled: false,
    isHidden: false,
    delay: 350,
    minCharacters: 3,
    placeholder: '',
    labelledby: '',
    /**
     *  -1 cache means not to cache return of filter Results
     *  1 cache means cache return of filter Results
     */
    cache: 1,
    /**
     * Callback that is triggered when my min characters and delay are met
     * must always return a promise
     */
    source: (e) => Promise.resolve(e),
    /**
     * Callback that is triggered on the results returned by source callback
     */
    transformResults: (e) => e,
    /**
     * Callback that is triggered on the results returned by transform results callback
     * Its is used to when user wants to remove certain results
     */
    filterResults: (e) => e,
    /**
     * Callback that is triggered on the result of filterResults
     */
    customRenderer: () => {},
    rendererId: '',
    handleKeyDown: () => {},
    valueCleared: () => {},
    minCharactersCallback: () => {}
  };

  set id(x) {
    this.props.id = x;
    this.node.setAttribute('id', x);
    this.autoCompleteInputNode.setAttribute('id', `${x}_input`);
  }

  get id() {
    return this.props.id;
  }

  set delay(x) {
    this.props.delay = x;
  }

  get delay() {
    return this.props.delay;
  }

  set minCharacters(x) {
    this.props.minCharacters = x;
  }

  get minCharacters() {
    return this.props.minCharacters;
  }

  set cache(x) {
    this.props.cache = x;
  }

  get cache() {
    return this.props.cache;
  }

  set source(x) {
    this.props.source = x;
  }

  get source() {
    return this.props.source;
  }

  set transformResults(x) {
    this.props.transformResults = x;
  }

  get transformResults() {
    return this.props.transformResults;
  }

  set filterResults(x) {
    this.props.filterResults = x;
  }

  get filterResults() {
    return this.props.filterResults;
  }

  set customRenderer(x) {
    this.props.customRenderer = x;
  }

  get customRenderer() {
    return this.props.customRenderer;
  }

  set minCharactersCallback(x) {
    this.props.minCharactersCallback = x;
  }

  get minCharactersCallback() {
    return this.props.minCharactersCallback;
  }

  set rendererId(x) {
    this.props.rendererId = x;
    this.autoCompleteInputNode.setAttribute('aria-owns', this.props.rendererId);
    this.autoCompleteInputNode.setAttribute('aria-controls', this.props.rendererId);
  }

  get rendererId() {
    return this.props.rendererId;
  }

  set handleKeyDown(x) {
    this.props.handleKeyDown = x;
  }

  get handleKeyDown() {
    return this.props.handleKeyDown;
  }

  set valueCleared(x) {
    this.props.valueCleared = x;
  }

  get valueCleared() {
    return this.props.valueCleared;
  }

  set placeholder(x) {
    this.autoCompleteInputNode.setAttribute('placeholder', x);
    this.autoCompleteInputNode.setAttribute('aria-label', x);
    if (x && x !== '') {
      this.autoCompleteInputNode.setAttribute('size', x.length);
    }
    this.autoCompleteInputNode.setAttribute('title', x);
    this.props.placeholder = x;
  }

  get placeholder() {
    return this.props.placeholder;
  }

  set labelledby(x) {
    this.props.labelledby = x;
    if (x && x !== '') {
      this.autoCompleteInputNode.setAttribute('aria-labelledby', x);
    } else {
      this.autoCompleteInputNode.removeAttribute('aria-labelledby');
    }
  }

  get labelledby() {
    return this.props.labelledby;
  }

  set isDisabled(x) {
    this.props.isDisabled = !!x;
    if (this.props.isDisabled) {
      this.autoCompleteInputNode.setAttribute('disabled', 'disabled');
    } else {
      this.autoCompleteInputNode.removeAttribute('disabled');
    }
  }

  get isDisabled() {
    return this.props.isDisabled;
  }

  set isHidden(x) {
    this.props.isHidden = x;
    if (x) {
      this.node.classList.add(styles.hidden);
    } else {
      this.node.classList.remove(styles.hidden);
    }
  }

  constructor(placeholderSelector) {
    if (document.querySelector(placeholderSelector) !== null) {
      this.node = document.querySelector(placeholderSelector);
    } else {
      console.error(`${placeholderSelector} doesn't exist in document, Please pass a valid container selector to autocomplete component`);
    }
  }

  handleKeyUp = (() => {
    /**
     * The function returns another function. When the keycode is other then home (36),
     * left arrow(37), up arrow(38), right arrow(39), down arrow(40), enter(13), shift(16),
     * ctrl(17), alt(18), pause/break(19), caps lock(20) we check to see if the current value
     *  is different from stored previous value. If it is we start a new timer and after the
     * timer is done we execute the source function. Here we also check for our input value to
     * be greater than min chars.
     */
    let prevValue,
      latestNonEmptyValue,
      timer;
    const cache = {};
    return (evt) => {
      const keyCode = evt.keyCode || evt.which;

      if (
        !keyCode ||
        ((keyCode < 35 || keyCode > 40) &&
          keyCode !== 13 &&
          keyCode !== 27 &&
          keyCode !== 9 &&
          keyCode !== 16 &&
          keyCode !== 17 &&
          keyCode !== 18 &&
          keyCode !== 19 &&
          keyCode !== 20)
      ) {
        const val = evt.target.value;
        emptyElement(this.ariaSuggestionsNode);
        if (val.length >= this.props.minCharacters && val !== prevValue) {
          prevValue = val;
          clearTimeout(timer);
          if (this.props.cache === 1 && Object.prototype.hasOwnProperty.call(cache, val)) {
            const result = cache[val];
            let message = '';
            emptyElement(this.ariaSuggestionsNode);
            if (keyCode === 8) {
              const s = String.fromCharCode(8);
              message = `${result.length} search results found. Use up and down arrow keys to navigate
              through the results`;
              const updatedMessage = s + message;
              this.ariaSuggestionsNode.textContent = updatedMessage;
            } else {
              if (result.length === 0) {
                message = 'zero search results found.';
              } else {
                message = `${result.length} search results found. Use up and down arrow keys to navigate 
                through the results.`;
              }
              this.ariaSuggestionsNode.textContent = message;
            }
            this.ariaSuggestionsNode.setAttribute('aria-live', 'polite');
            const transform1 = this.props.transformResults(result);
            const transform2 = this.props.filterResults(transform1);
            this.props.customRenderer(val, transform2);
          } else {
            timer = setTimeout(() => {
              this.props
                .source(val)
                .then(
                  (res) => {
                    let result = res;
                    if (evt.target.value === val && res.length === 0) {
                      const hasShortTokens = val.split(' ').some((v) => v.length < this.props.minCharacters);
                      if (hasShortTokens) {
                        // new elastic search algo returns empty results if any token is less than 3 characters,
                        // so we want to continue displaying the results that are already displaying
                        const withoutShortTokens = val.split(' ').filter((v) => v.length >= this.props.minCharacters).join(' ');
                        result = cache[withoutShortTokens] || cache[latestNonEmptyValue];
                      } else {
                        latestNonEmptyValue = null;
                      }
                    } else {
                      latestNonEmptyValue = val;
                    }
                    cache[val] = result;
                    if (evt.target.value === val) {
                      emptyElement(this.ariaSuggestionsNode);
                      if (result.length === 0) {
                        this.ariaSuggestionsNode
                          .appendChild(document.createTextNode('zero search results found.'));
                      } else {
                        this.ariaSuggestionsNode
                          .appendChild(document.createTextNode(`${result.length} search results found. Use up and down arrow keys to navigate 
                        through the results.`));
                      }
                      const transform1 = this.props.transformResults(result);
                      const transform2 = this.props.filterResults(transform1);
                      this.props.customRenderer(val, transform2);
                    }
                  },
                  (err) => {
                    // todo need to solve when there are errors
                    console.error(err);
                  }
                );
            }, this.props.delay);
          }
        } else {
          if (val.length === 0) {
            clearTimeout(timer);
            if (keyCode === KeyCodes.Backspace) this.props.valueCleared();
          } else if (val.length > 0 && val.length < this.props.minCharacters) {
            clearTimeout(timer);
            emptyElement(this.ariaSuggestionsNode);
            this.ariaSuggestionsNode
              .appendChild(document.createTextNode(`Please enter at least ${this.minCharacters} characters to search.`));
            this.props.minCharactersCallback();
          }
          prevValue = val;
        }
      }
    };
  })();

  blur() {
    this.autoCompleteInputNode.blur();
  }

  focus() {
    this.autoCompleteInputNode.focus();
  }

  clear() {
    this.autoCompleteInputNode.value = '';
  }

  getValue() {
    return this.autoCompleteInputNode.value;
  }

  render(props) {
    const AutoCompleteFragment = document
      .createRange()
      .createContextualFragment(autoCompleteTemplate());

    const autoCompleteTemp = this.node;

    this.node = getFirstElementChild(AutoCompleteFragment);
    this.autoCompleteInputNode = this.node.querySelector('input');
    this.ariaSuggestionsNode = this.node.querySelector('p');

    this.props = { ...this.defaultProps, ...props };
    this.id = this.props.id;
    this.delay = this.props.delay;
    this.minCharacters = this.props.minCharacters;
    this.placeholder = this.props.placeholder;
    this.labelledby = this.props.labelledby;
    this.cache = this.props.cache;
    this.source = this.props.source;
    this.transformResults = this.props.transformResults;
    this.filterResults = this.props.filterResults;
    this.customRenderer = this.props.customRenderer;
    this.rendererId = this.props.rendererId;
    this.handleKeyDown = this.props.handleKeyDown;
    this.valueCleared = this.props.valueCleared;
    this.minCharactersCallback = this.props.minCharactersCallback;
    this.isDisabled = this.props.isDisabled;
    this.isHidden = this.props.isHidden;

    this.autoCompleteInputNode.setAttribute('id', `give-widget_${this.id}_input`);
    this.autoCompleteInputNode.addEventListener('focus', this.handleKeyUp);
    this.autoCompleteInputNode.addEventListener('keyup', this.handleKeyUp);
    this.autoCompleteInputNode.addEventListener('keydown', (evt) => {
      if (evt.keyCode === 13 || evt.which === 13) {
        evt.preventDefault();
        evt.stopPropagation();
      }
      const currentActiveElm = this.props.handleKeyDown(evt)?.toString();
      const ariaActiveDescendant = (currentActiveElm !== '-1' && currentActiveElm !== undefined && evt.keyCode !== 13)
        ? currentActiveElm : '';
      const { autoCompleteInputNode } = this;
      autoCompleteInputNode.setAttribute('aria-activedescendant', ariaActiveDescendant);
    });

    autoCompleteTemp.parentNode.replaceChild(this.node, autoCompleteTemp);
  }
}

export default AutoComplete;
