import styles from './list.css';
import getImage from '../common_templates/avatar_template';

export const listItemTemplate = ({
  fullName, imageSrc, initials, departmentName, managerName, systemUserId, jobTitle, workLocation, showJobTitleAndWorkLocation
} = {}, translateKeyFn, index) => {
  const itemLabelId = `search-list-item-label-${index}`;
  const workLocationStyle = (showJobTitleAndWorkLocation) ? styles.workLocation : styles.oldWorkLocation;
  const jobTitleStyle = (showJobTitleAndWorkLocation) ? styles.jobTitle : styles.oldJobTitle;
  const managerNameStyle = (showJobTitleAndWorkLocation) ? styles.managerName : styles.oldManagerName;

  return `
    <li role="option" id="search-list-item-${index}" class="${styles.item}" data-item-id="${systemUserId}" tabindex="0" 
    aria-labelledby="${itemLabelId} subheading1-${index} subheading2-${index} subheading3-${index} subheading4-${index}">
      ${getImage(imageSrc, initials, fullName)}
      <div class="${styles.mainContent}">
        <p class="${styles.fullName}" id="${itemLabelId}">${fullName}</p>
        ${!jobTitle ? '' : `<p class="${jobTitleStyle}" id="subheading1-${index}">${jobTitle}</p>`}
        ${!workLocation ? '' : `<p class="${workLocationStyle}" id="subheading2-${index}">${workLocation}</p>`}
        ${managerName === '' ? '' : `<p class="${managerNameStyle}" id="subheading3-${index}">${translateKeyFn('manager', 'manager')}:
         ${managerName}</p>`}
        <p class="${styles.departmentName}" id="subheading4-${index}">${departmentName}</p>
      </div>
    </li>`;
};

export const listItemTemplateLoading = () => `
  <li class="${styles.item} ${styles.loadingShimmer} ${styles.loading}">
    <div class="${styles.circularInitials}"></div>
    <div class="${styles.mainContent}">
      <p class="${styles.fullName}"></p>
      <p class="${styles.managerName}"></p>
      <p class="${styles.departmentName}"></p>
    </div>
  </li>`;

const listTemplate = () => `
  <div class="${styles.wrapper}">
    <div aria-live="polite">
      <p class="${styles.noDataMessage} ${styles.hidden}"></p>
    </div>
    <ul role="listbox" class="fs-mask ${styles.list}" aria-live="off"></ul>
  </div>`;

export default listTemplate;
